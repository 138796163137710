import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  TextField,
  useTheme,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery
} from '@mui/material';
import { Check } from 'lucide-react';

interface ResidentTurnDialogProps {
  open: boolean;
  onClose: () => void;
  onResolve: (data: {
    turnSide: string;
    assistanceType: string;
    slidingSheetUsed: boolean;
    concerns: string;
  }) => void;
}

const turnSides = ['Left', 'Right'];
const assistanceTypes = ['One Person', 'Two Person'];

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius * 2,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(4)})`,
      maxWidth: 'none'
    }
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(2),
    fontSize: '1.25rem',
    fontWeight: 'bold'
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    }
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2)
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  color: theme.palette.text.primary
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  },
  transition: theme.transitions.create(['background-color', 'color'], {
    duration: theme.transitions.duration.short
  })
}));

const ResidentTurnDialog: React.FC<ResidentTurnDialogProps> = ({
  open,
  onClose,
  onResolve
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedTurnSide, setSelectedTurnSide] = useState('');
  const [selectedAssistanceType, setSelectedAssistanceType] = useState('');
  const [slidingSheetUsed, setSlidingSheetUsed] = useState(false);
  const [concerns, setConcerns] = useState('');

  const handleResolve = () => {
    if (selectedTurnSide && selectedAssistanceType) {
      onResolve({
        turnSide: selectedTurnSide,
        assistanceType: selectedAssistanceType,
        slidingSheetUsed,
        concerns
      });
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Resident Turn Assessment</DialogTitle>
      <DialogContent>
        <SectionTitle variant="h6">Turn Side</SectionTitle>
        <ToggleButtonGroup
          value={selectedTurnSide}
          exclusive
          onChange={(_, value) => setSelectedTurnSide(value)}
          aria-label="turn side"
          orientation={isMobile ? 'vertical' : 'horizontal'}
          fullWidth={isMobile}
        >
          {turnSides.map((side) => (
            <StyledToggleButton key={side} value={side}>
              {side}
            </StyledToggleButton>
          ))}
        </ToggleButtonGroup>

        <SectionTitle variant="h6">Assistance Type</SectionTitle>
        <ToggleButtonGroup
          value={selectedAssistanceType}
          exclusive
          onChange={(_, value) => setSelectedAssistanceType(value)}
          aria-label="assistance type"
          orientation={isMobile ? 'vertical' : 'horizontal'}
          fullWidth={isMobile}
        >
          {assistanceTypes.map((type) => (
            <StyledToggleButton key={type} value={type}>
              {type}
            </StyledToggleButton>
          ))}
        </ToggleButtonGroup>

        <SectionTitle variant="h6">Additional Equipment</SectionTitle>
        <StyledToggleButton
          value="check"
          selected={slidingSheetUsed}
          onChange={() => setSlidingSheetUsed(!slidingSheetUsed)}
          fullWidth={isMobile}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {slidingSheetUsed && (
              <Check size={16} style={{ marginRight: theme.spacing(1) }} />
            )}
            Sliding Sheet Used
          </Box>
        </StyledToggleButton>

        <SectionTitle variant="h6">Concerns</SectionTitle>
        <TextField
          label="Any concerns or observations"
          multiline
          rows={4}
          fullWidth
          value={concerns}
          onChange={(e) => setConcerns(e.target.value)}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleResolve}
          color="primary"
          variant="contained"
          disabled={!selectedTurnSide || !selectedAssistanceType}
        >
          Submit Assessment
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default ResidentTurnDialog;

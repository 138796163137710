// invoices.ts
import { baseApi } from './base';

export const permissionsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllPermissions: builder.query({
      query: () => '/permissions'
    }),
    updatePermisions: builder.mutation({
      query: (data: { permissions: string[]; userId: string }) => ({
        url: '/permissions',
        method: 'PATCH',
        body: data
      })
    })
  })
});

export const { useGetAllPermissionsQuery, useUpdatePermisionsMutation } =
  permissionsApi;

import { useEffect, useRef, useState } from 'react';

const MAX_RECONNECT_ATTEMPTS = 5;
const RECONNECT_INTERVAL = 5000; // 5 seconds

export const useWebSocketConnection = (
  currentUser,
  currentOrganization,
  wsHostname
) => {
  const wsRef = useRef(null);
  const reconnectAttempts = useRef(0);
  const [wsConnected, setWsConnected] = useState(false);

  const connectWebSocket = () => {
    wsRef.current = new WebSocket(
      `${wsHostname}/ws-tasks?userId=${currentUser._id}&orgId=${currentOrganization?._id}`
    );

    wsRef.current.onopen = () => {
      console.log('WebSocket connection established');
      setWsConnected(true);
      reconnectAttempts.current = 0;
    };

    wsRef.current.onclose = () => {
      console.log('WebSocket connection closed');
      setWsConnected(false);
      attemptReconnect();
    };

    return wsRef.current;
  };

  const attemptReconnect = () => {
    if (reconnectAttempts.current < MAX_RECONNECT_ATTEMPTS) {
      reconnectAttempts.current += 1;
      console.log(
        `Attempting to reconnect (${reconnectAttempts.current}/${MAX_RECONNECT_ATTEMPTS})...`
      );
      setTimeout(connectWebSocket, RECONNECT_INTERVAL);
    } else {
      console.log(
        'Max reconnection attempts reached. Please check your connection and refresh the page.'
      );
    }
  };

  useEffect(() => {
    const ws = connectWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [currentUser._id, currentOrganization?._id]);

  return { wsRef, wsConnected };
};

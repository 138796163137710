// tasks.ts
import { Task } from 'src/interfaces/tasks';
import { baseApi } from './base';

export const tasksApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUpcomingTasks: builder.query({
      query: (residentId) => `residents/${residentId}/tasks/upcoming`
    }),
    getPendingTasks: builder.query({
      query: (residentId) => `residents/${residentId}/tasks/pending`
    }),
    completeTask: builder.mutation({
      query: (task) => ({
        url: `residents/tasks/${task.taskId}/complete`,
        method: 'PUT',
        body: task
      }),
      transformResponse: (response: { data: Task }) => response.data,
      invalidatesTags: (result, error, task) => [
        { type: 'Task', id: task._id },
        { type: 'Resident', id: task.residentId }
      ],
    }),
    createTask: builder.mutation({
      query: (newTask) => ({
        url: 'residents/tasks',
        method: 'POST',
        body: newTask
      })
    }),
    createMultipleTasks: builder.mutation({
      query: (newTasks) => ({
        url: 'residents/multiple-tasks',
        method: 'POST',
        body: newTasks
      })
    })
  })
});

export const {
  useGetUpcomingTasksQuery,
  useGetPendingTasksQuery,
  useCompleteTaskMutation,
  useCreateTaskMutation,
  useCreateMultipleTasksMutation,
} = tasksApi;
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Switch,
  FormControlLabel,
  Chip
} from '@mui/material';
import { DesktopTimePicker as TimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  useCreateTaskMutation,
  useCreateMultipleTasksMutation
} from 'src/redux/@api/task';
import { taskTypes } from '../tabs/task-util';
import dayjs, { Dayjs } from 'dayjs';
import { MobileTimePicker } from '@mui/lab';

interface AddTaskDialogProps {
  open: boolean;
  onClose: () => void;
  onTaskAdded: () => void;
  residentId: string;
}

interface NewTask {
  taskName: string;
  description: string;
  taskType: string;
  frequency: {
    type: 'daily' | 'weekly' | 'monthly';
    timeOfDay: string;
  };
  status: 'upcoming';
}

const AddTaskDialog: React.FC<AddTaskDialogProps> = ({
  open,
  onClose,
  onTaskAdded,
  residentId
}) => {
  const [createTask] = useCreateTaskMutation();
  const [createMultipleTasks] = useCreateMultipleTasksMutation();
  const [newTask, setNewTask] = useState<NewTask>({
    taskName: '',
    description: '',
    taskType: '',
    frequency: { type: 'daily', timeOfDay: '' },
    status: 'upcoming'
  });
  const [useTimeInterval, setUseTimeInterval] = useState(false);
  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);
  const [intervalHours, setIntervalHours] = useState(1);
  const [selectedTimes, setSelectedTimes] = useState<string[]>([]);
  const [tempSelectedTime, setTempSelectedTime] = useState<Dayjs | null>(null);

  const handleAddTask = async () => {
    try {
      const tasksToCreate = useTimeInterval
        ? createTasksFromInterval()
        : createTasksFromSelectedTimes();

      if (tasksToCreate.length > 1) {
        await createMultipleTasks(tasksToCreate).unwrap();
      } else if (tasksToCreate.length === 1) {
        await createTask(tasksToCreate[0]).unwrap();
      }

      onTaskAdded();
      resetForm();
      onClose();
    } catch (error) {
      console.error('Error adding task(s):', error);
    }
  };

  const createTasksFromInterval = () => {
    const tasks = [];
    let currentTime = startTime;
    while (
      (currentTime && endTime && currentTime.isBefore(endTime)) ||
      currentTime?.isSame(endTime)
    ) {
      tasks.push({
        ...newTask,
        resident: residentId,
        frequency: {
          ...newTask.frequency,
          timeOfDay: currentTime.format('HH:mm')
        }
      });
      currentTime = currentTime.add(intervalHours, 'hour');
    }
    return tasks;
  };

  const createTasksFromSelectedTimes = () => {
    return selectedTimes.map((time) => ({
      ...newTask,
      resident: residentId,
      frequency: {
        ...newTask.frequency,
        timeOfDay: time
      }
    }));
  };

  const resetForm = () => {
    setNewTask({
      taskName: '',
      description: '',
      taskType: '',
      frequency: { type: 'daily', timeOfDay: '' },
      status: 'upcoming'
    });
    setSelectedTimes([]);
    setUseTimeInterval(false);
    setStartTime(null);
    setEndTime(null);
    setIntervalHours(1);
  };

  const handleAddTime = () => {
    if (tempSelectedTime) {
      const timeString = tempSelectedTime.format('HH:mm');
      if (!selectedTimes.includes(timeString)) {
        setSelectedTimes([...selectedTimes, timeString]);
      }
      setTempSelectedTime(null);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Add Task</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <InputLabel>Task Type</InputLabel>
            <Select
              value={newTask.taskType}
              onChange={(e) =>
                setNewTask({ ...newTask, taskType: e.target.value as string })
              }
            >
              {taskTypes.map((type) => (
                <MenuItem key={type.type} value={type.type}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {type.icon}
                    <Typography sx={{ ml: 1 }}>{type.type}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Task Name"
            fullWidth
            value={newTask.taskName}
            onChange={(e) =>
              setNewTask({ ...newTask, taskName: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            multiline
            rows={2}
            value={newTask.description}
            onChange={(e) =>
              setNewTask({ ...newTask, description: e.target.value })
            }
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Frequency</InputLabel>
            <Select
              value={newTask.frequency.type}
              onChange={(e) =>
                setNewTask({
                  ...newTask,
                  frequency: {
                    ...newTask.frequency,
                    type: e.target.value as 'daily' | 'weekly' | 'monthly'
                  }
                })
              }
            >
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
            </Select>
          </FormControl>
          {newTask.frequency.type === 'daily' && (
            <Box sx={{ mt: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={useTimeInterval}
                    onChange={(e) => setUseTimeInterval(e.target.checked)}
                  />
                }
                label="Use Time Interval"
              />
              {useTimeInterval ? (
                <>
                  <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                    <TimePicker
                      label="Start Time"
                      value={startTime}
                      onChange={(newValue) => setStartTime(newValue)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    <TimePicker
                      label="End Time"
                      value={endTime}
                      onChange={(newValue) => setEndTime(newValue)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Box>
                  <TextField
                    margin="dense"
                    label="Interval (hours)"
                    type="number"
                    fullWidth
                    value={intervalHours}
                    onChange={(e) => setIntervalHours(Number(e.target.value))}
                    InputProps={{ inputProps: { min: 1, max: 24 } }}
                  />
                </>
              ) : (
                <>
                  <Typography variant="subtitle1">Task Times</Typography>
                  {selectedTimes.map((time, index) => (
                    <Chip
                      key={index}
                      label={time}
                      onDelete={() => {
                        const newTimes = [...selectedTimes];
                        newTimes.splice(index, 1);
                        setSelectedTimes(newTimes);
                      }}
                      sx={{ mr: 1, mb: 1 }}
                    />
                  ))}
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <MobileTimePicker
                      label="Select Time"
                      views={['hours', 'minutes']}
                      value={tempSelectedTime}
                      onChange={(newValue) => setTempSelectedTime(newValue)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    <Button
                      onClick={handleAddTime}
                      variant="contained"
                      sx={{ ml: 1, mb: 1 }}
                      disabled={!tempSelectedTime}
                    >
                      Add
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={handleAddTask}
            variant="contained"
            color="primary"
            disabled={
              !newTask.taskName ||
              !newTask.taskType ||
              (useTimeInterval && (!startTime || !endTime)) ||
              (!useTimeInterval && selectedTimes.length === 0)
            }
          >
            Add Task{useTimeInterval || selectedTimes.length > 1 ? 's' : ''}
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default AddTaskDialog;

import React from 'react';
import { Grid, Typography, Box, Badge } from '@mui/material';
import moment from 'moment';
import { DayCell, ShiftAvatar } from './styles';
import { Shift } from './types';
import { CheckCircle, AlertCircle, Users } from 'lucide-react';

interface CalendarDayProps {
  day: moment.Moment;
  isCurrentMonth: boolean;
  shifts: Shift[];
  onClick: () => void;
  setSelectedDate: (date: moment.Moment) => void;
  isMobile: boolean;
}

const CalendarDay: React.FC<CalendarDayProps> = ({
  day,
  isCurrentMonth,
  shifts,
  onClick,
  setSelectedDate,
  isMobile
}) => {
  const isToday = day.isSame(moment(), 'day');

  const getShiftColor = (shiftName: string): string => {
    let hash = 0;
    for (let i = 0; i < shiftName.length; i++) {
      hash = shiftName.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = hash % 20;
    return `hsl(${hue}, 100%, 70%)`;
  };

  const renderShiftAvatars = () => {
    return shifts.slice(0, 2).map((shift, index) => (
      <ShiftAvatar
        key={shift._id}
        style={{
          backgroundColor: getShiftColor(shift.shiftPattern.name),
          boxShadow: `0 0 0 2px 3px gray`,
          zIndex: index,
          fontSize: '0.65rem'
        }}
      >
        {shift.shiftPattern.name.substring(0, 2).toUpperCase()}
      </ShiftAvatar>
    ));
  };

  const totalShifts = shifts.reduce((acc, shift) => acc + shift.count, 0);

  const acceptedShifts = shifts
    .filter((shift) => shift.isAccepted)
    .reduce((acc, shift) => acc + shift.count, 0);
  const completedShifts = shifts
    .filter((shift) => shift.isCompleted)
    .reduce((acc, shift) => acc + shift.count, 0);
  const doneShifts = shifts
    .filter((shift) => shift.isDone)
    .reduce((acc, shift) => acc + shift.count, 0);

  const getStatusIcon = () => {
    if (doneShifts === totalShifts) {
      return <Users size={19} color="green" />;
    } else if (completedShifts === totalShifts) {
      return <CheckCircle size={19} color="green" />;
    } else if (acceptedShifts > 0) {
      return <AlertCircle size={19} color="orange" />;
    }
    return null;
  };

  return (
    <Grid item xs={12 / 7}>
      <DayCell
        isCurrentMonth={isCurrentMonth}
        isToday={isToday}
        onClick={(e) => {
          e.stopPropagation();
          console.log('Clicked date:', day.format('YYYY-MM-DD'));
          setSelectedDate(day);
          onClick();
        }}
      >
        <Typography variant="subtitle2">{day.date()}</Typography>
        {isMobile ? (
          totalShifts > 0 && (
            <Badge
              badgeContent={totalShifts}
              color="primary"
              sx={{
                '& .MuiBadge-badge': {
                  position: 'absolute',
                  right: '-1rem',
                  top: '-1.5rem',
                  padding: '0 4px',
                  color: 'white',
                  boxShadow: '0.5px 0px 0px 0.1px rgba(0,0,0,0.2)'
                }
              }}
            >
              <Box />
            </Badge>
          )
        ) : (
          <>
            <Box display="flex" mt={1}>
              {renderShiftAvatars()}
            </Box>
            {totalShifts > 0 && (
              <Typography variant="caption">
                {totalShifts} shift{totalShifts > 1 ? 's' : ''}
              </Typography>
            )}
            {totalShifts > 0 && <Box ml="auto">{getStatusIcon()}</Box>}
          </>
        )}
      </DayCell>
    </Grid>
  );
};

export default CalendarDay;

import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  List,
  styled,
  TextField
} from '@mui/material';

// Common Dialog Styling
export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {},
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2)
  },
  borderRadius: '20px',
  '& .MuiPaper-root': {
    borderRadius: '20px'
  }
}));

export const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap'
});

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: '30%'
  }
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  minWidth: 'auto',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    minWidth: 400
  }
}));

export const SelectedShiftsContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  minHeight: 100,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  overflowY: 'auto',
  maxHeight: '200px',
  marginTop: theme.spacing(2)
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5)
}));

export const SearchField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

export const StaffList = styled(List)(({ theme }) => ({
  maxHeight: 200,
  overflow: 'auto',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius
}));

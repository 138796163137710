import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Grid, Card, CardContent, Typography, useTheme } from '@mui/material';
import { ApexOptions } from 'apexcharts';

interface MealAnalysisProps {
  mealAnalysis: {
    mealTypeDistribution: Record<string, number>;
    averageAmountEaten: number;
    averageFluidIntake: number;
  };
  mealProgressData: Array<{
    date: string;
    amountEaten: number;
    drinkAmount: number;
  }>;
}

const MealAnalysis: React.FC<MealAnalysisProps> = ({
  mealAnalysis,
  mealProgressData
}) => {
  const theme = useTheme();
  const { mealTypeDistribution, averageAmountEaten, averageFluidIntake } =
    mealAnalysis;

  const mealTypePieData = Object.entries(mealTypeDistribution).map(
    ([name, value]) => ({ name, value })
  );

  const lineChartOptions: ApexOptions = {
    chart: {
      type: 'line',
      toolbar: {
        show: false
      }
    },
    stroke: {
      curve: 'smooth',
      width: 3
    },
    xaxis: {
      type: 'category',
      categories: mealProgressData.map((item) => item.date),
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: theme.typography.fontFamily
        }
      }
    },
    yaxis: {
      title: {
        text: 'Amount Eaten (%)',
        style: {
          fontSize: '14px',
          fontFamily: theme.typography.fontFamily
        }
      },
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: theme.typography.fontFamily
        }
      }
    },
    colors: ['#008FFB'],
    tooltip: {
      theme: theme.palette.mode
    },
    grid: {
      borderColor: theme.palette.divider
    }
  };

  const barChartOptions: ApexOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: '60%'
      }
    },
    xaxis: {
      type: 'category',
      categories: mealProgressData.map((item) => item.date),
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: theme.typography.fontFamily
        }
      }
    },
    yaxis: {
      title: {
        text: 'Fluid Intake (ml)',
        style: {
          fontSize: '14px',
          fontFamily: theme.typography.fontFamily
        }
      },
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: theme.typography.fontFamily
        }
      }
    },
    colors: ['#00E396'],
    tooltip: {
      theme: theme.palette.mode
    },
    grid: {
      borderColor: theme.palette.divider
    }
  };

  const pieChartOptions: ApexOptions = {
    chart: {
      type: 'donut'
    },
    labels: mealTypePieData.map((item) => item.name),
    colors: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
    legend: {
      position: 'bottom',
      fontFamily: theme.typography.fontFamily
    },
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '18px',
              fontFamily: theme.typography.fontFamily,
              color: theme.palette.text.primary
            },
            value: {
              show: true,
              fontSize: '16px',
              fontFamily: theme.typography.fontFamily,
              color: theme.palette.text.secondary
            },
            total: {
              show: true,
              label: 'Total',
              color: theme.palette.text.primary
            }
          }
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      theme: theme.palette.mode
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Card elevation={3}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              style={{ fontWeight: 'bold' }}
            >
              Amount Eaten Over Time
            </Typography>
            <ReactApexChart
              options={lineChartOptions}
              series={[
                {
                  name: 'Amount Eaten',
                  data: mealProgressData.map((item) => item.amountEaten)
                }
              ]}
              type="line"
              height={300}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card elevation={3}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              style={{ fontWeight: 'bold' }}
            >
              Fluid Intake Over Time
            </Typography>
            <ReactApexChart
              options={barChartOptions}
              series={[
                {
                  name: 'Fluid Intake',
                  data: mealProgressData.map((item) => item.drinkAmount)
                }
              ]}
              type="bar"
              height={300}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card elevation={3}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              style={{ fontWeight: 'bold' }}
            >
              Meal Type Distribution
            </Typography>
            <ReactApexChart
              options={pieChartOptions}
              series={mealTypePieData.map((item) => item.value)}
              type="donut"
              height={300}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card elevation={3}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              style={{ fontWeight: 'bold' }}
            >
              Meal Averages
            </Typography>
            <Typography variant="body1" style={{ marginBottom: '8px' }}>
              Average Amount Eaten:{' '}
              <strong>{averageAmountEaten.toFixed(2)}%</strong>
            </Typography>
            <Typography variant="body1">
              Average Fluid Intake:{' '}
              <strong>{averageFluidIntake.toFixed(2)} ml</strong>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default MealAnalysis;

import React, { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Button,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Divider,
  IconButton
} from '@mui/material';
import moment from 'moment';
import toPdf from 'react-to-pdf';
import { useCreateInvoiceMutation } from 'src/redux/@api/invoices';
import { Close } from '@mui/icons-material';

const CompanyLogo = styled('img')({
  width: 100,
  height: 'auto',
  marginBottom: 20
});

interface Timesheet {
  shiftDate: string;
  shiftType: string;
  carerName: string;
  homeName: string;
  hourlyRate: number;
  hours: number;
  amount: number;
}

interface ShiftSummaryData {
  count: number;
  totalHours: number;
  weekdayHours: number;
  weekendHours: number;
  weekdayRate: number;
  weekendRate: number;
  totalAmount: number;
}

type ShiftSummary = Record<string, ShiftSummaryData>;

interface InvoiceDialogProps {
  open: boolean;
  onClose: () => void;
  timesheets: Timesheet[];
  totalAmount: number;
  home: {
    homeId: string;
    homeName: string;
    homeAddress: string;
    homeEmail: string;
    homePhone: string;
  };
  selectedStartDate: string;
  selectedEndDate: string;
}

const InvoiceDialog: React.FC<InvoiceDialogProps> = ({
  open,
  onClose,
  timesheets,
  totalAmount,
  home,
  selectedStartDate,
  selectedEndDate
}) => {
  const [createInvoice, { isLoading }] = useCreateInvoiceMutation();
  const contentRef = useRef<HTMLDivElement>(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState<string | undefined>(
    undefined
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const shiftSummary: ShiftSummary = timesheets.reduce((acc, timesheet) => {
    const shiftType = timesheet.shiftType;
    const isWeekend = moment(timesheet.shiftDate).isoWeekday() > 5;

    if (!acc[shiftType]) {
      acc[shiftType] = {
        count: 0,
        totalHours: 0,
        weekdayHours: 0,
        weekendHours: 0,
        weekdayRate: 0,
        weekendRate: 0,
        totalAmount: 0
      };
    }

    acc[shiftType].count += 1;
    acc[shiftType].totalHours += timesheet.hours;
    acc[shiftType].totalAmount += timesheet.amount;

    if (isWeekend) {
      acc[shiftType].weekendHours += timesheet.hours;
      acc[shiftType].weekendRate = timesheet.hourlyRate;
    } else {
      acc[shiftType].weekdayHours += timesheet.hours;
      acc[shiftType].weekdayRate = timesheet.hourlyRate;
    }

    return acc;
  }, {} as ShiftSummary);

  useEffect(() => {
    if (open) {
      setInvoiceNumber(undefined);
    }
    return () => {
      setInvoiceNumber(undefined);
    };
  }, [open]);

  const handleSave = async () => {
    try {
      const invoiceData = {
        homeId: home.homeId,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        totalAmount,
        shiftSummary
      };
      const response = await createInvoice(invoiceData).unwrap();
      console.log('Invoice created:', response);
      setInvoiceNumber(response.data.invoiceNumber);
    } catch (error) {
      console.error('Failed to create invoice:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleDownloadPdf = () => {
    setIsPrinting(true);
    setTimeout(() => {
      toPdf(contentRef, {
        filename: `invoice_${moment().format('YYYYMMDD')}.pdf`
      });
      setIsPrinting(false);
    }, 100);
  };

  const renderDesktopLayout = () => (
    <>
      <Typography variant="h6" gutterBottom>
        Shift Summary
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Shift Type</TableCell>
              <TableCell align="right">Count</TableCell>
              <TableCell align="right">Weekday Hours</TableCell>
              <TableCell align="right">Weekend Hours</TableCell>
              <TableCell align="right">Weekday Rate (£/hr)</TableCell>
              <TableCell align="right">Weekend Rate (£/hr)</TableCell>
              <TableCell align="right">Total (£)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(shiftSummary).map(([shiftType, data]) => (
              <TableRow key={shiftType}>
                <TableCell>{shiftType}</TableCell>
                <TableCell align="right">{data.count}</TableCell>
                <TableCell align="right">
                  {data.weekdayHours.toFixed(2)}
                </TableCell>
                <TableCell align="right">
                  {data.weekendHours.toFixed(2)}
                </TableCell>
                <TableCell align="right">
                  {data.weekdayRate.toFixed(2)}
                </TableCell>
                <TableCell align="right">
                  {data.weekendRate.toFixed(2)}
                </TableCell>
                <TableCell align="right">
                  {data.totalAmount.toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h6" gutterBottom>
        Detailed Breakdown
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Shift Type</TableCell>
              <TableCell>Carer Name</TableCell>
              <TableCell align="right">Hours</TableCell>
              <TableCell align="right">Rate (£/hr)</TableCell>
              <TableCell align="right">Amount (£)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timesheets.map((timesheet, index) => (
              <TableRow key={index}>
                <TableCell>
                  {moment(timesheet.shiftDate).format('MMM D, YYYY')}
                </TableCell>
                <TableCell>{timesheet.shiftType}</TableCell>
                <TableCell>{timesheet.carerName}</TableCell>
                <TableCell align="right">
                  {timesheet.hours.toFixed(2)}
                </TableCell>
                <TableCell align="right">
                  {timesheet.hourlyRate.toFixed(2)}
                </TableCell>
                <TableCell align="right">
                  {timesheet.amount.toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  const renderMobileLayout = () => (
    <>
      <Typography variant="h6" gutterBottom>
        Shift Summary
      </Typography>
      {Object.entries(shiftSummary).map(([shiftType, data]) => (
        <Card key={shiftType} sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="subtitle1">{shiftType}</Typography>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
            >
              <Typography variant="body2">Count:</Typography>
              <Typography variant="body2">{data.count}</Typography>
            </Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
            >
              <Typography variant="body2">Weekday Hours:</Typography>
              <Typography variant="body2">
                {data.weekdayHours.toFixed(2)}
              </Typography>
            </Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
            >
              <Typography variant="body2">Weekend Hours:</Typography>
              <Typography variant="body2">
                {data.weekendHours.toFixed(2)}
              </Typography>
            </Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
            >
              <Typography variant="body2">Weekday Rate (£/hr):</Typography>
              <Typography variant="body2">
                {data.weekdayRate.toFixed(2)}
              </Typography>
            </Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
            >
              <Typography variant="body2">Weekend Rate (£/hr):</Typography>
              <Typography variant="body2">
                {data.weekendRate.toFixed(2)}
              </Typography>
            </Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
            >
              <Typography variant="body2">Total (£):</Typography>
              <Typography variant="body2">
                {data.totalAmount.toFixed(2)}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))}

      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        Detailed Breakdown
      </Typography>
      {timesheets.map((timesheet, index) => (
        <Card key={index} sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="subtitle1">
              {moment(timesheet.shiftDate).format('MMM D, YYYY')}
            </Typography>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
            >
              <Typography variant="body2">Shift Type:</Typography>
              <Typography variant="body2">{timesheet.shiftType}</Typography>
            </Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
            >
              <Typography variant="body2">Carer Name:</Typography>
              <Typography variant="body2">{timesheet.carerName}</Typography>
            </Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
            >
              <Typography variant="body2">Hours:</Typography>
              <Typography variant="body2">
                {timesheet.hours.toFixed(2)}
              </Typography>
            </Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
            >
              <Typography variant="body2">Rate (£/hr):</Typography>
              <Typography variant="body2">
                {timesheet.hourlyRate.toFixed(2)}
              </Typography>
            </Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
            >
              <Typography variant="body2">Amount (£):</Typography>
              <Typography variant="body2">
                {timesheet.amount.toFixed(2)}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
    </>
  );

  return (
    <Dialog
      PaperProps={{
        sx: {
          [theme.breakpoints.down('sm')]: {
            width: '96vw',
            margin: 0
          }
        }
      }}
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogContent>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            color: theme.palette.grey[500],
            borderRadius: 100
          }}
        >
          <Close />
        </IconButton>
        <Box ref={contentRef} sx={{ p: 1 }}>
          <Typography variant="h4" gutterBottom>
            Invoice
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between',
              mb: 4
            }}
          >
            <Box sx={{ mb: isMobile ? 2 : 0 }}>
              <Typography variant="body1">Invoice To:</Typography>
              <Typography variant="body2">{home.homeName}</Typography>
              <Typography variant="body2">{home.homeAddress}</Typography>
              <Typography variant="body2">{home.homeEmail}</Typography>
              <Typography variant="body2">{home.homePhone}</Typography>
            </Box>
            <Box>
              <Typography variant="body1">Invoice Details:</Typography>
              <Typography variant="body2">
                Invoice #: {invoiceNumber || 'Pending'}
              </Typography>
              <Typography variant="body2">
                Date: {moment().format('MMMM D, YYYY')}
              </Typography>
              <Typography variant="body2">
                Period: {moment(selectedStartDate).format('MMM D, YYYY')} -{' '}
                {moment(selectedEndDate).format('MMM D, YYYY')}
              </Typography>
            </Box>
          </Box>

          {isMobile ? renderMobileLayout() : renderDesktopLayout()}

          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between',
              alignItems: isMobile ? 'stretch' : 'center',
              mt: 4
            }}
          >
            <Typography variant="h6" sx={{ mb: isMobile ? 2 : 0 }}>
              Total Amount: £{totalAmount.toFixed(2)}
            </Typography>
            {!isPrinting && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row'
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  disabled={isLoading}
                  sx={{ mr: isMobile ? 0 : 2, mb: isMobile ? 2 : 0 }}
                  fullWidth={isMobile}
                >
                  {isLoading ? 'Saving...' : 'Save Invoice'}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleDownloadPdf}
                  fullWidth={isMobile}
                >
                  Download PDF
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default InvoiceDialog;

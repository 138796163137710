import React, { useState, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  IconButton,
  Grid,
  CircularProgress,
  Divider,
  Checkbox,
  FormControlLabel,
  Chip,
  Menu,
  ListItemIcon,
  ListItemText,
  Stack
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Edit,
  Refresh
} from '@mui/icons-material';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import moment from 'moment';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import type { Shift } from './types';
import type { IStaff } from 'src/interfaces/staffs';
import {
  useCreateYourShiftTypeMutation,
  useUpdateYourShiftTypeMutation,
  useGetOtherShiftPatternsQuery,
  useGetYourShiftPatternsQuery,
  usePublishShiftMultipleFreeMutation,
  usePublishShiftMultipleMutation,
  useAssignUsersToShiftMutation
} from 'src/redux/@api/shift';
import { useGetLinkedOrganizationsQuery } from 'src/redux/@api/organisation';
import { useGetCareStaffsQuery } from 'src/redux/@api/misc';
import ShiftTypeDialog from 'src/content/dashboards/home/views/AddShiftTypeDialog';
import { openPaymentDialog } from 'src/redux/reducers/dialogs/payment';
import ConfirmationDialog from './confirm-assign';
import { IShiftPattern } from 'src/interfaces/shift-pattern';

interface IntegratedShiftDialogProps {
  open: boolean;
  onClose: () => void;
  selectedDate: moment.Moment | null;
  onAddShift: (newShifts: any) => void;
  shiftToEdit?: any;
}

interface ShiftFormData {
  selectedAgency: string;
  shifts: {
    shiftPatternId: string;
    count: number;
    assignedStaff: string[];
  }[];
  duplicationType: string;
  nextNDays: number;
  skipDays: number;
}

const IntegratedShiftDialog: React.FC<IntegratedShiftDialogProps> = ({
  open,
  onClose,
  selectedDate,
  onAddShift,
  shiftToEdit
}) => {
  const { control, handleSubmit, watch, setValue, reset } =
    useForm<ShiftFormData>({
      defaultValues: {
        selectedAgency: 'internal',
        shifts: [{ shiftPatternId: '', count: 1, assignedStaff: [] }],
        duplicationType: 'none',
        nextNDays: 1,
        skipDays: 0
      }
    });

  const [remainingDaysInMonth, setRemainingDaysInMonth] = useState(0);
  const [shiftPatterns, setShiftPatterns] = useState<IShiftPattern[]>([]);
  const [isLoadingShiftPatterns, setIsLoadingShiftPatterns] = useState(false);
  const [openShiftTypeDialog, setOpenShiftTypeDialog] = useState(false);
  const [selectedShiftType, setSelectedShiftType] =
    useState<IShiftPattern | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [roleFilter, setRoleFilter] = useState('all');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [editingStaffId, setEditingStaffId] = useState<string | null>(null);
  const [editingShiftIndex, setEditingShiftIndex] = useState<number | null>(
    null
  );

  const dispatch = useDispatch();
  const userState = useAppSelector((state) => state.userState);

  const [publishShiftMultiple] = usePublishShiftMultipleMutation();
  const [publishShiftMultipleFree] = usePublishShiftMultipleFreeMutation();
  const [createYourShiftType] = useCreateYourShiftTypeMutation();
  const [updateYourShiftType] = useUpdateYourShiftTypeMutation();
  const [assignCarers] = useAssignUsersToShiftMutation();

  const selectedAgency = watch('selectedAgency');
  const duplicationType = watch('duplicationType');
  const shifts = watch('shifts');

  const {
    data: yourShiftPatterns,
    isLoading: yourShiftPatternsLoading,
    refetch: refetchYourShift
  } = useGetYourShiftPatternsQuery(userState.currentOrganization?._id, {
    skip: !open
  });

  const {
    data: otherShiftPatterns,
    isLoading: otherShiftPatternsLoading,
    refetch: refetchOtherShift
  } = useGetOtherShiftPatternsQuery(selectedAgency, { skip: !open });

  const { data: linkedOrgs } = useGetLinkedOrganizationsQuery('agency', {
    skip: !open
  });

  const {
    data: careStaffs,
    isLoading: careStaffsLoading,
    isError: careStaffsError
  } = useGetCareStaffsQuery(undefined);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'shifts'
  });

  useEffect(() => {
    if (selectedDate) {
      const endOfMonth = selectedDate.clone().endOf('month');
      const daysRemaining = endOfMonth.diff(selectedDate, 'days') + 1;
      setRemainingDaysInMonth(daysRemaining);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedAgency === 'internal') {
      setShiftPatterns(yourShiftPatterns || []);
    } else {
      setShiftPatterns(otherShiftPatterns || []);
    }
  }, [selectedAgency, yourShiftPatterns, otherShiftPatterns]);

  useEffect(() => {
    if (open && shiftToEdit) {
      reset({
        selectedAgency: shiftToEdit.agentId || 'internal',
        shifts: [
          {
            shiftPatternId: shiftToEdit.shiftPattern._id,
            count: shiftToEdit.count,
            assignedStaff:
              shiftToEdit.shiftAssignments?.map(
                (assignment: any) => assignment.user
              ) || []
          }
        ],
        duplicationType: 'none',
        nextNDays: 1,
        skipDays: 0
      });
    } else if (open) {
      reset();
    }
  }, [open, shiftToEdit, reset]);

  const filteredStaff = useMemo(() => {
    return careStaffs?.filter((staff: IStaff) => {
      const nameMatch = `${staff.user?.firstName} ${staff.user?.lastName}`
        .toLowerCase()
        ?.includes(searchTerm.toLowerCase());
      const roleMatch = roleFilter === 'all' || staff.role === roleFilter;
      return nameMatch && roleMatch;
    });
  }, [careStaffs, searchTerm, roleFilter]);

  const handleSaveShiftType = async (shiftType: IShiftPattern) => {
    try {
      if (shiftType._id) {
        await updateYourShiftType(shiftType).unwrap();
        dispatch(
          showSnack({
            message: 'Shift Type updated successfully',
            color: 'success'
          })
        );
      } else {
        await createYourShiftType(shiftType).unwrap();
        dispatch(
          showSnack({
            message: 'Shift Type created successfully',
            color: 'success'
          })
        );
      }
      setOpenShiftTypeDialog(false);
      refetchYourShift();
    } catch (error) {
      console.error(error);
      dispatch(
        showSnack({ message: 'Error saving shift type', color: 'error' })
      );
    }
  };

  const handleStaffToggle = (staffId: string, shiftIndex: number) => {
    const currentAssignedStaff = shifts[shiftIndex].assignedStaff;
    const newAssignedStaff = currentAssignedStaff.includes(staffId)
      ? currentAssignedStaff.filter((id) => id !== staffId)
      : [...currentAssignedStaff, staffId];

    setValue(`shifts.${shiftIndex}.assignedStaff`, newAssignedStaff);
  };

  const handleEditClick = (
    event: React.MouseEvent<HTMLElement>,
    staffId: string,
    shiftIndex: number
  ) => {
    setAnchorEl(event.currentTarget);
    setEditingStaffId(staffId);
    setEditingShiftIndex(shiftIndex);
  };

  const handleEditClose = () => {
    setAnchorEl(null);
    setEditingStaffId(null);
    setEditingShiftIndex(null);
  };

  const handleRemove = () => {
    if (editingShiftIndex !== null && editingStaffId) {
      const currentAssignedStaff = shifts[editingShiftIndex].assignedStaff;
      const newAssignedStaff = currentAssignedStaff.filter(
        (id) => id !== editingStaffId
      );
      setValue(`shifts.${editingShiftIndex}.assignedStaff`, newAssignedStaff);
    }
    handleEditClose();
  };

  const handleReplace = () => {
    // TODO: Implement replace functionality
    handleEditClose();
  };

  const onSubmit = async (data: ShiftFormData) => {
    if (selectedDate) {
      const shiftsToAdd = [];
      let currentDate = selectedDate.clone();
      let daysToAdd = 1;

      switch (data.duplicationType) {
        case 'nextNDays':
          daysToAdd = data.nextNDays;
          break;
        case 'remainingMonth':
          daysToAdd = remainingDaysInMonth;
          break;
        case 'wholeMonthSkip':
          daysToAdd = remainingDaysInMonth - data.skipDays;
          currentDate = currentDate.add(data.skipDays, 'days');
          break;
      }

      const createShiftsForDate = (date: moment.Moment) => {
        return data.shifts.map((shift) => ({
          date: date.format('YYYY-MM-DD'),
          count: shift.count,
          shiftPattern: shift.shiftPatternId,
          agentId:
            data.selectedAgency === 'internal' ? null : data.selectedAgency,
          assignedStaff: shift.assignedStaff
        }));
      };

      for (let i = 0; i < daysToAdd; i++) {
        shiftsToAdd.push(...createShiftsForDate(currentDate));
        currentDate = currentDate.clone().add(1, 'day');
      }

      try {
        const publishFunction =
          data.selectedAgency === 'internal'
            ? publishShiftMultiple
            : publishShiftMultipleFree;
        const response = await publishFunction(shiftsToAdd).unwrap();

        console.log('Response:', response);
        dispatch(
          showSnack({ message: 'Shifts added successfully', color: 'success' })
        );
        onClose();
        onAddShift(response);
      } catch (error) {
        if (error.status === 402) {
          dispatch(
            openPaymentDialog(
              'You have reached the limit of free shifts. Please upgrade your plan to add more shifts.'
            )
          );
        }
        dispatch(showSnack({ message: 'Error adding shifts', color: 'error' }));
      }
    }
  };

  if (careStaffsLoading || isLoadingShiftPatterns) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  if (careStaffsError) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <Typography color="error">Error loading staff data</Typography>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {shiftToEdit
            ? 'Edit Shift'
            : `Add Shift for ${selectedDate?.format('MMMM D, YYYY')}`}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12}>
              <Controller
                name="selectedAgency"
                control={control}
                rules={{ required: 'Agency is required' }}
                render={({ field }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel id="select-agency">Agency</InputLabel>
                    <Select id="select-agency" {...field} label="Agency">
                      <MenuItem value="internal">Internal</MenuItem>
                      {linkedOrgs?.map((org) => (
                        <MenuItem key={org._id} value={org._id}>
                          {org.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>

            {fields.map((field, index) => (
              <Grid item xs={12} key={field.id}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <FormControl fullWidth size="small" sx={{ flex: 1 }}>
                    <InputLabel id={`shift-pattern-${index}`}>
                      Shift Pattern
                    </InputLabel>
                    <Controller
                      name={`shifts.${index}.shiftPatternId`}
                      control={control}
                      rules={{ required: 'Shift pattern is required' }}
                      render={({ field }) => (
                        <Select
                          id={`shift-pattern-${index}`}
                          {...field}
                          label="Shift Pattern"
                        >
                          {shiftPatterns?.map((pattern) => (
                            <MenuItem key={pattern._id} value={pattern._id}>
                              {pattern.name}
                            </MenuItem>
                          ))}
                          <Divider />
                          <MenuItem
                            onClick={() => {
                              setOpenShiftTypeDialog(true);
                              setSelectedShiftType(null);
                            }}
                          >
                            <AddIcon /> Add New Shift Pattern
                          </MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                  <Controller
                    name={`shifts.${index}.count`}
                    control={control}
                    rules={{ required: 'Count is required', min: 1 }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        type="number"
                        label="Count"
                        InputProps={{ inputProps: { min: 1 } }}
                        sx={{ width: '100px' }}
                      />
                    )}
                  />
                  <IconButton onClick={() => remove(index)} color="error">
                    <DeleteIcon />
                  </IconButton>
                </Stack>

                {selectedAgency === 'internal' && (
                  <Box mt={2}>
                    <Typography variant="subtitle2">Assign Staff:</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Search staff"
                          variant="outlined"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth size="small">
                          <InputLabel>Filter by role</InputLabel>
                          <Select
                            value={roleFilter}
                            onChange={(e) =>
                              setRoleFilter(e.target.value as string)
                            }
                            label="Filter by role"
                          >
                            <MenuItem value="all">All roles</MenuItem>
                            <MenuItem value="caregiver">Caregiver</MenuItem>
                            <MenuItem value="nurse">Nurse</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Box mt={1}>
                      {filteredStaff?.map((staff: IStaff) => (
                        <FormControlLabel
                          key={staff.user?._id}
                          control={
                            <Checkbox
                              checked={shifts[index].assignedStaff.includes(
                                staff.user?._id
                              )}
                              onChange={() =>
                                handleStaffToggle(staff.user?._id, index)
                              }
                              disabled={
                                shifts[index].assignedStaff.length >=
                                  shifts[index].count &&
                                !shifts[index].assignedStaff.includes(
                                  staff.user?._id
                                )
                              }
                            />
                          }
                          label={`${staff.user?.firstName} ${staff.user?.lastName} (${staff.role})`}
                        />
                      ))}
                    </Box>
                    <Box mt={1}>
                      <Typography variant="subtitle2">
                        Assigned Staff:
                      </Typography>
                      {shifts[index].assignedStaff.map((staffId) => {
                        const staff = careStaffs?.find(
                          (s) => s.user?._id === staffId
                        );
                        return (
                          <Chip
                            key={staffId}
                            label={`${staff?.user?.firstName} ${staff?.user?.lastName}`}
                            onDelete={(event) =>
                              handleEditClick(event, staffId, index)
                            }
                            deleteIcon={<Edit />}
                            style={{ margin: '0 4px 4px 0' }}
                          />
                        );
                      })}
                    </Box>
                  </Box>
                )}
              </Grid>
            ))}

            <Grid item xs={12}>
              <Button
                onClick={() =>
                  append({ shiftPatternId: '', count: 1, assignedStaff: [] })
                }
                variant="outlined"
                startIcon={<AddIcon />}
                size="small"
                fullWidth
              >
                Add Another Shift
              </Button>
            </Grid>

            {!shiftToEdit && (
              <>
                <Grid item xs={12}>
                  <Controller
                    name="duplicationType"
                    control={control}
                    rules={{ required: 'Duplication type is required' }}
                    render={({ field }) => (
                      <FormControl fullWidth size="small">
                        <InputLabel id="duplication">Duplication</InputLabel>
                        <Select id="duplication" {...field} label="Duplication">
                          <MenuItem value="none">No duplication</MenuItem>
                          <MenuItem value="nextNDays">Next N days</MenuItem>
                          <MenuItem value="remainingMonth">
                            Remaining days in this month
                          </MenuItem>
                          <MenuItem value="wholeMonthSkip">
                            Whole month (skip N days)
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>

                {duplicationType === 'nextNDays' && (
                  <Grid item xs={12}>
                    <Controller
                      name="nextNDays"
                      control={control}
                      rules={{ required: 'Number of days is required', min: 1 }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          fullWidth
                          label="Number of days"
                          type="number"
                          InputProps={{ inputProps: { min: 1 } }}
                        />
                      )}
                    />
                  </Grid>
                )}

                {duplicationType === 'wholeMonthSkip' && (
                  <Grid item xs={12}>
                    <Controller
                      name="skipDays"
                      control={control}
                      rules={{ required: 'Skip days is required', min: 0 }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          fullWidth
                          label="Skip days"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                        />
                      )}
                    />
                  </Grid>
                )}

                {duplicationType !== 'none' && (
                  <Grid item xs={12}>
                    <Box mt={2}>
                      <Typography variant="body2">
                        {duplicationType === 'nextNDays' &&
                          `This will create shifts for the next ${watch(
                            'nextNDays'
                          )} days.`}
                        {duplicationType === 'remainingMonth' &&
                          `This will create shifts for the remaining ${remainingDaysInMonth} days in this month.`}
                        {duplicationType === 'wholeMonthSkip' &&
                          `This will create shifts for ${
                            remainingDaysInMonth - watch('skipDays')
                          } days, starting after ${watch('skipDays')} days.`}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" color="primary" variant="contained">
            {shiftToEdit ? 'Update Shift' : 'Add Shifts'}
          </Button>
        </DialogActions>
      </form>

      <ShiftTypeDialog
        open={openShiftTypeDialog}
        onClose={() => setOpenShiftTypeDialog(false)}
        onSave={handleSaveShiftType}
        selectedShiftType={selectedShiftType}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleEditClose}
      >
        <MenuItem onClick={handleRemove}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Remove</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleReplace}>
          <ListItemIcon>
            <Refresh fontSize="small" />
          </ListItemIcon>
          <ListItemText>Replace</ListItemText>
        </MenuItem>
      </Menu>
    </Dialog>
  );
};

export default IntegratedShiftDialog;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentDayOfMonth: null,
  currentMonth: null,
  currentYear: null,
  shiftspublished: [],
  userAssignedSidebarToggled: false,
  shift: [],
  detailSidebarToggledAd: false,
  userSidebarToggledAd: false,
  days: [],
  assigny: [],
  dayDetail: {
    today: null,
    assigned: [],
    published: false
  },
  userAssignToggled: false,
  shiftType: null,
  detailSidebarToggled: false,
  eventsSidebarToggled: false,
  newEventSidebarToggled: false,
  editEventSidebarToggled: false,
  eventDate: '',
  loading: false,
  home_id: null,
  notification: [],
  events: []
};

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getCurrentDate: (state, action) => {
      state.currentDayOfMonth = action.payload.date;
      state.currentMonth = action.payload.month;
      state.currentYear = action.payload.year;
    },
    setDays: (state, action) => {
      state.days = action.payload;
    },
    prevMonth: (state, action) => {
      state.currentMonth = action.payload.month;
      state.currentYear = action.payload.year;
    },
    nextMonth: (state, action) => {
      state.currentMonth = action.payload.month;
      state.currentYear = action.payload.year;
    },
    toggleDetailSidebar: (state, action) => {
      state.detailSidebarToggled = action.payload;
    },
    addNewNotification: (state, action) => {
      state.notification.unshift(action.payload);
    },
    clearNewNotification: (state) => {
      state.notification = [];
    },
    toggleDetailSidebarAd: (state, action) => {
      state.detailSidebarToggledAd = true;
      state.shift = action.payload;
    },
    toggleUserSidebarAd: (state, action) => {
      state.userSidebarToggledAd = true;
      state.shiftType = action.payload.shiftType;
      state.home_id = action.payload.home_id;
    },
    userAssignSidebarClose: (state) => {
      state.userAssignToggled = false;
    },
    userAssignSidebarToggled: (state) => {
      state.userAssignToggled = true;
    },
    closeUserSidebarAd: (state) => {
      state.userSidebarToggledAd = false;
      state.shiftType = null;
      state.home_id = null;
    },
    closeDetailSidebarAd: (state) => {
      state.detailSidebarToggledAd = false;
    },
    toggleUserAssignedSidebar: (state) => {
      state.userAssignedSidebarToggled = !state.userAssignedSidebarToggled;
    },
    toggleEventsSidebar: (state, action) => {
      state.eventsSidebarToggled = action.payload;
    },
    toggleNewEventSidebar: (state, action) => {
      state.newEventSidebarToggled = action.payload;
    },
    setDayDetail: (state, action) => {
      state.dayDetail = action.payload;
    },
    deleteEvent: (state, action) => {
      state.events = state.events.filter((e) => e.id !== action.payload);
    },
    setShifts: (state, action) => {
      state.shiftspublished = action.payload;
    },
    setEvents: (state, action) => {
      state.events = action.payload;
    },
    addEventDate: (state, action) => {
      state.eventDate = action.payload;
    }
  }
});

export const {
  getCurrentDate,
  setDays,
  prevMonth,
  nextMonth,
  toggleDetailSidebar,
  addNewNotification,
  clearNewNotification,
  toggleDetailSidebarAd,
  toggleUserSidebarAd,
  userAssignSidebarClose,
  userAssignSidebarToggled,
  closeUserSidebarAd,
  closeDetailSidebarAd,
  toggleUserAssignedSidebar,
  toggleEventsSidebar,
  toggleNewEventSidebar,
  setDayDetail,
  deleteEvent,
  setShifts,
  setEvents,
  addEventDate
} = calendarSlice.actions;

export default calendarSlice.reducer;

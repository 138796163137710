import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Switch,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  ListItemButton,
  Card,
  CardContent
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandMore';
import ExpandMore from '@mui/icons-material/ExpandLess';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BusinessIcon from '@mui/icons-material/Business';
import EventIcon from '@mui/icons-material/Event';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import dayjs from 'dayjs';
import { styled } from '@mui/system';
import {
  useGetFieldVisibilityQuery,
  useUpdateFieldVisibilityMutation,
  useInitializeFieldVisibilityMutation
} from 'src/redux/@api/visibility';
import { IFieldVisibility } from 'src/interfaces/visiblity';
import { useAppSelector } from 'src/redux/hook';
import FieldVisibilityCheckboxes from './visibility-checkboxes';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

const widgetColors = [
  { main: '#4caf50', light: '#81c784' }, // Green
  { main: '#2196f3', light: '#64b5f6' }, // Blue
  { main: '#ff9800', light: '#ffb74d' }, // Orange
  { main: '#e91e63', light: '#f06292' } // Pink
];

const StyledInfoCard = styled(Paper)<{ index: number }>(({ theme, index }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  color: theme.palette.getContrastText(widgetColors[index].main),
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4]
  }
}));

const IconWrapper = styled(Box)<{ index: number }>(({ theme, index }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 60,
  height: 60,
  borderRadius: '50%',
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  color: widgetColors[index].main
}));

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.action.selected,
  margin: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  }
}));

const ComingSoonOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  zIndex: 1
}));

const VisibilityGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

const VisibilityItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.action.selected,
  marginInline: '15px',
  marginBlock: '5px',
  padding: '5px 10px !important',
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  }
}));

const ChartCard = styled(Card)<{ gradientColors: string[] }>(
  ({ theme, gradientColors }) => ({
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    background: `linear-gradient(135deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 100%)`,
    position: 'relative',
    overflow: 'hidden'
  })
);

const OrganizationOverviewTab = () => {
  const userState = useAppSelector((state) => state.userState);
  const shiftState = useAppSelector((state) => state.shifts.shifts);
  const [shiftPeriod, setShiftPeriod] = useState('month');
  const [visibilityState, setVisibilityState] =
    useState<IFieldVisibility | null>(null);
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error'
  });

  const {
    data: fieldVisibility,
    isLoading,
    isError,
    refetch
  } = useGetFieldVisibilityQuery(undefined);
  const [updateFieldVisibility, { isLoading: isUpdating }] =
    useUpdateFieldVisibilityMutation();
  const [initializeFieldVisibility, { isLoading: isInitializing }] =
    useInitializeFieldVisibilityMutation();

  useEffect(() => {
    if (fieldVisibility) {
      setVisibilityState(fieldVisibility.fields);
    }
  }, [fieldVisibility]);

  const handleVisibilityChange =
    (path: string[]) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setVisibilityState((prev) => {
        if (!prev) return null;
        const updateNestedObject = (
          obj: any,
          keys: string[],
          value: boolean
        ): any => {
          if (keys.length === 1) {
            return { ...obj, [keys[0]]: value };
          }
          const [currentKey, ...remainingKeys] = keys;
          return {
            ...obj,
            [currentKey]: updateNestedObject(
              obj[currentKey] || {},
              remainingKeys,
              value
            )
          };
        };
        return updateNestedObject(prev, path, event.target.checked);
      });
    };

  const handleSaveVisibility = async (data: any) => {
    try {
      await updateFieldVisibility(data).unwrap();
      setSnackbar({
        open: true,
        message: 'Visibility settings updated successfully',
        severity: 'success'
      });
      refetch();
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update visibility settings',
        severity: 'error'
      });
    }
  };

  const handleInitializeVisibility = async () => {
    try {
      await initializeFieldVisibility(undefined).unwrap();
      setSnackbar({
        open: true,
        message: 'Visibility settings initialized successfully',
        severity: 'success'
      });
      refetch();
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to initialize visibility settings',
        severity: 'error'
      });
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const toggleSection = (section: string) => {
    setOpenSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  // ApexCharts options and series
  const occupancyChartOptions: ApexOptions = {
    chart: {
      type: 'area',
      height: 350,
      toolbar: { show: false }
    },
    dataLabels: { enabled: false },
    stroke: { curve: 'smooth', width: 2 },
    xaxis: {
      type: 'datetime',
      categories: [
        '2023-05-01',
        '2023-05-02',
        '2023-05-03',
        '2023-05-04',
        '2023-05-05',
        '2023-05-06',
        '2023-05-07'
      ]
    },
    tooltip: { x: { format: 'dd/MM/yy' } },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100]
      }
    },
    colors: ['#00E396']
  };

  const occupancyChartSeries = [
    { name: 'Staff Count', data: [30, 40, 35, 50, 49, 60, 70] }
  ];

  const staffingChartOptions: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: { show: false }
    },
    plotOptions: { bar: { horizontal: false, columnWidth: '55%' } },
    dataLabels: { enabled: false },
    stroke: { show: true, width: 2, colors: ['transparent'] },
    xaxis: { categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] },
    yaxis: { title: { text: 'Shifts' } },
    fill: { opacity: 1 },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return `${val} shifts`;
        }
      }
    },
    colors: ['#008FFB', '#00E396']
  };

  const staffingChartSeries = [
    { name: 'Issued Shifts', data: [44, 55, 57, 56, 61, 58, 63] },
    { name: 'Completed Shifts', data: [76, 85, 101, 98, 87, 105, 91] }
  ];

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return (
      <Typography color="error">Error loading field visibility data</Typography>
    );
  }

  return (
    <>
      <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold' }}>
        Organization Overview
      </Typography>
      <Grid container spacing={3}>
        {[
          { icon: PeopleAltIcon, title: 'Number of Staff', value: 0 },
          {
            icon: BusinessIcon,
            title:
              userState.currentOrganization.type === 'home'
                ? 'Homes Linked'
                : 'Agencies Linked',
            value: 0
          },
          { icon: EventIcon, title: 'Shifts Issued', value: null },
          { icon: FolderSpecialIcon, title: 'Active Projects', value: 0 }
        ].map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index} p={1}>
            <StyledInfoCard index={index}>
              <IconWrapper index={index}>
                <item.icon fontSize="large" />
              </IconWrapper>
              <Typography variant="subtitle1" gutterBottom>
                {item.title}
              </Typography>
              {item.value !== null ? (
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                  {item.value}
                </Typography>
              ) : (
                <>
                  <FormControl fullWidth size="small" sx={{ mb: 1 }}>
                    <InputLabel>Period</InputLabel>
                    <Select
                      value={shiftPeriod}
                      onChange={(e) => setShiftPeriod(e.target.value as string)}
                      label="Period"
                    >
                      <MenuItem value="month">This Month</MenuItem>
                      <MenuItem value="year">This Year</MenuItem>
                    </Select>
                  </FormControl>
                  <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    {shiftPeriod === 'month'
                      ? shiftState.filter(
                          (shift) =>
                            dayjs(shift.date).month() === dayjs().month()
                        ).length || 0
                      : shiftState.filter(
                          (shift) => dayjs(shift.date).year() === dayjs().year()
                        ).length || 0}
                  </Typography>
                </>
              )}
            </StyledInfoCard>
          </Grid>
        ))}
      </Grid>

      <Typography
        variant="h4"
        gutterBottom
        sx={{ mt: 6, mb: 3, fontWeight: 'bold' }}
      >
        Update staff application visibility
      </Typography>
      <Paper sx={{ mt: 2, borderRadius: 2, p: 3 }}>
        {visibilityState ? (
          <FieldVisibilityCheckboxes
            initialData={visibilityState}
            onSave={(data) => {
              handleSaveVisibility(data);
            }}
          />
        ) : (
          <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleInitializeVisibility}
              disabled={isInitializing}
              sx={{ minWidth: 240 }}
            >
              {isInitializing ? (
                <CircularProgress size={24} />
              ) : (
                'Initialize Visibility Settings'
              )}
            </Button>
          </Box>
        )}
      </Paper>

      <Grid container spacing={3} sx={{ mt: 4 }}>
        <Grid item xs={12} md={6}>
          <ChartCard gradientColors={['#b3e5fc', '#4fc3f7']}>
            <CardContent>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ color: 'white' }}
              >
                Staff Count Trend
              </Typography>
              <ReactApexChart
                options={occupancyChartOptions}
                series={occupancyChartSeries}
                type="area"
                height={350}
              />
              <ComingSoonOverlay>
                <Typography variant="h5" fontWeight="bold">
                  Coming Soon
                </Typography>
              </ComingSoonOverlay>
            </CardContent>
          </ChartCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <ChartCard gradientColors={['#c8e6c9', '#81c784']}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
                Shift Overview
              </Typography>
              <ReactApexChart
                options={staffingChartOptions}
                series={staffingChartSeries}
                type="bar"
                height={350}
              />
              <ComingSoonOverlay>
                <Typography variant="h5" fontWeight="bold">
                  Coming Soon
                </Typography>
              </ComingSoonOverlay>
            </CardContent>
          </ChartCard>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default OrganizationOverviewTab;

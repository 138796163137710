// stripe.ts
import { baseApi } from './base';

export const stripeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createSubscription: builder.mutation({
      query: ({
        planId,
        organizationType,
        planTier,
        paymentMethodId,
        billingDetails
      }: {
        planId: string;
        organizationType: string;
        planTier: string;
        paymentMethodId: string;
        billingDetails: {
          name: string;
          email: string;
          phone: string;
          address: {
            street: string;
            city: string;
            state: string;
            zipCode: string;
            country: string;
          };
        };
      }) => ({
        url: '/stripe/create-subscription',
        method: 'POST',
        body: {
          planId,
          organizationType,
          planTier,
          paymentMethodId,
          billingDetails
        }
      })
    }),
    createSetupIntent: builder.mutation({
      query: ({
        planId,
        organizationType
      }: {
        planId: string;
        organizationType: string;
      }) => ({
        url: '/stripe/create-setup-intent',
        method: 'POST',
        body: {
          planId,
          organizationType
        }
      })
    }),

    getPlan: builder.query({
      query: (organizationType) => `/stripe/plans/${organizationType}`
    }),
    getPlans: builder.query({
      query: () => '/stripe/plans'
    }),
    getSubscriptionDetails: builder.query({
      query: () => `/stripe/subscription-details`
    }),
    createCheckoutSession: builder.mutation<
      { id: string },
      { organizationId: string; priceId: string }
    >({
      query: (data) => ({
        url: '/stripe/create-checkout-session',
        method: 'POST',
        body: data
      })
    }),
    verifyCheckoutSession: builder.query<
      { success: boolean; message: string },
      string
    >({
      query: (sessionId) => `/stripe/verify-checkout-session/${sessionId}`
    }),
    cancelSubscription: builder.mutation({
      query: () => ({
        url: '/stripe/cancel-subscription',
        method: 'POST'
      })
    }),
    restartSubscription: builder.mutation({
      query: (planId) => ({
        url: 'stripe/restart-subscription',
        method: 'POST',
        body: { planId }
      })
    }),
    updatePaymentMethod: builder.mutation({
      query: ({ paymentMethodId }) => ({
        url: 'stripe/update-payment-method',
        method: 'POST',
        body: { paymentMethodId }
      })
    }),
    getPaymentHistory: builder.query({
      query: () => 'stripe/payment-history'
    }),

    changePlan: builder.mutation({
      query: (body) => ({
        url: 'stripe/change-plan',
        method: 'POST',
        body
      })
    }),
    getCardDetails: builder.query({
      query: () => 'stripe/card-details'
    })
  })
});

export const {
  useChangePlanMutation,
  useGetCardDetailsQuery,
  useRestartSubscriptionMutation,
  useCreateSubscriptionMutation,
  useCreateSetupIntentMutation,
  useCancelSubscriptionMutation,
  useUpdatePaymentMethodMutation,
  useGetPlanQuery,
  useGetPlansQuery,
  useGetPaymentHistoryQuery,
  useGetSubscriptionDetailsQuery,
  useCreateCheckoutSessionMutation,
  useVerifyCheckoutSessionQuery
} = stripeApi;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  styled,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Tabs,
  Tab,
  Paper,
  Chip,
  Card,
  CardContent,
  alpha
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { LoadingButton } from '@mui/lab';
import { IJoinRequest } from 'src/interfaces/join-requests';
import {
  useAcceptInvitationMutation,
  useLazyGetInvitationsQuery
} from 'src/redux/@api/invitations';
import { useGetJoinRequestsQuery } from 'src/redux/@api/join-request';
import {
  useAddUserToOrganizationMutation,
  useGetMyOrgInvitationsQuery,
  useResponseToOrgInvitationMutation
} from 'src/redux/@api/organisation';
import { useLinkUserMutation } from 'src/redux/@api/auth';
import SendIcon from '@mui/icons-material/Send';
import ReceiveIcon from '@mui/icons-material/CallReceived';
import { useAppSelector } from 'src/redux/hook';
import Check from '@mui/icons-material/Check';

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
    transform: 'translateY(-2px)'
  }
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 60,
  height: 60,
  marginRight: theme.spacing(2)
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  boxShadow: theme.shadows[2],
  height: `calc(100vh - ${theme.header.height})`,
  display: 'flex',
  flexDirection: 'column'
}));

const ScrollableBox = styled(Box)({
  flexGrow: 1,
  overflowY: 'auto',
  marginTop: '16px'
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`invitation-tabpanel-${index}`}
      aria-labelledby={`invitation-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
};

const Invitations: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const userState = useAppSelector((state) => state.userState);

  const {
    data: joinRequests,
    isLoading: joinRequestsLoading,
    refetch
  } = useGetJoinRequestsQuery(undefined, { skip: tabValue === 1 });

  const { data: myOrgInvitations, isLoading: myOrgInvitationsLoading } =
    useGetMyOrgInvitationsQuery({ skip: tabValue === 0 });

  const [
    respondToOrgInv,
    { isLoading: respondToOrgInvLoading, error: respondToOrgInvError }
  ] = useResponseToOrgInvitationMutation();

  const [acceptInvitation] = useAcceptInvitationMutation();
  const [addUser, { isLoading: addUserLoading, error: addUserError }] =
    useAddUserToOrganizationMutation();
  const [linkUser] = useLinkUserMutation();
  const [acceptLoading, setAcceptLoading] = useState(false);

  const handleAccept = async (orgId: string, userId: string, role: string) => {
    try {
      setAcceptLoading(true);
      const response = await addUser({
        organizationId: orgId,
        userId,
        role
      }).unwrap();

      setAcceptLoading(false);
      refetch();
      dispatch(showSnack({ message: 'Invitation accepted', color: 'success' }));
    } catch (error) {
      setAcceptLoading(false);
      console.error(error);
      dispatch(
        showSnack({ message: 'Failed to accept invitation', color: 'error' })
      );
    }
  };

  const handleReject = (id: string) => {
    dispatch(showSnack({ message: 'Invitation rejected', color: 'error' }));
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleResponseToOrgInv = async (invId: string, response: boolean) => {
    try {
      const res = await respondToOrgInv({
        invitationId: invId,
        accept: response
      }).unwrap();
      console.log('Response to org invitation:', res);
      dispatch(
        showSnack({ message: 'Invitation responded', color: 'success' })
      );
    } catch (error) {
      dispatch(
        showSnack({
          message: 'Failed to respond to org invitation',
          color: 'error'
        })
      );
      console.error('Failed to respond to org invitation:', error);
    }
  };

  if (joinRequestsLoading || myOrgInvitationsLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StyledPaper>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="invitation tabs"
      >
        <Tab label="Staff Invitations" />
        <Tab label="Organization Invitations" />
      </Tabs>

      <ScrollableBox>
        <TabPanel value={tabValue} index={0}>
          <List sx={{ padding: 0 }}>
            {joinRequests?.data?.map((request: IJoinRequest) => (
              <StyledCard key={request.id}>
                <StyledCardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <StyledAvatar
                      src={''}
                      alt={`${request.user?.firstName} ${request.user?.lastName}`}
                    />
                    <Box>
                      <Typography variant="h6">
                        {request.user?.firstName} {request.user?.lastName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {request.user?.email}
                      </Typography>
                      <Chip
                        label={request?.role || 'Unknown Role'}
                        size="small"
                        sx={{ mt: 1 }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: isMobile ? 'column' : 'row',
                      gap: 1,
                      mt: isMobile ? 2 : 0
                    }}
                  >
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() =>
                        handleAccept(
                          request?.organization?._id,
                          request.user?._id,
                          request.role
                        )
                      }
                    >
                      Accept
                    </LoadingButton>
                    <LoadingButton
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={() => handleReject(request.id)}
                    >
                      Reject
                    </LoadingButton>
                  </Box>
                </StyledCardContent>
              </StyledCard>
            ))}
          </List>
          {joinRequests?.data?.length === 0 && (
            <Typography variant="body1" sx={{ textAlign: 'center', mt: 2 }}>
              No staff invitations at the moment.
            </Typography>
          )}
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <List sx={{ padding: 0 }}>
            {myOrgInvitations?.invitations?.map((invitation: any) => {
              const isSentInvitation =
                invitation.fromOrganization?._id ===
                  userState.currentOrganization?._id ||
                invitation.fromOrganization?.id ===
                  userState.currentOrganization?._id;

              return (
                <StyledCard
                  key={invitation._id}
                  sx={{
                    backgroundColor: isSentInvitation
                      ? alpha(theme.palette.primary.main, 0.1)
                      : theme.palette.background.paper
                  }}
                >
                  <StyledCardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <StyledAvatar
                        src={invitation.fromOrganization?.logoUrl}
                        alt={
                          isSentInvitation
                            ? invitation.toOrganization?.name
                            : invitation.fromOrganization?.name
                        }
                      />
                      <Box>
                        <Typography variant="h5">
                          {isSentInvitation
                            ? invitation.toOrganization?.name
                            : invitation.fromOrganization?.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {isSentInvitation
                            ? invitation.toOrganization?.email
                            : invitation.fromOrganization?.email}
                        </Typography>
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                        >
                          {isSentInvitation ? (
                            <Chip
                              icon={<Check />}
                              label="Sent"
                              size="small"
                              color="primary"
                            />
                          ) : (
                            <Chip
                              icon={<ReceiveIcon />}
                              label="Received"
                              size="small"
                              color="secondary"
                            />
                          )}
                        </Box>
                      </Box>
                    </Box>
                    {!isSentInvitation && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: isMobile ? 'column' : 'row',
                          gap: 1,
                          mt: isMobile ? 2 : 0
                        }}
                      >
                        <LoadingButton
                          variant="contained"
                          color="primary"
                          loading={acceptLoading}
                          size="small"
                          onClick={() => {
                            handleResponseToOrgInv(invitation._id, true);
                          }}
                        >
                          Accept
                        </LoadingButton>
                        <LoadingButton
                          variant="outlined"
                          color="error"
                          size="small"
                          onClick={() => {
                            handleResponseToOrgInv(invitation._id, false);
                          }}
                        >
                          Reject
                        </LoadingButton>
                      </Box>
                    )}
                  </StyledCardContent>
                </StyledCard>
              );
            })}
          </List>
          {myOrgInvitations?.invitations?.length === 0 && (
            <Typography variant="body1" sx={{ textAlign: 'center', mt: 2 }}>
              No organization invitations at the moment.
            </Typography>
          )}
        </TabPanel>
      </ScrollableBox>
    </StyledPaper>
  );
};

export default Invitations;

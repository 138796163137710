import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Avatar,
  Chip,
  List,
  ListItem,
  ListItemText,
  Divider,
  Rating,
  useTheme,
  styled
} from '@mui/material';
import {
  Person,
  Phone,
  Email,
  Home,
  School,
  Work,
  Assignment,
  Language,
  DirectionsCar,
  LocalHospital,
  DateRange,
  Star
} from '@mui/icons-material';
import { useAppSelector } from 'src/redux/hook';
import moment from 'moment';

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  paddingBottom: theme.spacing(1)
}));

const InfoItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1)
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  '& .MuiChip-icon': {
    color: theme.palette.primary.main
  }
}));

const CarerResumePage: React.FC = () => {
  const application = useAppSelector(
    (state) => state.carerApplication.application
  );
  const theme = useTheme();

  if (!application) {
    return <Typography>Loading...</Typography>;
  }

  const {
    personalInfo,
    skillsAssessment,
    qualificationsAndTraining,
    workExperience,
    specializations,
    languages,
    drivingLicense,
    ownVehicle,
    willingToTravel,
    healthInfo,
    additionalInfo
  } = application;

  return (
    <Box
      sx={{
        maxWidth: 1200,
        margin: 'auto',
        padding: 4,
        height: `calc(100vh - ${theme.header.height} - 20px)`,
        overflowY: 'auto'
      }}
    >
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Grid container spacing={4}>
          {/* Header */}
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Avatar
                src={personalInfo?.avatarUrl}
                sx={{ width: 120, height: 120, marginRight: 3 }}
              />
              <Box>
                <Typography variant="h4" fontWeight="bold" color="primary">
                  {`${personalInfo.firstName} ${personalInfo.lastName}`}
                </Typography>
                <Typography variant="h6" color="text.secondary">
                  Professional Carer
                </Typography>
              </Box>
            </Box>
            <Box>
              {personalInfo.phone && (
                <InfoItem>
                  <Phone sx={{ marginRight: 1, color: 'primary.main' }} />
                  <Typography>{personalInfo.phone}</Typography>
                </InfoItem>
              )}
              {personalInfo.email && (
                <InfoItem>
                  <Email sx={{ marginRight: 1, color: 'primary.main' }} />
                  <Typography>{personalInfo.email}</Typography>
                </InfoItem>
              )}
              {personalInfo.address && (
                <InfoItem>
                  <Home sx={{ marginRight: 1, color: 'primary.main' }} />
                  <Typography>{`${personalInfo.address.street}, ${personalInfo.address.city}, ${personalInfo.address.zipCode}`}</Typography>
                </InfoItem>
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Specializations */}
          {specializations && specializations.length > 0 && (
            <Grid item xs={12}>
              <SectionTitle variant="h6">Specializations</SectionTitle>
              <Box display="flex" flexWrap="wrap" gap={1}>
                {specializations.map((spec, index) => (
                  <StyledChip key={index} label={spec} icon={<Star />} />
                ))}
              </Box>
            </Grid>
          )}

          {/* Skills Assessment */}
          {skillsAssessment && Object.keys(skillsAssessment).length > 0 && (
            <Grid item xs={12} md={6}>
              <SectionTitle variant="h6">Skills Assessment</SectionTitle>
              <Grid container spacing={2}>
                {Object.entries(skillsAssessment)
                  .filter(([skill, rating]) => skill !== '_id')
                  .map(([skill, rating]) => (
                    <Grid item xs={6} key={skill}>
                      <Typography variant="body2">
                        {skill.charAt(0).toUpperCase() + skill.slice(1)}
                      </Typography>
                      {typeof rating === 'number' ? (
                        <Rating
                          value={rating as number}
                          readOnly
                          size="small"
                        />
                      ) : (
                        <Typography variant="body2">{rating}</Typography>
                      )}
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          )}

          {/* Qualifications and Training */}
          {qualificationsAndTraining &&
            (qualificationsAndTraining.qualifications?.length > 0 ||
              qualificationsAndTraining.trainings?.length > 0) && (
              <Grid item xs={12} md={6}>
                <SectionTitle variant="h6">
                  Qualifications and Training
                </SectionTitle>
                <List dense>
                  {qualificationsAndTraining.qualifications?.map(
                    (qual, index) => (
                      <ListItem key={index} disableGutters>
                        <ListItemText
                          primary={qual.name}
                          secondary={
                            <Typography variant="body2" color="text.secondary">
                              <School
                                fontSize="small"
                                sx={{ marginRight: 1, verticalAlign: 'middle' }}
                              />
                              {`${qual.institution}, ${qual.year}`}
                            </Typography>
                          }
                        />
                      </ListItem>
                    )
                  )}
                  {qualificationsAndTraining.trainings?.map(
                    (training, index) => (
                      <ListItem key={index} disableGutters>
                        <ListItemText
                          primary={training.name}
                          secondary={
                            <Typography variant="body2" color="text.secondary">
                              <Assignment
                                fontSize="small"
                                sx={{ marginRight: 1, verticalAlign: 'middle' }}
                              />
                              {`${training.provider}, ${training.completionDate}`}
                            </Typography>
                          }
                        />
                      </ListItem>
                    )
                  )}
                </List>
              </Grid>
            )}

          {/* Work Experience */}
          {workExperience && workExperience.length > 0 && (
            <Grid item xs={12}>
              <SectionTitle variant="h6">Work Experience</SectionTitle>
              {workExperience.map((exp, index) => (
                <Box key={index} mb={2}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    <Work
                      fontSize="small"
                      sx={{
                        marginRight: 1,
                        verticalAlign: 'middle',
                        color: 'primary.main'
                      }}
                    />
                    {exp.jobTitle} at {exp.employer}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <DateRange
                      fontSize="small"
                      sx={{ marginRight: 1, verticalAlign: 'middle' }}
                    />
                    {moment(exp.startDate).format('DD MMM YYYY')} -{' '}
                    {exp.currentlyWorking
                      ? 'Present'
                      : moment(exp.endDate).format('DD MMM YYYY')}
                  </Typography>
                  <Typography variant="body2">
                    {exp.responsibilities}
                  </Typography>
                </Box>
              ))}
            </Grid>
          )}

          {/* Languages and Driving */}
          <Grid item xs={12} md={6}>
            {languages && languages.length > 0 && (
              <>
                <SectionTitle variant="h6">Languages</SectionTitle>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {languages.map((lang, index) => (
                    <StyledChip key={index} icon={<Language />} label={lang} />
                  ))}
                </Box>
              </>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            {(drivingLicense ||
              ownVehicle !== undefined ||
              willingToTravel !== undefined) && (
              <>
                <SectionTitle variant="h6">Driving Information</SectionTitle>
                {drivingLicense && (
                  <Typography variant="body1">
                    <DirectionsCar
                      sx={{
                        marginRight: 1,
                        verticalAlign: 'middle',
                        color: 'primary.main'
                      }}
                    />
                    {drivingLicense.status
                      ? 'Has driving license'
                      : 'No driving license'}
                  </Typography>
                )}
                {ownVehicle !== undefined && (
                  <Typography variant="body1">
                    Own Vehicle: {ownVehicle ? 'Yes' : 'No'}
                  </Typography>
                )}
                {willingToTravel !== undefined && (
                  <Typography variant="body1">
                    Willing to Travel: {willingToTravel ? 'Yes' : 'No'}
                  </Typography>
                )}
              </>
            )}
          </Grid>

          {/* Health Information */}
          {healthInfo && (
            <Grid item xs={12}>
              <SectionTitle variant="h6">Health Information</SectionTitle>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  {healthInfo.tetanusVaccination && (
                    <Typography variant="body1">
                      <LocalHospital
                        sx={{
                          marginRight: 1,
                          verticalAlign: 'middle',
                          color: 'primary.main'
                        }}
                      />
                      Tetanus Vaccination: {healthInfo.tetanusVaccination}
                    </Typography>
                  )}
                  {healthInfo.covidVaccination && (
                    <Typography variant="body1">
                      <LocalHospital
                        sx={{
                          marginRight: 1,
                          verticalAlign: 'middle',
                          color: 'primary.main'
                        }}
                      />
                      COVID Vaccination: {healthInfo.covidVaccination}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {healthInfo.bloodType && (
                    <Typography variant="body1">
                      Blood Type: {healthInfo.bloodType}
                    </Typography>
                  )}
                  <Typography variant="body1">
                    Allergies: {healthInfo.allergies || 'None'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* Additional Info */}
          {additionalInfo && (
            <Grid item xs={12}>
              <SectionTitle variant="h6">Additional Information</SectionTitle>
              <Typography variant="body1">{additionalInfo}</Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Box>
  );
};

export default CarerResumePage;

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Tab,
  Tabs,
  Box
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    height: '80vh',
    display: 'flex',
    flexDirection: 'column'
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  },
  dialogContent: {
    flexGrow: 1,
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  tabContent: {
    marginTop: theme.spacing(2),
    flexGrow: 1,
    overflowY: 'auto',
    minHeight: '60vh' // Set a minimum height
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: theme.shadows[4]
    }
  },
  cardContent: {
    flexGrow: 1
  },
  cardActions: {
    justifyContent: 'flex-end'
  },
  noFilesMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
}));

interface Document {
  _id: string;
  name: string;
  type: string;
  uploadDate: string;
  url: string;
}

interface CarerDocumentsDialogProps {
  open: boolean;
  onClose: () => void;
  documents: Document[];
  certificates: Document[];
  onDelete: (
    type: 'document' | 'certificate',
    id: string,
    fileName: string
  ) => void;
}

const CarerDocumentsDialog: React.FC<CarerDocumentsDialogProps> = ({
  open,
  onClose,
  documents,
  certificates,
  onDelete
}) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const renderItems = (items: Document[], type: 'document' | 'certificate') =>
    items.length > 0 ? (
      <Grid container spacing={3}>
        {items.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item._id}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h6" component="div" noWrap>
                  {item.name?.split('_')[0]}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Type: {item.type}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Uploaded: {new Date(item.uploadDate).toLocaleDateString()}
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <IconButton
                  aria-label="view"
                  onClick={() => window.open(item.url, '_blank')}
                >
                  <VisibilityIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => onDelete(type, item._id, item.name)}
                >
                  <DeleteIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    ) : (
      <Box className={classes.noFilesMessage}>
        <Typography variant="h6" color="text.secondary">
          No {type}s found.
        </Typography>
      </Box>
    );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{ className: classes.dialogPaper }}
    >
      <DialogContent className={classes.dialogContent}>
        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label="Documents" />
          <Tab label="Certificates" />
        </Tabs>
        <Box className={classes.tabContent}>
          {tabValue === 0 && renderItems(documents, 'document')}
          {tabValue === 1 && renderItems(certificates, 'certificate')}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CarerDocumentsDialog;

import React from 'react';
import ReactApexChart from 'react-apexcharts';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useTheme,
  alpha
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { ApexOptions } from 'apexcharts';

interface PadCheckAnalysisProps {
  padCheckAnalysis: {
    stoolTypeAnalysis: Record<string, number>;
    clothingConditionAnalysis: Record<string, number>;
    healthInsights: string[];
  };
}

const PadCheckAnalysis: React.FC<PadCheckAnalysisProps> = ({
  padCheckAnalysis
}) => {
  const theme = useTheme();

  const stoolTypeData = Object.entries(
    padCheckAnalysis?.stoolTypeAnalysis || {}
  ).map(([type, count]) => ({
    x: `Type ${type}`,
    y: count
  }));

  const clothingConditionData = Object.entries(
    padCheckAnalysis?.clothingConditionAnalysis || {}
  ).map(([condition, count]) => ({
    x: condition,
    y: count
  }));

  const pieChartOptions: ApexOptions = {
    chart: {
      type: 'donut',
      background: 'transparent'
    },
    labels: stoolTypeData.map((item) => item.x),
    colors: [
      theme.palette.primary.main,
      theme.palette.secondary.main,
      theme.palette.error.main,
      theme.palette.warning.main,
      theme.palette.info.main,
      theme.palette.success.main
    ],
    legend: {
      position: 'bottom',
      fontFamily: theme.typography.fontFamily,
      fontSize: '14px',
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: '#fff',
        radius: 12
      },
      itemMargin: {
        horizontal: 10,
        vertical: 5
      }
    },
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
          background: 'transparent',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '22px',
              fontFamily: theme.typography.fontFamily,
              color: theme.palette.text.primary,
              offsetY: -10
            },
            value: {
              show: true,
              fontSize: '18px',
              fontFamily: theme.typography.fontFamily,
              color: theme.palette.text.secondary,
              offsetY: 10
            },
            total: {
              show: true,
              label: 'Total',
              color: theme.palette.text.primary,
              formatter: function (w) {
                return w.globals.seriesTotals
                  .reduce((a: number, b: number) => a + b, 0)
                  .toString();
              }
            }
          }
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ],
    stroke: {
      show: false
    }
  };

  const barChartOptions: ApexOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false
      },
      background: 'transparent'
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        horizontal: true,
        barHeight: '70%',
        distributed: true
      }
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        colors: ['#fff'],
        fontSize: '14px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold'
      },
      formatter: function (val) {
        return val.toString();
      },
      offsetX: 6
    },
    xaxis: {
      categories: clothingConditionData.map((item) => item.x),
      labels: {
        style: {
          fontSize: '14px',
          fontFamily: theme.typography.fontFamily
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '14px',
          fontFamily: theme.typography.fontFamily
        }
      }
    },
    colors: [
      theme.palette.primary.main,
      theme.palette.secondary.main,
      theme.palette.error.main,
      theme.palette.warning.main
    ],
    legend: {
      show: false
    },
    grid: {
      borderColor: alpha(theme.palette.text.primary, 0.1),
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    tooltip: {
      theme: theme.palette.mode,
      y: {
        formatter: function (val) {
          return val.toString();
        }
      }
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Card elevation={3} sx={{ height: '100%', borderRadius: 2 }}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: 'bold', mb: 3 }}
            >
              Stool Type Distribution
            </Typography>
            <ReactApexChart
              options={pieChartOptions}
              series={stoolTypeData.map((item) => item.y)}
              type="donut"
              height={350}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card elevation={3} sx={{ height: '100%', borderRadius: 2 }}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: 'bold', mb: 3 }}
            >
              Clothing Condition Analysis
            </Typography>
            <ReactApexChart
              options={barChartOptions}
              series={[{ data: clothingConditionData.map((item) => item.y) }]}
              type="bar"
              height={350}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={3} sx={{ borderRadius: 2 }}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: 'bold', mb: 3 }}
            >
              Health Insights
            </Typography>
            <List>
              {padCheckAnalysis?.healthInsights.map((insight, index) => (
                <ListItem key={index} sx={{ py: 1 }}>
                  <ListItemIcon>
                    <InfoOutlined color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={insight}
                    primaryTypographyProps={{
                      style: {
                        fontWeight: 500,
                        fontSize: '16px',
                        lineHeight: 1.5
                      }
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PadCheckAnalysis;

import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Link,
  InputAdornment,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { apiHostname } from 'src/api/api';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { RequestResetDialog, ResetPasswordDialog } from 'src/components/core/dialogs/users/password-reset';

interface AuthLoginProps {
  isDemo?: boolean;
}

const AuthLogin: React.FC<AuthLoginProps> = ({ isDemo = false }) => {
  const [checked, setChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openRequestDialog, setOpenRequestDialog] = useState(false);
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const onSubmit = async (data: any) => {
    try {
      const response = await fetch(`${apiHostname}/api/v1/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData?.error);
      }

      const data_ = await response.json();
      localStorage.setItem('token', data_.token);
      navigate('/');
    } catch (error) {
      let errorMessage = 'An unexpected error occurred';
      if (error instanceof Error) {
        errorMessage = error.message;
      } else if (typeof error === 'string') {
        errorMessage = error;
      }
      dispatch(showSnack({ message: errorMessage, color: 'error' }));
      console.error('Login failed', errorMessage);
    }
  };

  const handleRequestSuccess = () => {
    setOpenRequestDialog(false);
    setOpenResetDialog(true);
  };

  const handleResetSuccess = () => {
    setOpenResetDialog(false);
    dispatch(showSnack({ message: 'Password reset successfully. Please log in with your new password.', color: 'success' }));
  };

  return (
    <Box
      sx={{
        borderRadius: 2,
        padding: 4,
        margin: 'auto',
        width: '90vw',
        maxWidth: '98vw',
        backdropFilter: 'blur(10px)',
        [theme.breakpoints.up('md')]: {
          width: '35vw',
          backdropFilter: 'blur(10px)'
        },
        filter: 'brightness(1)',
        zIndex: 20
      }}
    >
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Box>
            <InputLabel htmlFor="email-login" sx={{ mb: 1 }}>
              Email Address
            </InputLabel>
            <Controller
              name="email"
              control={control}
              rules={{
                required: 'Email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address'
                }
              }}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  id="email-login"
                  type="email"
                  placeholder="Enter email address"
                  fullWidth
                  error={Boolean(errors.email)}
                />
              )}
            />
            {errors.email && (
              <FormHelperText error>{errors.email.message}</FormHelperText>
            )}
          </Box>

          <Box>
            <InputLabel htmlFor="password-login" sx={{ mb: 1 }}>
              Password
            </InputLabel>
            <Controller
              name="password"
              control={control}
              rules={{
                required: 'Password is required'
              }}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  id="password-login"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter password"
                  fullWidth
                  error={Boolean(errors.password)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              )}
            />
            {errors.password && (
              <FormHelperText error>{errors.password.message}</FormHelperText>
            )}
          </Box>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={(event) => setChecked(event.target.checked)}
                  name="checked"
                  color="primary"
                  size="small"
                />
              }
              label={<Typography variant="body2">Keep me signed in</Typography>}
            />
            <Box
              onClick={() => setOpenRequestDialog(true)}
              sx={{ color: 'primary.main' }}
            >
              <Typography sx={{ cursor: 'pointer' }} variant="body2">
                Forgot password?
              </Typography>
            </Box>
          </Stack>

          <Button
            disableElevation
            disabled={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{ borderRadius: 2 }}
          >
            Login
          </Button>

          <Typography variant="body2" align="center">
            Don't have an account?{' '}
            <Link component={RouterLink} to="/signup" color="primary">
              Sign up
            </Link>
          </Typography>
        </Stack>
      </form>

      <RequestResetDialog
        open={openRequestDialog}
        onClose={() => setOpenRequestDialog(false)}
        onRequestSuccess={handleRequestSuccess}
      />
      <ResetPasswordDialog 
        open={openResetDialog}
        onClose={() => setOpenResetDialog(false)}
        onResetSuccess={handleResetSuccess}
      />
    </Box>
  );
};

export default AuthLogin;
import React from 'react';
import { Dialog } from '@mui/material';
import PadCheckDialog from '../dialogs/pad-check';
import MealFluidDialog from '../dialogs/meal';
import MedicationDialog from '../dialogs/medication';
import PersonalCareDialog from '../dialogs/care';
import WakeAsleepCheckDialog from '../dialogs/wakeupasleep';
import ResidentTurnDialog from '../dialogs/turn';

interface TaskResolveDialogProps {
  open: boolean;
  onClose: () => void;
  onResolve: (taskData: any) => void;
  task: any;
}

const TaskResolveDialog: React.FC<TaskResolveDialogProps> = ({ open, onClose, onResolve, task }) => {
  if (!task) return null;

  const renderTaskDialog = () => {
    switch (task.taskType) {
      case 'turn':
        return <ResidentTurnDialog open={open} onClose={onClose} onResolve={onResolve} />;
      case 'pad check':
        return <PadCheckDialog open={open} onClose={onClose} onResolve={onResolve} />;
      case 'meal':
      case 'fluid intake':
        return <MealFluidDialog open={open} onClose={onClose} onResolve={onResolve} />;
      case 'day check':
      case 'night check':
        return <WakeAsleepCheckDialog open={open} onClose={onClose} onResolve={onResolve} />;
      case 'medication':
        return <MedicationDialog open={open} onClose={onClose} onResolve={onResolve} />;
      case 'personal care':
        return <PersonalCareDialog open={open} onClose={onClose} onResolve={onResolve} />;
      default:
        return null;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      {renderTaskDialog()}
    </Dialog>
  );
};

export default TaskResolveDialog;
import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Chip,
  Tooltip,
  IconButton,
  Button,
  Input
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  AccessTime as AccessTimeIcon,
  Home as HomeIcon,
  Group as GroupIcon,
  PersonAdd as PersonAddIcon,
  Check as CheckIcon,
  Upload as UploadIcon
} from '@mui/icons-material';

import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { useCreateTimesheetRequestMutation } from 'src/redux/@api/timesheet';
import { useGetAssignedStaffsQuery } from 'src/redux/@api/shift';
import axios from 'axios';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.grey[100],
  '&:before': {
    display: 'none'
  }
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    margin: `${theme.spacing(1)} 0`
  }
}));

const ShiftInfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1)
}));

const StyledUploadButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontSize: '0.8rem',
  padding: theme.spacing(0.5, 1),
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.text.secondary,
  '&:hover': {
    backgroundColor: theme.palette.grey[300],
  },
}));

interface ShiftAccordionProps {
  shift: any;
  userState: any;
  onAssignStaff: (shift: any) => void;
  onViewAssignedStaff: (shift: any) => void;
  onEditClick: (shift: any) => void;
  onDeleteClick: (shift: any) => void;
  setAssignedStaffs: React.Dispatch<React.SetStateAction<any[] | null>>;
  onShiftUpdate: (updatedShift: any) => void;
  onAccept: (shift: any) => Promise<void>;
}

const ShiftAccordion: React.FC<ShiftAccordionProps> = ({
  shift,
  userState,
  onAssignStaff,
  onViewAssignedStaff,
  onEditClick,
  onDeleteClick,
  setAssignedStaffs,
  onShiftUpdate,
  onAccept
}) => {
  const dispatch = useDispatch();
  const [requestTimeSheet, requestTimeSheetState] =
    useCreateTimesheetRequestMutation();

  const {
    data: assignedStaffs,
    isLoading: isAssignedStaffsLoading,
    refetch
  } = useGetAssignedStaffsQuery(shift?._id, {
    skip: !shift?._id,
    refetchOnMountOrArgChange: true
  });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);

  const companyName =
    userState.currentOrganization?.type === 'agency'
      ? shift?.homeId?.name
      : shift?.agentId?.name;
  const timing = shift?.shiftPattern?.timings?.find(
    (timing) => timing.careHomeId === shift?.homeId?._id
  );
  const shiftStartTime = timing?.startTime || '';
  const shiftEndTime = timing?.endTime || '';

  const handleRequestTimesheet = async () => {
    try {
      const response = await requestTimeSheet({
        shiftId: shift._id,
        shiftPatternId: shift.shiftPattern?._id,
        homeId: shift.homeId?._id
      }).unwrap();
      dispatch(showSnack({ message: response.message, color: 'success' }));
    } catch (error) {
      dispatch(
        showSnack({
          message:
            error?.message || 'Error requesting timesheet. Try again later',
          color: 'danger'
        })
      );
      console.log(error);
    }
  };

  const compressFile = async (file: File, maxSizeMB = 5): Promise<Blob> => {
    if (!file.type.startsWith('image/')) {
      return file;
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target?.result as string;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > 1920) {
              height *= 1920 / width;
              width = 1920;
            }
          } else {
            if (height > 1920) {
              width *= 1920 / height;
              height = 1920;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img, 0, 0, width, height);

          const compressAndCheck = (quality: number) => {
            canvas.toBlob(
              (blob) => {
                if (blob) {
                  if (blob.size > maxSizeMB * 1024 * 1024 && quality > 0.1) {
                    compressAndCheck(quality - 0.05);
                  } else {
                    resolve(blob);
                  }
                } else {
                  reject(new Error('Failed to compress file'));
                }
              },
              file.type,
              quality
            );
          };

          compressAndCheck(0.9);
        };
      };

      reader.onerror = (error) => reject(error);
    });
  };

  const uploadTimesheet = async ({
    file,
    shiftId,
    shiftPatternId,
    homeId,
    userId
  }) => {
    try {
      setUploading(true);

      const compressedBlob = await compressFile(file);
      const compressedFile = new File([compressedBlob], file.name, {
        type: compressedBlob.type
      });

      const formData = new FormData();
      formData.append('file', compressedFile);
      formData.append('shiftId', shiftId);
      formData.append('shiftPatternId', shiftPatternId);
      formData.append('homeId', homeId);
      formData.append('userId', userId);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/timesheets/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
          timeout: 30000
        }
      );

      if (response.data.timesheet) {
        dispatch(
          showSnack({
            message: 'Timesheet uploaded successfully',
            color: 'success'
          })
        );
      } else {
        throw new Error(response.data.message || 'Failed to upload timesheet');
      }
    } catch (error) {
      console.error('Error uploading timesheet:', error);
      dispatch(
        showSnack({
          message:
            error instanceof Error
              ? error.message
              : 'Error uploading timesheet',
          color: 'danger'
        })
      );
    } finally {
      setUploading(false);
      setSelectedFile(null);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUploadClick = async () => {
    if (selectedFile) {
      await uploadTimesheet({
        file: selectedFile,
        shiftId: shift._id,
        shiftPatternId: shift.shiftPattern?._id,
        homeId: shift.homeId?._id,
        userId: userState.user._id
      });
    }
  };

  const isCarer = ['carer', 'nurse'].includes(userState.user.accountType);
  const isAgency = userState.currentOrganization?.type === 'agency';
  const isShiftAccepted = shift.isAccepted;
  const isShiftDone = shift.isDone;
  const isShiftCompleted = shift.isCompleted;

  const handleAcceptShift = async () => {
    try {
      await onAccept(shift);
    } catch (error) {
      console.error('Error accepting shift:', error);
    }
  };

  return (
    <StyledAccordion>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${shift._id}-content`}
        id={`panel-${shift._id}-header`}
        sx={{
          display: 'flex',
          alignItems: 'center',
          '$ .MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center'
          }
        }}
      >
        <Typography variant="subtitle1">{shift.shiftPattern.name}</Typography>
        <Box>
          {isShiftDone ? (
            <Chip label="Signed" color="success" size="small" sx={{ ml: 1 }} />
          ) : shift.isCompleted ? (
            <Chip
              label="Assigned"
              color="success"
              size="small"
              sx={{ ml: 1 }}
            />
          ) : null}
        </Box>
      </StyledAccordionSummary>
      <AccordionDetails>
        <ShiftInfoBox>
          <HomeIcon color="action" sx={{ mr: 1 }} />
          <Typography variant="body2">{companyName || 'Internal'}</Typography>
        </ShiftInfoBox>
        <ShiftInfoBox>
          <AccessTimeIcon color="action" sx={{ mr: 1 }} />
          <Typography variant="body2">
            {shiftStartTime} - {shiftEndTime}
          </Typography>
        </ShiftInfoBox>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Chip
            label={
              isShiftDone ? 'Signed' : shift.isAccepted ? 'Accepted' : 'Pending'
            }
            color={
              isShiftDone ? 'success' : shift.isAccepted ? 'success' : 'warning'
            }
            size="small"
          />
          <Typography variant="body2">Count: {shift.count}</Typography>
        </Box>
        {isCarer && !isShiftDone && (
          <Box mt={2}>
            <LoadingButton
              variant="contained"
              color="primary"
              disabled={
                shift.timesheet?.status === 'pending' ||
                shift.timesheet?.status === 'approved'
              }
              onClick={handleRequestTimesheet}
              loading={requestTimeSheetState.isLoading}
              fullWidth
            >
              {shift.timesheet?.status === 'pending'
                ? 'Timesheet Pending'
                : shift.timesheet?.status === 'approved'
                ? 'Timesheet Approved'
                : 'Request Timesheet'}
            </LoadingButton>
          </Box>
        )}
        {!isCarer && (
          <>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button
                variant="contained"
                size="small"
                startIcon={<GroupIcon />}
                onClick={() => {
                  onViewAssignedStaff(shift);
                  setAssignedStaffs(assignedStaffs);
                }}
                disabled={isAgency && !isShiftAccepted}
              >
                Assigned Users ({assignedStaffs?.length || 0})
              </Button>
              {userState.currentOrganization?.type === 'home' && !isShiftDone && (
                <Box>
                  <Tooltip title="Edit Shift">
                    <IconButton
                      size="small"
                      onClick={() => onEditClick(shift)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Shift">
                    <IconButton
                      size="small"
                      onClick={() => onDeleteClick(shift)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
            {!isShiftDone && (
              <Box mt={2} display="flex" justifyContent="space-between">
                {isAgency && !isShiftAccepted ? (
                  <LoadingButton
                    variant="contained"
                    size="small"
                    startIcon={<CheckIcon />}
                    onClick={handleAcceptShift}
                    loading={false}
                  >
                    Accept Shift
                  </LoadingButton>
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<PersonAddIcon />}
                    onClick={() => {
                      onAssignStaff(shift);
                    }}
                    disabled={isShiftCompleted}
                  >
                    Assign Staff
                  </Button>
                )}
              </Box>
            )}
            
          </>
        )}
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default ShiftAccordion;
import React, { useState } from 'react';
import {
  Box,
  useMediaQuery,
  useTheme,
  styled,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Sidebar from './sidebar';
import ChatBox from './chat-box';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import {
  _removeNewMessagesCount,
  _setNewMessagesCount
} from 'src/redux/reducers/messages';

const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: `calc(100vh - ${theme.header.height} - 10px)`,
  overflow: 'hidden',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('sm')]: {
    width: '98%',
    margin: 'auto',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[10],
    backgroundColor: theme.palette.background.paper
  }
}));

const StyledSidebar = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    width: 350,
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

const StyledChatArea = styled(Box)({
  flexGrow: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden'
});

const ChatPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState<{
    user: any;
    type: string;
  } | null>(null);

  const handleSetSelectedUser = (user: any, type: string) => {
    dispatch(_removeNewMessagesCount(user._id));
    setSelectedUser({
      user,
      type
    });
  };

  const handleCloseChat = () => {
    setSelectedUser(null);
  };

  return (
    <StyledContainer>
      <StyledSidebar sx={{ width: isMobile ? '100%' : 350 }}>
        <Sidebar
          onSelectUser={handleSetSelectedUser}
          selectedUser={selectedUser}
        />
      </StyledSidebar>
      {!isMobile && (
        <StyledChatArea>
          {selectedUser ? (
            <ChatBox
              user={selectedUser}
              isMobile={false}
              onClose={handleCloseChat}
            />
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Typography variant="body1">
                Select a user to start chatting
              </Typography>
            </Box>
          )}
        </StyledChatArea>
      )}
      {isMobile && (
        <Dialog fullScreen open={!!selectedUser} onClose={handleCloseChat}>
          {selectedUser && (
            <ChatBox
              user={selectedUser}
              isMobile={true}
              onClose={handleCloseChat}
            />
          )}
        </Dialog>
      )}
    </StyledContainer>
  );
};

export default ChatPage;

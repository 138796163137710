// invoices.ts
import { baseApi } from './base';

export const invoicesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createInvoice: builder.mutation({
      query: (data) => ({
        url: '/invoices',
        method: 'POST',
        body: data
      })
    }),
    getInvoices: builder.query({
      query: () => '/invoices'
    }),
    getInvoiceById: builder.query({
      query: (id) => `/invoices/${id}`
    }),
    updateInvoiceStatus: builder.mutation({
      query: ({ invoiceId, status }) => ({
        url: `/invoices/${invoiceId}/status`,
        method: 'PATCH',
        body: { status }
      })
    }),
  })
});

export const {
  useCreateInvoiceMutation,
  useGetInvoicesQuery,
  useLazyGetInvoicesQuery,
  useGetInvoiceByIdQuery,
  useUpdateInvoiceStatusMutation,
} = invoicesApi;
import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Slide,
  Paper,
  IconButton,
  Avatar,
  CircularProgress
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';

interface ChatPopupProps {
  open: boolean;
  onClose: () => void;
}

interface Message {
  id: number;
  text: string;
  sender: 'user' | 'other';
  timestamp: Date;
  status: 'sent' | 'delivered' | 'read';
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChatPopup: React.FC<ChatPopupProps> = ({ open, onClose }) => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [recipient, setRecipient] = useState('');
  const [isTyping, setIsTyping] = useState(false);

  const messageEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollToBottom, [messages]);

  const handleSend = () => {
    if (message.trim() && recipient) {
      const newMessage: Message = {
        id: Date.now(),
        text: message,
        sender: 'user',
        timestamp: new Date(),
        status: 'sent'
      };
      setMessages([...messages, newMessage]);
      setMessage('');
      simulateResponse();
    }
  };

  const simulateResponse = () => {
    setIsTyping(true);
    setTimeout(() => {
      const responseMessage: Message = {
        id: Date.now(),
        text: 'This is a simulated response.',
        sender: 'other',
        timestamp: new Date(),
        status: 'read'
      };
      setMessages((prevMessages) => [...prevMessages, responseMessage]);
      setIsTyping(false);
    }, 2000);
  };

  useEffect(() => {
    // Simulate updating message status
    const timer = setInterval(() => {
      setMessages((prevMessages) =>
        prevMessages.map((msg) => {
          if (msg.sender === 'user') {
            if (msg.status === 'sent') return { ...msg, status: 'delivered' };
            if (msg.status === 'delivered') return { ...msg, status: 'read' };
          }
          return msg;
        })
      );
    }, 2000);

    return () => clearInterval(timer);
  }, []);

  const MessageStatus: React.FC<{ status: Message['status'] }> = ({
    status
  }) => {
    switch (status) {
      case 'sent':
        return <CheckIcon fontSize="small" />;
      case 'delivered':
        return <DoneAllIcon fontSize="small" />;
      case 'read':
        return <DoneAllIcon fontSize="small" color="primary" />;
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 16,
          height: '80vh',
          maxHeight: 600,
          bottom: '0px',
          right: '0px'
        }
      }}
    >
      <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white', pr: 6 }}>
        <Typography variant="h6" component="div">
          Global Chat
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white'
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <FormControl fullWidth margin="normal" size="small">
          <InputLabel>Recipient</InputLabel>
          <Select
            value={recipient}
            onChange={(e) => setRecipient(e.target.value as string)}
            label="Recipient"
          >
            <MenuItem value="carers">All Carers</MenuItem>
            <MenuItem value="agencies">All Agencies</MenuItem>
          </Select>
        </FormControl>
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'auto',
            bgcolor: 'background.paper',
            borderRadius: 1,
            boxShadow: 1,
            mb: 2,
            p: 1
          }}
        >
          {messages.map((msg) => (
            <Box
              key={msg.id}
              sx={{
                display: 'flex',
                justifyContent:
                  msg.sender === 'user' ? 'flex-end' : 'flex-start',
                mb: 1
              }}
            >
              {msg.sender === 'other' && (
                <Avatar sx={{ width: 32, height: 32, mr: 1 }}>O</Avatar>
              )}
              <Box
                sx={{
                  minWidth: '20%',
                  maxWidth: '70%',
                  bgcolor: msg.sender === 'user' ? 'primary.light' : 'grey.200',
                  color: msg.sender === 'user' ? 'white' : 'text.primary',
                  borderRadius: 1,
                  p: 1,
                  position: 'relative',
                  justifyContent: 'center'
                }}
              >
                <Typography variant="body1">{msg.text}</Typography>
                <Typography
                  variant="caption"
                  sx={{
                    display: 'block',
                    mt: 0.5,
                    opacity: 0.7,
                    fontSize: '0.6rem',
                    color: msg.sender === 'user' ? 'white' : 'text.primary'
                  }}
                >
                  {msg.timestamp.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </Typography>
                {msg.sender === 'user' && (
                  <Box sx={{ position: 'absolute', right: 2, bottom: 2 }}>
                    <MessageStatus status={msg.status} />
                  </Box>
                )}
              </Box>
            </Box>
          ))}
          {isTyping && (
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <Avatar sx={{ width: 32, height: 32, mr: 1 }}>O</Avatar>
              <CircularProgress size={20} />
            </Box>
          )}
          <div ref={messageEndRef} />
        </Box>
        <Box display="flex">
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Type your message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSend()}
          />
          <Button
            variant="contained"
            color="primary"
            endIcon={<SendIcon />}
            onClick={handleSend}
            sx={{ ml: 1 }}
          >
            Send
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ChatPopup;

import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
  InputAdornment,
  Divider
} from '@mui/material';
import {
  Save,
  AccountBalance,
  Person,
  CreditCard,
  FormatListNumbered
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import {
  useGetCarerApplicationQuery,
  useUpdateApplicationSectionMutation
} from 'src/redux/@api/carer-application';

interface BankDetailsProps {
  initialData?: any;
  onSubmit: (data: any, index: number | undefined, section: string) => void;
  selectedSection: string;
  editable?: boolean;
}

const BankDetails: React.FC<BankDetailsProps> = ({
  initialData,
  onSubmit,
  selectedSection,
  editable = true
}) => {
  const dispatch = useDispatch();
  const { data: carerApplication, refetch } =
    useGetCarerApplicationQuery(undefined);
  const [updateApplicationSection] = useUpdateApplicationSectionMutation();

  const [saving, setSaving] = useState(false);

  const defaultValues = editable
    ? carerApplication?.data[selectedSection] || {
        accountHolderName: '',
        bankName: '',
        accountNumber: '',
        sortCode: ''
      }
    : initialData || {};

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues
  });

  useEffect(() => {
    if (editable && carerApplication?.data[selectedSection]) {
      reset(carerApplication.data[selectedSection]);
    } else if (!editable && initialData) {
      reset(initialData);
    }
  }, [carerApplication, selectedSection, reset, editable, initialData]);

  const handleSave = async (data: any) => {
    if (!editable) return;
    setSaving(true);
    try {
      dispatch(showSnack({ message: 'Saving bank details...', color: 'info' }));
      await updateApplicationSection({
        section: 'bankDetails',
        data
      });
      await refetch();
      dispatch(
        showSnack({
          message: 'Bank details saved successfully',
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Save failed:', error);
      dispatch(
        showSnack({ message: 'Failed to save bank details', color: 'error' })
      );
    } finally {
      setSaving(false);
    }
  };

  const renderTextField = (
    name: string,
    label: string,
    icon: React.ReactNode,
    rules: any = {}
  ) => (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) =>
        editable ? (
          <TextField
            {...field}
            label={label}
            fullWidth
            error={!!errors[name]}
            helperText={errors[name]?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{icon}</InputAdornment>
              )
            }}
          />
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            {icon}
            <Typography variant="body1" sx={{ ml: 2 }}>
              <strong>{label}:</strong> {field.value || 'N/A'}
            </Typography>
          </Box>
        )
      }
    />
  );

  return (
    <Card elevation={3}>
      <CardContent>
        <Typography variant="h5" gutterBottom color="primary">
          Bank Details
        </Typography>
        <Divider sx={{ mb: 3 }} />
        <form onSubmit={handleSubmit(handleSave)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {renderTextField(
                'accountHolderName',
                'Account Holder Name',
                <Person />,
                { required: 'Account holder name is required' }
              )}
            </Grid>

            <Grid item xs={12}>
              {renderTextField('bankName', 'Bank Name', <AccountBalance />, {
                required: 'Bank name is required'
              })}
            </Grid>

            <Grid item xs={12} sm={6}>
              {renderTextField(
                'accountNumber',
                'Account Number',
                <CreditCard />,
                {
                  required: 'Account number is required',
                  pattern: {
                    value: /^\d{8}$/,
                    message: 'Account number must be 8 digits'
                  }
                }
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              {renderTextField(
                'sortCode',
                'Sort Code',
                <FormatListNumbered />,
                {
                  required: 'Sort code is required',
                  pattern: {
                    value: /^\d{2}-\d{2}-\d{2}$/,
                    message: 'Sort code must be in the format 00-00-00'
                  }
                }
              )}
            </Grid>
          </Grid>
        </form>
      </CardContent>
      {editable && (
        <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={saving ? <CircularProgress size={20} /> : <Save />}
            disabled={saving}
            onClick={handleSubmit(handleSave)}
          >
            {saving ? 'Saving...' : 'Save Bank Details'}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default BankDetails;

import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import CheckoutForm from './CheckoutForm';
import {
  useGetPlansQuery,
  useGetSubscriptionDetailsQuery,
  useCancelSubscriptionMutation,
  useGetPaymentHistoryQuery,
  useUpdatePaymentMethodMutation,
  useRestartSubscriptionMutation,
  useChangePlanMutation,
  useGetCardDetailsQuery
} from 'src/redux/@api/stripe';
import { useAppSelector } from 'src/redux/hook';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
  Paper,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Alert,
  Container,
  Divider,
  useTheme,
  useMediaQuery,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import { apiHostname } from 'src/api/api';
import axios from 'axios';
import UpdatePaymentForm from './Updatepayment';
import { format, isValid } from 'date-fns';

import CreditCardIcon from '@mui/icons-material/CreditCard';

const stripePromise = loadStripe(
  'pk_test_51Pid1H2M2oCccQjmdDbnK7EEm1aSwZ6Zy76aCgaDKvwUoLw5fjnd20Cq7PUqSEwbOmtoKGFB2ZxZHBaQgIlT0aDd00J4qRyAmm'
);

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4]
  }
}));

const StyledCardContent = styled(CardContent)({
  flexGrow: 1
});

const SubscriptionComponent = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const organizationType = useAppSelector(
    (state) => state.userState.currentOrganization?.type
  );

  const [openChangePlanDialog, setOpenChangePlanDialog] = useState(false);
  const [newPlanId, setNewPlanId] = useState('');

  const [changePlan] = useChangePlanMutation();
  const { data: cardDetails } = useGetCardDetailsQuery(undefined);

  const {
    data: subscriptionDetails,
    isLoading: subscriptionLoading,
    isError: subscriptionError,
    refetch: subscriptionDetailsRefetch
  } = useGetSubscriptionDetailsQuery(undefined);

  const {
    data: plansData,
    isLoading: plansLoading,
    isError: plansError
  } = useGetPlansQuery(undefined);

  const {
    data: paymentHistory,
    isLoading: paymentHistoryLoading,
    isError: paymentHistoryError
  } = useGetPaymentHistoryQuery(undefined);

  const [cancelSubscription, { isLoading: cancelLoading }] =
    useCancelSubscriptionMutation();
  const [updatePaymentMethod, { isLoading: updatePaymentLoading }] =
    useUpdatePaymentMethodMutation();

  const [restartSubscription] = useRestartSubscriptionMutation();

  useEffect(() => {
    if (plansData?.length > 0) {
      const firstPlan = plansData[0];
      setSelectedPlan(firstPlan.stripePriceId);
      handlePlanSelect(firstPlan.stripePriceId);
    }
  }, [plansData]);

  const handleChangePlan = async () => {
    try {
      await changePlan({ newPlanId }).unwrap();
      setOpenChangePlanDialog(false);
      subscriptionDetailsRefetch();
    } catch (error) {
      console.error('Error changing plan:', error);
    }
  };

  const handlePlanSelect = async (planId) => {
    setSelectedPlan(planId);
    setLoading(true);
    try {
      const response = await axios.post(
        `${apiHostname}/api/v1/stripe/create-setup-intent`,
        {
          planId: planId,
          organizationType
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      setClientSecret(response.data.clientSecret);
    } catch (error) {
      console.error('Error creating SetupIntent:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCancelSubscription = async () => {
    try {
      console.log('cancelSubscription');
      await cancelSubscription(undefined).unwrap();
      setOpenCancelDialog(false);
    } catch (error) {
      console.log(error);
      console.error('Error cancelling subscription:', error);
    }
  };

  const handleRestartSubscription = async () => {
    try {
      await restartSubscription(selectedPlan).unwrap();
      // Refetch subscription details after restarting
      subscriptionDetailsRefetch();
    } catch (error) {
      console.error('Error restarting subscription:', error);
    }
  };

  const handleUpdatePaymentMethod = async (paymentMethodId) => {
    try {
      await updatePaymentMethod({ paymentMethodId }).unwrap();
      // Refetch subscription details after updating payment method
      subscriptionDetailsRefetch();
    } catch (error) {
      console.error('Error updating payment method:', error);
    }
  };

  const planFeatures = {
    basic: [
      'Feature 1 of Basic Plan',
      'Feature 2 of Basic Plan',
      'Feature 3 of Basic Plan'
    ],
    standard: [
      'Feature 1 of Standard Plan',
      'Feature 2 of Standard Plan',
      'Feature 3 of Standard Plan',
      'Feature 4 of Standard Plan'
    ],
    premium: [
      'Feature 1 of Premium Plan',
      'Feature 2 of Premium Plan',
      'Feature 3 of Premium Plan',
      'Feature 4 of Premium Plan',
      'Feature 5 of Premium Plan'
    ]
  };

  if (subscriptionLoading || plansLoading || loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return isValid(date) ? format(date, 'PP') : 'Invalid Date';
  };

  return (
    <Box
      sx={{
        height: `calc(100vh - ${theme.header.height})`,
        overflowY: 'auto',
        backgroundColor: theme.palette.background.default,
        py: 4,
        width: '100%'
      }}
    >
      <Container maxWidth="lg">
        <Tabs value={tabValue} onChange={handleTabChange} sx={{ mb: 3 }}>
          <Tab label="Subscription Details" />
          <Tab label="Payment History" />
        </Tabs>

        {tabValue === 0 && (
          <>
            {subscriptionDetails && (
              <Paper elevation={3} sx={{ p: 3, mb: 6 }}>
                <Typography variant="h5" gutterBottom>
                  Current Subscription
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">
                      Status: <strong>{subscriptionDetails?.status}</strong>
                    </Typography>
                    {subscriptionDetails?.status === 'trialing' && (
                      <Typography variant="body1">
                        Trial ends on:{' '}
                        <strong>
                          {formatDate(subscriptionDetails?.trialEnd)}
                        </strong>
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {subscriptionDetails?.planName && (
                      <Typography variant="body1">
                        Current Plan:{' '}
                        <strong>{subscriptionDetails?.planName}</strong>
                      </Typography>
                    )}
                    {subscriptionDetails?.nextPaymentDate && (
                      <Typography variant="body1">
                        Next Payment:{' '}
                        <strong>
                          {formatDate(subscriptionDetails.nextPaymentDate)}
                        </strong>
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                {subscriptionDetails?.status === 'canceled' && (
                  <Box sx={{ mt: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleRestartSubscription}
                    >
                      Restart Subscription
                    </Button>
                  </Box>
                )}

                {subscriptionDetails?.status !== 'inactive' &&
                  subscriptionDetails?.status !== 'canceled' && (
                    <Box sx={{ mt: 2 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => setOpenChangePlanDialog(true)}
                        sx={{ mr: 2 }}
                      >
                        Change Plan
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => setOpenCancelDialog(true)}
                      >
                        Cancel Subscription
                      </Button>
                    </Box>
                  )}

                {subscriptionDetails?.status === 'active' && (
                  <Alert severity="success" sx={{ mt: 2 }}>
                    Your subscription is active and in good standing.
                  </Alert>
                )}
                {subscriptionDetails?.status === 'past_due' && (
                  <Alert severity="warning" sx={{ mt: 2 }}>
                    Your payment is past due. Please update your payment method.
                  </Alert>
                )}
              </Paper>
            )}

            {cardDetails && (
              <Paper elevation={3} sx={{ p: 3, mb: 6 }}>
                <Typography variant="h6" gutterBottom>
                  Current Payment Method
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CreditCardIcon sx={{ mr: 2 }} />
                  <Typography>
                    **** **** **** {cardDetails.last4} (Expires:{' '}
                    {cardDetails.expMonth}/{cardDetails.expYear})
                  </Typography>
                </Box>
              </Paper>
            )}

            <Grid container spacing={4}>
              <Grid item xs={12} md={12}>
                <Typography variant="h5" gutterBottom>
                  Available Plans
                </Typography>
                <Grid container spacing={3}>
                  {plansData?.map((plan) => (
                    <Grid item xs={12} sm={6} key={plan.stripePriceId}>
                      <StyledCard>
                        <StyledCardContent>
                          <Typography variant="h6" component="div" gutterBottom>
                            {plan.name}
                          </Typography>
                          <Typography variant="h4" color="primary" gutterBottom>
                            ${plan.price} / {plan.interval}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            paragraph
                          >
                            {plan.description}
                          </Typography>
                          <List dense>
                            {planFeatures[plan.planTier]?.map(
                              (feature, index) => (
                                <ListItem key={index} disablePadding>
                                  <ListItemIcon>
                                    <CheckIcon
                                      color="primary"
                                      fontSize="small"
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={feature} />
                                </ListItem>
                              )
                            )}
                          </List>
                        </StyledCardContent>
                        <CardActions>
                          <Button
                            fullWidth
                            variant={
                              selectedPlan === plan.stripePriceId
                                ? 'contained'
                                : 'outlined'
                            }
                            onClick={() => handlePlanSelect(plan.stripePriceId)}
                          >
                            {selectedPlan === plan.stripePriceId
                              ? 'Selected'
                              : 'Select Plan'}
                          </Button>
                        </CardActions>
                      </StyledCard>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={12} md={12}>
                <Paper elevation={3} sx={{ p: 3 }}>
                  {subscriptionDetails?.status !== 'inactive' ? (
                    <>
                      <Typography variant="h5" gutterBottom>
                        Update Payment Method
                      </Typography>
                      <Divider sx={{ my: 2 }} />
                      <Elements stripe={stripePromise}>
                        <UpdatePaymentForm
                          onUpdatePaymentMethod={updatePaymentMethod}
                        />
                      </Elements>
                    </>
                  ) : (
                    selectedPlan &&
                    clientSecret && (
                      <>
                        <Typography variant="h5" gutterBottom>
                          Complete Subscription
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Elements
                          stripe={stripePromise}
                          options={{ clientSecret }}
                        >
                          <CheckoutForm
                            selectedPlan={selectedPlan}
                            organizationType={organizationType}
                            planTier={
                              plansData.find(
                                (plan) => plan.stripePriceId === selectedPlan
                              )?.planTier
                            }
                          />
                        </Elements>
                      </>
                    )
                  )}
                </Paper>
              </Grid>
            </Grid>
          </>
        )}

        {tabValue === 1 && (
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>
              Payment History
            </Typography>
            {paymentHistoryLoading ? (
              <CircularProgress />
            ) : paymentHistoryError ? (
              <Alert severity="error">Error loading payment history.</Alert>
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paymentHistory?.map((payment) => (
                      <TableRow key={payment.id}>
                        <TableCell>
                          {new Date(payment.date).toLocaleDateString()}
                        </TableCell>
                        <TableCell>${payment.amount.toFixed(2)}</TableCell>
                        <TableCell>{payment.status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        )}
      </Container>

      <Dialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
      >
        <DialogTitle>Cancel Subscription</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel your subscription? This action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCancelDialog(false)}>
            No, Keep My Subscription
          </Button>
          <Button onClick={handleCancelSubscription} color="secondary">
            Yes, Cancel Subscription
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openChangePlanDialog}
        onClose={() => setOpenChangePlanDialog(false)}
      >
        <DialogTitle>Change Subscription Plan</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the new plan you would like to switch to:
          </DialogContentText>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="new-plan-select-label">New Plan</InputLabel>
            <Select
              labelId="new-plan-select-label"
              value={newPlanId}
              onChange={(e) => setNewPlanId(e.target.value)}
              label="New Plan"
            >
              {plansData?.map((plan) => (
                <MenuItem key={plan.stripePriceId} value={plan.stripePriceId}>
                  {plan.name} - ${plan.price}/{plan.interval}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenChangePlanDialog(false)}>Cancel</Button>
          <Button onClick={handleChangePlan} color="primary">
            Change Plan
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SubscriptionComponent;

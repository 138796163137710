import React, { FC, ReactNode, useState } from 'react';
import { alpha, Box, lighten, useTheme, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { motion } from 'framer-motion';

import Sidebar from './Sidebar';
import Header from './Header';
import { SidebarProvider, useSidebar } from './sidebarContext';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { useDispatch } from 'react-redux';
import { baseApi } from 'src/redux/@api/base';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { staffApi } from 'src/redux/@api/staff';
import { residentsApi } from 'src/redux/@api/resident';
import { chatApi } from 'src/redux/@api/chat';
import { timesheetsApi } from 'src/redux/@api/timesheet';
import { paymentsApi } from 'src/redux/@api/payments';
import { shiftsApi } from 'src/redux/@api/shift';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const MainContent: FC = () => {
  const theme = useTheme();
  const { isExpanded } = useSidebar();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [isRefreshing, setIsRefreshing] = useState(false);
  const dispatch = useDispatch();

  const handleRefresh = async (): Promise<void> => {
    setIsRefreshing(true);
    try {
      const currentPath = location.pathname.split('/')[1];

      const invalidationActions = [];

      switch (currentPath) {
        case 'home-staffs':
        case 'agency-staffs':
        case 'staffs':
          invalidationActions.push(staffApi.util.invalidateTags(['Staffs']));
          break;
        case 'home-residents':
        case 'carer-residents':
          invalidationActions.push(
            residentsApi.util.invalidateTags(['Resident'])
          );
          break;
        case 'chat':
          invalidationActions.push(
            chatApi.util.invalidateTags(['ChatMessages', 'Staffs'])
          );
          break;
        case 'timesheets':
        case 'carer-timesheets':
        case 'nurse-timesheets':
        case 'home-timesheets':
          invalidationActions.push(
            timesheetsApi.util.invalidateTags(['Timesheets'])
          );
          break;
        case 'payments':
          // Add specific invalidation for payments if needed
          break;
        default:
          invalidationActions.push(shiftsApi.util.invalidateTags(['Shift']));
          break;
      }

      // Add a delay to make the loading more noticeable
      await new Promise((resolve) => setTimeout(resolve, 1500));

      // Dispatch all invalidation actions
      await Promise.all(invalidationActions.map((action) => dispatch(action)));

      setIsRefreshing(false);
    } catch (error) {
      console.error('Error during refresh:', error);
      setIsRefreshing(false);
      dispatch(
        showSnack({
          message: 'Failed to refresh. Please try again.',
          color: 'error'
        })
      );
    }
  };

  return (
    <Box
      component={motion.div}
      initial={{ paddingLeft: theme.sidebar.width }}
      animate={{
        paddingLeft: isMobile ? 0 : isExpanded ? theme.sidebar.width : '80px'
      }}
      transition={{ duration: 0.3 }}
    >
      <Header />

      <Box
        sx={{
          position: 'relative',
          zIndex: 5,
          display: 'block',
          flex: 1,
          pt: `${theme.header.height}`,
          [theme.breakpoints.up('lg')]: {}
        }}
      >
        <Box display="block">
          {/* <PullToRefresh onRefresh={handleRefresh}>
            <Outlet />
          </PullToRefresh> */}
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();

  return (
    <SidebarProvider>
      <Box
        sx={{
          flex: 1,
          height: '100%',
          backgroundColor: theme.colors.secondary.light,
          [theme.breakpoints.down('sm')]: {
            backgroundColor: 'white'
          },
          overflow: 'hidden',
          '.MuiPageTitle-wrapper': {
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                    lighten(theme.colors.primary.main, 0.7),
                    0.15
                  )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                    theme.colors.alpha.black[100],
                    0.1
                  )}, 0px 5px 12px -4px ${alpha(
                    theme.colors.alpha.black[100],
                    0.05
                  )}`
          }
        }}
      >
        <Sidebar />
        <MainContent />
      </Box>
    </SidebarProvider>
  );
};

export default SidebarLayout;

// leave.ts
import { baseApi } from './base';

export const leaveApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAnnualLeaveConfig: builder.query({
      query: () => `leave/annual-leave/config`
    }),
    configureAnnualLeave: builder.mutation({
      query: (config: { staffType: string; daysPerYear: number }) => ({
        url: `leave/annual-leave/configure`,
        method: 'POST',
        body: config
      })
    }),
    requestAnnualLeave: builder.mutation({
      query: (leaveRequest: { startDate: string; endDate: string }) => ({
        url: `leave/annual-leave/request`,
        method: 'POST',
        body: leaveRequest
      })
    }),
    getAnnualLeaveRequests: builder.query({
      query: ({
        status,
        staffId
      }: {
        status?: 'pending' | 'approved' | 'rejected';
        staffId?: string;
      }) =>
        `leave/annual-leave/requests/${staffId}${
          status ? `?status=${status}` : ''
        }`
    }),
    approveAnnualLeave: builder.mutation({
      query: (leaveId: string) => ({
        url: `leave/annual-leave/approve/${leaveId}`,
        method: 'POST'
      })
    }),
    getStaffAvailability: builder.query({
      query: (date: string) => `leave/annual-leave/availability?date=${date}`
    }),
    getRemainingLeaveDays: builder.query({
      query: () => `leave/annual-leave/remaining-days`
    }),
    cancelAnnualLeave: builder.mutation({
      query: (leaveId: string) => ({
        url: `leave/annual-leave/cancel/${leaveId}`,
        method: 'POST'
      })
    }),
    getLeaveHistory: builder.query({
      query: (year?: number) =>
        `leave/annual-leave/history${year ? `?year=${year}` : ''}`
    }),
  })
});

export const {
  useGetAnnualLeaveConfigQuery,
  useConfigureAnnualLeaveMutation,
  useRequestAnnualLeaveMutation,
  useGetAnnualLeaveRequestsQuery,
  useLazyGetAnnualLeaveRequestsQuery,
  useApproveAnnualLeaveMutation,
  useGetStaffAvailabilityQuery,
  useGetRemainingLeaveDaysQuery,
  useCancelAnnualLeaveMutation,
  useGetLeaveHistoryQuery,
} = leaveApi;
import {
  alpha,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import { useRef, useState } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { styled, useTheme } from '@mui/material/styles';

import { formatDistance, subDays } from 'date-fns';
import {
  useGetUnreadNotificationCountQuery,
  useMarkAllNotificationsAsReadMutation
} from 'src/redux/@api/notifications';
import NotificationsList from 'src/components/core/ui/NotificationsList';
import { CloseOutlined } from '@mui/icons-material';

function HeaderNotifications() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [bulkReadNotifications] = useMarkAllNotificationsAsReadMutation();
  const {
    data: unreadNotificationCount,
    isLoading: isUnreadNotificationCountLoading,
    isError: isUnreadNotificationCountError
  } = useGetUnreadNotificationCountQuery();

  console.log(unreadNotificationCount, 'andi');

  const handleOpen = async () => {
    const response = await bulkReadNotifications(undefined);
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const theme = useTheme();

  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          {unreadNotificationCount?.data ? (
            <Badge
              sx={{
                '& .MuiBadge-badge': {
                  padding: '0 4px',
                  backgroundColor: theme.colors.primary.main,
                  color: 'white',
                  boxShadow: '0.5px 0px 0px 0.1px rgba(0,0,0,0.2)'
                }
              }}
              badgeContent={
                (!isUnreadNotificationCountLoading &&
                  !isUnreadNotificationCountError &&
                  unreadNotificationCount?.data) ||
                ''
              }
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <NotificationsActiveTwoToneIcon />
            </Badge>
          ) : (
            <NotificationsActiveTwoToneIcon />
          )}
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          sx: {
            width: '96vw',
            height: '95vh',
            overflowY: 'hidden !important',
            [theme.breakpoints.up('sm')]: {
              width: '50vw'
            },
            [theme.breakpoints.up('md')]: {
              width: '30vw'
            }
          }
        }}
      >
        <Box sx={{ p: 2 }} display="flex" alignItems="center" height={'60px'}>
          <IconButton onClick={handleClose}>
            <CloseOutlined />
          </IconButton>
          <Typography variant="h5">Notifications</Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            height: 'calc(100% - 60px)',
            overflowY: 'auto'
          }}
        >
          <NotificationsList />
        </Box>
      </Popover>
    </>
  );
}

export default HeaderNotifications;

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  IconButton,
  Grid,
  CircularProgress,
  Divider,
  styled,
  Stack
} from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';

import moment from 'moment';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { Shift } from './types';
import { LoadingButton } from '@mui/lab';
import {
  useLazyGetOtherShiftPatternsQuery,
  useLazyGetYourShiftPatternsQuery,
  useUpdateShiftMutation,
  useCreateYourShiftTypeMutation,
  useUpdateYourShiftTypeMutation
} from 'src/redux/@api/shift';
import { useGetLinkedOrganizationsQuery } from 'src/redux/@api/organisation';
import ShiftTypeDialog from 'src/content/dashboards/home/views/AddShiftTypeDialog';
import { IShiftPattern } from 'src/interfaces/shift-pattern';

interface EditShiftDialogProps {
  open: boolean;
  onClose: () => void;
  shift: any | null;
  onEditShift: (updatedShift: Shift) => void;
}

interface ShiftFormData {
  selectedAgency: string;
  shifts: {
    shiftPatternId: string;
    count: number;
  }[];
  date: string;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '40vw !important',
    [theme.breakpoints.down('sm')]: {
      width: '95vw'
    }
  },
  '& .MuiDialogTitle-root': {},
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2)
  }
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

const EditShiftDialog: React.FC<EditShiftDialogProps> = ({
  open,
  onClose,
  shift,
  onEditShift
}) => {
  const [shiftPatterns, setShiftPatterns] = useState([]);
  const [isLoadingShiftPatterns, setIsLoadingShiftPatterns] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedShiftType, setSelectedShiftType] = useState<any>(null);

  const [updateShift, { isLoading: updateShiftLoading }] =
    useUpdateShiftMutation();
  const [createYourShiftType] = useCreateYourShiftTypeMutation();
  const [updateYourShiftType] = useUpdateYourShiftTypeMutation();

  const dispatch = useDispatch();
  const userState = useAppSelector((state) => state.userState);

  const { control, handleSubmit, reset, watch } = useForm<ShiftFormData>({
    defaultValues: {
      selectedAgency: 'internal',
      shifts: [{ shiftPatternId: '', count: 1 }],
      date: moment().format('YYYY-MM-DD')
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'shifts'
  });

  const { data: linkedOrgs } = useGetLinkedOrganizationsQuery('agency', {
    skip: !open
  });

  const selectedAgency = watch('selectedAgency');

  const [getYourShiftPatterns] = useLazyGetYourShiftPatternsQuery();
  const [getOtherShiftPatterns] = useLazyGetOtherShiftPatternsQuery();

  useEffect(() => {
    if (open && shift) {
      reset({
        selectedAgency: shift.agentId?._id || 'internal',
        shifts: [
          { shiftPatternId: shift.shiftPattern._id, count: shift.count }
        ],
        date: shift.date
      });
    }
  }, [open, shift, reset]);

  useEffect(() => {
    const fetchShiftPatterns = async () => {
      setIsLoadingShiftPatterns(true);
      try {
        let patterns;
        if (selectedAgency === 'internal') {
          const response = await getYourShiftPatterns(
            userState.currentOrganization?._id
          ).unwrap();
          patterns = response;
        } else {
          const response = await getOtherShiftPatterns(selectedAgency).unwrap();
          patterns = response;
        }
        setShiftPatterns(patterns);
      } catch (error) {
        console.log('Error fetching shift patterns:', error);
        dispatch(
          showSnack({
            message: 'Error fetching shift patterns',
            color: 'error'
          })
        );
      } finally {
        setIsLoadingShiftPatterns(false);
      }
    };

    if (open && selectedAgency) {
      fetchShiftPatterns();
    }
  }, [selectedAgency, open, userState.currentOrganization?._id]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSaveShiftType = async (shiftType: IShiftPattern) => {
    try {
      if (shiftType._id) {
        await updateYourShiftType(shiftType).unwrap();
        dispatch(
          showSnack({
            message: 'Shift Type updated successfully',
            color: 'success'
          })
        );
      } else {
        await createYourShiftType(shiftType).unwrap();
        dispatch(
          showSnack({
            message: 'Shift Type created successfully',
            color: 'success'
          })
        );
      }

      handleCloseDialog();
    } catch (error) {
      console.error(error);
      dispatch(
        showSnack({ message: 'Error saving shift type', color: 'error' })
      );
    }
  };

  const onSubmit = async (data: ShiftFormData) => {
    if (shift) {
      try {
        const updatedShift = await updateShift({
          _id: shift._id,
          shiftPattern: data.shifts[0].shiftPatternId,
          count: data.shifts[0].count,
          agentId:
            data.selectedAgency === 'internal' ? null : data.selectedAgency,
          date: data.date
        }).unwrap();
        onEditShift(updatedShift);
        dispatch(
          showSnack({ message: 'Shift updated successfully', color: 'success' })
        );
        onClose();
      } catch (error) {
        dispatch(
          showSnack({ message: 'Error updating shift', color: 'error' })
        );
      }
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Edit Shift</DialogTitle>
        <DialogContent>
          <Controller
            name="selectedAgency"
            control={control}
            rules={{ required: 'Agency is required' }}
            render={({ field }) => (
              <StyledFormControl size="small" fullWidth>
                <InputLabel>Agency</InputLabel>
                <Select {...field}>
                  <MenuItem value="internal">Internal</MenuItem>
                  {linkedOrgs?.map((org) => (
                    <MenuItem key={org._id} value={org._id}>
                      {org.name}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            )}
          />

          {isLoadingShiftPatterns ? (
            <Box display="flex" justifyContent="center" my={2}>
              <CircularProgress />
            </Box>
          ) : (
            fields.map((field, index) => (
              <Stack key={field.id} spacing={1} direction="row">
                <Stack spacing={1} width="70%">
                  <Controller
                    name={`shifts.${index}.shiftPatternId`}
                    control={control}
                    rules={{ required: 'Shift pattern is required' }}
                    render={({ field }) => (
                      <StyledFormControl size="small" fullWidth>
                        <InputLabel>Shift Pattern</InputLabel>
                        <Select size="small" {...field}>
                          {shiftPatterns?.map((pattern) => (
                            <MenuItem key={pattern._id} value={pattern._id}>
                              {pattern.name}
                            </MenuItem>
                          ))}
                          <Divider />
                          <MenuItem
                            onClick={() => {
                              setOpenDialog(true);
                              setSelectedShiftType(null);
                            }}
                          >
                            <AddIcon /> Add New Shift Pattern
                          </MenuItem>
                        </Select>
                      </StyledFormControl>
                    )}
                  />
                </Stack>
                <Stack spacing={1} width="30%" direction="row">
                  <Controller
                    name={`shifts.${index}.count`}
                    control={control}
                    rules={{ required: 'Count is required', min: 1 }}
                    render={({ field }) => (
                      <TextField
                        size="small"
                        {...field}
                        type="number"
                        label="Count"
                        fullWidth
                        InputProps={{ inputProps: { min: 1 } }}
                      />
                    )}
                  />
                  <IconButton
                    onClick={() => remove(index)}
                    sx={{
                      marginBlock: 'auto',
                      height: 'fit-content'
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              </Stack>
            ))
          )}

          <StyledButton
            onClick={() => append({ shiftPatternId: '', count: 1 })}
            variant="outlined"
            startIcon={<AddIcon />}
            size="small"
          >
            Add Another Shift
          </StyledButton>

          <Controller
            name="date"
            control={control}
            rules={{ required: 'Date is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                type="date"
                label="Date"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            loading={updateShiftLoading}
            type="submit"
            color="primary"
            variant="contained"
          >
            Update Shift
          </LoadingButton>
        </DialogActions>
      </form>
      <ShiftTypeDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onSave={handleSaveShiftType}
        selectedShiftType={selectedShiftType}
      />
    </StyledDialog>
  );
};

export default EditShiftDialog;

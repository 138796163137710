import React, { useState } from 'react';
import {
  Typography,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Card,
  CardContent,
  Box
} from '@mui/material';
import { useAppSelector } from 'src/redux/hook';
import dayjs from 'dayjs';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonIcon from '@mui/icons-material/Person';
import EventIcon from '@mui/icons-material/Event';
import HotelIcon from '@mui/icons-material/Hotel';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

const widgetColors = [
  { main: '#4caf50', light: '#81c784' }, // Green
  { main: '#2196f3', light: '#64b5f6' }, // Blue
  { main: '#ff9800', light: '#ffb74d' }, // Orange
  { main: '#e91e63', light: '#f06292' } // Pink
];

const StyledInfoCard = styled(Paper)<{ index: number }>(({ theme, index }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  color: theme.palette.getContrastText(widgetColors[index].main),
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4]
  }
}));

const IconWrapper = styled(Box)<{ index: number }>(({ theme, index }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 60,
  height: 60,
  borderRadius: '50%',
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  color: widgetColors[index].main
}));

const ChartCard = styled(Card)<{ gradientColors: string[] }>(
  ({ theme, gradientColors }) => ({
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    background: `linear-gradient(135deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 100%)`,
    position: 'relative',
    overflow: 'hidden'
  })
);

const ComingSoonOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  zIndex: 1
}));

const CareHomeOverviewTab = () => {
  const [shiftPeriod, setShiftPeriod] = useState('month');
  const shiftState = useAppSelector((state) => state.shifts.shifts);

  const occupancyChartOptions: ApexOptions = {
    chart: {
      type: 'area',
      height: 350,
      toolbar: { show: false }
    },
    dataLabels: { enabled: false },
    stroke: { curve: 'smooth', width: 2 },
    xaxis: {
      type: 'datetime',
      categories: [
        '2023-05-01',
        '2023-05-02',
        '2023-05-03',
        '2023-05-04',
        '2023-05-05',
        '2023-05-06',
        '2023-05-07'
      ]
    },
    tooltip: { x: { format: 'dd/MM/yy' } },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100]
      }
    },
    colors: ['#00E396']
  };

  const occupancyChartSeries = [
    { name: 'Occupancy', data: [30, 40, 35, 50, 49, 60, 70] }
  ];

  const staffingChartOptions: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: { show: false }
    },
    plotOptions: { bar: { horizontal: false, columnWidth: '55%' } },
    dataLabels: { enabled: false },
    stroke: { show: true, width: 2, colors: ['transparent'] },
    xaxis: { categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] },
    yaxis: { title: { text: 'Staff Count' } },
    fill: { opacity: 1 },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return `${val} staff`;
        }
      }
    },
    colors: ['#008FFB', '#00E396']
  };

  const staffingChartSeries = [
    { name: 'Day Shift', data: [44, 55, 57, 56, 61, 58, 63] },
    { name: 'Night Shift', data: [76, 85, 101, 98, 87, 105, 91] }
  ];

  return (
    <Box sx={{}}>
      <Grid container spacing={1}>
        {[
          { icon: PeopleAltIcon, title: 'Number of Residents', value: 45 },
          { icon: PersonIcon, title: 'Staff Members', value: 32 },
          { icon: EventIcon, title: 'Shifts Scheduled', value: null },
          { icon: HotelIcon, title: 'Bed Capacity', value: 50 }
        ].map((item, index) => (
          <Grid item xs={6} sm={6} md={3} key={index} p={1}>
            <StyledInfoCard index={index}>
              <IconWrapper index={index}>
                <item.icon fontSize="large" />
              </IconWrapper>
              <Typography variant="subtitle1" gutterBottom>
                {item.title}
              </Typography>
              {item.value !== null ? (
                <Typography variant="h4">{item.value}</Typography>
              ) : (
                <>
                  <FormControl fullWidth size="small" sx={{ mb: 1 }}>
                    <InputLabel>Period</InputLabel>
                    <Select
                      value={shiftPeriod}
                      onChange={(e) => setShiftPeriod(e.target.value as string)}
                      label="Period"
                    >
                      <MenuItem value="month">This Month</MenuItem>
                      <MenuItem value="year">This Year</MenuItem>
                    </Select>
                  </FormControl>
                  <Typography variant="h4">
                    {shiftPeriod === 'month'
                      ? shiftState.filter(
                          (shift) =>
                            dayjs(shift.date).month() === dayjs().month()
                        ).length || 0
                      : shiftState.filter(
                          (shift) => dayjs(shift.date).year() === dayjs().year()
                        ).length || 0}
                  </Typography>
                </>
              )}
            </StyledInfoCard>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={3} sx={{ mt: 0 }}>
        <Grid item xs={12} md={6}>
          <ChartCard gradientColors={['#b3e5fc', '#4fc3f7']}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
                Occupancy Trend
              </Typography>
              <ReactApexChart
                options={occupancyChartOptions}
                series={occupancyChartSeries}
                type="area"
                height={350}
              />
            </CardContent>
            <ComingSoonOverlay>
              <Typography variant="h5" fontWeight="bold">
                Coming Soon
              </Typography>
            </ComingSoonOverlay>
          </ChartCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <ChartCard gradientColors={['#c8e6c9', '#81c784']}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
                Staffing Overview
              </Typography>
              <ReactApexChart
                options={staffingChartOptions}
                series={staffingChartSeries}
                type="bar"
                height={350}
              />
            </CardContent>
            <ComingSoonOverlay>
              <Typography variant="h5" fontWeight="bold">
                Coming Soon
              </Typography>
            </ComingSoonOverlay>
          </ChartCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CareHomeOverviewTab;

import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Avatar,
  Box,
  Chip,
  IconButton
} from '@mui/material';
import {
  ChevronDown,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Phone as PhoneIcon,
  Info as InfoIcon
} from 'lucide-react';
import { Email } from '@mui/icons-material';

interface OtherStaff {
  id: number;
  name: string;
  role: string;
  email: string;
  phone: string;
  notes?: string;
}

const OtherStaffAccordion: React.FC<{ staff: OtherStaff }> = ({ staff }) => (
  <Accordion>
    <AccordionSummary
      expandIcon={<ChevronDown />}
      aria-controls={`other-staff-content-${staff.id}`}
      id={`other-staff-header-${staff.id}`}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        <Avatar sx={{ bgcolor: 'info.main', mr: 2 }}>{staff.name[0]}</Avatar>
        <Typography>{staff.name}</Typography>
        <Chip
          label={staff.role.replace('_', ' ')}
          color="info"
          size="small"
          sx={{ ml: 2 }}
        />
      </Box>
      <Box>
        <IconButton
          size="small"
          color="info"
          onClick={(e) => {
            e.stopPropagation(); /* Handle edit */
          }}
        >
          <EditIcon size={16} />
        </IconButton>
        <IconButton
          size="small"
          color="error"
          onClick={(e) => {
            e.stopPropagation(); /* Handle delete */
          }}
        >
          <DeleteIcon size={16} />
        </IconButton>
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Email sx={{ marginRight: 8 }} />
          <Typography variant="body2">{staff.email}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PhoneIcon size={16} style={{ marginRight: 8 }} />
          <Typography variant="body2">{staff.phone}</Typography>
        </Box>
        {staff.notes && (
          <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <InfoIcon size={16} style={{ marginRight: 8, marginTop: 4 }} />
            <Typography variant="body2">{staff.notes}</Typography>
          </Box>
        )}
      </Box>
    </AccordionDetails>
  </Accordion>
);

const OtherStaffList: React.FC<{ staffList: OtherStaff[] }> = ({
  staffList
}) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
    {staffList.map((staff) => (
      <OtherStaffAccordion key={staff.id} staff={staff} />
    ))}
  </Box>
);

export default OtherStaffList;

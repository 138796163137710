import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  Paper,
  Chip,
  Autocomplete,
  CircularProgress,
  Avatar,
  IconButton,
  Divider,
  Snackbar,
  Alert
} from '@mui/material';
import {
  PhotoCamera as PhotoCameraIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { ICarePlan, IResident } from 'src/interfaces/resident';
import { apiHostname } from 'src/api/api';
import axios from 'axios';
import {
  useCreateCarePlanMutation,
  useDeleteResidentMutation,
  useGetCarePlanQuery,
  useGetResidentQuery,
  useUpdateCarePlanMutation,
  useUpdateResidentMutation
} from 'src/redux/@api/resident';
import { DeleteIcon } from 'lucide-react';
import DeleteResidentDialog from './deletion';
import { useDispatch } from 'react-redux';
import {
  updateResidentProfilePicture,
  updateResident as updateResidentState
} from 'src/redux/reducers/residents';

interface ResidentProfileProps {
  residentId: string;
  onClose: () => void;
}

const ResidentProfile: React.FC<ResidentProfileProps> = ({
  residentId,
  onClose
}) => {
  const theme = useTheme();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { data: resident, isLoading: isLoadingResident } =
    useGetResidentQuery(residentId);
  const { data: carePlan, isLoading: isLoadingCarePlan } =
    useGetCarePlanQuery(residentId);
  const [updateResident, { isLoading: isUpdatingResident }] =
    useUpdateResidentMutation();
  const [updateCarePlan, { isLoading: isUpdatingCarePlan }] =
    useUpdateCarePlanMutation();
  const [createCarePlan, { isLoading: isCreatingCarePlan }] =
    useCreateCarePlanMutation();
  const [deleteResident] = useDeleteResidentMutation();

  const dispatch = useDispatch();

  const [isUploading, setIsUploading] = useState(false);
  const [personalInfo, setPersonalInfo] = useState<IResident>({
    firstName: '',
    lastName: '',
    dateOfBirth: new Date(),
    roomNumber: '',
    admissionDate: new Date(),
    medicalConditions: [],
    allergies: [],
    group: null,
    emergencyContact: {
      name: '',
      relationship: '',
      phone: ''
    },
    tasks: [],
    taskSummary: {
      upcoming: 0,
      pending: 0,
      overdue: 0,
      total: 0,
      urgency: 'white'
    },
    profilePictureUrl: '',
    carePlan: '' as any
  });
  const [carePlanInfo, setCarePlanInfo] = useState<Partial<ICarePlan>>({
    mobilityAssistance: '',
    feedingRequirements: '',
    medicationNeeds: '',
    allergies: '',
    mentalHealth: '',
    behaviouralNotes: '',
    specialInstructions: ''
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error'
  });

  useEffect(() => {
    if (resident) {
      setPersonalInfo(resident);
    }
  }, [resident]);

  useEffect(() => {
    if (carePlan) {
      setCarePlanInfo(carePlan);
    }
  }, [carePlan]);

  const handlePersonalInfoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setPersonalInfo((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleEmergencyContactChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setPersonalInfo((prev) => ({
      ...prev,
      emergencyContact: {
        ...prev.emergencyContact,
        [name]: value
      }
    }));
  };

  const handleCarePlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCarePlanInfo((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setPersonalInfo((prev) => ({
        ...prev,
        dateOfBirth: date
      }));
    }
  };

  const handleSavePersonalInfo = async () => {
    try {
      const residentData: Partial<IResident> = {
        firstName: personalInfo.firstName,
        lastName: personalInfo.lastName,
        dateOfBirth: personalInfo.dateOfBirth,
        roomNumber: personalInfo.roomNumber,
        admissionDate: personalInfo.admissionDate,
        medicalConditions: personalInfo.medicalConditions,
        allergies: personalInfo.allergies,
        group: personalInfo.group,
        emergencyContact: personalInfo.emergencyContact,
        profilePictureUrl: personalInfo.profilePictureUrl
      };
      await updateResident({ id: residentId, residentData }).unwrap();
      setSnackbar({
        open: true,
        message: 'Personal information saved successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error saving personal info:', error);
      setSnackbar({
        open: true,
        message: 'Error saving personal information',
        severity: 'error'
      });
    }
  };

  const handleSaveCarePlan = async () => {
    try {
      if (carePlan) {
        await updateCarePlan({ residentId, ...carePlanInfo }).unwrap();
      } else {
        await createCarePlan({ residentId, ...carePlanInfo }).unwrap();
      }
      setSnackbar({
        open: true,
        message: 'Care plan saved successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error saving care plan:', error);
      setSnackbar({
        open: true,
        message: 'Error saving care plan',
        severity: 'error'
      });
    }
  };

  const compressImage = (file: File, maxSizeMB = 2): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target?.result as string;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          // Calculate new dimensions while maintaining aspect ratio
          if (width > height) {
            if (width > 1920) {
              height *= 1920 / width;
              width = 1920;
            }
          } else {
            if (height > 1920) {
              width *= 1920 / height;
              height = 1920;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img, 0, 0, width, height);

          const compressAndCheck = (quality: number) => {
            canvas.toBlob(
              (blob) => {
                if (blob) {
                  if (blob.size > maxSizeMB * 1024 * 1024 && quality > 0.1) {
                    compressAndCheck(quality - 0.05);
                  } else {
                    resolve(blob);
                  }
                } else {
                  reject(new Error('Failed to compress image'));
                }
              },
              'image/jpeg',
              quality
            );
          };

          compressAndCheck(0.9); // Start with 90% quality
        };
      };

      reader.onerror = (error) => reject(error);
    });
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsUploading(true);
      try {
        // Compress the image
        const compressedBlob = await compressImage(file);
        const compressedFile = new File([compressedBlob], file.name, {
          type: 'image/jpeg'
        });

        const formData = new FormData();
        formData.append('file', compressedFile);

        const response = await axios.post(
          `${apiHostname}/api/v1/pictures/resident/${residentId}/upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            timeout: 30000
          }
        );

        if (response.data.success) {
          setPersonalInfo((prev) => ({
            ...prev,
            profilePictureUrl: response.data.data.avatarUrl
          }));
          dispatch(
            updateResidentProfilePicture({
              residentId,
              profilePictureUrl: response.data.data.avatarUrl
            })
          );
          setSnackbar({
            open: true,
            message: 'Profile picture updated successfully',
            severity: 'success'
          });
        } else {
          throw new Error(response.data.error);
        }
      } catch (error) {
        console.error('Error uploading profile picture:', error);
        setSnackbar({
          open: true,
          message: 'Error uploading profile picture',
          severity: 'error'
        });
      } finally {
        setIsUploading(false);
      }
    }
  };

  if (isLoadingResident || isLoadingCarePlan) {
    return <CircularProgress />;
  }

  if (!resident) {
    return <Typography>No resident data available</Typography>;
  }

  const handleDeleteResident = async () => {
    try {
      await deleteResident(residentId).unwrap();
      setSnackbar({
        open: true,
        message: 'Resident deleted successfully',
        severity: 'success'
      });
      onClose();
      // Redirect to residents list or handle navigation as needed
    } catch (error) {
      console.error('Error deleting resident:', error);
      setSnackbar({
        open: true,
        message: 'Error deleting resident',
        severity: 'error'
      });
    }
    setDeleteDialogOpen(false);
  };

  const isMobile = theme.breakpoints.down('sm');

  return (
    <Box sx={{ width: '100%', margin: 'auto' }}>
      <Paper elevation={3} sx={{ borderRadius: 2 }}>
        <Grid container spacing={4}>
          {/* Header Section */}
          <Grid item xs={12} sx={{ mb: 2, position: 'relative' }}>
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems={isMobile ? 'center' : 'flex-start'}
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flexDirection={isMobile ? 'column' : 'row'}
                alignItems={isMobile ? 'center' : 'flex-start'}
                width="100%"
              >
                <Box
                  position="relative"
                  sx={{ mb: isMobile ? 2 : 0, mr: isMobile ? 0 : 3 }}
                >
                  <Avatar
                    src={personalInfo.profilePictureUrl}
                    sx={{
                      width: 120,
                      height: 120
                    }}
                  />
                  {isUploading && (
                    <CircularProgress
                      size={130}
                      thickness={2}
                      sx={{
                        position: 'absolute',
                        top: -5,
                        left: -5,
                        zIndex: 1
                      }}
                    />
                  )}
                </Box>
                <Box
                  textAlign={isMobile ? 'center' : 'left'}
                  width={isMobile ? '100%' : 'auto'}
                >
                  <Typography variant={isMobile ? 'h5' : 'h4'} gutterBottom>
                    {personalInfo.firstName} {personalInfo.lastName}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    gutterBottom
                  >
                    Room: {personalInfo.roomNumber} | Group:{' '}
                    {personalInfo.group}
                  </Typography>
                  <Box mt={1}>
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="icon-button-file"
                      type="file"
                      onChange={handleImageUpload}
                    />
                    <label htmlFor="icon-button-file">
                      <Button
                        variant="outlined"
                        component="span"
                        startIcon={<PhotoCameraIcon />}
                        size="small"
                      >
                        Update Photo
                      </Button>
                    </label>
                  </Box>
                </Box>
              </Box>
              {!isMobile && (
                <Box mt={isMobile ? 2 : 0} width={isMobile ? '100%' : 'auto'}>
                  <Button
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={handleDeleteResident}
                  >
                    Delete Resident
                  </Button>
                </Box>
              )}
              {isMobile && (
                <IconButton
                  color="error"
                  onClick={() => {
                    setDeleteDialogOpen(true);
                  }}
                  sx={{
                    position: 'absolute',
                    top: '20%',
                    right: 0
                  }}
                  aria-label="delete resident"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          </Grid>

          {/* Personal Information Section */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Personal Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="First Name"
                  name="firstName"
                  value={personalInfo.firstName || ''}
                  onChange={handlePersonalInfoChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  value={personalInfo.lastName || ''}
                  onChange={handlePersonalInfoChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Date of Birth"
                  value={personalInfo.dateOfBirth || null}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Room Number"
                  name="roomNumber"
                  value={personalInfo.roomNumber || ''}
                  onChange={handlePersonalInfoChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Group"
                  name="group"
                  value={personalInfo.group || ''}
                  onChange={handlePersonalInfoChange}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Medical Information Section */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Medical Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={[]}
                  freeSolo
                  value={personalInfo.medicalConditions || []}
                  onChange={(_, newValue) =>
                    setPersonalInfo((prev: any) => ({
                      ...prev,
                      medicalConditions: newValue
                    }))
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                        key={index}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Medical Conditions"
                      placeholder="Add medical condition"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={[]}
                  freeSolo
                  value={personalInfo.allergies || []}
                  onChange={(_, newValue) =>
                    setPersonalInfo((prev: any) => ({
                      ...prev,
                      allergies: newValue
                    }))
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                        key={index}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Allergies"
                      placeholder="Add allergy"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Emergency Contact Section */}
          <Grid item xs={12}>
            <Divider sx={{ my: 3 }} />
            <Typography variant="h6" gutterBottom>
              Emergency Contact
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={personalInfo.emergencyContact?.name || ''}
                  onChange={handleEmergencyContactChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Relationship"
                  name="relationship"
                  value={personalInfo.emergencyContact?.relationship || ''}
                  onChange={handleEmergencyContactChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Phone"
                  name="phone"
                  value={personalInfo.emergencyContact?.phone || ''}
                  onChange={handleEmergencyContactChange}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Save Personal Info Button */}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                onClick={handleSavePersonalInfo}
                variant="contained"
                color="primary"
                disabled={isUpdatingResident}
              >
                {isUpdatingResident ? 'Saving...' : 'Save Personal Information'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* Care Plan Section */}
      <Paper elevation={3} sx={{ mt: 4, p: 4, borderRadius: 2 }}>
        <Typography variant="h5" gutterBottom>
          Care Plan
        </Typography>
        <Grid container spacing={3}>
          {[
            { title: 'Mobility Assistance', name: 'mobilityAssistance' },
            { title: 'Feeding Requirements', name: 'feedingRequirements' },
            { title: 'Medication Needs', name: 'medicationNeeds' },
            { title: 'Allergies', name: 'allergies' },
            { title: 'Mental Health', name: 'mentalHealth' },
            { title: 'Behavioural Notes', name: 'behaviouralNotes' },
            { title: 'Special Instructions', name: 'specialInstructions' }
          ].map((item, index) => (
            <Grid item xs={12} key={index}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{item.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    label={item.title}
                    name={item.name}
                    value={carePlanInfo[item.name] || ''}
                    onChange={handleCarePlanChange}
                    variant="outlined"
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ mt: 3 }}>
          <Button
            onClick={handleSaveCarePlan}
            variant="contained"
            color="primary"
            disabled={isUpdatingCarePlan || isCreatingCarePlan}
          >
            {isUpdatingCarePlan || isCreatingCarePlan
              ? 'Saving...'
              : carePlan
              ? 'Update Care Plan'
              : 'Create Care Plan'}
          </Button>
        </Box>
      </Paper>
      <DeleteResidentDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDeleteResident}
      />
    </Box>
  );
};

export default ResidentProfile;

// misc.ts
import { baseApi } from './base';

export const miscApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompany: builder.query({
      query: () => `timeline/current-company`
    }),
    getPreviousCompany: builder.query({
      query: () => `timeline/previous-companies`
    }),
    uploadProfilePicture: builder.mutation({
      query: ({ userId, file }) => ({
        url: `/picture/${userId}/upload`,
        method: 'POST',
        body: file,
        formData: true
      })
    }),
    getThirdPartyUsers: builder.query({
      query: () => `auth/third-party`
    }),
    getHandoverData: builder.query({
      query: (homeId) => `handovers/latest/${homeId}`
    }),
    initiateHandover: builder.mutation({
      query: (homeId) => ({
        url: `handovers/initiate/${homeId}`,
        method: 'POST'
      })
    }),
    completeHandover: builder.mutation({
      query: ({ handoverId, notes }) => ({
        url: `handovers/complete/${handoverId}`,
        method: 'POST',
        body: { notes }
      })
    }),
    getHandoverHistory: builder.query({
      query: ({ homeId, startDate, endDate }) =>
        `handovers/history/${homeId}?startDate=${startDate}&endDate=${endDate}`
    }),
    getCareStaffs: builder.query({
      query: () => '/staffs/care'
    }),
    getAdminStaffs: builder.query({
      query: () => '/staffs/admin'
    }),
  })
});

export const {
  useGetCompanyQuery,
  useLazyGetCompanyQuery,
  useGetPreviousCompanyQuery,
  useLazyGetPreviousCompanyQuery,
  useUploadProfilePictureMutation,
  useGetThirdPartyUsersQuery,
  useLazyGetThirdPartyUsersQuery,
  useGetHandoverDataQuery,
  useInitiateHandoverMutation,
  useCompleteHandoverMutation,
  useGetHandoverHistoryQuery,
  useGetCareStaffsQuery,
  useGetAdminStaffsQuery,
} = miscApi;
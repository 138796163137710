import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Divider
} from '@mui/material';
import { Save, DirectionsCar, Public, NoteAdd } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import {
  useGetCarerApplicationQuery,
  useUpdateApplicationSectionMutation
} from 'src/redux/@api/carer-application';

interface AdditionalInfoProps {
  initialData?: any;
  onSubmit: (data: any, index: number | undefined, section: string) => void;
  selectedSection: string;
  editable?: boolean;
}

const AdditionalInfo: React.FC<AdditionalInfoProps> = ({
  initialData,
  onSubmit,
  selectedSection,
  editable = true
}) => {
  const dispatch = useDispatch();
  const { data: carerApplication, refetch } =
    useGetCarerApplicationQuery(undefined);
  const [updateApplicationSection] = useUpdateApplicationSectionMutation();

  const [saving, setSaving] = useState(false);

  const defaultValues = editable
    ? carerApplication?.data[selectedSection] || {
        hasTransport: false,
        willingToTravel: false,
        maxTravelDistance: 0,
        additionalNotes: ''
      }
    : initialData || {};

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues
  });

  useEffect(() => {
    if (editable && carerApplication?.data[selectedSection]) {
      reset(carerApplication.data[selectedSection]);
    } else if (!editable && initialData) {
      reset(initialData);
    }
  }, [carerApplication, selectedSection, reset, editable, initialData]);

  const handleSave = async (data: any) => {
    if (!editable) return;
    setSaving(true);
    try {
      dispatch(
        showSnack({
          message: 'Saving additional information...',
          color: 'info'
        })
      );
      await updateApplicationSection({
        section: 'additionalInfo',
        data
      });
      await refetch();
      dispatch(
        showSnack({
          message: 'Additional information saved successfully',
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Save failed:', error);
      dispatch(
        showSnack({
          message: 'Failed to save additional information',
          color: 'error'
        })
      );
    } finally {
      setSaving(false);
    }
  };

  const renderCheckbox = (
    name: string,
    label: string,
    icon: React.ReactNode
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) =>
        editable ? (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label={label}
            sx={{ display: 'flex', alignItems: 'center' }}
          />
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            {icon}
            <Typography variant="body1" sx={{ ml: 2 }}>
              <strong>{label}:</strong> {field.value ? 'Yes' : 'No'}
            </Typography>
          </Box>
        )
      }
    />
  );

  return (
    <Card elevation={3}>
      <CardContent>
        <Typography variant="h5" gutterBottom color="primary">
          Additional Information
        </Typography>
        <Divider sx={{ mb: 3 }} />
        <form onSubmit={handleSubmit(handleSave)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              {renderCheckbox(
                'hasTransport',
                'Do you have your own transport?',
                <DirectionsCar color="primary" />
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              {renderCheckbox(
                'willingToTravel',
                'Are you willing to travel?',
                <Public color="primary" />
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="maxTravelDistance"
                control={control}
                rules={{
                  min: { value: 0, message: 'Distance cannot be negative' },
                  max: { value: 1000, message: 'Distance seems too high' }
                }}
                render={({ field }) =>
                  editable ? (
                    <TextField
                      {...field}
                      label="Maximum Travel Distance"
                      type="number"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Public color="primary" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">miles</InputAdornment>
                        )
                      }}
                      error={!!errors.maxTravelDistance}
                      helperText={errors.maxTravelDistance?.message}
                    />
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Public color="primary" />
                      <Typography variant="body1" sx={{ ml: 2 }}>
                        <strong>Maximum Travel Distance:</strong> {field.value}{' '}
                        miles
                      </Typography>
                    </Box>
                  )
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="additionalNotes"
                control={control}
                render={({ field }) =>
                  editable ? (
                    <TextField
                      {...field}
                      label="Additional Notes"
                      multiline
                      rows={4}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <NoteAdd color="primary" />
                          </InputAdornment>
                        )
                      }}
                    />
                  ) : (
                    <Box
                      sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}
                    >
                      <NoteAdd color="primary" sx={{ mt: 0.5 }} />
                      <Typography variant="body1" sx={{ ml: 2 }}>
                        <strong>Additional Notes:</strong>
                        <br />
                        {field.value || 'No additional notes provided.'}
                      </Typography>
                    </Box>
                  )
                }
              />
            </Grid>
          </Grid>
        </form>
      </CardContent>
      {editable && (
        <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={saving ? <CircularProgress size={20} /> : <Save />}
            disabled={saving}
            onClick={handleSubmit(handleSave)}
          >
            {saving ? 'Saving...' : 'Save Additional Information'}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default AdditionalInfo;

import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Avatar,
  Box,
  Chip,
  IconButton,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress
} from '@mui/material';
import {
  Delete,
  DeleteOutlineOutlined,
  Edit,
  Email,
  Phone
} from '@mui/icons-material';
import { getRoleColor } from './util';
import type { IStaff } from 'src/interfaces/staffs';
import { ChevronsDownIcon, Briefcase } from 'lucide-react';
import { useGetAdminStaffsQuery } from 'src/redux/@api/misc';

const AdminStaffAccordion: React.FC<{ staff: IStaff }> = ({ staff }) => (
  <Accordion>
    <AccordionSummary
      expandIcon={<ChevronsDownIcon />}
      aria-controls={`admin-staff-content-${staff._id}`}
      id={`admin-staff-header-${staff._id}`}
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        <Avatar sx={{ bgcolor: 'secondary.main', mr: 2 }}>
          {staff.user?.firstName[0]}
        </Avatar>
        <Typography variant="body2">
          {staff.user?.firstName} {staff.user?.lastName}
        </Typography>
        <Chip
          label={staff.role.replace('_', ' ')}
          color={getRoleColor(staff.role)}
          size="small"
          sx={{ ml: 2 }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          marginY: 'auto'
        }}
      >
        <IconButton
          size="small"
          sx={{ display: 'flex', margin: 'auto', height: '100%' }}
          color="primary"
          onClick={(e) => {
            e.stopPropagation(); /* Handle edit */
          }}
        >
          <Edit fontSize="medium" />
        </IconButton>
        <IconButton
          size="small"
          sx={{ display: 'flex', marginBlock: 'auto', height: '100%' }}
          color="error"
          onClick={(e) => {
            e.stopPropagation(); /* Handle delete */
          }}
        >
          <DeleteOutlineOutlined fontSize="medium" />
        </IconButton>
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Briefcase size={16} style={{ marginRight: 8 }} />
          <Typography variant="body2">{staff.staffType}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Email fontSize={'medium'} sx={{ mr: 1 }} />
          <Typography variant="body2">{staff.user?.email}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Phone fontSize={'medium'} sx={{ mr: 1 }} />
          <Typography variant="body2">{staff.user?.phone}</Typography>
        </Box>
      </Box>
    </AccordionDetails>
  </Accordion>
);

const AdminStaffList: React.FC = () => {
  const [filter, setFilter] = useState<string>('all');
  const [staffs, setAdminStaffs] = useState<IStaff[]>([]);

  const {
    data: adminStaffs,
    isLoading,
    isError,
    error,
    refetch
  } = useGetAdminStaffsQuery(undefined);

  useEffect(() => {
    if (adminStaffs) {
      setAdminStaffs(adminStaffs);
    }
  }, [adminStaffs]);

  const filteredStaff =
    filter === 'all' ? staffs : staffs.filter((staff) => staff.role === filter);

  const roles = ['all', ...new Set(staffs.map((staff) => staff.role))];

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '300px'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <Typography variant="body2">
          An error occurred while fetching admin staff data.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ width: 220 + '!important' }}>
        <FormControl size="small">
          <InputLabel>Filter by Role</InputLabel>
          <Select
            size="small"
            value={filter}
            label="Filter by Role"
            onChange={(e) => setFilter(e.target.value as string)}
          >
            {roles.map((role) => (
              <MenuItem key={role} value={role}>
                {role === 'all' ? 'All Roles' : role.replace('_', ' ')}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {filteredStaff.length > 0 ? (
        <Grid container spacing={2}>
          {filteredStaff.map((staff) => (
            <Grid item xs={12} md={6} key={staff._id}>
              <AdminStaffAccordion staff={staff} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          sx={{
            textAlign: 'center',
            py: 4,
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography variant="body2">
            {filter === 'all'
              ? 'There are no admin staff members in the system.'
              : `There are no ${filter.replace('_', ' ')} staff members.`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default AdminStaffList;

import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  Collapse,
  IconButton,
  Button,
  Grid,
  Divider
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SaveIcon from '@mui/icons-material/Save';
import { fieldVisibilityMap } from './fieldmap';

const fieldsWithCheckboxes = [
  'personalInfo.title',
  'personalInfo.firstName',
  'personalInfo.lastName',
  'personalInfo.middleName',
  'personalInfo.preferredName',
  'personalInfo.dateOfBirth',
  'personalInfo.gender',
  'personalInfo.nationalInsuranceNumber',
  'personalInfo.phone',
  'personalInfo.email',
  'personalInfo.jobTitle',
  // Add other fields from different sections as needed
  'professionalInfo.hasWorkExperience',
  'healthAndSafety.healthDeclaration.hasHealthConditions',
  'availability.isFlexible'
];

const FieldVisibilityCheckboxes = ({ initialData, onSave }) => {
  const [fieldVisibility, setFieldVisibility] = useState(initialData);
  const [expandedSections, setExpandedSections] = useState({});

  useEffect(() => {
    setFieldVisibility(initialData);
    const allExpanded = {};
    Object.keys(fieldVisibilityMap).forEach((section) => {
      allExpanded[section] = false;
    });
    setExpandedSections(allExpanded);
  }, [initialData]);

  const handleCheckboxChange = (path) => (event) => {
    const checked = event.target.checked;
    setFieldVisibility((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      let current = newState;
      for (let i = 0; i < path.length - 1; i++) {
        if (!(path[i] in current)) {
          current[path[i]] = {};
        }
        current = current[path[i]];
      }

      const propagateCheck = (obj, value) => {
        if (typeof obj === 'boolean') {
          return value;
        }
        if (typeof obj !== 'object' || obj === null) {
          return obj;
        }
        const newObj = {};
        Object.keys(obj).forEach((key) => {
          newObj[key] = propagateCheck(obj[key], value);
        });
        return newObj;
      };

      if (
        path.length === 1 ||
        typeof current[path[path.length - 1]] === 'object'
      ) {
        current[path[path.length - 1]] = propagateCheck(
          current[path[path.length - 1]],
          checked
        );
      } else {
        current[path[path.length - 1]] = checked;
      }
      return newState;
    });
  };

  const toggleExpand = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const handleSave = () => {
    onSave(fieldVisibility);
  };

  const isChecked = (obj) => {
    if (typeof obj === 'boolean') return obj;
    if (typeof obj !== 'object' || obj === null) return false;
    return Object.values(obj).some(isChecked);
  };

  const hasExpandableContent = (obj) => {
    return Object.values(obj).some(
      (value) => typeof value === 'object' && value !== null
    );
  };

  const renderCheckboxes = (obj, mapObj, path = [], level = 0) => {
    return Object.entries(obj).map(([key, value]) => {
      const currentPath = [...path, key];
      const currentMapObj = mapObj?.fields?.[key] || mapObj?.[key] || {};
      const label =
        typeof currentMapObj === 'string'
          ? currentMapObj
          : currentMapObj.label || key;

      if (['consents', 'applicationStatus'].includes(key)) return null;

      if (typeof value === 'object' && value !== null) {
        const checked = isChecked(value);
        const isExpandable = hasExpandableContent(value);
        return (
          <Box key={currentPath.join('.')} sx={{ ml: level * 0.3, my: 0 }}>
            <Box display="flex" alignItems="center">
              {isExpandable && (
                <IconButton
                  onClick={() => toggleExpand(currentPath.join('.'))}
                  size="small"
                  sx={{ padding: 0, mr: 1 }}
                >
                  {expandedSections[currentPath.join('.')] ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              )}
              {!isExpandable && <Box sx={{ width: 24, mr: 1 }} />}{' '}
              {/* Spacer when no icon */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleCheckboxChange(currentPath)}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {label}
                  </Typography>
                }
              />
            </Box>
            {isExpandable && (
              <Collapse in={expandedSections[currentPath.join('.')]}>
                <Box sx={{ ml: 1, borderLeft: '1px solid #e0e0e0', pl: 2 }}>
                  {renderCheckboxes(
                    value,
                    currentMapObj,
                    currentPath,
                    level + 1
                  )}
                </Box>
              </Collapse>
            )}
          </Box>
        );
      } else if (fieldsWithCheckboxes.includes(currentPath.join('.'))) {
        return (
          <FormControlLabel
            key={currentPath.join('.')}
            control={
              <Checkbox
                checked={value as boolean}
                onChange={handleCheckboxChange(currentPath)}
                color="primary"
              />
            }
            label={<Typography variant="body2">{label}</Typography>}
            sx={{ my: 0.1 }}
          />
        );
      } else if (typeof value !== 'boolean') {
        return (
          <Typography
            key={currentPath.join('.')}
            variant="body2"
            sx={{ my: 0.1 }}
          >
            {label}
          </Typography>
        );
      }
      return null;
    });
  };

  return (
    <Box sx={{ p: 3, margin: 'auto' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {Object.entries(fieldVisibility).map(([sectionKey, sectionValue]) => (
            <Box key={sectionKey}>
              {renderCheckboxes(
                { [sectionKey]: sectionValue },
                fieldVisibilityMap[sectionKey]
              )}
            </Box>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSave}
            >
              Save Changes
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FieldVisibilityCheckboxes;

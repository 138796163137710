import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Card,
  CardContent,
  CardActions,
  Tooltip,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  useMediaQuery,
  Paper
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Add,
  Delete,
  CloudUpload,
  Visibility,
  Save,
  AttachFile
} from '@mui/icons-material';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { apiHostname } from 'src/api/api';
import {
  useDeleteArrayItemMutation,
  useGetCarerApplicationQuery,
  useUpdateApplicationSectionMutation,
  useDeleteDocumentMutation
} from 'src/redux/@api/carer-application';

interface ProfessionalInformationProps {
  initialData?: any;
  onSubmit: (data: any, index: number | undefined, section: string) => void;
  selectedSection: string;
  editable?: boolean;
}

const ProfessionalInformation: React.FC<ProfessionalInformationProps> = ({
  initialData,
  onSubmit,
  selectedSection,
  editable = true
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const {
    data: carerApplication,
    isLoading: isCarerApplicationLoading,
    refetch
  } = useGetCarerApplicationQuery(undefined);
  const [updateApplicationSection] = useUpdateApplicationSectionMutation();
  const [deleteArrayItem] = useDeleteArrayItemMutation();
  const [deleteDocument] = useDeleteDocumentMutation();

  const [selectedFiles, setSelectedFiles] = useState<{ [key: string]: File }>(
    {}
  );
  const [uploadingDocument, setUploadingDocument] = useState<{
    [key: string]: boolean;
  }>({});
  const [savingSection, setSavingSection] = useState<{
    [key: string]: boolean;
  }>({});
  const [savedSections, setSavedSections] = useState<{
    [key: string]: boolean;
  }>({});
  const [dbsFile, setDbsFile] = useState<File | null>(null);
  const [uploadingDbs, setUploadingDbs] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{
    section: string;
    index: number;
  } | null>(null);

  const defaultValues = editable
    ? carerApplication?.data[selectedSection] || {}
    : initialData || {};

  const { control, handleSubmit, reset, watch, getValues } = useForm({
    defaultValues
  });

  useEffect(() => {
    if (editable && carerApplication?.data[selectedSection]) {
      reset(carerApplication.data[selectedSection]);
    } else if (!editable && initialData) {
      reset(initialData);
    }
  }, [carerApplication, selectedSection, reset, editable, initialData]);

  const qualificationsArray = useFieldArray({
    control,
    name: 'qualifications'
  });
  const trainingsArray = useFieldArray({ control, name: 'trainings' });
  const workExperienceArray = useFieldArray({
    control,
    name: 'workExperience'
  });
  const referencesArray = useFieldArray({ control, name: 'references' });
  const professionalRegistrationsArray = useFieldArray({
    name: 'professionalRegistrations',
    control
  });

  const handleFileSelect =
    (section: string, index: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setSelectedFiles((prev) => ({
          ...prev,
          [`${section}.${index}`]: file
        }));
      }
    };

  const handleDbsFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setDbsFile(file);
    }
  };

  const handleDeleteItem = async (section: string, index: number) => {
    setItemToDelete({ section, index });
    setDeleteConfirmOpen(true);
  };

  const confirmDelete = async () => {
    if (!itemToDelete) return;
    const { section, index } = itemToDelete;

    try {
      dispatch(
        showSnack({ message: `Deleting ${section} item...`, color: 'info' })
      );
      await deleteArrayItem({ section: `professionalInfo.${section}`, index });
      await refetch();
      dispatch(
        showSnack({
          message: `${section} item deleted successfully`,
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Delete failed:', error);
      dispatch(
        showSnack({
          message: `Failed to delete ${section} item`,
          color: 'error'
        })
      );
    } finally {
      setDeleteConfirmOpen(false);
      setItemToDelete(null);
    }
  };

  const handleFileUpload = async (section: string, index?: number) => {
    const key = `${section}${index !== undefined ? `.${index}` : ''}`;
    const file = selectedFiles[key];
    if (file) {
      setUploadingDocument((prev) => ({ ...prev, [key]: true }));
      const formData = new FormData();
      formData.append('file', file);
      formData.append('section', `professionalInfo.${section}`);
      formData.append('documentType', section);
      if (index !== undefined) {
        formData.append('index', index.toString());
      }

      try {
        dispatch(
          showSnack({
            message: `Uploading ${section} document...`,
            color: 'info'
          })
        );
        const response = await axios.post(
          `${apiHostname}/api/v1/application/upload-document`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        );

        if (response.data.success) {
          dispatch(
            showSnack({
              message: `${section} document uploaded successfully`,
              color: 'success'
            })
          );
          await refetch();
        } else {
          throw new Error(response.data.error || 'Failed to upload document');
        }
      } catch (error: any) {
        console.error('Upload failed:', error);
        dispatch(
          showSnack({
            message: `Failed to upload ${section} document: ${error.message}`,
            color: 'error'
          })
        );
      } finally {
        setUploadingDocument((prev) => ({ ...prev, [key]: false }));
        setSelectedFiles((prev) => {
          const newFiles = { ...prev };
          delete newFiles[key];
          return newFiles;
        });
      }
    }
  };

  const handleDbsFileUpload = async () => {
    if (dbsFile) {
      setUploadingDbs(true);
      const formData = new FormData();
      formData.append('file', dbsFile);
      formData.append('section', 'professionalInfo.dbsCheck');
      formData.append('documentType', 'dbsCheck');

      try {
        dispatch(
          showSnack({
            message: 'Uploading DBS Check document...',
            color: 'info'
          })
        );
        const response = await axios.post(
          `${apiHostname}/api/v1/application/upload-document`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        );

        if (response.data.success) {
          dispatch(
            showSnack({
              message: 'DBS Check document uploaded successfully',
              color: 'success'
            })
          );
          await refetch();
        } else {
          throw new Error(
            response.data.error || 'Failed to upload DBS Check document'
          );
        }
      } catch (error: any) {
        console.error('DBS Check upload failed:', error);
        dispatch(
          showSnack({
            message: `Failed to upload DBS Check document: ${error.message}`,
            color: 'error'
          })
        );
      } finally {
        setUploadingDbs(false);
        setDbsFile(null);
      }
    }
  };

  const handleSaveSection = async (
    section: string,
    index: number | undefined
  ) => {
    const key = `${section}${index !== undefined ? `.${index}` : ''}`;
    setSavingSection((prev) => ({ ...prev, [key]: true }));
    try {
      dispatch(
        showSnack({
          message: `Saving ${section} information...`,
          color: 'info'
        })
      );
      let data;
      if (section === 'dbsCheck') {
        data = getValues('dbsCheck');
      } else {
        data =
          index !== undefined ? getValues(section)[index] : getValues(section);
      }

      await updateApplicationSection({
        section: `professionalInfo.${section}`,
        data,
        index
      });

      if (section === 'dbsCheck' && dbsFile) {
        await handleDbsFileUpload();
      } else if (selectedFiles[key]) {
        await handleFileUpload(section, index);
      }

      setSavedSections((prev) => ({ ...prev, [key]: true }));
      await refetch();
      dispatch(
        showSnack({
          message: `${section} information saved successfully`,
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Save failed:', error);
      dispatch(
        showSnack({
          message: `Failed to save ${section} information`,
          color: 'error'
        })
      );
    } finally {
      setSavingSection((prev) => ({ ...prev, [key]: false }));
    }
  };

  const handleDeleteDocument = async (section: string, index?: number) => {
    try {
      dispatch(showSnack({ message: `Deleting document...`, color: 'info' }));
      await deleteDocument({ section, index });
      await refetch();
      dispatch(
        showSnack({
          message: `Document deleted successfully`,
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Delete document failed:', error);
      dispatch(
        showSnack({ message: `Failed to delete document`, color: 'error' })
      );
    }
  };

  const renderTextField = (name: string, label: string) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) =>
        editable ? (
          <TextField
            size="small"
            {...field}
            label={label}
            fullWidth
            variant="outlined"
            margin="dense"
          />
        ) : (
          <Typography variant="body1">
            <strong>{label}:</strong> {field.value || 'N/A'}
          </Typography>
        )
      }
    />
  );

  const renderDateField = (name: string, label: string) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) =>
        editable ? (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              {...field}
              label={label}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                />
              )}
            />
          </LocalizationProvider>
        ) : (
          <Typography variant="body1">
            <strong>{label}:</strong>{' '}
            {field.value ? new Date(field.value).toLocaleDateString() : 'N/A'}
          </Typography>
        )
      }
    />
  );

  const renderFileUpload = (section: string, index?: number) => {
    const key = `${section}${index !== undefined ? `.${index}` : ''}`;
    const fileName =
      index !== undefined
        ? watch(`${section}.${index}.fileName`)
        : watch(`${section}.fileName`);
    const uploadUrl =
      index !== undefined
        ? watch(`${section}.${index}.uploadUrl`)
        : watch(`${section}.uploadUrl`);
    const selectedFile = selectedFiles[key];
    const isUploading = uploadingDocument[key];

    if (isCarerApplicationLoading) {
      return <CircularProgress size={24} />;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 1,
          mt: 1
        }}
      >
        {editable && (
          <>
            <input
              accept="image/*,.pdf"
              style={{ display: 'none' }}
              id={`${section}-upload-${index !== undefined ? index : ''}`}
              type="file"
              onChange={handleFileSelect(section, index as number)}
            />
            <label
              htmlFor={`${section}-upload-${index !== undefined ? index : ''}`}
            >
              <Button
                size="small"
                variant="outlined"
                component="span"
                startIcon={<CloudUpload />}
                disabled={isUploading}
              >
                {selectedFile
                  ? selectedFile.name
                  : uploadUrl
                  ? 'Replace File'
                  : 'Upload File'}
              </Button>
            </label>
            {isUploading && <CircularProgress size={24} />}
          </>
        )}
        {uploadUrl && (
          <>
            <Chip
              icon={<AttachFile />}
              label={fileName || `${section}`}
              variant="outlined"
              onDelete={
                editable
                  ? () =>
                      handleDeleteDocument(`professionalInfo.${section}`, index)
                  : undefined
              }
            />
            <Tooltip title="View uploaded file">
              <IconButton
                onClick={() => window.open(uploadUrl, '_blank')}
                color="primary"
                size="small"
              >
                <Visibility />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>
    );
  };

  const renderArraySection = (
    title: string,
    fieldsArray: any,
    section: string,
    renderFields: (index: number) => React.ReactNode
  ) => (
    <Box sx={{ mb: 3 }}>
      <Typography
        variant="h6"
        gutterBottom
        color="primary"
        sx={{ fontWeight: 'bold' }}
      >
        {title}
      </Typography>
      {fieldsArray.fields.map((field: any, index: number) => {
        const isSaving = savingSection[`${section}.${index}`];
        const isSaved = savedSections[`${section}.${index}`];
        return (
          <Card
            key={field.id}
            sx={{
              mb: 2,
              backgroundColor: isSaved ? '#e8f5e9' : 'inherit',
              transition: 'background-color 0.3s'
            }}
          >
            <CardContent>
              {renderFields(index)}
              {renderFileUpload(section, index)}
            </CardContent>
            {editable && (
              <CardActions sx={{ justifyContent: 'space-between', p: 1 }}>
                <Button
                  size="small"
                  onClick={() => handleDeleteItem(section, index)}
                  startIcon={<Delete />}
                  color="error"
                  variant="outlined"
                >
                  Remove
                </Button>
                <Button
                  size="small"
                  onClick={() => handleSaveSection(section, index)}
                  startIcon={
                    isSaving ? <CircularProgress size={20} /> : <Save />
                  }
                  variant="contained"
                  color="primary"
                  disabled={isSaving}
                >
                  {isSaving ? 'Saving...' : 'Save'}
                </Button>
              </CardActions>
            )}
          </Card>
        );
      })}
      {editable && (
        <Button
          size="small"
          onClick={() => {
            const lastIndex = fieldsArray.fields.length - 1;
            if (lastIndex >= 0 && selectedFiles[`${section}.${lastIndex}`]) {
              handleSaveSection(section, lastIndex).then(() => {
                fieldsArray.append({});
                refetch();
              });
            } else {
              fieldsArray.append({});
            }
          }}
          startIcon={<Add />}
          variant="outlined"
          color="primary"
          fullWidth
          sx={{ mt: 1 }}
        >
          Add {title}
        </Button>
      )}
    </Box>
  );

  const renderDbsFileUpload = () => {
    const uploadUrl = watch('dbsCheck.uploadUrl');
    const fileName = watch('dbsCheck.fileName');
    const isUploading = uploadingDbs;

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 1,
          mt: 1
        }}
      >
        {editable && (
          <>
            <input
              accept="image/*,.pdf"
              style={{ display: 'none' }}
              id="dbs-check-upload"
              type="file"
              onChange={handleDbsFileSelect}
            />
            <label htmlFor="dbs-check-upload">
              <Button
                size="small"
                variant="outlined"
                component="span"
                startIcon={<CloudUpload />}
                disabled={isUploading}
              >
                {dbsFile
                  ? dbsFile.name
                  : uploadUrl
                  ? 'Replace File'
                  : 'Upload File'}
              </Button>
            </label>
            {isUploading && <CircularProgress size={24} />}
          </>
        )}
        {uploadUrl && (
          <>
            <Chip
              icon={<AttachFile />}
              label={fileName || 'DBS Check'}
              variant="outlined"
              onDelete={
                editable
                  ? () =>
                      handleDeleteDocument(
                        'professionalInfo.dbsCheck',
                        undefined
                      )
                  : undefined
              }
            />
            <Tooltip title="View uploaded file">
              <IconButton
                onClick={() => window.open(uploadUrl, '_blank')}
                color="primary"
                size="small"
              >
                <Visibility />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>
    );
  };

  return (
    <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, mb: 3, borderRadius: 2 }}>
      <Divider sx={{ mb: 3 }} />

      {renderArraySection(
        'Qualifications',
        qualificationsArray,
        'qualifications',
        (index: number) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {renderTextField(
                `qualifications.${index}.name`,
                'Qualification Name'
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderTextField(`qualifications.${index}.level`, 'Level')}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderTextField(
                `qualifications.${index}.institution`,
                'Institution'
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDateField(
                `qualifications.${index}.dateObtained`,
                'Date Obtained'
              )}
            </Grid>
          </Grid>
        )
      )}

      {renderArraySection(
        'Trainings',
        trainingsArray,
        'trainings',
        (index: number) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {renderTextField(`trainings.${index}.name`, 'Training Name')}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderTextField(`trainings.${index}.provider`, 'Provider')}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDateField(
                `trainings.${index}.dateCompleted`,
                'Date Completed'
              )}
            </Grid>
          </Grid>
        )
      )}

      {renderArraySection(
        'Work Experience',
        workExperienceArray,
        'workExperience',
        (index: number) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {renderTextField(`workExperience.${index}.jobTitle`, 'Job Title')}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderTextField(`workExperience.${index}.employer`, 'Employer')}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDateField(
                `workExperience.${index}.startDate`,
                'Start Date'
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDateField(`workExperience.${index}.endDate`, 'End Date')}
            </Grid>
          </Grid>
        )
      )}

      {renderArraySection(
        'References',
        referencesArray,
        'references',
        (index: number) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {renderTextField(`references.${index}.name`, 'Name')}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderTextField(`references.${index}.position`, 'Position')}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderTextField(`references.${index}.company`, 'Company')}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderTextField(`references.${index}.email`, 'Email')}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderTextField(`references.${index}.phone`, 'Phone')}
            </Grid>
          </Grid>
        )
      )}

      {renderArraySection(
        'Professional Registrations',
        professionalRegistrationsArray,
        'professionalRegistrations',
        (index: number) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                name={`professionalRegistrations.${index}.type`}
                control={control}
                render={({ field }) =>
                  editable ? (
                    <FormControl fullWidth variant="outlined" margin="dense">
                      <InputLabel>Type</InputLabel>
                      <Select {...field} label="Type" size="small">
                        <MenuItem value="NMC">NMC</MenuItem>
                        <MenuItem value="HCPC">HCPC</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <Typography variant="body1">
                      <strong>Type:</strong> {field.value || 'N/A'}
                    </Typography>
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderTextField(
                `professionalRegistrations.${index}.registrationNumber`,
                'Registration Number'
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDateField(
                `professionalRegistrations.${index}.expiryDate`,
                'Expiry Date'
              )}
            </Grid>
          </Grid>
        )
      )}

      {/* DBS Check Section */}
      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h6"
          gutterBottom
          color="primary"
          sx={{ fontWeight: 'bold' }}
        >
          DBS Check
        </Typography>
        <Card
          sx={{
            mb: 2,
            backgroundColor: 'inherit',
            transition: 'background-color 0.3s'
          }}
        >
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {renderTextField(
                  'dbsCheck.certificateNumber',
                  'DBS Certificate Number'
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {renderDateField('dbsCheck.issueDate', 'Issue Date')}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="dbsCheck.status"
                  control={control}
                  render={({ field }) =>
                    editable ? (
                      <FormControl fullWidth variant="outlined" margin="dense">
                        <InputLabel>Status</InputLabel>
                        <Select {...field} label="Status" size="small">
                          <MenuItem value="Clear">Clear</MenuItem>
                          <MenuItem value="Not Clear">Not Clear</MenuItem>
                          <MenuItem value="Pending">Pending</MenuItem>
                          <MenuItem value="Not Applicable">
                            Not Applicable
                          </MenuItem>
                        </Select>
                      </FormControl>
                    ) : (
                      <Typography variant="body1">
                        <strong>Status:</strong> {field.value || 'N/A'}
                      </Typography>
                    )
                  }
                />
              </Grid>
            </Grid>
            {renderDbsFileUpload()}
          </CardContent>
          {editable && (
            <CardActions sx={{ justifyContent: 'flex-end', p: 1 }}>
              <Button
                size="small"
                onClick={() => handleSaveSection('dbsCheck', undefined)}
                variant="contained"
                color="primary"
                startIcon={
                  savingSection['dbsCheck'] ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Save />
                  )
                }
                disabled={savingSection['dbsCheck']}
              >
                {savingSection['dbsCheck'] ? 'Saving...' : 'Save DBS Check'}
              </Button>
            </CardActions>
          )}
        </Card>
      </Box>

      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Deletion'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this item? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default ProfessionalInformation;

import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  Radio,
  RadioGroup,
  FormLabel
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Save } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import {
  useGetCarerApplicationQuery,
  useUpdateApplicationSectionMutation
} from 'src/redux/@api/carer-application';

interface HealthAndSafetyProps {
  initialData?: any;
  onSubmit: (data: any, index: number | undefined, section: string) => void;
  selectedSection: string;
  editable?: boolean;
}

const HealthAndSafety: React.FC<HealthAndSafetyProps> = ({
  initialData,
  onSubmit,
  selectedSection,
  editable = true
}) => {
  const dispatch = useDispatch();
  const { data: carerApplication, refetch } =
    useGetCarerApplicationQuery(undefined);
  const [updateApplicationSection] = useUpdateApplicationSectionMutation();

  const [saving, setSaving] = useState(false);

  const defaultValues = editable
    ? carerApplication?.data[selectedSection] || {
        healthDeclaration: {
          hasHealthConditions: 'false',
          healthConditionsDetails: '',
          requiresAdjustments: 'false',
          adjustmentsDetails: '',
          vaccinationStatus: {
            tetanus: false,
            tetanusDate: null,
            covid19: false,
            covid19Date: null,
            fluShot: false,
            fluShotDate: null
          }
        },
        manualHandlingTraining: {
          completed: false,
          completionDate: null
        },
        foodHygieneCertificate: {
          held: false,
          expiryDate: null
        }
      }
    : initialData || {};

  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues
  });

  const hasHealthConditions =
    watch('healthDeclaration.hasHealthConditions') === 'true';
  const requiresAdjustments =
    watch('healthDeclaration.requiresAdjustments') === 'true';

  useEffect(() => {
    if (editable && carerApplication?.data[selectedSection]) {
      reset(carerApplication.data[selectedSection]);
    } else if (!editable && initialData) {
      reset(initialData);
    }
  }, [carerApplication, selectedSection, reset, editable, initialData]);

  const handleSave = async (data: any) => {
    if (!editable) return;
    setSaving(true);
    try {
      dispatch(
        showSnack({
          message: 'Saving health and safety information...',
          color: 'info'
        })
      );
      await updateApplicationSection({
        section: 'healthAndSafety',
        data
      });
      await refetch();
      dispatch(
        showSnack({
          message: 'Health and safety information saved successfully',
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Save failed:', error);
      dispatch(
        showSnack({
          message: 'Failed to save health and safety information',
          color: 'error'
        })
      );
    } finally {
      setSaving(false);
    }
  };

  const renderDatePicker = (name: string, label: string) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) =>
        editable ? (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              {...field}
              label={label}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        ) : (
          <Typography variant="body1">
            <strong>{label}:</strong>{' '}
            {field.value ? new Date(field.value).toLocaleDateString() : 'N/A'}
          </Typography>
        )
      }
    />
  );

  const renderTextField = (
    name: string,
    label: string,
    multiline = false,
    rows = 1
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) =>
        editable ? (
          <TextField
            {...field}
            label={label}
            multiline={multiline}
            rows={rows}
            fullWidth
            error={!!error}
            helperText={error?.message}
          />
        ) : (
          <Typography variant="body1">
            <strong>{label}:</strong> {field.value || 'N/A'}
          </Typography>
        )
      }
    />
  );

  return (
    <Box sx={{ p: 1, mb: 3 }}>
      <Typography variant="h5" gutterBottom>
        Health and Safety Information
      </Typography>
      <form onSubmit={handleSubmit(handleSave)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">Health Declaration</Typography>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="healthDeclaration.hasHealthConditions"
              control={control}
              render={({ field }) =>
                editable ? (
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Do you have any health conditions that may affect your
                      work?
                    </FormLabel>
                    <RadioGroup {...field}>
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                ) : (
                  <Typography variant="body1">
                    <strong>Health Conditions:</strong>{' '}
                    {field.value === 'true' ? 'Yes' : 'No'}
                  </Typography>
                )
              }
            />
          </Grid>

          {hasHealthConditions && (
            <Grid item xs={12}>
              {renderTextField(
                'healthDeclaration.healthConditionsDetails',
                'Health Conditions Details',
                true,
                4
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            <Controller
              name="healthDeclaration.requiresAdjustments"
              control={control}
              render={({ field }) =>
                editable ? (
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Do you require any adjustments to perform your work?
                    </FormLabel>
                    <RadioGroup {...field}>
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                ) : (
                  <Typography variant="body1">
                    <strong>Requires Adjustments:</strong>{' '}
                    {field.value === 'true' ? 'Yes' : 'No'}
                  </Typography>
                )
              }
            />
          </Grid>

          {requiresAdjustments && (
            <Grid item xs={12}>
              {renderTextField(
                'healthDeclaration.adjustmentsDetails',
                'Adjustments Details',
                true,
                4
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography variant="subtitle1">Vaccination Status</Typography>
            <Grid container spacing={2}>
              {['tetanus', 'covid19', 'fluShot'].map((vaccine) => (
                <React.Fragment key={vaccine}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name={`healthDeclaration.vaccinationStatus.${vaccine}`}
                      control={control}
                      render={({ field }) =>
                        editable ? (
                          <FormControlLabel
                            control={
                              <Checkbox {...field} checked={field.value} />
                            }
                            label={`${
                              vaccine.charAt(0).toUpperCase() + vaccine.slice(1)
                            } Vaccination`}
                          />
                        ) : (
                          <Typography variant="body1">
                            <strong>{`${
                              vaccine.charAt(0).toUpperCase() + vaccine.slice(1)
                            } Vaccination:`}</strong>{' '}
                            {field.value ? 'Yes' : 'No'}
                          </Typography>
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {renderDatePicker(
                      `healthDeclaration.vaccinationStatus.${vaccine}Date`,
                      `${
                        vaccine.charAt(0).toUpperCase() + vaccine.slice(1)
                      } Vaccination Date`
                    )}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Manual Handling Training</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="manualHandlingTraining.completed"
              control={control}
              render={({ field }) =>
                editable ? (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label="Manual Handling Training Completed"
                  />
                ) : (
                  <Typography variant="body1">
                    <strong>Manual Handling Training Completed:</strong>{' '}
                    {field.value ? 'Yes' : 'No'}
                  </Typography>
                )
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {renderDatePicker(
              'manualHandlingTraining.completionDate',
              'Completion Date'
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Food Hygiene Certificate</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="foodHygieneCertificate.held"
              control={control}
              render={({ field }) =>
                editable ? (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label="Food Hygiene Certificate Held"
                  />
                ) : (
                  <Typography variant="body1">
                    <strong>Food Hygiene Certificate Held:</strong>{' '}
                    {field.value ? 'Yes' : 'No'}
                  </Typography>
                )
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {renderDatePicker(
              'foodHygieneCertificate.expiryDate',
              'Expiry Date'
            )}
          </Grid>
        </Grid>

        {editable && (
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={saving ? <CircularProgress size={20} /> : <Save />}
              disabled={saving}
            >
              {saving ? 'Saving...' : 'Save Health and Safety Information'}
            </Button>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default HealthAndSafety;

// src/services/staffApi.ts
import { baseApi } from './base';

export const staffApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    removeStaff: builder.mutation<any, string>({
      query: (staffId: string) => ({
        url: `/staffs/${staffId}`,
        method: 'DELETE'
      })
    }),
    getCareStaffs: builder.query<any[], void>({
      query: () => '/staffs/care',
      providesTags: ['StaffsForChat', 'Staffs']
    }),
    getAdminStaffs: builder.query<any[], void>({
      query: () => '/staffs/admin',
      providesTags: ['Staffs', 'StaffsForChat']
    }),
    getStaffsForAssign: builder.query<any[], { date: string }>({
      query: ({ date }) => `auth/staffs?date=${date}`
    }),
    getCarerResume: builder.query<any, string>({
      query: (carerId) => `auth/${carerId}/carer-resume`
    }),
    searchUsers: builder.query<any[], { query: string; accountType: string }>({
      query: ({ query, accountType }) =>
        `auth/search-users/${accountType}?companyName=${query}`
    }),
    getThirdPartyUsers: builder.query<any[], void>({
      query: () => `auth/third-party`
    }),
    uploadProfilePicture: builder.mutation<any, { userId: string; file: File }>(
      {
        query: ({ userId, file }) => ({
          url: `/picture/${userId}/upload`,
          method: 'POST',
          body: file,
          formData: true
        })
      }
    )
  })
});

export const {
  useRemoveStaffMutation,
  useGetCareStaffsQuery,
  useGetAdminStaffsQuery,
  useGetStaffsForAssignQuery,
  useGetCarerResumeQuery,
  useSearchUsersQuery,
  useGetThirdPartyUsersQuery,
  useUploadProfilePictureMutation
} = staffApi;

import { Card, styled } from "@mui/material";
import { Theme } from "@mui/material/styles";

export const AppCard = styled(Card)(({ theme }: { theme: Theme }) => ({
    borderRadius: theme.spacing(2),
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
    '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 6px 12px rgba(0,0,0,0.15)'
    }
}));

import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Grid,
    Paper,
    useTheme,
    useMediaQuery,
    IconButton,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction
} from '@mui/material';
import moment from 'moment';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

interface HolidayCalendarForHomeProps {
    staffId: string;
    leaveRequests: any[];
    onApprove: (leaveId: string) => void;
    onReject: (leaveId: string) => void;
}

const HolidayCalendarForHome: React.FC<HolidayCalendarForHomeProps> = ({
    staffId,
    leaveRequests,
    onApprove,
    onReject
}) => {
    const [currentMonth, setCurrentMonth] = useState(moment().month() + 1);
    const [currentYear, setCurrentYear] = useState(moment().year());
    const [days, setDays] = useState<Array<{ date: string; dayOfMonth: number; visible: boolean }>>([]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));



    const isDateInLeaveRequest = (date: string) => {
        return leaveRequests?.some(request =>
            moment(date).isBetween(request.startDate, request.endDate, null, '[]')
        );
    };

    const getLeaveStatus = (date: string) => {
        const request = leaveRequests.find(request =>
            moment(date).isBetween(request.startDate, request.endDate, null, '[]')
        );
        return request ? request.status : null;
    };

    return (
        <Box sx={{ p: 3 }}>


            <Box sx={{ mt: 2 }}>
                <Typography variant="h6">Leave Requests</Typography>
                <List>
                    {leaveRequests?.map((request) => (
                        <ListItem key={request._id}>
                            <ListItemText
                                primary={`${moment(request.startDate).format('DD/MM/YYYY')} - ${moment(request.endDate).format('DD/MM/YYYY')}`}
                                secondary={`Status: ${request.status}`}
                            />
                            <ListItemSecondaryAction>
                                {request.status === 'pending' && (
                                    <>
                                        <IconButton edge="end" aria-label="approve" onClick={() => onApprove(request._id)}>
                                            <CheckIcon />
                                        </IconButton>
                                        <IconButton edge="end" aria-label="reject" onClick={() => onReject(request._id)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </>
                                )}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Box>
    );
};

export default HolidayCalendarForHome;
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Save, Check } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import {
  useGetCarerApplicationQuery,
  useUpdateApplicationSectionMutation
} from 'src/redux/@api/carer-application';

interface AvailabilityProps {
  initialData?: any;
  onSubmit: (data: any, index: number | undefined, section: string) => void;
  selectedSection: string;
  editable?: boolean;
}

interface AvailabilityDetails {
  [key: string]: {
    available: boolean;
    morning: boolean;
    afternoon: boolean;
    evening: boolean;
    night: boolean;
  };
}

const daysOfWeek = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
] as const;

const timeSlots = ['morning', 'afternoon', 'evening', 'night'] as const;

type Day = typeof daysOfWeek[number];
type TimeSlot = typeof timeSlots[number];

const Availability: React.FC<AvailabilityProps> = ({
  initialData,
  onSubmit,
  selectedSection,
  editable = true
}) => {
  const dispatch = useDispatch();
  const { data: carerApplication, refetch } =
    useGetCarerApplicationQuery(undefined);
  const [updateApplicationSection] = useUpdateApplicationSectionMutation();

  const [saving, setSaving] = useState(false);

  const defaultValues = editable
    ? carerApplication?.data[selectedSection] || {
        preferredWorkPattern: '',
        availabilityDetails: Object.fromEntries(
          daysOfWeek.map((day) => [
            day,
            {
              available: false,
              morning: false,
              afternoon: false,
              evening: false,
              night: false
            }
          ])
        ),
        maxHoursPerWeek: '',
        minHoursPerWeek: ''
      }
    : initialData || {};

  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues
  });

  useEffect(() => {
    if (editable && carerApplication?.data[selectedSection]) {
      reset(carerApplication.data[selectedSection]);
    } else if (!editable && initialData) {
      reset(initialData);
    }
  }, [carerApplication, selectedSection, reset, editable, initialData]);

  const handleSave = async (data: any) => {
    if (!editable) return;
    setSaving(true);
    try {
      dispatch(
        showSnack({
          message: 'Saving availability information...',
          color: 'info'
        })
      );
      await updateApplicationSection({
        section: 'availability',
        data
      });
      await refetch();
      dispatch(
        showSnack({
          message: 'Availability information saved successfully',
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Save failed:', error);
      dispatch(
        showSnack({
          message: 'Failed to save availability information',
          color: 'error'
        })
      );
    } finally {
      setSaving(false);
    }
  };

  const renderAvailabilityTable = () => {
    const availabilityDetails = watch(
      'availabilityDetails'
    ) as AvailabilityDetails;
    return (
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Day</TableCell>
              <TableCell align="center">Available</TableCell>
              {timeSlots.map((slot) => (
                <TableCell key={slot} align="center">
                  {slot.charAt(0).toUpperCase() + slot.slice(1)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {daysOfWeek.map((day) => (
              <TableRow key={day}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}
                >
                  {day}
                </TableCell>
                <TableCell align="center">
                  <Controller
                    name={`availabilityDetails.${day}.available`}
                    control={control}
                    render={({ field }) =>
                      editable ? (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e);
                            if (!e.target.checked) {
                              timeSlots.forEach((slot) => {
                                setValue(
                                  `availabilityDetails.${day}.${slot}`,
                                  false
                                );
                              });
                            }
                          }}
                        />
                      ) : field.value ? (
                        <Check color="primary" />
                      ) : null
                    }
                  />
                </TableCell>
                {timeSlots.map((slot) => (
                  <TableCell key={slot} align="center">
                    <Controller
                      name={`availabilityDetails.${day}.${slot}`}
                      control={control}
                      render={({ field }) =>
                        editable ? (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            disabled={!availabilityDetails[day].available}
                          />
                        ) : field.value ? (
                          <Check color="primary" />
                        ) : null
                      }
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box sx={{ p: 1, mb: 3 }}>
      <Typography variant="h5" gutterBottom>
        Availability Information
      </Typography>
      <form onSubmit={handleSubmit(handleSave)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="preferredWorkPattern"
              control={control}
              rules={{ required: 'Preferred work pattern is required' }}
              render={({ field, fieldState: { error } }) =>
                editable ? (
                  <FormControl fullWidth error={!!error}>
                    <InputLabel>Preferred Work Pattern</InputLabel>
                    <Select {...field} label="Preferred Work Pattern">
                      <MenuItem value="Full-time">Full-time</MenuItem>
                      <MenuItem value="Part-time">Part-time</MenuItem>
                      <MenuItem value="Flexible">Flexible</MenuItem>
                    </Select>
                    {error && (
                      <Typography color="error">{error.message}</Typography>
                    )}
                  </FormControl>
                ) : (
                  <Typography variant="body1">
                    <strong>Preferred Work Pattern:</strong>{' '}
                    {field.value || 'N/A'}
                  </Typography>
                )
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="maxHoursPerWeek"
              control={control}
              rules={{
                required: 'Maximum hours per week is required',
                min: { value: 1, message: 'Must be at least 1 hour' },
                max: { value: 168, message: 'Cannot exceed 168 hours (7 days)' }
              }}
              render={({ field, fieldState: { error } }) =>
                editable ? (
                  <TextField
                    {...field}
                    label="Maximum Hours per Week"
                    type="number"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                  />
                ) : (
                  <Typography variant="body1">
                    <strong>Maximum Hours per Week:</strong>{' '}
                    {field.value || 'N/A'}
                  </Typography>
                )
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="minHoursPerWeek"
              control={control}
              rules={{
                required: 'Minimum hours per week is required',
                min: { value: 0, message: 'Cannot be negative' },
                max: { value: 168, message: 'Cannot exceed 168 hours (7 days)' }
              }}
              render={({ field, fieldState: { error } }) =>
                editable ? (
                  <TextField
                    {...field}
                    label="Minimum Hours per Week"
                    type="number"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                  />
                ) : (
                  <Typography variant="body1">
                    <strong>Minimum Hours per Week:</strong>{' '}
                    {field.value || 'N/A'}
                  </Typography>
                )
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Weekly Availability
            </Typography>
            {renderAvailabilityTable()}
          </Grid>
        </Grid>

        {editable && (
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={saving ? <CircularProgress size={20} /> : <Save />}
              disabled={saving}
            >
              {saving ? 'Saving...' : 'Save Availability'}
            </Button>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default Availability;

import { ChipProps } from '@mui/material';

export interface Staff {
  id: number;
  name: string;
  role: string;
  email: string;
  phone: string;
}

export const getRoleColor = (role: string): ChipProps['color'] => {
  switch (role.toLowerCase()) {
    case 'carer':
      return 'success';
    case 'nurse':
      return 'info';
    case 'admin':
      return 'info';
    case 'hr_manager':
      return 'success';
    case 'accounting_manager':
      return 'warning';
    default:
      return 'default';
  }
};

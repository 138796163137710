import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid
} from '@mui/material';

interface MedicationDialogProps {
  open: boolean;
  onClose: () => void;
  onResolve: (data: { administered: boolean; notes: string }) => void;
}

const MedicationDialog: React.FC<MedicationDialogProps> = ({
  open,
  onClose,
  onResolve
}) => {
  const [administered, setAdministered] = useState(false);
  const [notes, setNotes] = useState('');

  const handleResolve = () => {
    onResolve({ administered, notes });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Medication Administration</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={administered}
                  onChange={(e) => setAdministered(e.target.checked)}
                />
              }
              label="Medication Administered"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Notes"
              fullWidth
              multiline
              rows={3}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleResolve} variant="contained" color="primary">
          Resolve
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MedicationDialog;

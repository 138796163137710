import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid
} from '@mui/material';

interface PersonalCareDialogProps {
  open: boolean;
  onClose: () => void;
  onResolve: (data: {
    bathing: boolean;
    oralCare: boolean;
    grooming: boolean;
    dressing: boolean;
    notes: string;
  }) => void;
}

const PersonalCareDialog: React.FC<PersonalCareDialogProps> = ({
  open,
  onClose,
  onResolve
}) => {
  const [bathing, setBathing] = useState(false);
  const [oralCare, setOralCare] = useState(false);
  const [grooming, setGrooming] = useState(false);
  const [dressing, setDressing] = useState(false);
  const [notes, setNotes] = useState('');

  const handleResolve = () => {
    onResolve({ bathing, oralCare, grooming, dressing, notes });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Personal Care</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={bathing}
                  onChange={(e) => setBathing(e.target.checked)}
                />
              }
              label="Bathing"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={oralCare}
                  onChange={(e) => setOralCare(e.target.checked)}
                />
              }
              label="Oral Care"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={grooming}
                  onChange={(e) => setGrooming(e.target.checked)}
                />
              }
              label="Grooming"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={dressing}
                  onChange={(e) => setDressing(e.target.checked)}
                />
              }
              label="Dressing"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Notes"
              fullWidth
              multiline
              rows={3}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleResolve} variant="contained" color="primary">
          Resolve
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PersonalCareDialog;

import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { Provider } from 'react-redux';
import store from './redux/store';
import './scrollbar.css';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then(function (registration) {
        console.log(
          'Service Worker registered with scope:',
          registration.scope
        );

        // Check if the service worker is active
        if (registration.active) {
          console.log('Service Worker is active');
        } else {
          console.log('Service Worker is not active yet');
        }

        // Listen for any state changes
        registration.addEventListener('updatefound', () => {
          const newWorker = registration.installing;
          console.log('Service Worker update found!');
          newWorker.addEventListener('statechange', () => {
            console.log('Service Worker state changed to:', newWorker.state);
          });
        });
      })
      .catch(function (err) {
        console.error('Service Worker registration failed:', err);
      });
  });
}

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <SidebarProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SidebarProvider>
    </HelmetProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();

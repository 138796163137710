import React, { useEffect, useState } from 'react';
import { useRoutes, useSearchParams } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { CustomSnackBar } from './components/core/snack/snack';
import { useDispatch } from 'react-redux';
import { useAppSelector } from './redux/hook';
import type { RootState } from './redux/store';
import { Capacitor } from '@capacitor/core';
import { showSnack } from './redux/reducers/snack/snack-slice';
import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from './config/firebase';
import { PushNotifications } from '@capacitor/push-notifications';
import { _setFcmToken } from './redux/reducers/users';
import SubscriptionDialog from './components/core/dialogs/payment-reminder';
import { closePaymentDialog } from './redux/reducers/dialogs/payment';
import router from './newrouter';
import { useUpdateFcmTokenMutation } from './redux/@api/auth';
import { Device } from '@capacitor/device';
import {
  notificationApi,
  useRegisterFCMTokenMutation
} from './redux/@api/notifications';
import { _setNewMessagesCount } from './redux/reducers/messages';
import { baseApi } from './redux/@api/base';
import { shiftsApi } from './redux/@api/shift';

function App() {
  const content = useRoutes(router);
  const [loading, setLoading] = useState(true);
  const [fcmToken, setFcmToken] = useState<string | null>(null);
  const [showNotificationDialog, setShowNotificationDialog] = useState(false);
  const [notificationInitAttempts, setNotificationInitAttempts] = useState(0);
  const dispatch = useDispatch();

  const userState = useAppSelector((state: RootState) => state.userState);
  const [registerFCMToken] = useRegisterFCMTokenMutation();
  const [uploadFCMToken] = useUpdateFcmTokenMutation();
  const [searchParams] = useSearchParams();

  const MAX_INIT_ATTEMPTS = 3;
  const NOTIFICATION_DIALOG_SHOWN_KEY = 'notificationDialogShown';

  if (searchParams.has('token')) {
    localStorage.setItem('invToken', searchParams.get('token') || '');
    localStorage.setItem('invCompany', searchParams.get('company') || '');
  }

  const getDeviceInfo = async () => {
    try {
      const info = await Device.getInfo();
      const id = await Device.getId();
      return {
        type: info.platform as 'ios' | 'android' | 'web',
        model: info.model,
        osVersion: info.osVersion,
        appVersion: '1.0.0',
        identifier: id.identifier
      };
    } catch (error) {
      console.error('Error getting device info:', error);
      return null;
    }
  };

  const setupForegroundMessageListener = () => {
    onMessage(messaging, (payload) => {
      dispatch(notificationApi.util.invalidateTags(['NotificationsCount']));
      switch (payload.data?.type) {
        case 'NEW_MESSAGE':
          dispatch(_setNewMessagesCount(payload.data?.senderId));
          break;
        case '"NEW_SHIFTS_ASSIGNED"':
          dispatch(shiftsApi.util.invalidateTags(['Shift']));
          break;
        default:
          break;
      }
      const { notification } = payload;
      if (notification) {
        showForegroundNotification(notification);
      }
    });
  };

  const showForegroundNotification = (notification) => {
    const { title, body } = notification;
    dispatch(
      showSnack({
        message: body,
        color: 'info'
      })
    );
  };

  const initializeNotifications = async () => {
    try {
      if (Capacitor.isNativePlatform()) {
        await initializeNativeNotifications();
      } else {
        await initializeWebNotifications();
      }
      dispatch(_setFcmToken(fcmToken));
    } catch (error) {
      console.error('Error initializing notifications:', error);
      if (notificationInitAttempts < MAX_INIT_ATTEMPTS) {
        setNotificationInitAttempts(prev => prev + 1);
      } else if (!localStorage.getItem(NOTIFICATION_DIALOG_SHOWN_KEY)) {
        setShowNotificationDialog(true);
        localStorage.setItem(NOTIFICATION_DIALOG_SHOWN_KEY, 'true');
      }
    }
  };

  const initializeWebNotifications = async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const vapidKey = process.env.REACT_APP_FB_VAPID_KEY;
      if (!vapidKey || typeof vapidKey !== 'string') {
        throw new Error('REACT_APP_FB_VAPID_KEY is not set correctly in the environment');
      }
      const token = await getToken(messaging, { vapidKey });
      setFcmToken(token);
      setupForegroundMessageListener();
      await handleUploadFCMToken(token);
    } else {
      throw new Error('Notification permission not granted');
    }
  };

  const initializeNativeNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }
    if (permStatus.receive !== 'granted') {
      throw new Error('Push notification permissions not granted');
    }
    await PushNotifications.register();
    await addNativeNotificationListeners();
  };

  const addNativeNotificationListeners = async () => {
    await PushNotifications.addListener('registration', ({ value }) => {
      console.log('Push registration success, token: ', value);
      setFcmToken(value);
      handleUploadFCMToken(value);
    });

    await PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener(
      'pushNotificationReceived',
      handleIncomingMessage
    );

    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification) => {
        console.log(
          'Push notification action performed',
          notification.actionId,
          notification.inputValue
        );
      }
    );
  };

  const handleCloseNotificationDialog = () => {
    setShowNotificationDialog(false);
  };

  const handleReloadPage = () => {
    window.location.reload();
  };

  const handleIncomingMessage = (payload) => {
    console.log('Incoming message:', payload);
    showForegroundNotification(payload);
  };

  const handleUploadFCMToken = async (token: string) => {
    try {
      console.log('Uploading FCM Token:', token);
      const deviceInfo = await getDeviceInfo();
      if (deviceInfo) {
        const payload = {
          token,
          device: deviceInfo
        };
        const response = await registerFCMToken(payload).unwrap();
        if (response.success) {
          dispatch(_setFcmToken(token));
          console.log('FCM token registered successfully');
        } else {
          throw new Error(response.message);
        }
      } else {
        throw new Error('Failed to get device info');
      }
    } catch (error) {
      console.error('Error uploading FCM token:', error);
    }
  };

  const [openSubscriptionReminder, setopenSubscriptionReminder] = useState(false);
  const paymentDialogState = useAppSelector((state: RootState) => state.paymentDialog);

  const handleOpenDialog = () => {
    setopenSubscriptionReminder(true);
  };

  const handleCloseDialog = () => {
    dispatch(closePaymentDialog());
    setopenSubscriptionReminder(false);
  };

  useEffect(() => {
    if (
      userState.user?._id &&
      userState.user?.emailVerified &&
      userState.currentOrganization &&
      !userState?.notificationEnabled
    ) {
      initializeNotifications();
    }
  }, [userState]);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
      </LocalizationProvider>
      <CustomSnackBar />

      <SubscriptionDialog
        open={paymentDialogState.isOpen}
        onClose={handleCloseDialog}
        message={paymentDialogState.message}
      />
      <Dialog
        open={showNotificationDialog}
        onClose={handleCloseNotificationDialog}
      >
        <DialogTitle>Notification Permissions Required</DialogTitle>
        <DialogContent>
          You are not registered for notifications. Please enable notifications
          or go to account settings to enable notifications.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNotificationDialog}>Close</Button>
          <Button onClick={handleReloadPage} color="primary">
            Reload
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default App;
import { ICarerApplication } from 'src/interfaces/application';
import { baseApi } from './base';

export const carerApplicationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCarerApplication: builder.query({
      query: (carerId?: string) => ({
        url: carerId ? `application?carerId=${carerId}` : 'application'
      }),
      providesTags: ['Application']
    }),
    getAgencyCarerApplication: builder.query({
      query: ({
        carerId,
        agencyOrgId
      }: {
        carerId: string;
        agencyOrgId: string;
      }) => ({
        url: `application/agency-application?carerId=${carerId}&agencyOrgId=${agencyOrgId}`
      }),
      providesTags: ['Application']
    }),
    createOrUpdateApplication: builder.mutation<
      ICarerApplication,
      Partial<ICarerApplication>
    >({
      query: (applicationData) => ({
        url: 'application',
        method: 'POST',
        body: applicationData
      })
    }),
    updateApplicationSection: builder.mutation({
      query: ({ section, data, index }) => ({
        url: `application/${section}/${index}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ['Application']
    }),
    addArrayItem: builder.mutation<
      ICarerApplication,
      { arrayField: keyof ICarerApplication; item: any }
    >({
      query: ({ arrayField, item }) => ({
        url: `application/${arrayField}`,
        method: 'POST',
        body: item
      })
    }),
    removeArrayItem: builder.mutation<
      ICarerApplication,
      { arrayField: keyof ICarerApplication; itemId: string }
    >({
      query: ({ arrayField, itemId }) => ({
        url: `application/${arrayField}/${itemId}`,
        method: 'DELETE'
      })
    }),
    submitApplication: builder.mutation<ICarerApplication, void>({
      query: () => ({
        url: 'application/submit',
        method: 'POST'
      })
    }),
    getApplicationStatus: builder.query({
      query: () => 'application/status'
    }),
    uploadDocument: builder.mutation({
      query: ({ section, file, documentType }) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('section', section);
        formData.append('documentType', documentType);
        return {
          url: 'application/upload-document',
          method: 'POST',
          body: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };
      }
    }),
    getApplicationStatusByCarerId: builder.query<
      { percentageComplete: number; sectionsStatus: any[] },
      string
    >({
      query: (carerId) => `application/status/${carerId}`
    }),
    deleteArrayItem: builder.mutation<
      ICarerApplication,
      { section: string; index: number }
    >({
      query: ({ section, index }) => ({
        url: `application/${section}/${index}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Application']
    }),

    deleteDocument: builder.mutation<
      ICarerApplication,
      { section: string; index?: number }
    >({
      query: ({ section, index }) => ({
        url:
          index !== undefined
            ? `application/document/${section}/${index}`
            : `application/document/${section}/undefined`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Application']
    })
  })
});

export const {
  useGetAgencyCarerApplicationQuery,
  useGetCarerApplicationQuery,
  useCreateOrUpdateApplicationMutation,
  useUpdateApplicationSectionMutation,
  useAddArrayItemMutation,
  useRemoveArrayItemMutation,
  useSubmitApplicationMutation,
  useGetApplicationStatusQuery,
  useUploadDocumentMutation,
  useDeleteDocumentMutation,
  useGetApplicationStatusByCarerIdQuery,
  useDeleteArrayItemMutation
} = carerApplicationApi;

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Container,
  Box,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { motion } from 'framer-motion';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { apiHostname } from 'src/api/api';
import { pageTransition, pageVariants } from '../animation';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { Link, useNavigate } from 'react-router-dom';

const countryCodes = [
  { code: '+1', country: 'USA' },
  { code: '+44', country: 'UK' },
  { code: '+91', country: 'India' },
  { code: '+61', country: 'Australia' },
  { code: '+86', country: 'China' }
  // Add more country codes as needed
];

const OrganizationForm = ({ onSubmit }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [useUserAddress, setUseUserAddress] = useState(false);
  const userState = useAppSelector((state) => state.userState.user);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm({
    defaultValues: {
      name: '',
      type: '',
      address: {
        street: '',
        city: '',
        state: '',
        zipCode: '',
        country: ''
      },
      countryCode: '',
      phoneNumber: '',
      email: ''
    }
  });

  const dispatch = useDispatch();

  const watchedAddress = watch('address');

  useEffect(() => {
    if (useUserAddress && userState?.address) {
      setValue('address', userState.address);
      setValue('countryCode', userState.countryCode || '');
      setValue('phoneNumber', userState.phone || '');
      setValue('email', userState.email || '');
    }
  }, [useUserAddress, userState, setValue]);

  const handleUseUserAddress = (event) => {
    setUseUserAddress(event.target.checked);
    if (event.target.checked && userState?.address) {
      setValue('address', userState.address);
      setValue('countryCode', userState.countryCode || '');
      setValue('phoneNumber', userState.phone || '');
      setValue('email', userState.email || '');
    } else {
      setValue('address', {
        street: '',
        city: '',
        state: '',
        zipCode: '',
        country: ''
      });
      setValue('countryCode', '');
      setValue('phoneNumber', '');
      setValue('email', '');
    }
  };

  const onFormSubmit = async (data) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${apiHostname}/api/v1/organizations`,
        {
          ...data,
          phone: data.phoneNumber,
          ownerId: userState?._id
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      dispatch(
        showSnack({
          message: 'Organization created successfully',
          color: 'success'
        })
      );
      onSubmit(response.data);
    } catch (error) {
      dispatch(
        showSnack({ message: 'Organization creation failed', color: 'error' })
      );
      console.error('Organization creation failed:', error);
      // Handle error (show message to user)
    }
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Container
        maxWidth="sm"
        sx={{
          py: 4,
          height: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            py: 4
          }}
        >
          <Typography variant="h4" gutterBottom align="left" my={2}>
            Create Your Organization
          </Typography>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: 'Organization name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Organization Name"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="type"
                  control={control}
                  rules={{ required: 'Organization type is required' }}
                  render={({ field }) => (
                    <FormControl fullWidth error={!!errors.type}>
                      <InputLabel>Organization Type</InputLabel>
                      <Select {...field}>
                        <MenuItem value="agency">Agency</MenuItem>
                        <MenuItem value="home">Home</MenuItem>
                      </Select>
                      {errors.type && (
                        <Typography color="error">
                          {errors.type.message}
                        </Typography>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={useUserAddress}
                      onChange={handleUseUserAddress}
                      name="useUserAddress"
                      color="primary"
                    />
                  }
                  label="Use my personal information"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="address.street"
                  control={control}
                  rules={{ required: 'Street is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Street"
                      error={!!errors.address?.street}
                      helperText={errors.address?.street?.message}
                      disabled={useUserAddress}
                      value={watchedAddress.street}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="address.city"
                  control={control}
                  rules={{ required: 'City is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="City"
                      error={!!errors.address?.city}
                      helperText={errors.address?.city?.message}
                      disabled={useUserAddress}
                      value={watchedAddress.city}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="address.state"
                  control={control}
                  rules={{ required: 'State is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="State"
                      error={!!errors.address?.state}
                      helperText={errors.address?.state?.message}
                      disabled={useUserAddress}
                      value={watchedAddress.state}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="address.zipCode"
                  control={control}
                  rules={{ required: 'Zip Code is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Postal Code"
                      error={!!errors.address?.zipCode}
                      helperText={errors.address?.zipCode?.message}
                      disabled={useUserAddress}
                      value={watchedAddress.zipCode}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="address.country"
                  control={control}
                  rules={{ required: 'Country is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Country"
                      error={!!errors.address?.country}
                      helperText={errors.address?.country?.message}
                      disabled={useUserAddress}
                      value={watchedAddress.country}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="countryCode"
                  control={control}
                  rules={{ required: 'Country code is required' }}
                  render={({ field }) => (
                    <FormControl fullWidth error={!!errors.countryCode}>
                      <InputLabel>Country Code</InputLabel>
                      <Select {...field} label="Country Code">
                        {countryCodes.map((country) => (
                          <MenuItem key={country.code} value={country.code}>
                            {`${country.country} (${country.code})`}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.countryCode && (
                        <Typography color="error">
                          {errors.countryCode.message}
                        </Typography>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    required: 'Phone number is required',
                    pattern: {
                      value: /^[0-9]{7,15}$/,
                      message: 'Invalid phone number'
                    }
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Phone Number"
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: 'Email is required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address'
                    }
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Email"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{
                    marginTop: 2,
                    padding: '12px',
                    fontSize: '1.1rem',
                    fontWeight: 600
                  }}
                >
                  Create Organization
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    localStorage.removeItem('token');
                    navigate('/login');
                  }}
                  variant="outlined"
                >
                  <Typography variant="body2" color="textSecondary">
                    Login
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </motion.div>
  );
};

export default OrganizationForm;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InvitationState {
  open: boolean;
  company: string | null;
  token: string | null;
  userId: string | null;
  userType: string | null;
}

const initialState: InvitationState = {
  open: false,
  company: null,
  token: null,
  userId: null,
  userType: null
};

const invitationSlice = createSlice({
  name: 'invitation',
  initialState,
  reducers: {
    openInvDialog: (
      state,
      action: PayloadAction<{
        company: string;
        token: string;
        userId: string;
        userType: null;
      }>
    ) => {
      state.open = true;
      state.company = action.payload.company;
      state.token = action.payload.token;
      state.userId = action.payload.userId;
      state.userType = action.payload.userType;
    },
    closeInvDialog: (state) => {
      state.open = false;
      state.company = null;
      state.token = null;
      state.userId = null;
      state.userType = null;
    }
  }
});

export const { openInvDialog, closeInvDialog } = invitationSlice.actions;
export default invitationSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IResident } from 'src/interfaces/resident';
import { residentsApi } from '../@api/resident';

interface ResidentState {
  residents: IResident[];
  loading: boolean;
  error: any;
}

const initialState: ResidentState = {
  residents: [],
  loading: false,
  error: null
};

export const residentSlice = createSlice({
  name: 'residents',
  initialState,
  reducers: {
    updateResident: (state, action: PayloadAction<IResident>) => {
      const updatedResident = action.payload;
      const index = state.residents.findIndex(
        (resident) => resident._id === updatedResident._id
      );

      if (index !== -1) {
        state.residents[index] = updatedResident;
      }
    },

    updateResidentProfilePicture: (
      state,
      action: PayloadAction<{
        residentId: string;
        profilePictureUrl: string;
      }>
    ) => {
      const { residentId, profilePictureUrl } = action.payload;
      const index = state.residents.findIndex(
        (resident) => resident._id === residentId
      );

      if (index !== -1) {
        state.residents[index].profilePictureUrl = profilePictureUrl;
      }
    },
    fetchResidentsStart(state) {
      state.loading = true;
    },
    fetchResidentsSuccess(state, action) {
      state.loading = false;
      state.residents = action.payload;
    },
    fetchResidentsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      residentsApi.endpoints.getResidents.matchFulfilled,
      (state, action: PayloadAction<IResident[]>) => {
        state.residents = action.payload;
      }
    );
  }
});

export const {
  fetchResidentsStart,
  fetchResidentsSuccess,
  fetchResidentsFailure,
  updateResident,
  updateResidentProfilePicture
} = residentSlice.actions;

export default residentSlice.reducer;

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  IconButton,
  CircularProgress,
  useTheme,
  Icon,
  Tooltip
} from '@mui/material';
import { Shift } from './types';
import {
  Person as PersonIcon,
  Close as CloseIcon,
  Delete
} from '@mui/icons-material';
import {
  useGetAssignedStaffsQuery,
  useUnassignUserFromShiftMutation
} from 'src/redux/@api/shift';
import CarerProfile from 'src/content/pages/staffs/staff-profile';
import { Icon as IconifyIcon } from '@iconify/react';
import { useAppSelector } from 'src/redux/hook';
import Staffapplication from 'src/content/pages/staffs/staff-application';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';

interface ViewAssignedStaffDialogProps {
  open: boolean;
  onClose: () => void;
  shift: Shift;
  assignedStaffs?: any[];
  onCallback: () => void;
  isInternal: boolean;
}

const ViewAssignedStaffDialog: React.FC<ViewAssignedStaffDialogProps> = ({
  open,
  onClose,
  isInternal,
  shift,
  onCallback
}) => {
  const [unassignUserFromShift, { isLoading: isUnassigning }] =
    useUnassignUserFromShiftMutation();

  const dispatch = useDispatch();

  const {
    data: assignedStaffs,
    isLoading: isAssignedStaffsLoading,
    refetch
  } = useGetAssignedStaffsQuery(shift?._id, {
    skip: !shift?._id,
    refetchOnMountOrArgChange: true
  });

  const userState = useAppSelector((state) => state.userState);

  const handleUnassignStaff = async (userId: string) => {
    try {
      await unassignUserFromShift({ shiftId: shift._id, userId }).unwrap();
      await onCallback();
    } catch (error) {
      alert(JSON.stringify(error));
      dispatch(
        showSnack({
          message: error.data?.message || 'Failed to unassign staff',
          color: 'error'
        })
      );
      console.error('Failed to unassign staff:', error);
      // You might want to show an error message to the user here
    }
  };

  const [viewProfile, setViewProfile] = React.useState(false);
  const [selectedStaff, setSelectedStaff] = React.useState<string | null>(null);

  const theme = useTheme();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Assigned Staff for {shift.shiftPattern.name} </DialogTitle>
      <DialogContent
        sx={{
          padding: 0
        }}
      >
        {assignedStaffs.length > 0 ? (
          <List
            sx={{
              padding: 0
            }}
          >
            {assignedStaffs.map((assignment) => (
              <ListItem key={assignment._id}>
                <ListItemAvatar>
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${assignment.user?.firstName} ${assignment.user?.lastName}`}
                  secondary={assignment.user?.email}
                />
                <ListItemSecondaryAction>
                  <Tooltip title="View Profile">
                    <IconButton
                      style={{
                        marginLeft: theme.spacing(1)
                      }}
                      edge="end"
                      aria-label="unassign"
                      onClick={() => {
                        setSelectedStaff(assignment.user?._id);
                        setViewProfile(true);
                      }}
                      disabled={isUnassigning}
                    >
                      <IconifyIcon icon="f7:doc-text-viewfinder" />
                    </IconButton>
                  </Tooltip>
                  {isInternal && (
                    <Tooltip title="Unassign">
                      <IconButton
                        style={{
                          marginLeft: theme.spacing(1)
                        }}
                        edge="end"
                        aria-label="unassign"
                        onClick={() =>
                          handleUnassignStaff(assignment.user?._id)
                        }
                        disabled={isUnassigning}
                      >
                        {isUnassigning ? (
                          <CircularProgress size={24} />
                        ) : (
                          <IconifyIcon icon="weui:delete-filled" />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography>No staff assigned to this shift yet.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>

      <Dialog
        open={viewProfile && selectedStaff !== null}
        onClose={() => {
          setSelectedStaff(null);
          setViewProfile(false);
        }}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            padding: '0px !important',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
              width: '95vw',
              maxHeight: '90vh',
              minHeight: '90vh'
            }
          }
        }}
      >
        <DialogContent
          sx={{
            padding: '0px !important'
          }}
        >
          {selectedStaff && (
            <Staffapplication
              userId={selectedStaff}
              isInternal={isInternal}
              orgId={shift.agentId?._id}
            />
          )}
        </DialogContent>
      </Dialog>
    </Dialog>
  );
};

export default ViewAssignedStaffDialog;

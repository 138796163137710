import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ICarerApplication } from 'src/interfaces/application';

interface PersonalInformationProps {
  initialData?: ICarerApplication['personalInfo'];
  onSubmit: (data: any, index: number, section: string) => void;
  selectedSection: string;
  editable?: boolean;
}

const PersonalInformation: React.FC<PersonalInformationProps> = ({
  initialData,
  onSubmit,
  editable = true
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ICarerApplication['personalInfo']>({
    defaultValues: {
      ...initialData,
      address: initialData?.address || {},
      emergencyContact: initialData?.emergencyContact || {},
      jobTitle: initialData?.jobTitle || 'Health Care Assistant'
    }
  });

  const onSubmitForm = (data: ICarerApplication['personalInfo']) => {
    onSubmit(data, undefined, 'personalInfo');
  };

  const renderField = (
    name: string,
    label: string,
    rules = {},
    options = {}
  ) => (
    <Controller
      name={name as any}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Box>
          {editable ? (
            <TextField
              {...field}
              label={label}
              fullWidth
              variant="outlined"
              error={!!errors[name]}
              helperText={errors[name]?.message}
              {...options}
            />
          ) : (
            <Typography variant="body1">{field.value || 'N/A'}</Typography>
          )}
        </Box>
      )}
    />
  );

  const renderSelect = (
    name: string,
    label: string,
    options: { value: string; label: string }[]
  ) => (
    <Controller
      name={name as any}
      control={control}
      render={({ field }) => (
        <Box>
          {editable ? (
            <FormControl fullWidth variant="outlined">
              <InputLabel>{label}</InputLabel>
              <Select {...field} label={label}>
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Typography variant="body1">{field.value || 'N/A'}</Typography>
          )}
        </Box>
      )}
    />
  );

  return (
    <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, mb: 3, borderRadius: 2 }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ mb: 3, color: theme.palette.primary.main, fontWeight: 'bold' }}
      >
        Personal Information
      </Typography>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {renderSelect('title', 'Title', [
              { value: 'Mr', label: 'Mr' },
              { value: 'Mrs', label: 'Mrs' },
              { value: 'Miss', label: 'Miss' },
              { value: 'Ms', label: 'Ms' },
              { value: 'Dr', label: 'Dr' },
              { value: 'Other', label: 'Other' }
            ])}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderField('firstName', 'First Name', {
              required: 'First name is required'
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderField('lastName', 'Last Name', {
              required: 'Last name is required'
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderField('middleName', 'Middle Name')}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderField('preferredName', 'Preferred Name')}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="dateOfBirth"
              control={control}
              rules={{ required: 'Date of birth is required' }}
              render={({ field }) => (
                <Box>
                  <Typography variant="subtitle2" gutterBottom>
                    Date of Birth
                  </Typography>
                  {editable ? (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        {...field}
                        label="Date of Birth"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            error={!!errors.dateOfBirth}
                            helperText={errors.dateOfBirth?.message}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  ) : (
                    <Typography variant="body1">
                      {field.value
                        ? new Date(field.value).toLocaleDateString()
                        : 'N/A'}
                    </Typography>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderSelect('gender', 'Gender', [
              { value: 'Male', label: 'Male' },
              { value: 'Female', label: 'Female' },
              { value: 'Non-binary', label: 'Non-binary' },
              { value: 'Other', label: 'Other' },
              { value: 'Prefer not to say', label: 'Prefer not to say' }
            ])}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderSelect('jobTitle', 'Job Title', [
              {
                value: 'Health Care Assistant',
                label: 'Health Care Assistant'
              },
              {
                value: 'Senior Care Assistant',
                label: 'Senior Care Assistant'
              },
              { value: 'Nurse', label: 'Nurse' }
            ])}
          </Grid>
          <Grid item xs={12}>
            {renderField(
              'nationalInsuranceNumber',
              'National Insurance Number',
              {
                required: 'National Insurance number is required',
                pattern: {
                  value:
                    /^[A-CEGHJ-PR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[A-D\s]$/,
                  message: 'Invalid National Insurance number'
                }
              }
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ mt: 2, mb: 2, color: theme.palette.secondary.main }}
            >
              Address
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {renderField('address.street', 'Street', {
              required: 'Street is required'
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderField('address.city', 'City', {
              required: 'City is required'
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderField('address.county', 'County', {
              required: 'County is required'
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderField('address.postcode', 'Postcode', {
              required: 'Postcode is required'
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderField('address.country', 'Country', {
              required: 'Country is required'
            })}
          </Grid>
          <Grid item xs={12}>
            {renderField('phone', 'Phone Number', {
              required: 'Phone number is required',
              pattern: {
                value: /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/,
                message: 'Invalid UK phone number'
              }
            })}
          </Grid>
          <Grid item xs={12}>
            {renderField('email', 'Email', {
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address'
              }
            })}
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ mt: 2, mb: 2, color: theme.palette.secondary.main }}
            >
              Emergency Contact
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderField('emergencyContact.name', 'Emergency Contact Name', {
              required: 'Emergency contact name is required'
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderField('emergencyContact.relationship', 'Relationship', {
              required: 'Relationship is required'
            })}
          </Grid>
          <Grid item xs={12}>
            {renderField('emergencyContact.phone', 'Emergency Contact Phone', {
              required: 'Emergency contact phone is required',
              pattern: {
                value: /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/,
                message: 'Invalid UK phone number'
              }
            })}
          </Grid>
        </Grid>
        {editable && (
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size={isMobile ? 'large' : 'medium'}
              sx={{
                minWidth: isMobile ? '100%' : 'auto',
                py: isMobile ? 1.5 : 1,
                px: isMobile ? 3 : 2,
                borderRadius: 2,
                fontWeight: 'bold',
                textTransform: 'none'
              }}
            >
              Save Personal Information
            </Button>
          </Box>
        )}
      </form>
    </Paper>
  );
};

export default PersonalInformation;

import React from 'react';
import TaskGrid from './task-grid';

interface PendingTasksTabProps {
  tasks: any[];
  onResolveTask: (task: any) => void;
}

const PendingTasksTab: React.FC<PendingTasksTabProps> = ({ tasks, onResolveTask }) => {
  const pendingTasks = tasks.filter(task => task.status === 'pending');
  return <TaskGrid tasks={pendingTasks} onTaskClick={onResolveTask} />;
};

export default PendingTasksTab;
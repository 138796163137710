import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/redux/hook';
import { Snackbar, Alert, Slide, SlideProps, Typography } from '@mui/material';
import { hideSnack } from 'src/redux/reducers/snack/snack-slice';

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

export function CustomSnackBar() {
  const snackState = useAppSelector((state) => state.snack);
  const dispatch = useDispatch();

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(hideSnack());
  };

  return (
    <Snackbar
      open={snackState.open}
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        onClose={handleClose}
        severity={snackState.color}
        sx={{
          width: '100%',
          boxShadow: 3,
          '& .MuiAlert-icon': {
            fontSize: '1.5rem'
          },
          '& .MuiAlert-message': {
            fontSize: '1rem',
            fontWeight: 500
          }
        }}
        elevation={6}
        variant="filled"
      >
        <Typography
          style={{
            color: 'whitesmoke'
          }}
        >
          {snackState.message}
        </Typography>
      </Alert>
    </Snackbar>
  );
}

export default CustomSnackBar;

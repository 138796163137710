// residents.ts
import { baseApi } from './base';
import { ICarePlan } from 'src/interfaces/resident';
interface ProgressData {
  date: string;
  value: number;
  details: any;
}

interface CompletionRateData {
  completionRate: number;
}

interface PadCheckAnalysisData {
  stoolTypeAnalysis: { [key: string]: number };
  clothingConditionAnalysis: { [key: string]: number };
  healthInsights: string[];
}

interface ProgressSummaryData {
  progressData: ProgressData[];
  completionRate: number;
  padCheckAnalysis: PadCheckAnalysisData;
}

export const residentsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getResidentTaskHistory: builder.query({
      query: ({
        residentId,
        startDate,
        endDate
      }: {
        residentId: string;
        startDate: string;
        endDate: string;
      }) =>
        `residents/${residentId}/task-history?startDate=${startDate}&endDate=${endDate}`
    }),
    initiateHandOver: builder.mutation({
      query: (homeId: string) => ({
        url: `residents/${homeId}/handover`,
        method: 'POST'
      })
    }),
    getHandOverData: builder.query({
      query: (homeId: string) => `residents/${homeId}/handover`
    }),
    getResidentsForCarer: builder.query({
      query: () => `residents/carer`
    }),
    createResident: builder.mutation({
      query: (data: any) => ({
        url: `residents`,
        method: 'POST',
        body: data
      })
    }),
    getCurrentCareStatus: builder.query({
      query: (residentId: string) => `residents/${residentId}/care-status`
    }),
    markTaskAsDue: builder.mutation({
      query: ({
        residentId,
        taskType,
        taskKey
      }: {
        residentId: string;
        taskType: string;
        taskKey: string;
      }) => ({
        url: `/residents/${residentId}/mark-task-due/${taskType}/${taskKey}`,
        method: 'POST'
      })
    }),
    getTasks: builder.query({
      query: (residentId: string) => `residents/${residentId}/tasks`
    }),
    resolveTask: builder.mutation({
      query: ({
        residentId,
        taskType,
        taskKey,
        description,
        additionalData,
        taskIndex,
        taskData
      }: {
        residentId: string;
        taskType: string;
        taskKey: string;
        description: string;
        additionalData?: any;
        taskIndex?: number;
        taskData?: any;
      }) => ({
        url: `/residents/${residentId}/resolve-task/${taskIndex}/${taskType}/${taskKey}`,
        method: 'POST',
        body: {
          description,
          additionalData,
          taskData
        }
      })
    }),
    getTaskHistory: builder.query({
      query: ({
        residentId,
        startDate,
        endDate
      }: {
        residentId: string;
        startDate: string;
        endDate: string;
      }) =>
        `residents/${residentId}/task-history?startDate=${startDate}&endDate=${endDate}`
    }),
    createCarePlan: builder.mutation({
      query: ({ residentId, carePlanData }) => ({
        url: `residents/${residentId}/care-plan`,
        method: 'POST',
        body: carePlanData
      }),
      invalidatesTags: ['CarePlan']
    }),
    getCarePlan: builder.query<ICarePlan, string>({
      query: (residentId) => `residents/${residentId}/care-plan`,
      providesTags: ['CarePlan']
    }),
    updateCarePlan: builder.mutation<
      ICarePlan,
      Partial<ICarePlan> & { residentId: string }
    >({
      query: ({ residentId, ...patch }) => ({
        url: `residents/${residentId}/care-plan`,
        method: 'PUT',
        body: patch
      }),
      invalidatesTags: ['CarePlan']
    }),
    getResidents: builder.query({
      query: () => 'residents',
      providesTags: ['Resident']
    }),
    getHistoricTasks: builder.query({
      query: ({
        residentId,
        startDate,
        endDate,
        page = 1,
        limit = 20
      }: {
        residentId: string;
        startDate: string;
        endDate: string;
        page?: number;
        limit?: number;
      }) =>
        `residents/${residentId}/historical-tasks?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`,
      serializeQueryArgs: ({ queryArgs }) => {
        const { residentId, startDate, endDate } = queryArgs;
        return { residentId, startDate, endDate };
      },
      merge: (currentCache, newItems) => {
        if (currentCache && newItems) {
          return {
            ...newItems,
            tasks: [...(currentCache.tasks || []), ...newItems.tasks]
          };
        }
        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      }
    }),
    getResident: builder.query({
      query: (id) => `residents/${id}`
    }),
    deleteResident: builder.mutation({
      query: (id) => ({
        url: `residents/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Resident']
    }),
    addResident: builder.mutation({
      query: (newResident) => ({
        url: 'residents',
        method: 'POST',
        body: newResident
      }),
      invalidatesTags: ['Resident']
    }),
    updateResident: builder.mutation({
      query: ({ id, residentData }) => ({
        url: `residents/${id}`,
        method: 'PUT',
        body: residentData
      }),
      invalidatesTags: ['Resident']
    }),

    //progress
    getResidentProgress: builder.query<
      ProgressData[],
      {
        residentId: string;
        startDate: string;
        endDate: string;
        taskType?: string;
      }
    >({
      query: ({ residentId, startDate, endDate, taskType }) => ({
        url: `residents/${residentId}/progress`,
        params: { startDate, endDate, taskType }
      })
    }),

    getTaskCompletionRate: builder.query<
      CompletionRateData,
      {
        residentId: string;
        startDate: string;
        endDate: string;
        taskType?: string;
      }
    >({
      query: ({ residentId, startDate, endDate, taskType }) => ({
        url: `residents/${residentId}/completion-rate`,
        params: { startDate, endDate, taskType }
      })
    }),

    getPadCheckAnalysis: builder.query<
      PadCheckAnalysisData,
      {
        residentId: string;
        startDate: string;
        endDate: string;
      }
    >({
      query: ({ residentId, startDate, endDate }) => ({
        url: `residents/${residentId}/pad-check-analysis`,
        params: { startDate, endDate }
      })
    }),

    getResidentProgressSummary: builder.query<
      ProgressSummaryData,
      {
        residentId: string;
        startDate: string;
        endDate: string;
      }
    >({
      query: ({ residentId, startDate, endDate }) => ({
        url: `residents/${residentId}/progress-summary`,
        params: { startDate, endDate }
      })
    }),
    getMealAnalysis: builder.query({
      query: ({ residentId, startDate, endDate }) =>
        `residents/${residentId}/meal-analysis?startDate=${startDate}&endDate=${endDate}`
    }),
    getMealProgressData: builder.query({
      query: ({ residentId, startDate, endDate }) =>
        `residents/${residentId}/meal-progress?startDate=${startDate}&endDate=${endDate}`
    }),
    // New sleep analysis endpoints
    getSleepAnalysis: builder.query({
      query: ({ residentId, startDate, endDate }) =>
        `residents/${residentId}/sleep-analysis?startDate=${startDate}&endDate=${endDate}`
    }),
    getDayNightCheckAnalysis: builder.query({
      query: ({ residentId, startDate, endDate }) =>
        `residents/${residentId}/day-night-analysis?startDate=${startDate}&endDate=${endDate}`
    })
  })
});

export const {
  useDeleteResidentMutation,
  useGetResidentTaskHistoryQuery,
  useLazyGetResidentTaskHistoryQuery,
  useInitiateHandOverMutation,
  useGetHandOverDataQuery,
  useLazyGetHandOverDataQuery,
  useGetResidentsForCarerQuery,
  useLazyGetResidentsForCarerQuery,
  useCreateResidentMutation,
  useGetCurrentCareStatusQuery,
  useLazyGetCurrentCareStatusQuery,
  useMarkTaskAsDueMutation,
  useGetTasksQuery,
  useResolveTaskMutation,
  useGetTaskHistoryQuery,
  useLazyGetTaskHistoryQuery,
  useCreateCarePlanMutation,
  useGetCarePlanQuery,
  useUpdateCarePlanMutation,
  useGetResidentsQuery,
  useGetHistoricTasksQuery,
  useGetResidentQuery,
  useAddResidentMutation,
  useUpdateResidentMutation,
  useGetResidentProgressQuery,
  useGetTaskCompletionRateQuery,
  useGetPadCheckAnalysisQuery,
  useGetResidentProgressSummaryQuery,
  useGetMealAnalysisQuery,
  useGetMealProgressDataQuery,
  useGetSleepAnalysisQuery,
  useGetDayNightCheckAnalysisQuery
} = residentsApi;

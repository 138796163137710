import React, { useState, useCallback, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  Fab,
  Box,
  Avatar,
  CircularProgress,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
  Badge,
  Tooltip
} from '@mui/material';
import { Add as AddIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import TaskManagementComponent from './views/task-management';
import AddResidentDialog from 'src/components/core/dialogs/add-residents';
import { useAppSelector } from 'src/redux/hook';
import { useGetResidentsQuery } from 'src/redux/@api/resident';
import TaskManagementDialog from './views/task-management';
import { useWebSocketConnection } from 'src/components/core/ws/task-hook';
import { wsHostname } from 'src/api/api';
import { IResident } from 'src/interfaces/resident';
import { Icon } from '@iconify/react';
import { useDispatch } from 'react-redux';
import { updateResident } from 'src/redux/reducers/residents';

export interface ITaskSummary {
  upcoming: number;
  pending: number;
  overdue: number;
  total: number;
  urgency: 'white' | 'yellow' | 'orange' | 'red';
}

const POLLING_INTERVAL = 30000; // 30 seconds

const ResidentPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const [residents, setResidents] = useState<IResident[]>([]);
  const residents = useAppSelector((state) => state.residents.residents);
  const homeId = useAppSelector(
    (state) => state.userState.currentOrganization._id
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedResident, setSelectedResident] = useState<IResident | null>(
    null
  );
  const [taskManagementDialogOpen, setTaskManagementDialogOpen] =
    useState(false);

  const {
    data: residentsData,
    isLoading,
    refetch
  } = useGetResidentsQuery(undefined, {});

  const dispatch = useDispatch();

  const currentUser = useAppSelector((state) => state.userState.user);
  const currentOrganization = useAppSelector(
    (state) => state.userState.currentOrganization
  );

  const { wsRef, wsConnected } = useWebSocketConnection(
    currentUser,
    currentOrganization,
    wsHostname
  );

  useEffect(() => {
    if (wsRef.current) {
      wsRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);

        let residentToUpdate = residents.find(
          (resident) => resident._id === data.data?.residentId
        );
        if (residentToUpdate) {
          residentToUpdate = {
            ...residentToUpdate,
            taskSummary: data.data?.summary
          };
          dispatch(updateResident(residentToUpdate));
        }
      };
    }
  }, [wsRef, currentUser._id]);

  const getTaskStatus = (taskSummary) => {
    const urgency = taskSummary.urgency;
    let icon: string;
    let color: string;
    let tooltip: string;

    switch (urgency) {
      case 'red':
        icon = 'material-symbols:sentiment-sad';
        color = theme.palette.error.main;
        tooltip = 'Critical: Immediate attention required';
        break;
      case 'orange':
        icon = 'fluent:emoji-sad-slight-20-filled';
        color = theme.palette.warning.main;
        tooltip = 'Urgent: Action needed soon.';
        break;
      case 'yellow':
        icon = 'heroicons:face-smile-solid';
        color = theme.palette.warning.light;
        tooltip = 'Attention: Tasks approaching due time';
        break;

      case 'violet':
        icon = 'material-symbols:sentiment-sad';
        color = '#702963';
        tooltip = 'Critical: Immediate attention required';
        break;
      default:
        icon = 'ri:emotion-happy-fill';
        color = theme.palette.success.main;
        tooltip = 'On track: No immediate action required';
    }

    return { icon, color, tooltip };
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container
      sx={{
        height: `calc(100vh - ${theme.header.height})`,
        overflowY: 'hidden',
        padding: 1
      }}
      maxWidth="xl"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: '60px' }}
      >
        <Typography variant="h4" component="h1">
          Residents
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<RefreshIcon />}
          onClick={() => refetch()}
        >
          Refresh
        </Button>
      </Box>

      <Box
        sx={{
          height: `calc(100vh - ${theme.header.height} - 60px)`,
          overflowY: 'auto'
        }}
      >
        <Grid container spacing={1} p={1}>
          {residents?.map((resident) => {
            const status = getTaskStatus(resident.taskSummary);
            return (
              <Grid
                item
                xs={4}
                sm={4}
                md={2}
                key={resident._id}
                sx={{
                  height: isMobile ? '130px' : '200px'
                }}
              >
                <Card
                  onClick={() => {
                    setSelectedResident(resident);
                    setTaskManagementDialogOpen(true);
                  }}
                  sx={{
                    padding: 0,
                    position: 'relative',
                    zIndex: 100,
                    cursor: 'pointer',
                    transition: 'transform 0.3s',
                    '&:hover': { transform: 'scale(1.05)' },
                    overflow: 'visible',
                    height: '100%',
                    width: '100%'
                  }}
                >
                  <Tooltip title={status.tooltip}>
                    <Box
                      sx={{
                        position: 'absolute',
                        top: -5,
                        left: -5,
                        zIndex: 200,
                        backgroundColor: status.color,
                        borderRadius: '50%',
                        padding: '4px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0px 2px 4px rgba(0,0,0,0.2)'
                      }}
                    >
                      <Icon
                        icon={status.icon}
                        style={{
                          fontSize: isMobile ? '1.2rem' : '1.5rem',
                          color: '#fff'
                        }}
                      />
                    </Box>
                  </Tooltip>
                  <CardContent
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      padding: '3px !important',
                      zIndex: 1,
                      height: '100%'
                    }}
                  >
                    <Box
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 0
                      }}
                    >
                      <Avatar
                        src={resident.profilePictureUrl}
                        alt={`${resident.firstName} ${resident.lastName}`}
                        sx={{
                          width: '100%',
                          height: '60%',
                          margin: '0 auto',
                          borderRadius: '10px !important',
                          objectFit: 'contain'
                        }}
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '40%'
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            padding: '1px 2px',
                            borderRadius: '12px',
                            marginBottom: '4px',
                            fontSize: isMobile ? '0.6rem' : '0.7rem',
                            fontWeight: 'bold'
                          }}
                        >
                          Room {resident.roomNumber}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          textAlign="center"
                          sx={{
                            zIndex: 1000,
                            fontSize: isMobile ? '0.7rem' : '0.8rem',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                            width: '100%',
                            wordBreak: 'break-word'
                          }}
                        >
                          {`${resident.firstName} ${resident.lastName}`.toUpperCase()}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Fab
        color="primary"
        aria-label="add resident"
        sx={{
          position: 'fixed',
          bottom: theme.spacing(2),
          right: theme.spacing(2)
        }}
        onClick={() => setDialogOpen(true)}
      >
        <AddIcon />
      </Fab>

      <AddResidentDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        homeId={homeId}
      />
      {selectedResident && (
        <TaskManagementDialog
          open={taskManagementDialogOpen}
          onClose={() => {
            setTaskManagementDialogOpen(false);
            setSelectedResident(null);
          }}
          resident={selectedResident}
        />
      )}
    </Container>
  );
};

export default ResidentPage;

import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  CircularProgress
} from '@mui/material';

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  similarShifts: any[];
  onConfirm: (assignToAll: boolean) => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  similarShifts,
  onConfirm
}) => {
  React.useEffect(() => {
    if (open && similarShifts.length === 0) {
      // Automatically confirm with "Assign to This Shift Only" if there are no similar shifts
      onConfirm(false);
    }
  }, [open, similarShifts, onConfirm]);

  if (similarShifts.length === 0) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Assign to Similar Shifts</DialogTitle>
      <DialogContent>
        <Typography>
          We found {similarShifts.length} similar shift
          {similarShifts.length !== 1 ? 's' : ''} this month. Would you like to
          assign the selected staff to{' '}
          {similarShifts.length !== 1 ? 'these shifts' : 'this shift'} as well?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onConfirm(false)}>
          Assign to This Shift Only
        </Button>
        <Button onClick={() => onConfirm(true)} color="primary">
          Assign to All Similar Shifts
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;

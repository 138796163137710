import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  IconButton,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Paper,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  ExpandMore,
  ExpandLess
} from '@mui/icons-material';
import moment from 'moment';
import QRCode from 'react-qr-code';

import {
  CalendarContainer,
  DayCell,
  ShiftAvatar,
  ShiftIndicator
} from './calendar-views/styles';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { useGetAssignmentsForUserQuery } from 'src/redux/@api/shift';
import {
  useCreateTimesheetAndGenerateQRCodeMutation,
  useCreateTimesheetRequestMutation,
  useValidateQRCodeMutation
} from 'src/redux/@api/timesheet';

interface ShiftAssignment {
  _id: string;
  status: string;
  shift: {
    _id: string;
    date: string;
    shiftPattern: {
      _id: string;
      name: string;
      timings: {
        careHomeId: string;
        startTime: string;
        endTime: string;
      }[];
    };
    homeId: {
      _id: string;
      name: string;
    };
  };
  timesheet?: {
    status: string;
  };
}

interface CareStaffShiftCalendarProps {
  onMonthChange: (month: number, year: number) => void;
}

const CareStaffShiftCalendar: React.FC<CareStaffShiftCalendarProps> = ({
  onMonthChange
}) => {
  const [currentDate, setCurrentDate] = useState<moment.Moment>(moment());
  const [assignments, setAssignments] = useState<
    Record<string, ShiftAssignment[]>
  >({});
  const [showFullCalendar, setShowFullCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [isViewShiftOpen, setIsViewShiftOpen] = useState(false);
  const [selectedShifts, setSelectedShifts] = useState<ShiftAssignment[]>([]);
  const [qrCodeData, setQRCodeData] = useState<string | null>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  useEffect(() => {
    // Call onMonthChange when the component mounts
    onMonthChange(currentDate.month() + 1, currentDate.year());
  }, []);

  const {
    data: userAssignments,
    isLoading,
    isError
  } = useGetAssignmentsForUserQuery(undefined);

  const [requestTimeSheet, requestTimeSheetState] =
    useCreateTimesheetRequestMutation();
  const [
    createTimesheetAndGenerateQRCode,
    createTimesheetAndGenerateQRCodeState
  ] = useCreateTimesheetAndGenerateQRCodeMutation();
  const [validateQRCode] = useValidateQRCodeMutation();

  useEffect(() => {
    if (userAssignments) {
      const groupedAssignments = groupAssignmentsByDate(userAssignments);
      setAssignments(groupedAssignments);
    }
  }, [userAssignments]);

  const groupAssignmentsByDate = (
    assignmentsData: ShiftAssignment[]
  ): Record<string, ShiftAssignment[]> => {
    return assignmentsData?.reduce((acc, assignment) => {
      const dateKey = moment(assignment.shift?.date).format('YYYY-MM-DD');
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(assignment);
      return acc;
    }, {} as Record<string, ShiftAssignment[]>);
  };

  const handlePrevMonth = (): void => {
    const newDate = moment(currentDate).subtract(1, 'month');
    setCurrentDate(newDate);
    onMonthChange(newDate.month() + 1, newDate.year());
  };

  const handleNextMonth = (): void => {
    const newDate = moment(currentDate).add(1, 'month');
    setCurrentDate(newDate);
    onMonthChange(newDate.month() + 1, newDate.year());
  };

  const handleDayClick = (day: moment.Moment) => {
    const dateKey = day.format('YYYY-MM-DD');
    const dayAssignments = assignments[dateKey] || [];
    setSelectedDate(day);
    setSelectedShifts(dayAssignments);
    setIsViewShiftOpen(true);
    setQRCodeData(null); // Reset QR code when opening a new day
  };

  const handleRequestTimesheet = async (shift: ShiftAssignment) => {
    try {
      const response = await requestTimeSheet({
        shiftId: shift.shift?._id,
        shiftPatternId: shift.shift?.shiftPattern._id,
        homeId: shift.shift?.homeId._id
      }).unwrap();
      console.log(response, 'POORI');
      setSelectedShifts((prev) =>
        prev.map((s) =>
          s.shift?._id === shift.shift?._id
            ? { ...s, timesheet: response.timesheet }
            : s
        )
      );
      dispatch(showSnack({ message: response.message, color: 'success' }));
    } catch (error) {
      dispatch(
        showSnack({
          message:
            error?.message || 'Error requesting timesheet. Try again later',
          color: 'danger'
        })
      );
      console.error(error);
    }
  };

  const handleGenerateQRCode = async ({
    shiftId,
    shiftPatternId,
    homeId
  }: {
    shiftId: string;
    shiftPatternId: string;
    homeId: string;
  }) => {
    try {
      const response = await createTimesheetAndGenerateQRCode({
        shiftId,
        shiftPatternId,
        homeId
      }).unwrap();
      setQRCodeData(response.qrCodeToken);
      dispatch(
        showSnack({
          message: 'QR code generated successfully',
          color: 'success'
        })
      );
    } catch (error) {
      dispatch(
        showSnack({
          message: 'Error generating QR code. Try again later',
          color: 'danger'
        })
      );
      console.error(error);
    }
  };

  const getShiftColor = (shiftName: string): string => {
    let hash = 0;
    for (let i = 0; i < shiftName.length; i++) {
      hash = shiftName.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = hash % 20;
    return `hsl(${hue}, 100%, 70%)`;
  };

  const renderCalendarDay = (
    day: moment.Moment,
    isCurrentMonth: boolean
  ): JSX.Element => {
    const dateKey = day.format('YYYY-MM-DD');
    const dayAssignments = assignments[dateKey] || [];
    const isToday = day.isSame(moment(), 'day');

    const renderShiftAvatars = () => {
      return dayAssignments.slice(0, 2).map((assignment, index) => (
        <ShiftAvatar
          key={assignment._id}
          style={{
            backgroundColor: getShiftColor(assignment.shift?.shiftPattern.name),
            boxShadow: `0 0 0 2px 3px gray`,
            zIndex: index,
            fontSize: '0.65rem'
          }}
        >
          {assignment.shift?.shiftPattern?.name.substring(0, 2).toUpperCase()}
        </ShiftAvatar>
      ));
    };

    if (isMobile) {
      return (
        <Grid item xs={12 / 7} key={day.format('YYYY-MM-DD')}>
          <Badge
            badgeContent={dayAssignments.length}
            color="primary"
            invisible={dayAssignments.length === 0}
            sx={{
              '& .MuiBadge-badge': {
                right: 5,
                top: 5,
                padding: '0 4px',
                color: 'white',
                boxShadow: '0.5px 0px 0px 0.1px rgba(0,0,0,0.2)'
              }
            }}
          >
            <Paper
              elevation={1}
              sx={{
                height: '2.5rem',
                width: '2.5rem',
                margin: 'auto',
                borderRadius: '30%',
                border: isToday
                  ? `1px solid ${theme.palette.error.main}`
                  : 'none',
                backgroundColor: isCurrentMonth
                  ? 'white'
                  : theme.palette.action.hover,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }}
              onClick={() => handleDayClick(day)}
            >
              <Typography variant="caption">{day?.date()}</Typography>
            </Paper>
          </Badge>
        </Grid>
      );
    }

    return (
      <Grid item xs={12 / 7} key={day.format('YYYY-MM-DD')}>
        <DayCell
          isCurrentMonth={isCurrentMonth}
          isToday={isToday}
          onClick={() => handleDayClick(day)}
        >
          <Typography variant="subtitle2">{day.date()}</Typography>
          {dayAssignments.length > 0 && <ShiftIndicator />}
          <Box display="flex" mt={1}>
            {renderShiftAvatars()}
          </Box>
          {dayAssignments.length > 0 && (
            <Typography variant="caption">
              {dayAssignments.length}{' '}
              {dayAssignments.length === 1 ? 'shift' : 'shifts'}
            </Typography>
          )}
        </DayCell>
      </Grid>
    );
  };

  const renderCalendarDays = (): JSX.Element[] => {
    const days: JSX.Element[] = [];
    const startDate = moment(currentDate).startOf('month');
    const endDate = moment(currentDate).endOf('month');

    const firstDayOfMonth = startDate.day();
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(
        <Grid item xs={12 / 7} key={`empty-start-${i}`}>
          <Box />
        </Grid>
      );
    }

    let day = startDate.clone();
    while (day.isSameOrBefore(endDate)) {
      days.push(renderCalendarDay(day.clone(), true));
      day.add(1, 'day');
    }

    const lastDayOfMonth = endDate.day();
    for (let i = lastDayOfMonth; i < 6; i++) {
      days.push(
        <Grid item xs={12 / 7} key={`empty-end-${i}`}>
          <Box />
        </Grid>
      );
    }

    return days;
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Typography color="error">
        Error loading shifts. Please try again later.
      </Typography>
    );
  }

  return (
    <CalendarContainer>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h5">{currentDate.format('MMMM YYYY')}</Typography>
        <Box>
          <IconButton onClick={handlePrevMonth}>
            <ChevronLeft />
          </IconButton>
          <IconButton onClick={handleNextMonth}>
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>
      <Grid container spacing={1}>
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
          <Grid item xs={12 / 7} key={day}>
            <Typography variant="subtitle1" align="center">
              {day}
            </Typography>
          </Grid>
        ))}
        {isMobile && !showFullCalendar
          ? renderCalendarDays().slice(0, 7)
          : renderCalendarDays()}
      </Grid>
      {isMobile && (
        <Box
          onClick={() => setShowFullCalendar(!showFullCalendar)}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 2,
            cursor: 'pointer'
          }}
        >
          <Typography variant="button" sx={{ mr: 1 }}>
            {showFullCalendar ? 'Show Current Week' : 'View Full Month'}
          </Typography>
          {showFullCalendar ? <ExpandLess /> : <ExpandMore />}
        </Box>
      )}

      <Dialog
        open={isViewShiftOpen}
        onClose={() => setIsViewShiftOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h5">
            Shifts for {selectedDate?.format('MMMM D, YYYY')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            {selectedShifts.map((assignment) => (
              <Grid item xs={12} key={assignment._id}>
                <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    {assignment.shift?.shiftPattern.name}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        <strong>Home:</strong> {assignment.shift?.homeId.name}
                      </Typography>
                      <Typography>
                        <strong>Time:</strong>{' '}
                        {
                          assignment.shift?.shiftPattern?.timings?.find(
                            (timing) =>
                              timing.careHomeId ===
                              assignment.shift?.homeId?._id
                          )?.startTime
                        }{' '}
                        -{' '}
                        {
                          assignment.shift?.shiftPattern?.timings?.find(
                            (timing) =>
                              timing.careHomeId ===
                              assignment.shift?.homeId?._id
                          )?.endTime
                        }
                      </Typography>
                      <Typography>
                        <strong>Status:</strong> {assignment.status}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box display="flex" justifyContent="flex-end" gap={2}>
                        <LoadingButton
                          variant="contained"
                          color="primary"
                          disabled={
                            assignment?.timesheet?.status === 'pending' ||
                            assignment?.timesheet?.status === 'approved'
                          }
                          onClick={() => handleRequestTimesheet(assignment)}
                          loading={requestTimeSheetState.isLoading}
                        >
                          {assignment?.timesheet?.status === 'pending'
                            ? 'Timesheet Pending'
                            : assignment?.timesheet?.status === 'approved'
                            ? 'Timesheet Approved'
                            : 'Request Timesheet'}
                        </LoadingButton>
                      </Box>
                    </Grid>
                  </Grid>
                  {qrCodeData && (
                    <Box mt={3} display="flex" justifyContent="center">
                      <QRCode
                        size={256}
                        style={{
                          height: 'auto',
                          maxWidth: '100%',
                          width: '100%'
                        }}
                        value={qrCodeData}
                        viewBox={`0 0 256 256`}
                      />
                    </Box>
                  )}
                </Paper>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsViewShiftOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </CalendarContainer>
  );
};

export default CareStaffShiftCalendar;

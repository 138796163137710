import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography
} from '@mui/material';

interface DeleteResidentDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteResidentDialog: React.FC<DeleteResidentDialogProps> = ({
  open,
  onClose,
  onConfirm
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-resident-dialog-title"
      aria-describedby="delete-resident-dialog-description"
    >
      <DialogTitle id="delete-resident-dialog-title">
        Confirm Deletion
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-resident-dialog-description">
          Are you sure you want to delete this resident?
        </DialogContentText>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ marginTop: '1rem' }}
        >
          Note: Deleted resident data will be archived in our systems for 3 days
          and will be automatically deleted afterwards to prevent unwanted data
          loss.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="error" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteResidentDialog;

import React from 'react';
import TaskGrid from './task-grid';

interface AllTasksTabProps {
  tasks: any[];
  onResolveTask: (task: any) => void;
}

const AllTasksTab: React.FC<AllTasksTabProps> = ({ tasks, onResolveTask }) => {
  return <TaskGrid tasks={tasks} onTaskClick={onResolveTask} />;
};

export default AllTasksTab;
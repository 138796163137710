// chat.ts
import { baseApi } from './base';

export const chatApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    sendChat: builder.mutation({
      query: (data: {
        senderId: string;
        recipientId: string;
        content: string;
        messageType: string;
      }) => ({
        url: `chat/`,
        method: 'POST',
        body: data
      })
    }),
    sendGlobalChat: builder.mutation({
      query: (data: {
        senderId: string;
        content: string;
        messageType: string;
        recipient: string;
      }) => ({
        url: `chat/global`,
        method: 'POST',
        body: data
      })
    }),
    markAsRead: builder.mutation({
      query: (messageId: string) => ({
        url: `chat/read/${messageId}`,
        method: 'PUT'
      })
    }),
    markAllAsRead: builder.mutation({
      query: (otherUserId: string) => ({
        url: `chat/read-all/${otherUserId}`,
        method: 'PUT'
      }),
      invalidatesTags: ['StaffsForChat']
    }),
    getUnreadCount: builder.query({
      query: () => `chat/unread/count`
    }),
    getChats: builder.query({
      query: ({ otherUserId, page = 1, limit = 20 }) => ({
        url: `chat/history/${otherUserId}`,
        params: { page, limit }
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.page === 1) {
          return newItems;
        }
        return {
          ...newItems,
          data: {
            ...newItems.data,
            messages: [
              ...newItems.data.messages,
              ...(currentCache?.data?.messages || [])
            ]
          }
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      transformResponse: (response: any) => {
        return {
          data: {
            messages: response.data.messages,
            currentPage: response.data.currentPage,
            totalPages: response.data.totalPages,
            totalCount: response.data.totalCount
          }
        };
      },

      providesTags: ['ChatMessages']
    })
  })
});

export const {
  useMarkAllAsReadMutation,
  useSendChatMutation,
  useSendGlobalChatMutation,
  useMarkAsReadMutation,
  useGetUnreadCountQuery,
  useGetChatsQuery,
  useLazyGetChatsQuery
} = chatApi;

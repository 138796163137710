import React, { useState, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Chip,
  Menu,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import moment from 'moment';
import { Shift } from './types';
import type { IStaff } from 'src/interfaces/staffs';
import ConfirmationDialog from './confirm-assign';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { useAssignUsersToShiftMutation } from 'src/redux/@api/shift';
import { useGetCareStaffsQuery } from 'src/redux/@api/misc';
import { Edit, Delete, Refresh } from '@mui/icons-material';

interface AssignStaffDialogProps {
  open: boolean;
  onClose: () => void;
  shift: Shift;
  onAssign: (staffIds: string[]) => void;
}

const AssignStaffDialog: React.FC<AssignStaffDialogProps> = ({
  open,
  onClose,
  shift,
  onAssign
}) => {
  const [selectedStaff, setSelectedStaff] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [roleFilter, setRoleFilter] = useState('all');
  const [similarShifts, setSimilarShifts] = useState<Shift[]>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [editingStaffId, setEditingStaffId] = useState<string | null>(null);
  const dispatch = useDispatch();
  const [assignCarers] = useAssignUsersToShiftMutation();
  const shifts = useAppSelector((state) => state.shifts.shifts);
  const {
    data: careStaffs,
    isLoading,
    isError
  } = useGetCareStaffsQuery(undefined);

  const assignedStaffIds = useMemo(
    () => shift.shiftAssignments?.map((assignment) => assignment.user),
    [shift.shiftAssignments]
  );

  const handleStaffToggle = (staffId: string) => {
    const newSelectedStaff = selectedStaff.includes(staffId)
      ? selectedStaff.filter((id) => id !== staffId)
      : [...selectedStaff, staffId];

    setSelectedStaff(newSelectedStaff);

    // Remove the condition that checks for exact match with shift count
    if (newSelectedStaff?.length > 0) {
      const foundSimilarShifts = findSimilarShifts();
      if (foundSimilarShifts?.length > 0) {
        setSimilarShifts(foundSimilarShifts);
        setShowConfirmation(true);
      }
    }
  };

  const handleAssign = async (assignToAll: boolean) => {
    if (selectedStaff?.length > 0) {
      try {
        let assignments;
        if (assignToAll) {
          assignments = [shift, ...similarShifts]?.map((s) => ({
            shiftId: s._id,
            userIds: selectedStaff // Only include newly selected staff
          }));
        } else {
          assignments = [
            {
              shiftId: shift._id,
              userIds: selectedStaff // Only include newly selected staff
            }
          ];
        }
        const result = await assignCarers({
          assignments,
          shiftId: shift._id
        }).unwrap();

        if (result.some((r) => 'error' in r)) {
          dispatch(
            showSnack({
              message: 'Some assignments failed. Please check and try again.',
              color: 'warning'
            })
          );
        } else {
          dispatch(
            showSnack({
              message: 'Staff assigned successfully',
              color: 'success'
            })
          );
        }

        onAssign(result);
        onClose();
      } catch (error) {
        console.error('Failed to assign staff:', error);
        dispatch(
          showSnack({
            message: 'Failed to assign staff. Please try again.',
            color: 'danger'
          })
        );
      }
    }
  };

  const findSimilarShifts = (): any[] => {
    const currentDate = moment(shift.date);

    return shifts
      .filter((s) => {
        const shiftDate = moment(s.date);

        return (
          shiftDate.isAfter(currentDate) &&
          s.shiftPattern._id === shift.shiftPattern._id &&
          s._id !== shift._id &&
          !s.isCompleted &&
          (!s.shiftAssignments || s.shiftAssignments?.length < s.count)
        );
      })
      .sort((a, b) => moment(a.date).diff(moment(b.date)));
  };

  const filteredStaff = useMemo(() => {
    return careStaffs?.filter((staff: IStaff) => {
      const nameMatch = `${staff.user?.firstName} ${staff.user?.lastName}`
        .toLowerCase()
        ?.includes(searchTerm.toLowerCase());
      const roleMatch = roleFilter === 'all' || staff.role === roleFilter;
      const isUnassigned = !assignedStaffIds?.includes(staff.user?._id);
      return nameMatch && roleMatch && isUnassigned;
    });
  }, [careStaffs, searchTerm, roleFilter, assignedStaffIds]);

  const handleEditClick = (
    event: React.MouseEvent<HTMLElement>,
    staffId: string
  ) => {
    setAnchorEl(event.currentTarget);
    setEditingStaffId(staffId);
  };

  const handleEditClose = () => {
    setAnchorEl(null);
    setEditingStaffId(null);
  };

  const handleRemove = () => {
    // TODO: Implement remove functionality
    handleEditClose();
  };

  const handleReplace = () => {
    // TODO: Implement replace functionality
    handleEditClose();
  };

  if (isLoading) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  if (isError) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <Typography color="error">Error loading staff data</Typography>
        </DialogContent>
      </Dialog>
    );
  }

  const remainingStaff = shift.count - assignedStaffIds?.length;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Assign Staff to {shift.shiftPattern.name}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom>
          Select {remainingStaff > 0 ? remainingStaff : 0} more staff members
          for this shift
        </Typography>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Assigned Staff:</Typography>
            {shift.shiftAssignments?.map((assignment) => {
              const staff = careStaffs?.find(
                (s) => s.user?._id === assignment.user
              );
              return (
                <Chip
                  key={assignment.user}
                  label={`${staff?.user?.firstName} ${staff?.user?.lastName}`}
                  onDelete={(event) => handleEditClick(event, assignment.user)}
                  deleteIcon={<Edit />}
                  style={{ margin: '0 4px 4px 0' }}
                />
              );
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Search staff"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Filter by role</InputLabel>
              <Select
                value={roleFilter}
                onChange={(e) => setRoleFilter(e.target.value as string)}
                label="Filter by role"
              >
                <MenuItem value="all">All roles</MenuItem>
                <MenuItem value="caregiver">Caregiver</MenuItem>
                <MenuItem value="nurse">Nurse</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {filteredStaff?.map((staff: IStaff) => (
            <Grid item xs={12} sm={6} md={4} key={staff._id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedStaff?.includes(staff.user?._id)}
                    onChange={() => handleStaffToggle(staff.user?._id)}
                    disabled={
                      selectedStaff?.length + assignedStaffIds?.length >=
                        shift.count && !selectedStaff?.includes(staff.user?._id)
                    }
                  />
                }
                label={`${staff.user?.firstName} ${staff.user?.lastName} (${staff.role})`}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => setShowConfirmation(true)}
          disabled={selectedStaff?.length === 0}
          variant="contained"
          color="primary"
        >
          Assign Staff
        </Button>
      </DialogActions>

      <ConfirmationDialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        similarShifts={similarShifts}
        onConfirm={(assignToAll) => {
          setShowConfirmation(false);
          handleAssign(assignToAll);
        }}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleEditClose}
      >
        <MenuItem onClick={handleRemove}>
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <ListItemText>Remove</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleReplace}>
          <ListItemIcon>
            <Refresh fontSize="small" />
          </ListItemIcon>
          <ListItemText>Replace</ListItemText>
        </MenuItem>
      </Menu>
    </Dialog>
  );
};

export default AssignStaffDialog;

// timesheets.ts
import { baseApi } from './base';

export const timesheetsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTimesheets: builder.query({
      query: () => `timesheet`,
      providesTags: ['Timesheets']
    }),
    getQuickStats: builder.query({
      query: ({ month, year }) => ({
        url: 'shift/utils/quickstats',
        method: 'GET',
        params: { month, year }
      })
    }),
    createTimesheetRequest: builder.mutation({
      query: (data: {
        shiftId: string;
        shiftPatternId: string;
        homeId: string;
      }) => ({
        url: `timesheet`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Timesheets']
    }),

    approveTimesheet: builder.mutation({
      query: ({
        timesheetId,
        rating,
        review
      }: {
        timesheetId: string;
        rating?: number;
        review?: string;
      }) => ({
        url: `timesheet/${timesheetId}/approve`,
        method: 'PATCH',
        body: {
          rating,
          review
        }
      }),
      invalidatesTags: ['Timesheets']
    }),
    rejectTimesheet: builder.mutation({
      query: (timesheetId: string) => ({
        url: `timesheet/${timesheetId}/reject`,
        method: 'PATCH'
      }),
      invalidatesTags: ['Timesheets']
    }),
    // New endpoint for generating QR code
    createTimesheetAndGenerateQRCode: builder.mutation<
      { timesheet; qrCodeToken: string },
      { shiftId: string; shiftPatternId: string; homeId: string }
    >({
      query: (body) => ({
        url: 'timesheet/generate-qr',
        method: 'POST',
        body
      })
    }),
    validateQRCode: builder.mutation<
      { message: string; timesheet },
      { token: string }
    >({
      query: (body) => ({
        url: 'timesheet/validate-qr',
        method: 'POST',
        body
      })
    })
  })
});

export const {
  useValidateQRCodeMutation,
  useGetTimesheetsQuery,
  useLazyGetTimesheetsQuery,
  useGetQuickStatsQuery,
  useCreateTimesheetRequestMutation,
  useApproveTimesheetMutation,
  useRejectTimesheetMutation,
  useCreateTimesheetAndGenerateQRCodeMutation,
  useLazyGetQuickStatsQuery
} = timesheetsApi;

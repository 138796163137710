import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RefreshState {
  isRefreshing: boolean;
  lastRefreshed: string | null;
}

const initialState: RefreshState = {
  isRefreshing: false,
  lastRefreshed: null
};

const refreshSlice = createSlice({
  name: 'refresh',
  initialState,
  reducers: {
    startRefresh: (state) => {
      state.isRefreshing = true;
    },
    endRefresh: (state) => {
      state.isRefreshing = false;
      state.lastRefreshed = new Date().toISOString();
    }
  }
});

export const { startRefresh, endRefresh } = refreshSlice.actions;
export default refreshSlice.reducer;

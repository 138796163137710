import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import {
  PieChart,
  Pie,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell
} from 'recharts';
import { Moon, Sun, Activity } from 'lucide-react';

const SleepAnalysisUI = ({ sleepAnalysis }) => {
  const { dayAnalysis, nightAnalysis, overallAnalysis } = sleepAnalysis;

  const pieChartData = [
    {
      name: 'Day Sleep',
      value: overallAnalysis.daytimeSleepPercentage,
      color: '#FFB74D'
    },
    {
      name: 'Night Sleep',
      value: overallAnalysis.nighttimeSleepPercentage,
      color: '#4FC3F7'
    }
  ];

  const barChartData = [
    { name: 'Longest Sleep', hours: overallAnalysis.longestSleepPeriod },
    { name: 'Average Sleep', hours: overallAnalysis.averageSleepPeriod },
    { name: 'Shortest Sleep', hours: overallAnalysis.shortestSleepPeriod }
  ];

  const sleepStatusData = [
    {
      name: 'Day Awake',
      value: dayAnalysis.statusCounts.awake,
      color: '#FFA726'
    },
    {
      name: 'Day Asleep',
      value: dayAnalysis.statusCounts.asleep,
      color: '#FFD54F'
    },
    {
      name: 'Night Awake',
      value: nightAnalysis.statusCounts.awake,
      color: '#29B6F6'
    },
    {
      name: 'Night Asleep',
      value: nightAnalysis.statusCounts.asleep,
      color: '#4FC3F7'
    }
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Card elevation={3}>
          <CardContent>
            <Typography variant="h6" gutterBottom fontWeight="bold">
              Sleep Distribution
            </Typography>
            <Box height={300}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={pieChartData}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    label={({ name, percent }) =>
                      `${name} ${(percent * 100).toFixed(0)}%`
                    }
                  >
                    {pieChartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card elevation={3}>
          <CardContent>
            <Typography variant="h6" gutterBottom fontWeight="bold">
              Sleep Periods (Hours)
            </Typography>
            <Box height={300}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={barChartData}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="hours" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card elevation={3}>
          <CardContent>
            <Typography variant="h6" gutterBottom fontWeight="bold">
              Sleep Status Distribution
            </Typography>
            <Box height={300}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={sleepStatusData} layout="vertical">
                  <XAxis type="number" />
                  <YAxis dataKey="name" type="category" />
                  <Tooltip />
                  <Bar dataKey="value" fill="#8884d8">
                    {sleepStatusData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card elevation={3}>
          <CardContent>
            <Typography variant="h6" gutterBottom fontWeight="bold">
              Sleep Summary
            </Typography>
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" alignItems="center" gap={2}>
                <Activity size={24} color="#8884d8" />
                <Typography>
                  Total Sleep: {overallAnalysis.totalSleepHours.toFixed(1)}{' '}
                  hours
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                <Sun size={24} color="#FFA726" />
                <Typography>
                  Daytime Sleep: {dayAnalysis.totalSleepHours.toFixed(1)} hours
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                <Moon size={24} color="#29B6F6" />
                <Typography>
                  Nighttime Sleep: {nightAnalysis.totalSleepHours.toFixed(1)}{' '}
                  hours
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SleepAnalysisUI;

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Box,
  Select,
  MenuItem,
  Button,
  useTheme
} from '@mui/material';

const FilterDialog = ({
  open,
  onClose,
  filterOptions,
  onFilterChange,
  onApplyFilters,
  onClearFilters
}) => {
  const theme = useTheme();

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Filter Timesheets</DialogTitle>
      <DialogContent>
        <TextField
          label="Home Name"
          value={filterOptions.homeName}
          onChange={(e) => onFilterChange('homeName', e.target.value)}
          fullWidth
          margin="normal"
        />
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              gap: 0
            }
          }}
        >
          <TextField
            label="Start Date"
            type="date"
            value={filterOptions.startDate}
            onChange={(e) => onFilterChange('startDate', e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="End Date"
            type="date"
            value={filterOptions.endDate}
            onChange={(e) => onFilterChange('endDate', e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Select
          value={filterOptions.shiftType}
          onChange={(e) => onFilterChange('shiftType', e.target.value)}
          fullWidth
          displayEmpty
          margin="dense"
        >
          <MenuItem value="">All Shift Types</MenuItem>
          <MenuItem value="Longday">Longday</MenuItem>
          <MenuItem value="Night">Night</MenuItem>
          <MenuItem value="Early">Early</MenuItem>
          <MenuItem value="Late">Late</MenuItem>
        </Select>
        <Select
          value={filterOptions.status}
          onChange={(e) => onFilterChange('status', e.target.value)}
          fullWidth
          displayEmpty
          margin="dense"
          sx={{ mt: 1 }}
        >
          <MenuItem value="all">All Statuses</MenuItem>
          <MenuItem value="pending">Pending</MenuItem>
          <MenuItem value="approved">Approved</MenuItem>
        </Select>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={onClearFilters} variant="outlined" color="secondary">
            Clear All Filters
          </Button>
          <Button onClick={onApplyFilters} variant="contained" color="primary">
            Apply Filters
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FilterDialog;

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Tabs,
  Tab,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { IResident } from 'src/interfaces/resident';
import { useGetTasksQuery } from 'src/redux/@api/resident';
import AllTasksTab from './tabs/all-task';
import PendingTasksTab from './tabs/pending-tasks';
import HistoricalTasksTab from './tabs/historical-tasks';
import ResidentProfile from './dialogs/resident-profile';
import TaskResolveDialog from './tabs/rosolve-task';
import AddTaskDialog from './dialogs/add-tasks';
import { useCompleteTaskMutation } from 'src/redux/@api/task';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { he } from 'date-fns/locale';

interface TaskManagementDialogProps {
  open: boolean;
  onClose: () => void;
  resident: IResident;
}

const TaskManagementDialog: React.FC<TaskManagementDialogProps> = ({
  open,
  onClose,
  resident
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [addTaskDialogOpen, setAddTaskDialogOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [resolveDialogOpen, setResolveDialogOpen] = useState(false);

  const { data: tasks, refetch: refetchTasks } = useGetTasksQuery(resident._id);

  const [completeTask] = useCompleteTaskMutation();
  const dispatch = useDispatch();

  const handleAddTask = () => {
    setAddTaskDialogOpen(true);
  };

  const handleTaskAdded = () => {
    setAddTaskDialogOpen(false);
    refetchTasks();
  };

  const handleResolveTask = (task) => {
    setSelectedTask(task);
    setResolveDialogOpen(true);
  };

  const handleCompleteTask = async (taskData: any) => {
    if (selectedTask) {
      try {
        await completeTask({ taskId: selectedTask._id, taskData }).unwrap();
        setResolveDialogOpen(false);
        setSelectedTask(null);
        refetchTasks();
        dispatch(showSnack({ color: 'success', message: 'Task completed' }));
      } catch (error) {
        dispatch(
          showSnack({ color: 'error', message: 'Error completing task' })
        );
        console.error('Error completing task:', error);
      }
    }
  };

  const handleTaskResolved = async (taskData) => {
    await handleCompleteTask(taskData);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="lg"
        sx={{
          '& .MuiDialog-paper': {
            [theme.breakpoints.down('sm')]: {
              margin: 0
            }
          },
          '$ .MuiDialog-root': {
            width: '80vw',
            [theme.breakpoints.down('sm')]: {
              width: '95vw !important'
            }
          }
        }}
        PaperProps={{
          sx: {
            height: '80vh',
            maxHeight: 'none',
            width: '80vw',
            [theme.breakpoints.down('sm')]: {
              height: '90vh',
              width: '95vw !important'
            }
          }
        }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <Typography variant={isMobile ? 'h5' : 'h4'} component="span">
              Task Management: {resident.firstName} {resident.lastName}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                mt: isMobile ? 2 : 0
              }}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<Icon icon="fe:line-chart" />}
                size="small"
                onClick={() =>
                  navigate('/charts', { state: { residentId: resident._id } })
                }
              >
                Charts
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Icon icon="ri:add-line" />}
                onClick={handleAddTask}
                size="small"
              >
                Add Task
              </Button>
            </Box>
          </Box>
          <Tabs
            value={tabValue}
            onChange={(_, newValue) => setTabValue(newValue)}
            variant={isMobile ? 'fullWidth' : 'standard'}
            sx={{
              mt: 2,
              '& .MuiTab-root': {
                minWidth: isMobile ? 'auto' : 120,
                px: isMobile ? 1 : 2
              }
            }}
          >
            <Tab label="All" />
            <Tab label="Pending" />
            <Tab label="Historical" />
            <Tab label="Profile" />
          </Tabs>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%'
          }}
          dividers
        >
          <Box sx={{ height: '100%', overflow: 'auto' }}>
            {tabValue === 0 && (
              <AllTasksTab tasks={tasks} onResolveTask={handleResolveTask} />
            )}
            {tabValue === 1 && (
              <PendingTasksTab
                tasks={tasks}
                onResolveTask={handleResolveTask}
              />
            )}
            {tabValue === 2 && <HistoricalTasksTab residentId={resident._id} />}
            {tabValue === 3 && (
              <ResidentProfile residentId={resident._id} onClose={onClose} />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>

        <AddTaskDialog
          open={addTaskDialogOpen}
          onClose={() => setAddTaskDialogOpen(false)}
          onTaskAdded={handleTaskAdded}
          residentId={resident._id}
        />

        <TaskResolveDialog
          open={resolveDialogOpen}
          onClose={() => setResolveDialogOpen(false)}
          onResolve={handleTaskResolved}
          task={selectedTask}
        />
      </Dialog>
    </LocalizationProvider>
  );
};

export default TaskManagementDialog;

import React from 'react';
import { Box, Typography, Button, Link } from '@mui/material';
import { motion } from 'framer-motion';
import { pageTransition, pageVariants } from '../animation';

const GetStarted = ({ onStart }) => (
  <motion.div
    initial="initial"
    animate="in"
    exit="out"
    variants={pageVariants}
    transition={pageTransition}
  >
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      position="relative"
    >
      <Box position="absolute" top={16} left={16}>
        <Link href="/login" underline="always">
          Login
        </Link>
      </Box>
      <Typography variant="h2" gutterBottom>
        Welcome
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={onStart}
      >
        Get Started
      </Button>
    </Box>
  </motion.div>
);

export default GetStarted;

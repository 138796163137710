// documents.ts
import { baseApi } from './base';

export const documentsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadFiles: builder.mutation({
      query: ({
        userId,
        formData
      }: {
        userId: string;
        formData: FormData;
      }) => ({
        url: `carer-documents/${userId}/upload`,
        method: 'POST',
        body: formData
      })
    }),
    getCarerDocuments: builder.query({
      query: (userId: string) => `carer-documents/${userId}/documents`
    }),
    deleteCarerDocument: builder.mutation({
      query: ({
        carerId,
        documentId,
        fileName
      }: {
        carerId: string;
        documentId: string;
        fileName: string;
      }) => ({
        url: `carer-documents/${carerId}/document/${documentId}/file/${fileName}`,
        method: 'DELETE'
      })
    }),
    deleteCarerCertificate: builder.mutation({
      query: ({
        carerId,
        certificateId,
        fileName
      }: {
        carerId: string;
        certificateId: string;
        fileName: string;
      }) => ({
        url: `carer-documents/${carerId}/certificate/${certificateId}/file/${fileName}`,
        method: 'DELETE'
      })
    }),
    getCarerCertificates: builder.query({
      query: (userId: string) => `carer-documents/${userId}/certificates`
    }),
    uploadShareCode: builder.mutation({
      query: ({
        userId,
        shareCode
      }: {
        userId: string;
        shareCode: string;
      }) => ({
        url: `carer-documents/${userId}/share-code`,
        method: 'PATCH',
        body: {
          shareCode
        }
      })
    }),
    updateNINumber: builder.mutation({
      query: ({ userId, niNumber }: { userId: string; niNumber: string }) => ({
        url: `carer-documents/${userId}/ni-number`,
        method: 'PATCH',
        body: {
          niNumber
        }
      })
    }),
    getAdditionalInfo: builder.query({
      query: (userId: string) => `carer-documents/${userId}/additional-info`
    }),
  })
});

export const {
  useUploadFilesMutation,
  useGetCarerDocumentsQuery,
  useLazyGetCarerDocumentsQuery,
  useDeleteCarerDocumentMutation,
  useDeleteCarerCertificateMutation,
  useGetCarerCertificatesQuery,
  useLazyGetCarerCertificatesQuery,
  useUploadShareCodeMutation,
  useUpdateNINumberMutation,
  useGetAdditionalInfoQuery,
  useLazyGetAdditionalInfoQuery,
} = documentsApi;
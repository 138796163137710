import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  TextField,
  IconButton,
  Typography,
  Avatar,
  Paper,
  List,
  ListItem,
  styled,
  useTheme,
  CircularProgress,
  Divider
} from '@mui/material';
import {
  Send,
  AttachFile,
  Mic,
  MoreVert,
  ArrowBack
} from '@mui/icons-material';
import { useAppSelector } from 'src/redux/hook';
import {
  useLazyGetChatsQuery,
  useSendChatMutation,
  useSendGlobalChatMutation
} from 'src/redux/@api/chat';
import { wsHostname } from 'src/api/api';

interface ChatBoxProps {
  user: {
    user: any;
    type: string;
  };
  isMobile: boolean;
  onClose: () => void;
}

interface Message {
  _id: string;
  sender: any;
  receiver: any;
  content: string;
  messageType: string;
  createdAt: string;
  readBy: string[];
}

const ChatContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}));

const ChatHeader = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: '10px',
  position: 'sticky',
  top: 0,
  zIndex: 1000,
  height: '64px'
}));

const MessageList = styled(List)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  padding: theme.spacing(2),
  backgroundColor: 'transparent',
  height: `calc(100% - 128px)`,
  position: 'relative',
  zIndex: 1
}));

const MessageBubbleBase = styled(Paper)(({ theme }) => ({
  maxWidth: '70%',
  padding: theme.spacing(1, 2),
  marginBottom: theme.spacing(1),
  borderRadius: 16,
  position: 'relative',
  boxShadow: 'none'
}));

const MessageBubble = styled(MessageBubbleBase, {
  shouldForwardProp: (prop) => prop !== 'isCurrentUser'
})<{ isCurrentUser: boolean }>(({ theme, isCurrentUser }) => ({
  alignSelf: isCurrentUser ? 'flex-end' : 'flex-start',
  backgroundColor: isCurrentUser
    ? theme.palette.primary.main + ' !important'
    : theme.palette.background.paper + ' !important',
  color: isCurrentUser
    ? theme.palette.primary.contrastText
    : theme.palette.text.primary,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    [isCurrentUser ? 'right' : 'left']: -8,
    borderTop: `8px solid ${
      isCurrentUser
        ? theme.palette.primary.main
        : theme.palette.background.paper
    }`,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent'
  }
}));

const InputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`
}));

const ChatBox: React.FC<ChatBoxProps> = ({ user, isMobile, onClose }) => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const messageListRef = useRef<HTMLUListElement>(null);
  const wsRef = useRef<WebSocket | null>(null);

  const theme = useTheme();
  const currentUser = useAppSelector((state) => state.userState.user);

  const [sendMessage] = useSendChatMutation();
  const [sendGlobal] = useSendGlobalChatMutation();
  const [getMessages, { isLoading, isFetching }] = useLazyGetChatsQuery();

  useEffect(() => {
    wsRef.current = new WebSocket(`${wsHostname}?userId=${currentUser._id}`);

    wsRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Received message:', data);
      console.log('Current user:', currentUser._id);
      if (data.sender && data.sender._id === user.user?._id) {
        setMessages((prevMessages) => [...prevMessages, data]);
        scrollToBottom();
      }
    };

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [currentUser._id, user.user?._id]);

  useEffect(() => {
    if (user.user?._id) {
      setPage(1);
      setHasMore(true);
      setMessages([]);
      loadMessages(1);
    }
  }, [user.user?._id]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        messageListRef.current &&
        messageListRef.current.scrollTop === 0 &&
        !isLoading &&
        !isFetching &&
        hasMore
      ) {
        loadMoreMessages();
      }
    };

    messageListRef.current?.addEventListener('scroll', handleScroll);
    return () =>
      messageListRef.current?.removeEventListener('scroll', handleScroll);
  }, [isLoading, isFetching, hasMore]);

  const loadMessages = async (newPage = 1) => {
    if (!user.user?._id) return;

    try {
      const result = await getMessages({
        otherUserId: user.user._id,
        page: newPage,
        limit: 20
      }).unwrap();

      const newMessages = result.data.messages;
      setMessages((prevMessages) =>
        newPage === 1 ? newMessages : [...newMessages, ...prevMessages]
      );

      setHasMore(result.data.currentPage < result.data.totalPages);
      setPage(result.data.currentPage);

      if (newPage === 1) {
        scrollToBottom();
      }
    } catch (error) {
      console.error('Failed to load messages:', error);
    }
  };

  const loadMoreMessages = () => {
    if (hasMore && !isLoading && !isFetching) {
      loadMessages(page + 1);
    }
  };

  const handleSend = async () => {
    if (message.trim() && user.user?._id) {
      try {
        const result = await sendMessage({
          senderId: currentUser._id,
          recipientId: user.user._id,
          content: message,
          messageType: 'text'
        }).unwrap();
        setMessages((prevMessages) => [...prevMessages, result.data]);
        setMessage('');
        scrollToBottom();
      } catch (error) {
        console.error('Failed to send message:', error);
      }
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messageListRef.current) {
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
      }
    }, 0);
  };

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const isCurrentUserMessage = (msg: Message) => {
    if (typeof msg.sender === 'string') {
      return msg.sender === currentUser._id;
    }
    return msg.sender._id === currentUser._id;
  };

  return (
    <ChatContainer>
      <ChatHeader elevation={0}>
        {isMobile && (
          <IconButton onClick={onClose}>
            <ArrowBack />
          </IconButton>
        )}
        <Avatar
          src={user.user.avatar || ''}
          sx={{ width: 40, height: 40, mr: 1 }}
        />
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1">{`${user.user?.firstName} ${user.user?.lastName}`}</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {user.type}
          </Typography>
        </Box>
        <IconButton>
          <MoreVert />
        </IconButton>
      </ChatHeader>
      <Divider />

      <MessageList ref={messageListRef}>
        {isLoading && page === 1 ? (
          <Box display="flex" justifyContent="center" my={2}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {isFetching && page > 1 && (
              <Box display="flex" justifyContent="center" my={2}>
                <CircularProgress size={20} />
              </Box>
            )}
            {messages.map((msg) => (
              <ListItem
                key={msg._id}
                sx={{
                  display: 'flex',
                  justifyContent: isCurrentUserMessage(msg)
                    ? 'flex-end'
                    : 'flex-start',
                  padding: 0.5
                }}
              >
                <MessageBubble isCurrentUser={isCurrentUserMessage(msg)}>
                  <Typography variant="body2">{msg.content}</Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      display: 'block',
                      textAlign: 'right',
                      mt: 0.5,
                      opacity: 0.7
                    }}
                  >
                    {formatTime(msg.createdAt)}
                  </Typography>
                </MessageBubble>
              </ListItem>
            ))}
          </>
        )}
      </MessageList>

      <InputContainer>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type a message"
          value={message}
          size="small"
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSend()}
          sx={{
            mx: 1,
            '& .MuiOutlinedInput-root': {
              borderRadius: 20,
              backgroundColor: theme.palette.background.default
            }
          }}
        />
        <IconButton
          color="primary"
          onClick={handleSend}
          disabled={!message.trim()}
        >
          {message.trim() ? <Send /> : <Mic />}
        </IconButton>
      </InputContainer>
    </ChatContainer>
  );
};

export default ChatBox;

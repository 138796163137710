// invitations.ts
import { baseApi } from './base';

export const invitationsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvitations: builder.query({
      query: () => ({
        url: `invitations/`,
        method: 'GET'
      }),
      providesTags: ['Invitations']
    }),
    getInvitation: builder.query({
      query: (invToken: string) => ({
        url: `invitations/token/${invToken}`,
        method: 'GET'
      })
    }),
    acceptInvitation: builder.mutation({
      query: (invitationId: string) => ({
        url: `invitations/accept/${invitationId}`,
        method: 'PUT'
      }),
      invalidatesTags: ['Invitations']
    }),
    sendInvitation: builder.mutation({
      query: ({
        accountType,
        receiverEmail,
        companyName
      }: {
        accountType: string;
        receiverEmail: string;
        companyName: string;
      }) => ({
        url: `invitations/`,
        method: 'POST',
        body: {
          accountType: accountType,
          receiverEmail: receiverEmail,
          companyName: companyName
        }
      })
    }),
    sendHomeStaffInvitation: builder.mutation({
      query: ({
        accountType,
        receiverEmail,
        companyName
      }: {
        accountType: string;
        receiverEmail: string;
        companyName: string;
      }) => ({
        url: `invitations`,
        method: 'POST',
        body: {
          accountType: accountType,
          receiverEmail: receiverEmail,
          companyName: companyName
        }
      }),
      invalidatesTags: ['Invitations']
    }),
    sendHomeInvitation: builder.mutation({
      query: ({
        accountType,
        receiverEmail,
        companyName
      }: {
        accountType: string;
        receiverEmail: string;
        companyName: string;
      }) => ({
        url: `invitations/home`,
        method: 'POST',
        body: {
          accountType: accountType,
          receiverEmail: receiverEmail,
          companyName: companyName
        }
      }),
      invalidatesTags: ['Invitations']
    }),
    sendAgencyInvitation: builder.mutation({
      query: ({
        accountType,
        receiverEmail,
        companyName
      }: {
        accountType: string;
        receiverEmail: string;
        companyName: string;
      }) => ({
        url: `invitations/agency`,
        method: 'POST',
        body: {
          accountType: accountType,
          receiverEmail: receiverEmail,
          companyName: companyName
        }
      }),
      invalidatesTags: ['Invitations']
    }),
    getHomeStaffInvitationByToken: builder.query({
      query: (invToken: string) => ({
        url: `invitations/home-staff/token/${invToken}`,
        method: 'GET'
      })
    }),
    rejectInvitation: builder.mutation({
      query: (invitationId: string) => ({
        url: `invitations/reject/${invitationId}`,
        method: 'PUT'
      }),
      invalidatesTags: ['Invitations']
    }),
    cancelInvitation: builder.mutation({
      query: (invitationId: string) => ({
        url: `invitations/${invitationId}`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useGetInvitationsQuery,
  useLazyGetInvitationsQuery,
  useGetInvitationQuery,
  useLazyGetInvitationQuery,
  useAcceptInvitationMutation,
  useSendInvitationMutation,
  useSendHomeStaffInvitationMutation,
  useSendHomeInvitationMutation,
  useSendAgencyInvitationMutation,
  useGetHomeStaffInvitationByTokenQuery,
  useLazyGetHomeStaffInvitationByTokenQuery,
  useRejectInvitationMutation,
  useCancelInvitationMutation
} = invitationsApi;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  messageSent: null,
  newMessagesCount: {}
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    _sendMessage: (state, action: PayloadAction<string>) => {
      state.messageSent = action.payload;
    },
    _setNewMessagesCount: (state, action: PayloadAction<string>) => {
      if (!state.newMessagesCount[action.payload]) {
        state.newMessagesCount[action.payload] = 0;
      }
      state.newMessagesCount[action.payload] += 1;
    },
    _removeNewMessagesCount: (state, action: PayloadAction<string>) => {
      state.newMessagesCount[action.payload] = 0;
    }
  }
});

export const { _sendMessage, _setNewMessagesCount, _removeNewMessagesCount } =
  messageSlice.actions;
export default messageSlice.reducer;

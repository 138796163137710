import { Capacitor } from '@capacitor/core';
import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from '../config/firebase';
import { PushNotifications } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import store from 'src/redux/store';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { _setNewMessagesCount } from 'src/redux/reducers/messages';
import { useRegisterFCMTokenMutation } from 'src/redux/@api/notifications';
import { _setFcmToken } from 'src/redux/reducers/users';

let fcmToken: string | null = null;

export const getDeviceInfo = async () => {
  try {
    const info = await Device.getInfo();
    const id = await Device.getId();
    return {
      type: info.platform as 'ios' | 'android' | 'web',
      model: info.model,
      osVersion: info.osVersion,
      appVersion: '1.0.0', // You might want to get this dynamically
      identifier: id.identifier
    };
  } catch (error) {
    console.error('Error getting device info:', error);
    return null;
  }
};

export const initializeNotifications = async () => {
  try {
    if (Capacitor.isNativePlatform()) {
      await initializeNativeNotifications();
    } else {
      await initializeWebNotifications();
    }
  } catch (error) {
    console.error('Error initializing notifications:', error);
    store.dispatch(
      showSnack({
        message: 'Failed to initialize notifications. Please try again.',
        color: 'error'
      })
    );
    throw error;
  }
};

const initializeWebNotifications = async () => {
  const permission = await Notification.requestPermission();
  console.log('Notification permission:', permission);
  if (permission === 'granted') {
    const vapidKey = process.env.REACT_APP_FB_VAPID_KEY;

    if (!vapidKey || typeof vapidKey !== 'string') {
      throw new Error(
        'REACT_APP_FB_VAPID_KEY is not set correctly in the environment'
      );
    }

    try {
      const token = await getToken(messaging, { vapidKey });
      console.log('FCM Token:', token);
      fcmToken = token;
      setupForegroundMessageListener();
      await handleUploadFCMToken(token);
    } catch (error) {
      console.error('Error getting FCM token:', error);
      throw error;
    }
  } else {
    throw new Error('Notification permission not granted');
  }
};

const initializeNativeNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions();
  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }
  if (permStatus.receive !== 'granted') {
    throw new Error('Push notification permissions not granted');
  }
  await PushNotifications.register();
  await addNativeNotificationListeners();
};

const addNativeNotificationListeners = async () => {
  await PushNotifications.addListener('registration', ({ value }) => {
    console.log('Push registration success, token: ', value);
    fcmToken = value;
    handleUploadFCMToken(value);
  });

  await PushNotifications.addListener('registrationError', (err) => {
    console.error('Registration error: ', err.error);
  });

  await PushNotifications.addListener(
    'pushNotificationReceived',
    handleIncomingMessage
  );

  await PushNotifications.addListener(
    'pushNotificationActionPerformed',
    (notification) => {
      console.log(
        'Push notification action performed',
        notification.actionId,
        notification.inputValue
      );
    }
  );
};

const setupForegroundMessageListener = () => {
  onMessage(messaging, (payload) => {
    console.log('Received foreground message:', payload);

    if (payload.data?.type === 'NEW_MESSAGE') {
      store.dispatch(_setNewMessagesCount(payload?.data?.senderId));
    }
    const { notification } = payload;
    if (notification) {
      showForegroundNotification(notification as any);
    }
  });
};

const showForegroundNotification = (notification: {
  title: string;
  body: string;
}) => {
  const { title, body } = notification;
  if (Notification.permission === 'granted') {
    new Notification(title, { body });
  }
  store.dispatch(
    showSnack({
      message: body,
      color: 'info'
    })
  );
};

const handleIncomingMessage = (payload: any) => {
  console.log('Incoming message:', payload);
  showForegroundNotification(payload);
};

export const handleUploadFCMToken = async (token: string) => {
  try {
    console.log('Uploading FCM Token:', token);
    const deviceInfo = await getDeviceInfo();
    if (deviceInfo) {
      const [registerFCMToken] = useRegisterFCMTokenMutation();
      const payload = {
        token,
        device: deviceInfo
      };
      const response = await registerFCMToken(payload).unwrap();
      if (response.success) {
        store.dispatch(_setFcmToken(token));
        console.log('FCM token registered successfully');
      } else {
        throw new Error(response.message);
      }
    } else {
      throw new Error('Failed to get device info');
    }
  } catch (error) {
    console.error('Error uploading FCM token:', error);
    store.dispatch(
      showSnack({
        message:
          'Failed to register for notifications. Please try again later.',
        color: 'warning'
      })
    );
    // You might want to implement a retry mechanism here
  }
};

export const getFCMToken = () => fcmToken;

export const checkNotificationPermission = async () => {
  if (Capacitor.isNativePlatform()) {
    const permStatus = await PushNotifications.checkPermissions();
    return permStatus.receive === 'granted';
  } else {
    return Notification.permission === 'granted';
  }
};

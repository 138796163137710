import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  IconButton,
  CircularProgress,
  Tooltip,
  useTheme,
  useMediaQuery,
  Paper
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ICarerApplication } from 'src/interfaces/application';
import {
  CloudUpload,
  Delete,
  Visibility,
  Save,
  AttachFile
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import axios from 'axios';
import { apiHostname } from 'src/api/api';
import {
  useGetCarerApplicationQuery,
  useUpdateApplicationSectionMutation,
  useDeleteDocumentMutation
} from 'src/redux/@api/carer-application';

interface IdentificationDocumentsProps {
  initialData?: ICarerApplication['identificationDocuments'];
  onSubmit: (data: ICarerApplication['identificationDocuments']) => void;
  selectedSection: string;
  editable?: boolean;
}

type DocumentType = 'passport' | 'drivingLicense' | 'biometricResidencePermit';

const IdentificationDocuments: React.FC<IdentificationDocumentsProps> = ({
  initialData,
  onSubmit,
  selectedSection,
  editable = true
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const { data: carerApplication, refetch } =
    useGetCarerApplicationQuery(undefined);
  const [updateApplicationSection] = useUpdateApplicationSectionMutation();
  const [deleteDocument] = useDeleteDocumentMutation();

  const [selectedFiles, setSelectedFiles] = useState<{
    [key in DocumentType]?: File;
  }>({});
  const [uploadingDocument, setUploadingDocument] = useState<{
    [key in DocumentType]?: boolean;
  }>({});
  const [savingSection, setSavingSection] = useState<{
    [key: string]: boolean;
  }>({});

  const defaultValues = editable
    ? carerApplication?.data[selectedSection] || {
        rightToWorkStatus: '',
        passport: {},
        drivingLicense: {},
        biometricResidencePermit: {}
      }
    : initialData || {
        rightToWorkStatus: '',
        passport: {},
        drivingLicense: {},
        biometricResidencePermit: {}
      };

  const { control, handleSubmit, watch, setValue, reset } = useForm<
    ICarerApplication['identificationDocuments']
  >({
    defaultValues
  });

  useEffect(() => {
    if (editable && carerApplication?.data[selectedSection]) {
      reset(carerApplication.data[selectedSection]);
    } else if (!editable && initialData) {
      reset(initialData);
    }
  }, [carerApplication, selectedSection, reset, editable, initialData]);

  const rightToWorkStatus = watch('rightToWorkStatus');

  const handleFileSelect =
    (documentType: DocumentType) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setSelectedFiles((prev) => ({ ...prev, [documentType]: file }));
      }
    };

  const handleFileUpload = async (documentType: DocumentType) => {
    const file = selectedFiles[documentType];
    if (file) {
      setUploadingDocument((prev) => ({ ...prev, [documentType]: true }));
      const formData = new FormData();
      formData.append('file', file);
      formData.append('section', `identificationDocuments.${documentType}`);
      formData.append('documentType', documentType);

      try {
        const response = await axios.post(
          `${apiHostname}/api/v1/application/upload-document`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        );

        if (response.data.success) {
          setValue(
            `${documentType}.uploadUrl` as any,
            response.data.data.documentUrl
          );
          dispatch(
            showSnack({
              message: 'Document uploaded successfully',
              color: 'success'
            })
          );
        } else {
          throw new Error(response.data.error || 'Failed to upload document');
        }
      } catch (error: any) {
        console.error('Upload failed:', error);
        dispatch(
          showSnack({
            message: error.message || 'Failed to upload document',
            color: 'error'
          })
        );
      } finally {
        setUploadingDocument((prev) => ({ ...prev, [documentType]: false }));
        setSelectedFiles((prev) => {
          const newFiles = { ...prev };
          delete newFiles[documentType];
          return newFiles;
        });
      }
    }
  };

  const handleDeleteDocument = async (documentType: DocumentType) => {
    try {
      await deleteDocument({
        section: `identificationDocuments.${documentType}`
      }).unwrap();
      setValue(`${documentType}.uploadUrl` as any, undefined);
      dispatch(
        showSnack({
          message: 'Document deleted successfully',
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Delete failed:', error);
      dispatch(
        showSnack({ message: 'Failed to delete document', color: 'error' })
      );
    }
  };

  const handleSaveSection = async (section: string, data: any) => {
    setSavingSection((prev) => ({ ...prev, [section]: true }));
    try {
      await updateApplicationSection({
        section: `identificationDocuments.${section}`,
        data
      }).unwrap();
      dispatch(
        showSnack({
          message: `${section} information saved successfully`,
          color: 'success'
        })
      );

      if (selectedFiles[section as DocumentType]) {
        await handleFileUpload(section as DocumentType);
      }

      await refetch();
    } catch (error) {
      console.error('Save failed:', error);
      dispatch(
        showSnack({
          message: `Failed to save ${section} information`,
          color: 'error'
        })
      );
    } finally {
      setSavingSection((prev) => ({ ...prev, [section]: false }));
    }
  };

  const renderDocumentSection = (docType: DocumentType) => {
    const uploadUrl = watch(`${docType}.uploadUrl` as any);
    const isUploading = uploadingDocument[docType];
    const isSaving = savingSection[docType];

    return (
      <React.Fragment key={docType}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}
          >
            {docType.charAt(0).toUpperCase() + docType.slice(1)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" gutterBottom>
            {`${docType} Number`}
          </Typography>
          <Controller
            name={`${docType}.number` as any}
            control={control}
            render={({ field }) =>
              editable ? (
                <TextField
                  {...field}
                  label={`${docType} Number`}
                  fullWidth
                  variant="outlined"
                />
              ) : (
                <Typography variant="body1">{field.value || 'N/A'}</Typography>
              )
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" gutterBottom>
            {`${docType} Expiry Date`}
          </Typography>
          <Controller
            name={`${docType}.expiryDate` as any}
            control={control}
            render={({ field }) =>
              editable ? (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    {...field}
                    label={`${docType} Expiry Date`}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth variant="outlined" />
                    )}
                  />
                </LocalizationProvider>
              ) : (
                <Typography variant="body1">
                  {field.value
                    ? new Date(field.value).toLocaleDateString()
                    : 'N/A'}
                </Typography>
              )
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Box>
            {editable && (
              <>
                <input
                  accept="image/*,.pdf"
                  style={{ display: 'none' }}
                  id={`${docType}-upload`}
                  type="file"
                  onChange={handleFileSelect(docType)}
                />
                <label htmlFor={`${docType}-upload`}>
                  <Button
                    variant="outlined"
                    component="span"
                    startIcon={<CloudUpload />}
                    disabled={isUploading}
                    sx={{ mr: 1 }}
                  >
                    {selectedFiles[docType]
                      ? selectedFiles[docType]?.name
                      : uploadUrl
                      ? 'Replace File'
                      : 'Upload File'}
                  </Button>
                </label>
                {isUploading && <CircularProgress size={20} sx={{ mr: 1 }} />}
              </>
            )}
            {uploadUrl && (
              <>
                <Chip
                  icon={<AttachFile sx={{ fontSize: '17px' }} />}
                  label={`${docType} Document`}
                  variant="outlined"
                  onDelete={
                    editable ? () => handleDeleteDocument(docType) : undefined
                  }
                  sx={{ mr: 1 }}
                />
                <Tooltip title="View uploaded file">
                  <IconButton
                    onClick={() => window.open(uploadUrl, '_blank')}
                    color="primary"
                  >
                    <Visibility />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Box>
        </Grid>
        {editable && (
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={() =>
                  handleSaveSection(docType, watch(docType as any))
                }
                variant="contained"
                color="primary"
                startIcon={isSaving ? <CircularProgress size={20} /> : <Save />}
                disabled={isSaving}
              >
                {isSaving ? 'Saving...' : `Save ${docType}`}
              </Button>
            </Box>
          </Grid>
        )}
      </React.Fragment>
    );
  };

  return (
    <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, mb: 3, borderRadius: 2 }}>
      <Grid container spacing={2}>
        {renderDocumentSection('passport')}
        {renderDocumentSection('drivingLicense')}
        {rightToWorkStatus === 'Visa Required' &&
          renderDocumentSection('biometricResidencePermit')}

        {/* <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            Right to Work Proof URL
          </Typography>
          <Controller
            name="rightToWorkProofUrl"
            control={control}
            render={({ field }) =>
              editable ? (
                <TextField
                  {...field}
                  label="Right to Work Proof URL"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    endAdornment: field.value && (
                      <Tooltip title="View proof document">
                        <IconButton
                          onClick={() => window.open(field.value, '_blank')}
                        >
                          <Visibility />
                        </IconButton>
                      </Tooltip>
                    )
                  }}
                />
              ) : (
                <Box display="flex" alignItems="center">
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    {field.value || 'N/A'}
                  </Typography>
                  {field.value && (
                    <Tooltip title="View proof document">
                      <IconButton
                        onClick={() => window.open(field.value, '_blank')}
                        size="small"
                      >
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              )
            }
          />
        </Grid> */}

        {/* {editable && (
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={() =>
                  handleSaveSection('rightToWorkStatus', {
                    rightToWorkStatus: watch('rightToWorkStatus'),
                    rightToWorkProofUrl: watch('rightToWorkProofUrl')
                  })
                }
                variant="contained"
                color="primary"
                startIcon={
                  savingSection['rightToWorkStatus'] ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Save />
                  )
                }
                disabled={savingSection['rightToWorkStatus']}
                fullWidth={isMobile}
              >
                {savingSection['rightToWorkStatus']
                  ? 'Saving...'
                  : 'Save Right to Work Info'}
              </Button>
            </Box>
          </Grid>
        )} */}
      </Grid>
    </Paper>
  );
};

export default IdentificationDocuments;

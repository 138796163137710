// SidebarMenuContext.tsx
import React, { createContext, useContext, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/redux/hook';
import { MenuItem, calculateMenuItems } from './items';

interface SidebarMenuContextType {
  menuItems: MenuItem[];
  isMinimized: boolean;
  selectedPath: string;
  toggleMinimize: () => void;
  handleItemClick: (item: MenuItem) => void;
}

const SidebarMenuContext = createContext<SidebarMenuContextType | undefined>(
  undefined
);

export const SidebarMenuProvider: React.FC = ({ children }) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { user, currentOrganization } = useAppSelector(
    (state) => state.userState
  );
  const homeSettings = useAppSelector((state) => state.homeSettings);

  const menuItems = React.useMemo(
    () => calculateMenuItems(user, currentOrganization, homeSettings),
    [user, currentOrganization, homeSettings]
  );

  const toggleMinimize = useCallback(() => {
    setIsMinimized((prev) => !prev);
  }, []);

  const handleItemClick = useCallback(
    (item: MenuItem) => {
      if (item.link) {
        navigate(item.link);
      }
    },
    [navigate]
  );

  const value = {
    menuItems,
    isMinimized,
    selectedPath: location.pathname,
    toggleMinimize,
    handleItemClick
  };

  return (
    <SidebarMenuContext.Provider value={value}>
      {children}
    </SidebarMenuContext.Provider>
  );
};

export const useSidebarMenu = () => {
  const context = useContext(SidebarMenuContext);
  if (context === undefined) {
    throw new Error('useSidebarMenu must be used within a SidebarMenuProvider');
  }
  return context;
};

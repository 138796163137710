import React, { useState, useEffect } from 'react';
import {
  List,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputAdornment,
  styled,
  Badge,
  Snackbar
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { useAppSelector } from 'src/redux/hook';
import {
  useGetLinkedOrganizationAdminForCarerQuery,
  useGetLinkedOrgranizationAdminsQuery
} from 'src/redux/@api/organisation';
import {
  useGetAdminStaffsQuery,
  useGetCareStaffsQuery
} from 'src/redux/@api/misc';
import { useMarkAllAsReadMutation } from 'src/redux/@api/chat';

interface SidebarProps {
  onSelectUser: (user: any, type: string) => void;
  selectedUser: any;
}

interface User {
  _id: string;
  user: {
    _id: string;
    firstName: string;
    lastName: string;
    avatar: string;
  };
  lastMessage?: {
    content: string;
  };
  unreadCount?: number;
}

const StyledBox = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  '&:before': {
    display: 'none'
  },
  '&.Mui-expanded': {
    margin: 0
  }
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.colors.success.main,
    color: 'white',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: '50px',
  '&.Mui-expanded': {
    minHeight: '50px'
  }
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  maxHeight: `calc(100vh - ${theme.header.height} - 200px)`,
  overflowY: 'auto'
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  '&.Mui-selected': {
    backgroundColor: theme.palette.action.selected
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  }
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius
  }
}));

const ScrollableList = styled(List)(({ theme }) => ({
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.divider,
    borderRadius: '4px'
  }
}));

const Sidebar: React.FC<SidebarProps> = ({ onSelectUser, selectedUser }) => {
  const userState = useAppSelector((state) => state.userState);
  const [expanded, setExpanded] = useState<string | false>('Organizations');
  const [searches, setSearches] = useState<{ [key: string]: string }>({});
  const [users, setUsers] = useState<{ [key: string]: User[] }>({
    Organizations: [],
    'Care Staffs': [],
    'Admin Staffs': []
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { data: careStaffs } = useGetCareStaffsQuery(undefined);
  const { data: adminStaffs } = useGetAdminStaffsQuery(undefined);
  const [markAllAsRead] = useMarkAllAsReadMutation();
  const messageState = useAppSelector((state) => state.message);

  const { data: linkedOrganizations } = useGetLinkedOrgranizationAdminsQuery(
    undefined,
    {
      skip: ['carer', 'nurse', 'senior_carer'].includes(userState?.user?.role)
    }
  );
  const { data: linkedOrganizationsForCarer } =
    useGetLinkedOrganizationAdminForCarerQuery(undefined, {
      skip: !['carer', 'nurse', 'senior_carer'].includes(userState?.user?.role)
    });

  useEffect(() => {
    if (['carer', 'nurse', 'senior_carer'].includes(userState?.user?.role)) {
      setUsers((prev) => ({
        ...prev,
        Organizations: linkedOrganizationsForCarer || []
      }));
    } else {
      setUsers((prev) => ({
        ...prev,
        Organizations: linkedOrganizations || []
      }));
    }
  }, [linkedOrganizations, linkedOrganizationsForCarer, userState?.user?.role]);

  useEffect(() => {
    setUsers((prev) => ({ ...prev, 'Care Staffs': careStaffs || [] }));
  }, [careStaffs]);

  useEffect(() => {
    setUsers((prev) => ({ ...prev, 'Admin Staffs': adminStaffs || [] }));
  }, [adminStaffs]);

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleSearch = (type: string, value: string) => {
    setSearches((prev) => ({ ...prev, [type]: value }));
  };

  const filterUsers = (users: User[], searchTerm: string) => {
    return users.filter((user) =>
      `${user.user?.firstName} ${user.user?.lastName}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  };

  const handleUserSelect = async (user: User, type: string) => {
    try {
      onSelectUser(user.user, type);
      await markAllAsRead(user.user?._id).unwrap();

      // Clear last message and unread count for the selected user
      setUsers((prevUsers) => ({
        ...prevUsers,
        [type]: prevUsers[type].map((u) =>
          u._id === user._id
            ? { ...u, lastMessage: undefined, unreadCount: 0 }
            : u
        )
      }));
    } catch (error) {
      console.error('Error marking messages as read:', error);
      setErrorMessage('Failed to mark messages as read. Please try again.');
    }
  };

  const renderUserList = (type: string) => {
    const filteredUsers = filterUsers(users[type], searches[type] || '');

    return (
      <StyledAccordion
        expanded={expanded === type}
        onChange={handleAccordionChange(type)}
      >
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1" fontWeight="bold">
            {type}
          </Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <StyledTextField
            size="small"
            placeholder={`Search ${type}...`}
            value={searches[type] || ''}
            onChange={(e) => handleSearch(type, e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <ScrollableList disablePadding>
            {filteredUsers.map((user) => (
              <StyledListItemButton
                key={user._id}
                selected={selectedUser?._id === user.user?._id}
                onClick={() => handleUserSelect(user, type)}
              >
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                  badgeContent={
                    user.unreadCount ||
                    messageState?.newMessagesCount[user.user?._id] ||
                    null
                  }
                >
                  <StyledAvatar
                    src={user.user?.avatar || ''}
                    alt={`${user.user?.firstName} ${user.user?.lastName}`}
                    sx={{
                      mr: 1
                    }}
                  />
                </StyledBadge>

                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      fontWeight={
                        selectedUser?._id === user.user?._id ? 'bold' : 'normal'
                      }
                    >
                      {`${user.user?.firstName} ${user.user?.lastName}`}
                    </Typography>
                  }
                  secondary={
                    <Box>
                      <Typography variant="body2" color="textSecondary" noWrap>
                        {user.lastMessage?.content}
                      </Typography>
                    </Box>
                  }
                  primaryTypographyProps={{
                    variant: 'body1',
                    fontWeight:
                      selectedUser?._id === user._id ? 'bold' : 'normal'
                  }}
                  secondaryTypographyProps={{
                    variant: 'body2',
                    color: 'textSecondary'
                  }}
                />
              </StyledListItemButton>
            ))}
          </ScrollableList>
        </StyledAccordionDetails>
      </StyledAccordion>
    );
  };

  return (
    <StyledBox>
      {renderUserList('Organizations')}
      {renderUserList('Care Staffs')}
      {renderUserList('Admin Staffs')}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(null)}
        message={errorMessage}
      />
    </StyledBox>
  );
};

export default Sidebar;

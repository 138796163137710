import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Grid,
  useMediaQuery,
  useTheme,
  IconButton,
  CircularProgress,
  Avatar,
  Dialog,
  Tooltip,
  alpha
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/redux/store';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { useAppSelector } from 'src/redux/hook';
import {
  useGetAgencyCarerApplicationQuery,
  useGetApplicationStatusQuery,
  useGetCarerApplicationQuery,
  useUpdateApplicationSectionMutation
} from 'src/redux/@api/carer-application';
import PersonalInfo from 'src/content/dashboards/carers/components/personal';
import IdentificationDocuments from 'src/content/dashboards/carers/components/identification';
import ProfessionalInformation from 'src/content/dashboards/carers/components/proffesional';
import Skills from 'src/content/dashboards/carers/components/skills';
import Availability from 'src/content/dashboards/carers/components/availability';
import HealthAndSafety from 'src/content/dashboards/carers/components/health-info';
import BankDetails from 'src/content/dashboards/carers/components/bank';
import AdditionalInfo from 'src/content/dashboards/carers/components/additional';
import Consents from 'src/content/dashboards/carers/components/consents';

interface Category {
  name: string;
  component: React.ComponentType<any>;
  label: string;
}

const allCategories: Category[] = [
  {
    name: 'personalInfo',
    component: PersonalInfo,
    label: 'Personal Information'
  },
  {
    name: 'identificationDocuments',
    component: IdentificationDocuments,
    label: 'Identification Documents'
  },
  {
    name: 'professionalInfo',
    component: ProfessionalInformation,
    label: 'Professional Information'
  },
  { name: 'skills', component: Skills, label: 'Skills' },
  { name: 'availability', component: Availability, label: 'Availability' },
  {
    name: 'healthAndSafety',
    component: HealthAndSafety,
    label: 'Health and Safety'
  },
  { name: 'bankDetails', component: BankDetails, label: 'Bank Details' },
  {
    name: 'additionalInfo',
    component: AdditionalInfo,
    label: 'Additional Information'
  },
  { name: 'consents', component: Consents, label: 'Consents' }
];

const Staffapplication: React.FC<{
  userId: string;
  isInternal?: boolean;
  orgId?: string;
}> = ({ userId, isInternal = false, orgId }) => {
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch<AppDispatch>();
  const userState = useAppSelector((state) => state.userState);

  const [updateApplicationSection] = useUpdateApplicationSectionMutation();
  const {
    data: applicationStatus,
    isLoading: isApplicationStatusLoading,
    refetch: refetchApplicationStatus
  } = useGetApplicationStatusQuery(undefined);
  const {
    data: profile,
    isLoading: isProfileLoading,
    isError: isProfileError,
    refetch: refetchProfile
  } = useGetCarerApplicationQuery(userId, { skip: !isInternal });
  const {
    data: agencyCarerProfile,
    isLoading: agencyCarerIsLoading,
    isError: agencyCarerIsError,
    refetch: refetchAgencyCarerProfile
  } = useGetAgencyCarerApplicationQuery(
    { carerId: userId, agencyOrgId: orgId },
    { skip: isInternal }
  );

  const data = isInternal ? profile?.data : agencyCarerProfile?.data;

  const categories = useMemo(() => {
    if (!data) return [];
    return allCategories.filter(
      (category) => data[category.name] !== undefined
    );
  }, [data]);

  useEffect(() => {
    if (!isMobile && categories.length > 0) {
      setSelectedCategory(0);
    }
  }, [isMobile, categories]);

  if (isInternal ? isProfileLoading : agencyCarerIsLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (isInternal ? isProfileError : agencyCarerIsError) {
    return <Typography>Error loading profile</Typography>;
  }

  if (!data) {
    return <Typography>No profile data available</Typography>;
  }

  const handleCategoryChange = (index: number): void => {
    setSelectedCategory(index);
  };

  const handleCloseDialog = (): void => {
    setSelectedCategory(null);
  };

  const handleSubmit = async (
    data: any,
    index: number,
    fullSection: string
  ): Promise<void> => {
    try {
      if (selectedCategory === null) return;
      const currentCategory = categories[selectedCategory];
      await updateApplicationSection({
        section: fullSection || currentCategory.name,
        data,
        index
      }).unwrap();
      dispatch(
        showSnack({
          message: `${currentCategory.label} saved successfully`,
          color: 'success'
        })
      );
    } catch (error: any) {
      dispatch(
        showSnack({
          message: error.data?.message || 'An error occurred while saving',
          color: 'error'
        })
      );
    }
  };

  let statusColor: 'success' | 'warning' | 'error' | 'info' = 'info';
  if (applicationStatus?.data?.completionPercentage === 100) {
    statusColor = 'success';
  } else if (
    applicationStatus?.data?.completionPercentage > 50 &&
    applicationStatus?.data?.completionPercentage < 100
  ) {
    statusColor = 'warning';
  } else {
    statusColor = 'error';
  }

  const handleRefresh = async () => {
    try {
      setIsRefetching(true);
      await refetchApplicationStatus();
      if (isInternal) {
        await refetchProfile();
      } else {
        await refetchAgencyCarerProfile();
      }
      setIsRefetching(false);
      dispatch(
        showSnack({
          message: 'Data refreshed successfully',
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Refresh failed:', error);
      setIsRefetching(false);
      dispatch(
        showSnack({
          message: 'Failed to refresh data',
          color: 'error'
        })
      );
    }
  };

  const SideMenu: React.FC = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        py: 2
      }}
    >
      <Box sx={{ position: 'relative', width: 120, height: 120, mb: 2 }}>
        <CircularProgress
          variant="determinate"
          value={applicationStatus?.data?.completionPercentage || 0}
          size={120}
          thickness={4}
          color={statusColor}
          sx={{ position: 'absolute', top: 0, left: 0 }}
        />
        <Avatar
          sx={{
            width: 100,
            height: 100,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
      </Box>
      <Typography variant="subtitle1" gutterBottom>
        Application Progress:{' '}
        {Math.round(applicationStatus?.data?.completionPercentage || 0)}%
      </Typography>
      <Tooltip title="Refresh Data">
        <IconButton onClick={handleRefresh} color="primary">
          <RefreshIcon />
        </IconButton>
      </Tooltip>
      <List sx={{ width: '100%' }}>
        {categories.map((category, index) => (
          <ListItem
            button
            key={category.name}
            selected={selectedCategory === index}
            onClick={() => handleCategoryChange(index)}
          >
            <ListItemText
              sx={{
                color: selectedCategory === index ? 'primary.main' : 'gray'
              }}
              primary={category.label}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const renderSelectedComponent = () => {
    if (selectedCategory === null || categories.length === 0) {
      return (
        <Typography>Select a category from the sidebar to begin.</Typography>
      );
    }
    const SelectedComponent = categories[selectedCategory].component;
    return (
      <SelectedComponent
        initialData={data[categories[selectedCategory].name]}
        selectedSection={categories[selectedCategory].name}
        onSubmit={handleSubmit}
        editable={false}
      />
    );
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      {isRefetching && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: alpha(theme.colors.secondary.light, 0.5)
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Grid container spacing={3}>
        {!isMobile && (
          <Grid item md={3}>
            <Paper
              sx={{
                overflowY: 'auto',
                height: `calc(100vh - ${theme.header.height} - 3rem)`
              }}
              elevation={3}
            >
              <SideMenu />
            </Paper>
          </Grid>
        )}
        <Grid item xs={12} md={isMobile ? 12 : 9}>
          {!isMobile && (
            <Box
              sx={{
                height: `calc(100vh - ${theme.header.height} - 40px)`,
                overflowY: 'auto'
              }}
            >
              {renderSelectedComponent()}
            </Box>
          )}
          {isMobile && (
            <Paper
              sx={{
                overflowY: 'auto',
                height: `calc(100vh - ${theme.header.height} - 3rem)`
              }}
              elevation={3}
            >
              <SideMenu />
            </Paper>
          )}
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        open={isMobile && selectedCategory !== null}
        onClose={handleCloseDialog}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: theme.palette.primary.main,
            color: 'white'
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ ml: 2, display: 'inline' }}
          >
            {selectedCategory !== null && categories.length > 0
              ? categories[selectedCategory].label
              : ''}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, p: 3 }}>{renderSelectedComponent()}</Box>
      </Dialog>
    </Box>
  );
};

export default Staffapplication;

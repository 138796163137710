// fieldVisibilityMap.ts

export const fieldVisibilityMap = {
  personalInfo: {
    label: 'Personal Information',
    fields: {
      title: 'Title',
      firstName: 'First Name',
      lastName: 'Last Name',
      middleName: 'Middle Name',
      preferredName: 'Preferred Name',
      dateOfBirth: 'Date of Birth',
      gender: 'Gender',
      nationalInsuranceNumber: 'National Insurance Number',
      address: {
        label: 'Address',
        fields: {
          street: 'Street',
          city: 'City',
          county: 'County',
          country: 'Country',
          postcode: 'Postcode'
        }
      },
      phone: 'Phone Number',
      email: 'Email Address',
      emergencyContact: {
        label: 'Emergency Contact',
        fields: {
          name: 'Name',
          relationship: 'Relationship',
          phone: 'Phone'
        }
      },
      jobTitle: 'Job Title'
    }
  },
  identificationDocuments: {
    label: 'Identification Documents',
    fields: {
      passport: {
        label: 'Passport',
        fields: {
          number: 'Number',
          expiryDate: 'Expiry Date',
          uploadUrl: 'Upload URL'
        }
      },
      drivingLicense: {
        label: 'Driving License',
        fields: {
          number: 'Number',
          expiryDate: 'Expiry Date',
          uploadUrl: 'Upload URL'
        }
      },
      biometricResidencePermit: {
        label: 'Biometric Residence Permit',
        fields: {
          number: 'Number',
          expiryDate: 'Expiry Date',
          uploadUrl: 'Upload URL'
        }
      },
      rightToWorkStatus: 'Right to Work Status',
      rightToWorkProofUrl: 'Right to Work Proof URL'
    }
  },
  professionalInfo: {
    label: 'Professional Information',
    fields: {
      qualifications: {
        label: 'Qualifications',
        fields: {
          name: 'Name',
          level: 'Level',
          institution: 'Institution',
          dateObtained: 'Date Obtained',
          expiryDate: 'Expiry Date',
          uploadUrl: 'Upload URL'
        }
      },
      trainings: {
        label: 'Trainings',
        fields: {
          name: 'Name',
          provider: 'Provider',
          dateCompleted: 'Date Completed',
          expiryDate: 'Expiry Date',
          uploadUrl: 'Upload URL'
        }
      },
      workExperience: {
        label: 'Work Experience',
        fields: {
          jobTitle: 'Job Title',
          employer: 'Employer',
          startDate: 'Start Date',
          endDate: 'End Date',
          responsibilities: 'Responsibilities',
          reasonForLeaving: 'Reason for Leaving',
          uploadUrl: 'Upload URL'
        }
      },
      references: {
        label: 'References',
        fields: {
          name: 'Name',
          position: 'Position',
          company: 'Company',
          relationship: 'Relationship',
          email: 'Email',
          phone: 'Phone',
          uploadUrl: 'Upload URL'
        }
      },
      dbsCheck: {
        label: 'DBS Check',
        fields: {
          certificateNumber: 'Certificate Number',
          issueDate: 'Issue Date',
          status: 'Status',
          uploadUrl: 'Upload URL'
        }
      },
      professionalRegistrations: {
        label: 'Professional Registrations',
        fields: {
          type: 'Type',
          registrationNumber: 'Registration Number',
          expiryDate: 'Expiry Date',
          uploadUrl: 'Upload URL'
        }
      }
    }
  },
  skills: {
    label: 'Skills',
    fields: {
      languages: {
        label: 'Languages',
        fields: {
          language: 'Language',
          proficiency: 'Proficiency'
        }
      },
      careSkills: {
        label: 'Care Skills',
        fields: {
          skill: 'Skill',
          experienceLevel: 'Experience Level'
        }
      },
      specializations: 'Specializations'
    }
  },
  availability: {
    label: 'Availability',
    fields: {
      preferredWorkPattern: 'Preferred Work Pattern',
      availabilityDetails: {
        label: 'Availability Details',
        fields: {
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday',
          sunday: 'Sunday'
        }
      },
      maxHoursPerWeek: 'Maximum Hours per Week',
      minHoursPerWeek: 'Minimum Hours per Week'
    }
  },
  healthAndSafety: {
    label: 'Health and Safety',
    fields: {
      healthDeclaration: {
        label: 'Health Declaration',
        fields: {
          hasHealthConditions: 'Has Health Conditions',
          healthConditionsDetails: 'Health Conditions Details',
          requiresAdjustments: 'Requires Adjustments',
          adjustmentsDetails: 'Adjustments Details',
          vaccinationStatus: {
            label: 'Vaccination Status',
            fields: {
              tetanus: 'Tetanus',
              tetanusDate: 'Tetanus Date',
              covid19: 'COVID-19',
              covid19Date: 'COVID-19 Date',
              fluShot: 'Flu Shot',
              fluShotDate: 'Flu Shot Date'
            }
          }
        }
      },
      manualHandlingTraining: {
        label: 'Manual Handling Training',
        fields: {
          completed: 'Completed',
          completionDate: 'Completion Date'
        }
      },
      foodHygieneCertificate: {
        label: 'Food Hygiene Certificate',
        fields: {
          held: 'Held',
          expiryDate: 'Expiry Date'
        }
      }
    }
  },
  bankDetails: {
    label: 'Bank Details',
    fields: {
      accountHolderName: 'Account Holder Name',
      bankName: 'Bank Name',
      accountNumber: 'Account Number',
      sortCode: 'Sort Code'
    }
  },
  additionalInfo: {
    label: 'Additional Information',
    fields: {
      hasTransport: 'Has Transport',
      willingToTravel: 'Willing to Travel',
      maxTravelDistance: 'Maximum Travel Distance',
      additionalNotes: 'Additional Notes'
    }
  },
  applicationStatus: {
    label: 'Application Status',
    fields: {
      status: 'Status',
      submissionDate: 'Submission Date',
      reviewDate: 'Review Date',
      reviewedBy: 'Reviewed By',
      statusChangeLog: {
        label: 'Status Change Log',
        fields: {
          status: 'Status',
          changedAt: 'Changed At',
          changedBy: 'Changed By',
          reason: 'Reason'
        }
      }
    }
  },
  consents: {
    label: 'Consents',
    fields: {
      dataProcessing: 'Data Processing',
      backgroundCheck: 'Background Check',
      termsAndConditions: 'Terms and Conditions'
    }
  }
};

// src/redux/slices/carerApplicationSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ICarerApplication,
  ICarerApplicationState
} from 'src/interfaces/carer-application';

const initialState = {
  application: {
    userId: '',
    personalInfo: {},
    emergencyContact: {
      name: '',
      relationship: '',
      phone: ''
    },
    identificationInfo: {},
    rightToWork: {
      rightToWorkType: '' as any,
      documentUrl: '',
      fileName: '',
      uploadDate: '',
      expiryDate: '',
      shareCode: ''
    },
    backgroundCheck: {
      backgroundCheckType: '' as any,
      documentUrl: '',
      fileName: '',
      uploadDate: '',
      expiryDate: '',
      dbsNumber: ''
    },

    dbsCheck: {
      certificateNumber: '',
      issueDate: '',
      status: 'clear'
    },
    bankDetails: {
      accountHolderName: '',
      bankName: '',
      accountNumber: '',
      sortCode: ''
    },
    workExperience: [],
    availability: {
      monday: {
        available: false,
        day: false,
        night: false
      },
      tuesday: {
        available: false,
        day: false,
        night: false
      },
      wednesday: {
        available: false,
        day: false,
        night: false
      },
      thursday: {
        available: false,
        day: false,
        night: false
      },
      friday: {
        available: false,
        day: false,
        night: false
      },
      saturday: {
        available: false,
        day: false,
        night: false
      },
      sunday: {
        available: false,
        day: false,
        night: false
      },
      preferredShiftLength: '',
      maxHoursPerWeek: 0,
      additionalNotes: ''
    },
    diversityInfo: {},
    skillsAssessment: {},
    healthInfo: {},
    references: [],
    documents: [],
    drivingLicense: {
      status: false,
      number: '',
      expiryDate: ''
    },
    ownVehicle: false,
    willingToTravel: false,
    professionalMemberships: [],
    additionalInfo: '',
    applicationStatus: 'draft',
    consentToDataProcessing: false
  },
  loading: false,
  error: null
} as any;

const carerApplicationSlice = createSlice({
  name: 'carerApplication',
  initialState,
  reducers: {
    saveApplication: (state, action: PayloadAction<ICarerApplication>) => {
      state.loading = true;
      state.error = null;
      state.application = action.payload;
    },
    setApplication: (state, action: PayloadAction<ICarerApplication>) => {
      state.application = action.payload;
    },
    updatePersonalInfo: (
      state,
      action: PayloadAction<ICarerApplication['personalInfo']>
    ) => {
      if (state.application) {
        state.application.personalInfo = action.payload;
      }
    },
    updateEmergencyContact: (
      state,
      action: PayloadAction<ICarerApplication['emergencyContact']>
    ) => {
      if (state.application) {
        state.application.emergencyContact = action.payload;
      }
    },
    updateIdentificationInfo: (
      state,
      action: PayloadAction<ICarerApplication['identificationInfo']>
    ) => {
      if (state.application) {
        state.application.identificationInfo = action.payload;
      }
    },
    updateRightToWork: (
      state,
      action: PayloadAction<ICarerApplication['rightToWork']>
    ) => {
      if (state.application) {
        state.application.rightToWork = action.payload as any;
      }
    },
    updateDbsCheck: (
      state,
      action: PayloadAction<ICarerApplication['dbsCheck']>
    ) => {
      if (state.application) {
        state.application.dbsCheck = action.payload;
      }
    },
    updateBankDetails: (
      state,
      action: PayloadAction<ICarerApplication['bankDetails']>
    ) => {
      if (state.application) {
        state.application.bankDetails = action.payload;
      }
    },

    addWorkExperience: (
      state,
      action: PayloadAction<ICarerApplication['workExperience'][0]>
    ) => {
      if (state.application) {
        state.application.workExperience.push(action.payload);
      }
    },
    updateAvailability: (
      state,
      action: PayloadAction<ICarerApplication['availability']>
    ) => {
      if (state.application) {
        state.application.availability = action.payload;
      }
    },
    updateSkillsAssessment: (
      state,
      action: PayloadAction<ICarerApplication['skillsAssessment']>
    ) => {
      if (state.application) {
        state.application.skillsAssessment = action.payload;
      }
    },
    addReference: (
      state,
      action: PayloadAction<ICarerApplication['references'][0]>
    ) => {
      if (state.application) {
        state.application.references.push(action.payload);
      }
    },
    addDocument: (
      state,
      action: PayloadAction<ICarerApplication['documents'][0]>
    ) => {
      if (state.application) {
        state.application.documents.push(action.payload);
      }
    },
    updateApplicationStatus: (
      state,
      action: PayloadAction<ICarerApplication['applicationStatus']>
    ) => {
      if (state.application) {
        state.application.applicationStatus = action.payload;
      }
    },
    clearApplication: () => initialState
  },
  extraReducers: (builder) => {
    // builder.addMatcher(
    //   applicationsApi.endpoints.submitApplicationSection.matchFulfilled,
    //   (state, action) => {
    //     if (state.application) {
    //       state.application = {
    //         ...state.application,
    //         ...action.payload.data
    //       };
    //     }
    //   }
    // );
  }
});

export const {
  setApplication,
  updatePersonalInfo,
  updateEmergencyContact,
  updateIdentificationInfo,
  updateRightToWork,
  updateDbsCheck,
  updateBankDetails,
  addWorkExperience,
  updateAvailability,
  updateSkillsAssessment,
  addReference,
  addDocument,
  updateApplicationStatus,
  clearApplication,
  saveApplication
} = carerApplicationSlice.actions;

export default carerApplicationSlice.reducer;

import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Avatar,
  Box,
  Chip,
  IconButton,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  useTheme,
  Checkbox
} from '@mui/material';
import {
  DeleteOutlineOutlined,
  Email,
  Phone,
  Security,
  Add as AddIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { getRoleColor } from './util';
import type { IStaff } from 'src/interfaces/staffs';
import { useGetCareStaffsQuery } from 'src/redux/@api/misc';
import Staffapplication from './staff-application';
import { Icon } from '@iconify/react';
import DeleteConfirmationDialog from 'src/components/core/ui/delete-confirmation';
import { useRemoveStaffMutation } from 'src/redux/@api/staff';
import {
  useGetAllPermissionsQuery,
  useUpdatePermisionsMutation
} from 'src/redux/@api/permissions';
import { CheckIcon } from 'lucide-react';

const CareStaffAccordion: React.FC<{
  staff: IStaff;
  onViewProfile: (staffId: string) => void;
  onDeleteStaff: (staffId: string) => void;
  onViewPermissions: (staffId: string) => void;
}> = ({ staff, onViewProfile, onDeleteStaff, onViewPermissions }) => (
  <Accordion>
    <AccordionSummary
      expandIcon={
        <Icon
          icon="hugeicons:arrow-down-01"
          style={{
            fontSize: '1.5rem'
          }}
        />
      }
      aria-controls={`care-staff-content-${staff._id}`}
      id={`care-staff-header-${staff._id}`}
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>
          {staff.user?.firstName[0]}
        </Avatar>
        <Typography variant="body2">
          {staff.user?.firstName} {staff.user?.lastName}
        </Typography>
        <Chip
          label={staff.role.replace('_', ' ')}
          color={getRoleColor(staff.role)}
          size="small"
          sx={{ ml: 2 }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          marginY: 'auto'
        }}
      >
        <IconButton
          size="small"
          sx={{ display: 'flex', marginBlock: 'auto', height: '100%' }}
          color="error"
          onClick={(e) => {
            e.stopPropagation();
            onDeleteStaff(staff.user._id);
          }}
        >
          <DeleteOutlineOutlined fontSize="medium" />
        </IconButton>
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Email fontSize={'medium'} sx={{ mr: 1 }} />
          <Typography variant="body2">{staff.user?.email}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Phone fontSize={'medium'} sx={{ mr: 1 }} style={{}} />
          <Typography variant="body2">{staff.user?.phone}</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
          <Button
            onClick={() => onViewProfile(staff.user._id)}
            size="small"
            variant="contained"
          >
            <strong>View Profile</strong>
          </Button>
          <Button
            onClick={() => onViewPermissions(staff.user._id)}
            size="small"
            variant="outlined"
            startIcon={<Security />}
          >
            <strong>Manage Permissions</strong>
          </Button>
        </Box>
      </Box>
    </AccordionDetails>
  </Accordion>
);

const CareStaffList: React.FC = () => {
  const [filter, setFilter] = useState<any>('all');
  const [staffs, setCareStaffs] = useState<IStaff[]>([]);
  const [viewProfile, setViewProfile] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [staffToDelete, setStaffToDelete] = useState<IStaff | null>(null);
  const [permissionsDialogOpen, setPermissionsDialogOpen] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
  const [newPermission, setNewPermission] = useState<string>('');

  const {
    data: careStaffs,
    isLoading,
    isError,
    error,
    refetch
  } = useGetCareStaffsQuery(undefined);

  const [removeStaff] = useRemoveStaffMutation();
  const [updateStaffPermissions] = useUpdatePermisionsMutation();

  const { data: allPermissions, isLoading: permissionsLoading } =
    useGetAllPermissionsQuery(undefined);

  useEffect(() => {
    if (careStaffs) {
      setCareStaffs(careStaffs);
    }
  }, [careStaffs]);

  const filteredStaff =
    filter === 'all' ? staffs : staffs.filter((staff) => staff.role === filter);

  const roles = ['all', ...new Set(staffs.map((staff) => staff.role))];
  const theme = useTheme();

  const handleDeleteStaff = (staffId: string) => {
    const staff = staffs.find((s) => s._id === staffId);
    if (staff) {
      setStaffToDelete(staff);
      setDeleteDialogOpen(true);
    }
  };

  const confirmDeleteStaff = async () => {
    if (staffToDelete) {
      await removeStaff(staffToDelete._id);
      await refetch();
    }
    setDeleteDialogOpen(false);
    setStaffToDelete(null);
  };

  const handleViewPermissions = (staffId: string) => {
    const staff = staffs.find((s) => s.user._id === staffId);
    if (staff) {
      setSelectedStaff(staffId);
      setSelectedPermissions(staff.permissions || []);
      setPermissionsDialogOpen(true);
    }
  };

  const handlePermissionChange = (permission: string) => {
    setSelectedPermissions((prev) =>
      prev.includes(permission)
        ? prev.filter((p) => p !== permission)
        : [...prev, permission]
    );
  };

  const handleAddNewPermission = () => {
    if (newPermission && !selectedPermissions.includes(newPermission)) {
      setSelectedPermissions([...selectedPermissions, newPermission]);
      setNewPermission('');
    }
  };

  const handleSavePermissions = async () => {
    if (selectedStaff) {
      const response = await updateStaffPermissions({
        userId: selectedStaff,
        permissions: selectedPermissions
      });
      await refetch();
      setPermissionsDialogOpen(false);
    }
  };

  const getPermissionKeyword = (permission: string) => {
    const foundPermission = allPermissions?.permissions.find(
      (p) => p.permission === permission
    );
    return foundPermission ? foundPermission.keyword : permission;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ width: 220 + '!important' }}>
        <FormControl size="small">
          <InputLabel>Filter by Role</InputLabel>
          <Select
            size="small"
            value={filter}
            label="Filter by Role"
            onChange={(e) => setFilter(e.target.value)}
          >
            {roles.map((role) => (
              <MenuItem key={role} value={role}>
                {role === 'all' ? 'All Roles' : role.replace('_', ' ')}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Grid container spacing={2}>
        {filteredStaff.map((staff) => (
          <Grid item xs={12} md={4} key={staff._id}>
            <CareStaffAccordion
              staff={staff}
              onViewProfile={(id) => {
                setSelectedStaff(id);
                setViewProfile(true);
              }}
              onDeleteStaff={handleDeleteStaff}
              onViewPermissions={handleViewPermissions}
            />
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={viewProfile && selectedStaff !== null}
        onClose={() => setViewProfile(false)}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            padding: '0px !important',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
              width: '95vw',
              maxHeight: '90vh',
              minHeight: '90vh'
            }
          }
        }}
      >
        <DialogContent sx={{ padding: '0px !important' }}>
          <Staffapplication userId={selectedStaff} isInternal={true} />
        </DialogContent>
      </Dialog>

      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={confirmDeleteStaff}
        itemName={`${staffToDelete?.user?.firstName} ${staffToDelete?.user?.lastName}`}
      />

      <Dialog
        open={permissionsDialogOpen}
        onClose={() => setPermissionsDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Staff Permissions</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', height: '60vh' }}
        >
          <Box sx={{ mb: 2 }}>
            <FormControl fullWidth size="small">
              <InputLabel>Add Permission</InputLabel>
              <Select
                value={newPermission}
                onChange={(e) => setNewPermission(e.target.value as string)}
                label="Add Permission"
              >
                {allPermissions?.permissions
                  .filter((p) => !selectedPermissions.includes(p.permission))
                  .map((p) => (
                    <MenuItem key={p.permission} value={p.permission}>
                      {p.keyword}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Button
              startIcon={<AddIcon />}
              onClick={handleAddNewPermission}
              disabled={!newPermission}
              sx={{ mt: 1 }}
            >
              Add Permission
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {selectedPermissions.map((permission) => (
                <Chip
                  key={permission}
                  label={getPermissionKeyword(permission)}
                  onDelete={() => handlePermissionChange(permission)}
                  deleteIcon={
                    <CloseIcon
                      fontSize="small"
                      onClick={() => handlePermissionChange(permission)}
                    />
                  }
                  sx={{
                    bgcolor: 'transparent',
                    border: '1px solid',
                    borderColor: 'primary.main',
                    '& .MuiChip-deleteIcon': {
                      color: 'primary.main'
                    },
                    '& .MuiChip-label': {
                      color: 'text.primary'
                    }
                  }}
                />
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPermissionsDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleSavePermissions}
            variant="contained"
            color="primary"
          >
            Save Permissions
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CareStaffList;

import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Grid,
  Typography,
  Box,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  Chip,
  Autocomplete
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AppDispatch } from 'src/redux/store';
import {
  useCreateGroupMutation,
  useGetGroupsQuery
} from 'src/redux/@api/groups';
import {
  useAddResidentMutation,
  useUpdateResidentMutation
} from 'src/redux/@api/resident';
import { openPaymentDialog } from 'src/redux/reducers/dialogs/payment';

interface IEmergencyContact {
  name: string;
  relationship: string;
  phone: string;
}

interface IResident {
  _id?: string;
  homeId: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Date | null;
  roomNumber: string;
  admissionDate: Date | null;
  medicalConditions: string[];
  allergies: string[];
  groupId?: string;
  emergencyContact: IEmergencyContact;
}

interface AddResidentDialogProps {
  open: boolean;
  onClose: () => void;
  homeId: string;
  existingResident?: IResident;
}

interface IGroup {
  _id: string;
  name: string;
}

const AddResidentDialog: React.FC<AddResidentDialogProps> = ({
  open,
  onClose,
  homeId,
  existingResident
}) => {
  const { control, handleSubmit, reset, setValue } = useForm<IResident>();
  const [isGroupDialogOpen, setIsGroupDialogOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [newGroupDescription, setNewGroupDescription] = useState('');

  const dispatch = useDispatch<AppDispatch>();
  const { data: groups, isLoading: isLoadingGroups } =
    useGetGroupsQuery(homeId);
  const [createGroup] = useCreateGroupMutation();
  const [createResident, { isLoading: isCreatingResident }] =
    useAddResidentMutation();
  const [updateResident] = useUpdateResidentMutation();

  useEffect(() => {
    if (existingResident) {
      reset({
        ...existingResident,
        dateOfBirth: existingResident.dateOfBirth
          ? new Date(existingResident.dateOfBirth)
          : null,
        admissionDate: existingResident.admissionDate
          ? new Date(existingResident.admissionDate)
          : null
      });
    } else {
      reset({
        homeId,
        firstName: '',
        lastName: '',
        dateOfBirth: null,
        roomNumber: '',
        admissionDate: null,
        medicalConditions: [],
        allergies: [],
        emergencyContact: {
          name: '',
          relationship: '',
          phone: ''
        }
      });
    }
  }, [existingResident, reset, homeId]);

  const handleAddGroup = async () => {
    try {
      const result = await createGroup({
        homeId,
        name: newGroupName,
        description: newGroupDescription
      }).unwrap();
      setNewGroupName('');
      setNewGroupDescription('');
      dispatch(
        showSnack({ message: 'Group created successfully', color: 'success' })
      );
      return result;
    } catch (error) {
      dispatch(showSnack({ message: 'Error creating group', color: 'error' }));
      throw error;
    }
  };

  const onSubmit = async (data: IResident) => {
    try {
      if (existingResident) {
        await updateResident({ ...data, _id: existingResident._id }).unwrap();
      } else {
        await createResident(data).unwrap();
      }
      reset();
      onClose();
      dispatch(
        showSnack({
          message: `Resident ${
            existingResident ? 'updated' : 'created'
          } successfully`,
          color: 'success'
        })
      );
    } catch (error) {
      if (error.status === 402) {
        dispatch(
          openPaymentDialog(
            'You have reached the maximum number of residents for your plan. Please upgrade your plan to add more residents or call support for assistance.'
          )
        );
      }
      dispatch(
        showSnack({
          message:
            error.data.message ||
            `Error ${existingResident ? 'updating' : 'creating'} resident`,
          color: 'error'
        })
      );
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>
          {existingResident ? 'Edit Resident' : 'Add New Resident'}
        </DialogTitle>
        <DialogContent>
          {isLoadingGroups ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{ required: 'First name is required' }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        label="First Name"
                        fullWidth
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{ required: 'Last name is required' }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        label="Last Name"
                        fullWidth
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="dateOfBirth"
                    control={control}
                    rules={{ required: 'Date of birth is required' }}
                    render={({ field, fieldState: { error } }) => (
                      <DatePicker
                        label="Date of Birth"
                        value={field.value}
                        onChange={(date: Date | null) => field.onChange(date)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="roomNumber"
                    control={control}
                    rules={{ required: 'Room number is required' }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        label="Room Number"
                        fullWidth
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="admissionDate"
                    control={control}
                    rules={{ required: 'Admission date is required' }}
                    render={({ field, fieldState: { error } }) => (
                      <DatePicker
                        label="Admission Date"
                        value={field.value}
                        onChange={(date: Date | null) => field.onChange(date)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="medicalConditions"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        multiple
                        freeSolo
                        options={[]}
                        renderTags={(value: any[], getTagProps) =>
                          value.map((option: any, index: number) => (
                            <Chip
                              key={index}
                              variant="outlined"
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Medical Conditions"
                            placeholder="Add condition"
                          />
                        )}
                        onChange={(_, data) => field.onChange(data)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="allergies"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        multiple
                        freeSolo
                        options={[]}
                        renderTags={(value: any[], getTagProps) =>
                          value.map((option: string, index: number) => (
                            <Chip
                              key={index}
                              variant="outlined"
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Allergies"
                            placeholder="Add allergy"
                          />
                        )}
                        onChange={(_, data) => field.onChange(data)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Emergency Contact
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="emergencyContact.name"
                    control={control}
                    rules={{ required: 'Emergency contact name is required' }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        label="Name"
                        fullWidth
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="emergencyContact.relationship"
                    control={control}
                    rules={{ required: 'Relationship is required' }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        label="Relationship"
                        fullWidth
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="emergencyContact.phone"
                    control={control}
                    rules={{ required: 'Phone number is required' }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        label="Phone"
                        fullWidth
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <FormControl fullWidth>
                    <InputLabel id="group-select-label">Group</InputLabel>
                    <Controller
                      name="groupId"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="group-select-label"
                          label="Group"
                          displayEmpty
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {groups?.data?.map((group: IGroup) => (
                            <MenuItem key={group._id} value={group._id}>
                              {group.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="outlined"
                    onClick={() => setIsGroupDialogOpen(true)}
                    fullWidth
                    startIcon={<AddIcon />}
                  >
                    New Group
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSubmit(onSubmit)}
            loading={isCreatingResident}
            variant="contained"
            color="primary"
          >
            {existingResident ? 'Update Resident' : 'Add Resident'}
          </LoadingButton>
        </DialogActions>

        {/* Add New Group Dialog */}
        <Dialog
          open={isGroupDialogOpen}
          onClose={() => setIsGroupDialogOpen(false)}
        >
          <DialogTitle>Add New Group</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Group Name"
              type="text"
              fullWidth
              value={newGroupName}
              onChange={(e) => setNewGroupName(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Group Description"
              type="text"
              fullWidth
              multiline
              rows={3}
              value={newGroupDescription}
              onChange={(e) => setNewGroupDescription(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsGroupDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <LoadingButton
              onClick={async () => {
                try {
                  const newGroup = await handleAddGroup();
                  setValue('groupId', newGroup._id);
                  setIsGroupDialogOpen(false);
                } catch (error) {
                  console.error('Error creating group:', error);
                }
              }}
              color="primary"
              variant="contained"
            >
              Add Group
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Dialog>
    </LocalizationProvider>
  );
};

export default AddResidentDialog;

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  Box,
  TextField,
  useTheme,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery
} from '@mui/material';
import { Check } from 'lucide-react';
import { color } from 'framer-motion';

interface PadCheckDialogProps {
  open: boolean;
  onClose: () => void;
  onResolve: (data: {
    stoolType: string;
    urineType: string;
    careMethod: string;
    slidingSheetUsed: boolean;
    clothingChanged: boolean;
    clothingType: string;
    clothingCondition: string;
    additionalNotes: string;
  }) => void;
}

const stoolTypes = [
  { type: '1', image: require('src/assets/images/stool/type1.png') },
  { type: '2', image: require('src/assets/images/stool/type2.png') },
  { type: '3', image: require('src/assets/images/stool/type3.png') },
  { type: '4', image: require('src/assets/images/stool/type4.png') },
  { type: '5', image: require('src/assets/images/stool/type5.png') },
  { type: '6', image: require('src/assets/images/stool/type6.png') },
  { type: '7', image: require('src/assets/images/stool/type7.png') }
];

const urineTypes = ['Dry', 'Damp', 'Wet'];
const careMethods = ['Hoist', 'One Person by Hand', 'Two Person by Hand'];
const clothingTypes = ['Day Clothes', 'Night Clothes'];
const clothingConditions = ['Dry', 'Damp', 'Soiled', 'Wet'];

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius * 2,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(4)})`,
      maxWidth: 'none'
    }
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(2),
    fontSize: '1.25rem',
    fontWeight: 'bold'
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    }
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2)
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  color: theme.palette.text.primary
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  },
  transition: theme.transitions.create(['background-color', 'color'], {
    duration: theme.transitions.duration.short
  })
}));

const ImageToggleButton = styled(ToggleButton)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.light,
    color: 'white !important',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  },
  transition: theme.transitions.create(['background-color'], {
    duration: theme.transitions.duration.short
  })
}));

const PadCheckDialog: React.FC<PadCheckDialogProps> = ({
  open,
  onClose,
  onResolve
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedStoolType, setSelectedStoolType] = useState('');
  const [selectedUrineType, setSelectedUrineType] = useState('');
  const [selectedCareMethod, setSelectedCareMethod] = useState('');
  const [slidingSheetUsed, setSlidingSheetUsed] = useState(false);
  const [clothingChanged, setClothingChanged] = useState(false);
  const [selectedClothingType, setSelectedClothingType] = useState('');
  const [selectedClothingCondition, setSelectedClothingCondition] =
    useState('');
  const [additionalNotes, setAdditionalNotes] = useState('');

  const handleResolve = () => {
    if (
      selectedStoolType &&
      selectedUrineType &&
      selectedCareMethod &&
      (clothingChanged
        ? selectedClothingType && selectedClothingCondition
        : true)
    ) {
      onResolve({
        stoolType: selectedStoolType,
        urineType: selectedUrineType,
        careMethod: selectedCareMethod,
        slidingSheetUsed,
        clothingChanged,
        clothingType: selectedClothingType,
        clothingCondition: selectedClothingCondition,
        additionalNotes
      });
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Resident Pad Check Assessment</DialogTitle>
      <DialogContent>
        <SectionTitle variant="h6">Stool Consistency</SectionTitle>
        <ToggleButtonGroup
          value={selectedStoolType}
          exclusive
          onChange={(_, value) => setSelectedStoolType(value)}
          aria-label="stool type"
          orientation={isMobile ? 'vertical' : 'horizontal'}
          fullWidth={isMobile}
        >
          {stoolTypes.map((stool) => (
            <ImageToggleButton
              key={stool.type}
              value={stool.type}
              aria-label={`Type ${stool.type}`}
            >
              <img
                src={stool.image}
                alt={`Type ${stool.type}`}
                style={{
                  width: isMobile ? '40px' : '50px',
                  height: 'auto',
                  marginBottom: theme.spacing(1)
                }}
              />
              <Typography
                sx={{
                  fontSize: '0.75rem',
                  fontWeight: 'bold'
                }}
              >
                Type {stool.type}
              </Typography>
            </ImageToggleButton>
          ))}
        </ToggleButtonGroup>

        <SectionTitle variant="h6">Urine Saturation</SectionTitle>
        <ToggleButtonGroup
          value={selectedUrineType}
          exclusive
          onChange={(_, value) => setSelectedUrineType(value)}
          aria-label="urine type"
          orientation={isMobile ? 'vertical' : 'horizontal'}
          fullWidth={isMobile}
        >
          {urineTypes.map((urine) => (
            <StyledToggleButton key={urine} value={urine}>
              {urine}
            </StyledToggleButton>
          ))}
        </ToggleButtonGroup>

        <SectionTitle variant="h6">Care Method</SectionTitle>
        <ToggleButtonGroup
          value={selectedCareMethod}
          exclusive
          onChange={(_, value) => setSelectedCareMethod(value)}
          aria-label="care method"
          orientation={isMobile ? 'vertical' : 'horizontal'}
          fullWidth={isMobile}
        >
          {careMethods.map((method) => (
            <StyledToggleButton key={method} value={method}>
              {method}
            </StyledToggleButton>
          ))}
        </ToggleButtonGroup>

        <SectionTitle variant="h6">Additional Equipment</SectionTitle>
        <StyledToggleButton
          value="check"
          selected={slidingSheetUsed}
          onChange={() => setSlidingSheetUsed(!slidingSheetUsed)}
          fullWidth={isMobile}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {slidingSheetUsed && (
              <Check size={16} style={{ marginRight: theme.spacing(1) }} />
            )}
            Sliding Sheet Used
          </Box>
        </StyledToggleButton>

        <SectionTitle variant="h6">Clothing</SectionTitle>
        <StyledToggleButton
          value="check"
          selected={clothingChanged}
          onChange={() => setClothingChanged(!clothingChanged)}
          sx={{ marginBottom: theme.spacing(2) }}
          fullWidth={isMobile}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {clothingChanged && (
              <Check size={16} style={{ marginRight: theme.spacing(1) }} />
            )}
            Clothing Changed
          </Box>
        </StyledToggleButton>

        {clothingChanged && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" gutterBottom>
                Clothing Type
              </Typography>
              <ToggleButtonGroup
                value={selectedClothingType}
                exclusive
                onChange={(_, value) => setSelectedClothingType(value)}
                aria-label="clothing type"
                orientation={isMobile ? 'vertical' : 'horizontal'}
                fullWidth={isMobile}
              >
                {clothingTypes.map((type) => (
                  <StyledToggleButton key={type} value={type}>
                    {type}
                  </StyledToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" gutterBottom>
                Clothing Condition
              </Typography>
              <ToggleButtonGroup
                value={selectedClothingCondition}
                exclusive
                onChange={(_, value) => setSelectedClothingCondition(value)}
                aria-label="clothing condition"
                orientation={isMobile ? 'vertical' : 'horizontal'}
                fullWidth={isMobile}
              >
                {clothingConditions.map((condition) => (
                  <StyledToggleButton key={condition} value={condition}>
                    {condition}
                  </StyledToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        )}

        <SectionTitle variant="h6">Additional Notes</SectionTitle>
        <TextField
          label="Notes"
          multiline
          rows={4}
          fullWidth
          value={additionalNotes}
          onChange={(e) => setAdditionalNotes(e.target.value)}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleResolve}
          color="primary"
          variant="contained"
          disabled={
            !selectedStoolType ||
            !selectedUrineType ||
            !selectedCareMethod ||
            (clothingChanged
              ? !selectedClothingType || !selectedClothingCondition
              : false)
          }
        >
          Submit Assessment
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default PadCheckDialog;

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Rating,
  TextField,
  Button
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

const RateAndReviewDialog = ({ open, onClose, timesheet, onSubmit }) => {
  const [ratingValue, setRatingValue] = useState(null);
  const [reviewText, setReviewText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await onSubmit(ratingValue, reviewText);
    setIsSubmitting(false);
    setRatingValue(null);
    setReviewText('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Rate and Review Carer's Shift</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
          <Typography variant="body1" gutterBottom>
            Carer: {timesheet?.carer.firstName} {timesheet?.carer.lastName}
          </Typography>
          <Rating
            name="shift-rating"
            value={ratingValue}
            onChange={(_, newValue) => setRatingValue(newValue)}
            precision={1}
            size="large"
          />
          <TextField
            label="Review (optional)"
            multiline
            rows={3}
            value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          loading={isSubmitting}
        >
          Approve with Review
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RateAndReviewDialog;

// organizationSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { IOrganization } from 'src/interfaces/heirarchy';

interface IOrganizationState {
  organizations: IOrganization[];
  currentOrganization: IOrganization | null;
  loading: boolean;
  error: string | null;
}

const initialState: IOrganizationState = {
  organizations: [],
  currentOrganization: null,
  loading: false,
  error: null
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganizations: (state, action: PayloadAction<IOrganization[]>) => {
      state.organizations = action.payload;
    },
    addOrganization: (state, action: PayloadAction<IOrganization>) => {
      state.organizations.push(action.payload);
    },
    updateOrganization: (state, action: PayloadAction<IOrganization>) => {
      const index = state.organizations.findIndex(
        (org) => org._id === action.payload._id
      );
      if (index !== -1) {
        state.organizations[index] = action.payload;
      }
    },
    setCurrentOrganization: (state, action: PayloadAction<IOrganization>) => {
      state.currentOrganization = action.payload;
    },
    clearOrganizations: (state) => {
      state.organizations = [];
      state.currentOrganization = null;
      state.error = null;
    },
    setOrganizationError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    }
  }
});

export const {
  setOrganizations,
  addOrganization,
  updateOrganization,
  setCurrentOrganization,
  clearOrganizations,
  setOrganizationError
} = organizationSlice.actions;
export default organizationSlice.reducer;

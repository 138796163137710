import React from 'react';
import {
  Grid,
  Paper,
  Box,
  Typography,
  Chip,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Icon } from '@iconify/react';
import dayjs from 'dayjs';
import { taskTypes } from './task-util';

interface TaskGridProps {
  tasks: any[];
  isHistorical?: boolean;
  onTaskClick: (task: any) => void;
}

const TaskGrid: React.FC<TaskGridProps> = ({
  tasks,
  isHistorical = false,
  onTaskClick
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'upcoming':
        return theme.palette.info.main;
      case 'pending':
        return theme.palette.warning.main;
      case 'completed':
        return theme.palette.success.main;
      case 'overdue':
        return theme.palette.error.main;
      default:
        return theme.palette.grey[500];
    }
  };

  const getTaskIcon = (type: string) => {
    const taskType = taskTypes.find((t) => t.type === type);
    return taskType ? taskType.icon : <Icon icon="mdi:account" />;
  };

  return (
    <Grid container spacing={2}>
      {tasks?.map((task) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={task._id}>
          <Paper
            elevation={2}
            sx={{
              p: 1,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              position: 'relative',
              overflow: 'hidden',
              cursor: isHistorical ? 'default' : 'pointer',
              '&:hover': {
                transform: isHistorical ? 'none' : 'scale(1.03)',
                transition: 'transform 0.2s ease-in-out'
              }
            }}
            onClick={() => !isHistorical && onTaskClick(task)}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
              {getTaskIcon(task.taskType)}
              <Typography
                variant="body2"
                sx={{ ml: 0.5, fontSize: isMobile ? '0.7rem' : '0.875rem' }}
              >
                {task.taskName}
              </Typography>
            </Box>
            <Box sx={{ my: 0.5, display: { xs: 'none', sm: 'block' } }}>
              <Typography variant="body2" color="textSecondary">
                {isHistorical ? task.notes : task.description}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mt: 'auto',
                [theme.breakpoints.down('sm')]: { display: 'block' }
              }}
            >
              <Chip
                size="small"
                label={task.status}
                sx={{
                  backgroundColor: getStatusColor(task.status),
                  color: 'white',
                  fontSize: '0.6rem',
                  height: 20
                }}
              />
              <Chip
                icon={<Icon icon="ph:clock-duotone" />}
                label={dayjs(
                  isHistorical ? task.completedDate : task.dueDate
                ).format('DD/MM/YYYY HH:mm')}
                size="small"
                sx={{ fontSize: '0.6rem', marginLeft: 'auto' }}
              />
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default TaskGrid;

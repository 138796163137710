// joinRequests.ts
import { baseApi } from './base';

export const joinRequestsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    sendJoinRequest: builder.mutation({
      query: (data) => ({
        url: 'join-requests',
        method: 'POST',
        body: data
      })
    }),
    getJoinRequests: builder.query({
      query: () => `join-requests`
    }),
  })
});

export const {
  useSendJoinRequestMutation,
  useGetJoinRequestsQuery,
} = joinRequestsApi;
import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Rating,
  Card,
  CardContent,
  CardActions,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  InputAdornment,
  styled,
  Badge
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { IUser } from 'src/interfaces/user.interface';
import {
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle
} from 'src/components/core/ui/dialog-styles';
import SubscriptionDialog from 'src/components/core/dialogs/payment-reminder';
import CarerDocumentsDialog from 'src/components/core/dialogs/carer-docs';
import ConfirmationDialog from 'src/components/core/dialogs/confirmation';
import SearchIcon from '@mui/icons-material/Search';
import HolidayCalendarForHome from 'src/components/holiday/home-holiday-calendar';
import { useUnLinkUserMutation } from 'src/redux/@api/user';
import { useSendHomeStaffInvitationMutation } from 'src/redux/@api/invitations';
import { useLazyGetLinkedUsersQuery } from 'src/redux/@api/auth';
import { useLazyGetCarerDocumentsQuery } from 'src/redux/@api/doc';
import { useApproveAnnualLeaveMutation, useGetAnnualLeaveRequestsQuery, useLazyGetAnnualLeaveRequestsQuery } from 'src/redux/@api/leave';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  marginRight: theme.spacing(2),
  cursor: 'pointer',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[4]
  },
  [theme.breakpoints.down('sm')]: {
    width: 60,
    height: 60,
    marginRight: theme.spacing(1)
  }
}));

const accountTypes = ['carer', 'senior carer', 'nurse', 'guest'];

const InviteDialog = ({ open, onClose, onSendInvite }) => {
  const [email, setEmail] = useState('');
  const [selectedStaffType, setSelectedStaffType] = useState('');
  const dispatch = useDispatch();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleStaffTypeChange = (event) => {
    setSelectedStaffType(event.target.value);
  };

  const handleSendInvite = async () => {
    try {
      await onSendInvite(email, selectedStaffType.toLowerCase());
      setEmail('');
      setSelectedStaffType('');
      onClose();
    } catch (error) {
      if (error.status === 402) {
        dispatch(
          showSnack({
            message: 'You have reached the maximum number of staffs',
            color: 'error'
          })
        );
      }
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledDialogTitle>Invite Staff</StyledDialogTitle>
      <StyledDialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          paddingTop: '1rem !important'
        }}
      >
        <TextField
          autoFocus
          margin="dense"
          label="Email Address"
          type="email"
          fullWidth
          value={email}
          onChange={handleEmailChange}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Staff Type</InputLabel>
          <Select
            value={selectedStaffType}
            onChange={handleStaffTypeChange}
            label="Staff Type"
          >
            {accountTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type?.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </StyledDialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSendInvite}
          disabled={!email || !selectedStaffType}
        >
          Send Invite
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

const StaffAccordion = ({ staff, onMenuOpen, onFetchDocs }) => {
  return (
    <Accordion sx={{ mb: 1, boxShadow: 1 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${staff._id}-content`}
        id={`panel-${staff._id}-header`}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Avatar src={staff?.avatar?.url} alt={staff.fname} sx={{ mr: 2 }} />
          <Typography>
            {staff.fname} {staff.lname}
          </Typography>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onMenuOpen(e, staff);
            }}
            sx={{ ml: 'auto' }}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          Email: {staff.email}
        </Typography>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          Job Position: {staff.accountType}
        </Typography>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          Status: {staff.accountStatus}
        </Typography>
        <Button
          size="small"
          variant="contained"
          onClick={() => onFetchDocs(staff._id)}
          sx={{ mt: 1 }}
        >
          View Docs
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};

const StaffsComponent = () => {
  const [staffs, setStaffs] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [filter, setFilter] = useState('All');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openInviteDialog, setOpenInviteDialog] = useState(false);
  const [unlinkUser] = useUnLinkUserMutation();
  const userState = useAppSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [docDialogOpen, setDocDialogOpen] = useState(false);
  const [openSubscriptionReminder, setOpenSubscriptionReminder] =
    useState(false);
  const [sendInvite] = useSendHomeStaffInvitationMutation();
  const [getLinkedUsers] = useLazyGetLinkedUsersQuery();
  const [getCarerDocs] = useLazyGetCarerDocumentsQuery();
  const [documents, setDocuments] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');

  const [openHolidayCalendar, setOpenHolidayCalendar] = useState(false);
  const [selectedStaffForHoliday, setSelectedStaffForHoliday] = useState(null);
  const { data: leaveRequests, refetch: refetchLeaveRequests } =
    useGetAnnualLeaveRequestsQuery(undefined);
  const [getLeaveRequests, { data: leaveRequestsData }] =
    useLazyGetAnnualLeaveRequestsQuery();
  const [approveLeave] = useApproveAnnualLeaveMutation();
  // const [rejectLeave] = useRejectAnnualLeaveMutation();

  const handleOpenHolidayCalendar = (staff) => {
    setSelectedStaffForHoliday(staff);
    setOpenHolidayCalendar(true);
  };

  const handleCloseHolidayCalendar = () => {
    setOpenHolidayCalendar(false);
    setSelectedStaffForHoliday(null);
  };

  const handleApproveLeave = async (leaveId) => {
    try {
      await approveLeave(leaveId).unwrap();
      dispatch(
        showSnack({ message: 'Leave approved successfully', color: 'success' })
      );
      refetchLeaveRequests();
    } catch (error) {
      dispatch(showSnack({ message: 'Error approving leave', color: 'error' }));
    }
  };

  const handleRejectLeave = async (leaveId) => {
    try {
      // await rejectLeave(leaveId).unwrap();
      // dispatch(showSnack({ message: 'Leave rejected successfully', color: 'success' }));
      // refetchLeaveRequests();
    } catch (error) {
      dispatch(showSnack({ message: 'Error rejecting leave', color: 'error' }));
    }
  };

  async function fetchStaffs() {
    try {
      const response = await getLinkedUsers('staffs').unwrap();
      setStaffs(response.data);
    } catch (error) {
      console.error('Error fetching staffs:', error);
    }
  }

  useEffect(() => {
    fetchStaffs();
  }, []);

  const handleOpenInviteDialog = () => {
    setOpenInviteDialog(true);
  };

  const handleCloseInviteDialog = () => {
    setOpenInviteDialog(false);
  };

  const handleSendInvite = async (email, staffType) => {
    try {
      const response = await sendInvite({
        accountType: staffType,
        companyName: userState.company.name,
        receiverEmail: email
      }).unwrap();
      dispatch(
        showSnack({ message: 'Invite sent successfully', color: 'success' })
      );
    } catch (error) {
      if (error.status === 402) {
        setOpenSubscriptionReminder(true);
      } else {
        dispatch(
          showSnack({ message: 'Error sending invite', color: 'error' })
        );
      }
    }
  };

  const handleMenuOpen = (event, staff) => {
    setAnchorEl(event.currentTarget);
    setSelectedStaff(staff);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedStaff(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function handleFetchCarerDocs(carerId) {
    try {
      const response = await getCarerDocs(carerId).unwrap();
      setDocuments(response.data);
      setDocDialogOpen(true);
    } catch (error) {
      console.error('Error fetching carer documents:', error);
    }
  }

  const handleActionClick = async (action) => {
    if (action === 'delete') {
      const response = await unlinkUser({
        userId: selectedStaff?._id,
        userType: 'carer',
        rating: rating,
        review: feedback
      }).unwrap();
      setRating(0);
      setFeedback('');

      dispatch(showSnack({ message: response.message, color: 'success' }));
    }
    handleMenuClose();
  };

  const [searchTerm, setSearchTerm] = useState('');

  const filteredStaffs = useMemo(() => {
    return staffs.filter((staff) => {
      const matchesFilter = filter === 'All' || staff.accountStatus === filter;
      const searchTermLower = searchTerm.toLowerCase().trim();
      const matchesSearch =
        searchTermLower === '' ||
        `${staff.fname} ${staff.lname}`
          .toLowerCase()
          .includes(searchTermLower) ||
        staff.email.toLowerCase().includes(searchTermLower) ||
        staff.accountType.toLowerCase().includes(searchTermLower);

      return matchesFilter && matchesSearch;
    });
  }, [staffs, filter, searchTerm]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when search term changes
  };

  const currentPageData = filteredStaffs?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box sx={{ margin: 'auto', padding: 3 }}>
      <Box
        sx={{
          mb: 2,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: isMobile ? 'column' : 'row',
          gap: 2
        }}
      >
        <TextField
          placeholder="Search staff..."
          variant="outlined"
          fullWidth={isMobile}
          sx={{
            maxWidth: isMobile ? '100%' : '300px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '28px',
              '&:hover fieldset': {
                borderColor: theme.palette.primary.main
              }
            },
            '& .MuiOutlinedInput-input': {
              padding: '12px 14px'
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            )
          }}
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Filter</InputLabel>
            <Select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              label="Filter"
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Invited">Invited</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            onClick={handleOpenInviteDialog}
          >
            Invite Staff
          </Button>
        </Box>
      </Box>

      {isMobile ? (
        <Box sx={{ mt: 2 }}>
          {currentPageData?.map((staff: IUser) => (
            <StaffAccordion
              key={staff._id}
              staff={staff}
              onMenuOpen={handleMenuOpen}
              onFetchDocs={handleFetchCarerDocs}
            />
          ))}
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Profile</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Documents</TableCell>
                <TableCell>Job Position</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Holiday Calendar</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageData?.map((staff: IUser) => (
                <TableRow key={staff._id}>
                  <TableCell>
                    <StyledAvatar
                      src={`${staff?.avatar?.url}` || '/profile-image.jpg'}
                      alt={staff?.fname + ' ' + staff?.lname}
                    />
                  </TableCell>
                  <TableCell>
                    {staff.fname} {staff.lname}
                  </TableCell>
                  <TableCell>{staff.email}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleFetchCarerDocs(staff._id)}
                      variant="contained"
                    >
                      <Typography>Docs</Typography>
                    </Button>
                  </TableCell>
                  <TableCell>{staff.accountType}</TableCell>
                  <TableCell>{staff.accountStatus}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={async () => {
                        const response = await getLeaveRequests({
                          staffId: staff._id,
                          status: 'pending'
                        }).unwrap();
                        console.log(response, 'response');
                        setSelectedStaffForHoliday(staff);
                        handleOpenHolidayCalendar(staff);
                      }}
                    >
                      <Badge
                        badgeContent={
                          leaveRequests?.filter(
                            (request) => request.userId === staff._id
                          ).length
                        }
                        color="primary"
                      >
                        <CalendarTodayIcon />
                      </Badge>
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleMenuOpen(e, staff)}>
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {!isMobile && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredStaffs?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleActionClick('edit')}>Edit</MenuItem>
        <MenuItem
          onClick={() => {
            setOpenConfirmation(true);
          }}
        >
          Delete
        </MenuItem>
      </Menu>

      <SubscriptionDialog
        open={openSubscriptionReminder}
        onClose={() => setOpenSubscriptionReminder(false)}
        message="You must have a subscription to invite more staffs"
      />

      <CarerDocumentsDialog
        open={docDialogOpen}
        onClose={() => setDocDialogOpen(false)}
        documents={documents}
        certificates={[]}
        onDelete={() => {}}
      />

      <ConfirmationDialog
        open={openConfirmation}
        onCancel={() => {
          setOpenConfirmation(false);
          setRating(0);
          setFeedback('');
        }}
        onConfirm={() => {
          handleActionClick('delete');
        }}
        node={
          <Box
            sx={{ width: '100%', mx: 'auto', backgroundColor: 'white', mt: 2 }}
          >
            <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
              <Typography component="legend" sx={{ mr: 2 }}>
                Rating:
              </Typography>
              <Rating
                name="staff-rating"
                value={rating}
                onChange={(event, newValue) => {
                  setRating(newValue);
                }}
              />
            </Box>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Feedback"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              sx={{ mb: 2 }}
            />
          </Box>
        }
        title="Are you sure you want to delete this staff?"
        message="This action cannot be undone. Please provide a rating and feedback for the staff."
      />
      <InviteDialog
        open={openInviteDialog}
        onClose={handleCloseInviteDialog}
        onSendInvite={handleSendInvite}
      />

      <Dialog
        open={openHolidayCalendar}
        onClose={handleCloseHolidayCalendar}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          Holiday Calendar - {selectedStaffForHoliday?.fname}{' '}
          {selectedStaffForHoliday?.lname}
        </DialogTitle>
        <DialogContent>
          <HolidayCalendarForHome
            staffId={selectedStaffForHoliday?._id}
            leaveRequests={leaveRequestsData?.filter(
              (request) => request.userId === selectedStaffForHoliday?._id
            )}
            onApprove={handleApproveLeave}
            onReject={handleRejectLeave}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHolidayCalendar}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StaffsComponent;

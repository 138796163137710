import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider
} from '@mui/material';
import { Save, Check, Info } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import {
  useGetCarerApplicationQuery,
  useUpdateApplicationSectionMutation
} from 'src/redux/@api/carer-application';

interface ConsentsProps {
  initialData?: any;
  onSubmit: (data: any, index: number | undefined, section: string) => void;
  selectedSection: string;
  editable?: boolean;
}

const Consents: React.FC<ConsentsProps> = ({
  initialData,
  onSubmit,
  selectedSection,
  editable = true
}) => {
  const dispatch = useDispatch();
  const { data: carerApplication, refetch } =
    useGetCarerApplicationQuery(undefined);
  const [updateApplicationSection] = useUpdateApplicationSectionMutation();

  const [saving, setSaving] = useState(false);
  const [openDialog, setOpenDialog] = useState<string | null>(null);

  const defaultValues = editable
    ? carerApplication?.data[selectedSection] || {
        dataProcessing: false,
        backgroundCheck: false,
        termsAndConditions: false
      }
    : initialData || {};

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues
  });

  useEffect(() => {
    if (editable && carerApplication?.data[selectedSection]) {
      reset(carerApplication.data[selectedSection]);
    } else if (!editable && initialData) {
      reset(initialData);
    }
  }, [carerApplication, selectedSection, reset, editable, initialData]);

  const handleSave = async (data: any) => {
    if (!editable) return;
    setSaving(true);
    try {
      dispatch(showSnack({ message: 'Saving consents...', color: 'info' }));
      await updateApplicationSection({
        section: 'consents',
        data
      });
      await refetch();
      dispatch(
        showSnack({ message: 'Consents saved successfully', color: 'success' })
      );
    } catch (error) {
      console.error('Save failed:', error);
      dispatch(
        showSnack({ message: 'Failed to save consents', color: 'error' })
      );
    } finally {
      setSaving(false);
    }
  };

  const handleOpenDialog = (dialogType: string) => {
    setOpenDialog(dialogType);
  };

  const handleCloseDialog = () => {
    setOpenDialog(null);
  };

  const renderConsentDialog = (title: string, content: string) => (
    <Dialog
      open={openDialog === title}
      onClose={handleCloseDialog}
      aria-labelledby="consent-dialog-title"
    >
      <DialogTitle id="consent-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        <Typography>{content}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderConsent = (name: string, label: string, dialogTitle: string) => (
    <Controller
      name={name}
      control={control}
      rules={{ required: `You must agree to ${label}` }}
      render={({ field }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          {editable ? (
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label={
                <span>
                  I agree to the{' '}
                  <Link
                    component="button"
                    type="button"
                    onClick={() => handleOpenDialog(dialogTitle)}
                  >
                    {label}
                  </Link>
                </span>
              }
            />
          ) : (
            <>
              {field.value ? <Check color="primary" /> : <Info color="error" />}
              <Typography variant="body1" sx={{ ml: 1 }}>
                {field.value ? 'Agreed to' : 'Not agreed to'} {label}
              </Typography>
            </>
          )}
        </Box>
      )}
    />
  );

  return (
    <Card elevation={3}>
      <CardContent>
        <Typography variant="h5" gutterBottom color="primary">
          Consents and Agreements
        </Typography>
        <Divider sx={{ mb: 3 }} />
        <form onSubmit={handleSubmit(handleSave)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {renderConsent(
                'dataProcessing',
                'data processing terms',
                'Data Processing'
              )}
              {errors.dataProcessing && (
                <Typography color="error" variant="caption">
                  {errors.dataProcessing.message}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              {renderConsent(
                'backgroundCheck',
                'background checks',
                'Background Check'
              )}
              {errors.backgroundCheck && (
                <Typography color="error" variant="caption">
                  {errors.backgroundCheck.message}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              {renderConsent(
                'termsAndConditions',
                'terms and conditions',
                'Terms and Conditions'
              )}
              {errors.termsAndConditions && (
                <Typography color="error" variant="caption">
                  {errors.termsAndConditions.message}
                </Typography>
              )}
            </Grid>
          </Grid>
        </form>
      </CardContent>
      {editable && (
        <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={saving ? <CircularProgress size={20} /> : <Save />}
            disabled={saving}
            onClick={handleSubmit(handleSave)}
          >
            {saving ? 'Saving...' : 'Save Consents'}
          </Button>
        </CardActions>
      )}

      {renderConsentDialog(
        'Data Processing',
        'Here are the detailed data processing terms...'
      )}
      {renderConsentDialog(
        'Background Check',
        'Here are the details about the background check process...'
      )}
      {renderConsentDialog(
        'Terms and Conditions',
        'Here are our full terms and conditions...'
      )}
    </Card>
  );
};

export default Consents;

// organizationRoleSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { IOrganizationRole } from 'src/interfaces/heirarchy';

interface IOrganizationRoleState {
  organizationRoles: IOrganizationRole[];
  loading: boolean;
  error: string | null;
}

const initialState: IOrganizationRoleState = {
  organizationRoles: [],
  loading: false,
  error: null
};

const organizationRoleSlice = createSlice({
  name: 'organizationRole',
  initialState,
  reducers: {
    setOrganizationRoles: (
      state,
      action: PayloadAction<IOrganizationRole[]>
    ) => {
      state.organizationRoles = action.payload;
    },
    addOrganizationRole: (state, action: PayloadAction<IOrganizationRole>) => {
      state.organizationRoles.push(action.payload);
    },
    updateOrganizationRole: (
      state,
      action: PayloadAction<IOrganizationRole>
    ) => {
      const index = state.organizationRoles.findIndex(
        (role) => role._id === action.payload._id
      );
      if (index !== -1) {
        state.organizationRoles[index] = action.payload;
      }
    },
    clearOrganizationRoles: (state) => {
      state.organizationRoles = [];
      state.error = null;
    },
    setOrganizationRoleError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    }
  }
});

export const {
  setOrganizationRoles,
  addOrganizationRole,
  updateOrganizationRole,
  clearOrganizationRoles,
  setOrganizationRoleError
} = organizationRoleSlice.actions;
export default organizationRoleSlice.reducer;

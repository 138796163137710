import React, { useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Button,
  Typography,
  useTheme,
  IconButton,
  Tooltip,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import AddIcon from '@mui/icons-material/Add';

import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import type { IShiftPattern, IShiftType } from 'src/interfaces/shift-pattern';
import ShiftTypeDialog from './AddShiftTypeDialog';
import {
  useCreateYourShiftTypeMutation,
  useDeleteYourShiftTypeMutation,
  useLazyGetYourShiftPatternsQuery,
  useUpdateYourShiftTypeMutation
} from 'src/redux/@api/shift';

const StyledSection = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  boxShadow: theme.shadows[1]
}));

const StyledChipContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2)
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1)
}));

const StyledAddButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: 'fit-content'
}));

const Preferences: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedShiftType, setSelectedShiftType] =
    useState<IShiftPattern | null>(null);
  const theme = useTheme();

  const userState = useAppSelector((state) => state.user.user);
  const userId = userState._id;
  const [shiftTypes, setShiftTypes] = useState<IShiftType[]>([]);
  const [getYourShiftTypes] = useLazyGetYourShiftPatternsQuery();
  const [createYourShiftType] = useCreateYourShiftTypeMutation();
  const [deleteYourShiftType] = useDeleteYourShiftTypeMutation();
  const [updateYourShiftType] = useUpdateYourShiftTypeMutation();

  const dispatch = useDispatch();

  async function fetchShiftTypes() {
    try {
      const response = await getYourShiftTypes(userId).unwrap();
      setShiftTypes(response);
    } catch (error) {
      console.error(error);
      dispatch(
        showSnack({ message: 'Error fetching shift types', color: 'error' })
      );
    }
  }

  useEffect(() => {
    fetchShiftTypes();
  }, []);

  const handleOpenDialog = (shiftType?: IShiftType) => {
    setSelectedShiftType(shiftType || null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedShiftType(null);
  };

  const handleSaveShiftType = async (shiftType: IShiftType) => {
    try {
      if (shiftType._id) {
        await updateYourShiftType(shiftType).unwrap();
        dispatch(
          showSnack({
            message: 'Shift Type updated successfully',
            color: 'success'
          })
        );
      } else {
        await createYourShiftType(shiftType).unwrap();
        dispatch(
          showSnack({
            message: 'Shift Type created successfully',
            color: 'success'
          })
        );
      }
      await fetchShiftTypes();
      handleCloseDialog();
    } catch (error) {
      console.error(error);
      dispatch(
        showSnack({ message: 'Error saving shift type', color: 'error' })
      );
    }
  };

  const handleDeleteShiftType = async (shiftTypeId: string) => {
    try {
      await deleteYourShiftType(shiftTypeId).unwrap();
      await fetchShiftTypes();
      dispatch(
        showSnack({
          message: 'Shift Type deleted successfully',
          color: 'success'
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(
        showSnack({ message: 'Error deleting shift type', color: 'error' })
      );
    }
  };

  return (
    <Box>
      <StyledSection>
        <Typography variant="h6" gutterBottom>
          Shift Types
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <StyledChipContainer>
          {shiftTypes.map((shiftType) => (
            <Tooltip title={``} key={shiftType._id}>
              <StyledChip
                label={shiftType.name}
                onDelete={() => handleDeleteShiftType(shiftType._id!)}
                deleteIcon={<Icon icon="mdi:delete" />}
                onClick={() => handleOpenDialog(shiftType)}
                icon={
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenDialog(shiftType);
                    }}
                  >
                    <Icon icon="mdi:eye" />
                  </IconButton>
                }
              />
            </Tooltip>
          ))}
        </StyledChipContainer>
        <StyledAddButton
          variant="contained"
          onClick={() => handleOpenDialog()}
          color="primary"
          startIcon={<AddIcon />}
        >
          Add Shift Type
        </StyledAddButton>
      </StyledSection>

      {/* Placeholder for future sections */}
      <StyledSection>
        <Typography variant="h6" gutterBottom>
          Shift Preferences (Coming Soon)
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Typography variant="body1" color="text.secondary">
          This section will allow you to set your preferences for shift
          scheduling.
        </Typography>
      </StyledSection>

      <StyledSection>
        <Typography variant="h6" gutterBottom>
          Shift Notifications (Coming Soon)
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Typography variant="body1" color="text.secondary">
          Configure how and when you receive notifications about shifts.
        </Typography>
      </StyledSection>

      <ShiftTypeDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onSave={handleSaveShiftType}
        selectedShiftType={selectedShiftType}
      />
    </Box>
  );
};

export default Preferences;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Paper,
  Container,
  Snackbar,
  Alert,
  ThemeProvider,
  TextField,
  Fade,
  useTheme
} from '@mui/material';
import { styled } from '@mui/system';
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAppSelector } from 'src/redux/hook';
import {
  useResendVerificationEmailMutation,
  useVerifyEmailCodeMutation
} from 'src/redux/@api/auth';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(6),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: 500,
  margin: 'auto',
  marginTop: theme.spacing(8),
  borderRadius: 16,
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
}));

const StyledIcon = styled(EmailIcon)(({ theme }) => ({
  fontSize: 80,
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(3)
}));

const StyledCheckIcon = styled(CheckCircleIcon)(({ theme }) => ({
  fontSize: 80,
  color: theme.palette.secondary.main,
  marginBottom: theme.spacing(3)
}));

const EmailVerification = ({ onVerified }: { onVerified?: () => void }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [verificationCode, setVerificationCode] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [isVerified, setIsVerified] = useState(false);

  const user = useAppSelector((state) => state.userState.user);

  const [resendVerificationEmail, { isLoading: isResending }] =
    useResendVerificationEmailMutation();
  const [verifyEmailCode, { isLoading: isVerifying }] =
    useVerifyEmailCodeMutation();

  const handleResendEmail = async () => {
    if (user?.email) {
      try {
        await resendVerificationEmail(user.email).unwrap();
        setSnackbarMessage('Verification email sent successfully');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
      } catch (error) {
        setSnackbarMessage('Failed to send verification email');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      }
    }
  };

  const handleVerifyCode = async () => {
    if (user?.email && verificationCode) {
      try {
        await verifyEmailCode({
          email: user.email,
          code: verificationCode
        }).unwrap();
        setIsVerified(true);
        setSnackbarMessage('Email verified successfully');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        setTimeout(() => navigate('/'), 3000); // Redirect after 3 seconds
      } catch (error) {
        setSnackbarMessage('Failed to verify email');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      }
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Fade in={true} timeout={1000}>
          <StyledPaper elevation={3}>
            {isVerified ? (
              <>
                <StyledCheckIcon />
                <Typography variant="h4" gutterBottom>
                  Email Verified!
                </Typography>
                <Typography variant="body1" align="center" paragraph>
                  Thank you for verifying your email address. You will be
                  redirected to the home page shortly.
                </Typography>
              </>
            ) : (
              <>
                <StyledIcon />
                <Typography variant="h4" gutterBottom>
                  Verify Your Email
                </Typography>
                <Typography variant="body1" align="center" paragraph>
                  We've sent a verification code to your email. Please enter the
                  code below to verify your email address.
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Verification Code"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  margin="normal"
                />
                <Box mt={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleVerifyCode}
                    disabled={isVerifying || !verificationCode}
                    size="large"
                    fullWidth
                  >
                    {isVerifying ? (
                      <CircularProgress size={24} />
                    ) : (
                      'Verify Email'
                    )}
                  </Button>
                </Box>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    onClick={handleResendEmail}
                    disabled={isResending}
                  >
                    {isResending ? (
                      <CircularProgress size={24} />
                    ) : (
                      'Resend Verification Code'
                    )}
                  </Button>
                </Box>

                <Box mt={2}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      localStorage.removeItem('token');
                      navigate('/');
                    }}
                    disabled={isResending}
                  >
                    Logout
                  </Button>
                </Box>
              </>
            )}
          </StyledPaper>
        </Fade>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
};

export default EmailVerification;

// userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { IQuickStats } from 'src/interfaces/quick-stats';
import { miscApi } from '../@api/misc';
import { timesheetsApi } from '../@api/timesheet';



const initialState: IQuickStats = {
  totalShifts: 0,
  completedShifts: 0,
  acceptedShifts: 0,
  rejectedShifts: 0,
  completionRate: 0,
  acceptanceRate: 0
};

const quickStatsSlice = createSlice({
  name: 'quickStats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // builder.addMatcher(
    //   api.endpoints.updateUser.matchFulfilled,
    //   (state, action: PayloadAction<IUser>) => {
    //     state.user = action.payload;
    //   }
    // );

    builder.addMatcher(
      timesheetsApi.endpoints.getQuickStats.matchFulfilled,
      (state, action: PayloadAction<IQuickStats>) => {
        return action.payload;
      }
    );
  }
});

export const {} = quickStatsSlice.actions;
export default quickStatsSlice.reducer;

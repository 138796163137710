import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';

interface WakeAsleepCheckDialogProps {
  open: boolean;
  onClose: () => void;
  onResolve: (data: { status: string; notes: string }) => void;
}

const WakeAsleepCheckDialog: React.FC<WakeAsleepCheckDialogProps> = ({
  open,
  onClose,
  onResolve
}) => {
  const [status, setStatus] = useState('');
  const [notes, setNotes] = useState('');

  const handleResolve = () => {
    onResolve({ status, notes });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Wake/Asleep Check</DialogTitle>
      <DialogContent>
        <RadioGroup
          aria-label="status"
          name="status"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          <FormControlLabel value="awake" control={<Radio />} label="Awake" />
          <FormControlLabel value="asleep" control={<Radio />} label="Asleep" />
          <FormControlLabel value="drowsy" control={<Radio />} label="Drowsy" />
        </RadioGroup>
        <TextField
          margin="normal"
          label="Notes"
          fullWidth
          multiline
          rows={3}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleResolve} variant="contained" color="primary">
          Resolve
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WakeAsleepCheckDialog;

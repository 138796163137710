import { IHistoricNotification } from 'src/interfaces/historic-notification';
import { baseApi } from './base';

interface ApiResponse<T = any> {
  success: boolean;
  message: string;
  data: T;
}

interface CreateNotificationRequest {
  title: string;
  body: string;
  recipient: string;
  data?: Record<string, any>;
  status?: 'sent' | 'failed';
}

interface Notification {
  _id: string;
  // Add other notification fields here
}

interface NotificationsResponse {
  notifications: IHistoricNotification[];
  totalCount: number;
  nextCursor: string | null;
}

// Existing interfaces
interface RegisterTokenRequest {
  token: string;
  device: {
    type: 'ios' | 'android' | 'web';
    model?: string;
    osVersion?: string;
    appVersion?: string;
  };
}

interface FCMToken {
  _id: string;
  token: string;
  device: {
    type: 'ios' | 'android' | 'web';
    model?: string;
    osVersion?: string;
    appVersion?: string;
  };
  createdAt: string;
  updatedAt: string;
}

// New interfaces for historic notifications

interface GetNotificationsResponse extends ApiResponse {
  data: {
    notifications: IHistoricNotification[];
    totalCount: number;
  };
}

export const notificationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Existing endpoints
    registerFCMToken: builder.mutation<ApiResponse, RegisterTokenRequest>({
      query: (payload) => ({
        url: 'fcm/register',
        method: 'POST',
        body: payload
      })
    }),

    getFCMTokens: builder.query<ApiResponse & { data: FCMToken[] }, void>({
      query: () => 'fcm/tokens'
    }),

    deleteFCMToken: builder.mutation<ApiResponse, string>({
      query: (token) => ({
        url: 'fcm/token',
        method: 'DELETE',
        body: { token }
      })
    }),

    createHistoricNotification: builder.mutation<
      ApiResponse<Notification>,
      CreateNotificationRequest
    >({
      query: (payload) => ({
        url: 'notifications',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [{ type: 'Notifications', id: 'LIST' }]
    }),

    getHistoricNotifications: builder.query<
      ApiResponse<NotificationsResponse>,
      { cursor?: string; limit?: number }
    >({
      query: ({ cursor, limit = 10 }) => {
        const params = new URLSearchParams();
        if (cursor) params.append('cursor', cursor);
        params.append('limit', limit.toString());
        return `notifications?${params.toString()}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.notifications.map(({ _id }) => ({
                type: 'Notifications' as const,
                id: _id
              })),
              { type: 'Notifications', id: 'LIST' }
            ]
          : [{ type: 'Notifications', id: 'LIST' }]
    }),

    markNotificationAsRead: builder.mutation<ApiResponse<Notification>, string>(
      {
        query: (id) => ({
          url: `notifications/${id}/read`,
          method: 'PUT'
        }),
        invalidatesTags: (result, error, id) => [
          { type: 'Notifications', id },
          { type: 'NotificationsCount', id: 'LIST' }
        ]
      }
    ),

    deleteHistoricNotification: builder.mutation<ApiResponse<void>, string>({
      query: (notificationId) => ({
        url: `notifications/${notificationId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [
        { type: 'Notifications', id: 'LIST' },
        { type: 'NotificationsCount', id: 'LIST' }
      ]
    }),

    getUnreadNotificationCount: builder.query<ApiResponse<number>, void>({
      query: () => 'notifications/unread/count',
      providesTags: [{ type: 'NotificationsCount', id: 'LIST' }]
    }),

    markAllNotificationsAsRead: builder.mutation<ApiResponse<void>, void>({
      query: () => ({
        url: 'notifications/read-all',
        method: 'PUT'
      }),
      invalidatesTags: [
        { type: 'Notifications', id: 'LIST' },
        { type: 'NotificationsCount', id: 'LIST' }
      ]
    })
  })
});

export const {
  useLazyGetHistoricNotificationsQuery,
  useRegisterFCMTokenMutation,
  useGetFCMTokensQuery,
  useDeleteFCMTokenMutation,
  useCreateHistoricNotificationMutation,
  useGetHistoricNotificationsQuery,
  useMarkNotificationAsReadMutation,
  useDeleteHistoricNotificationMutation,
  useGetUnreadNotificationCountQuery,
  useMarkAllNotificationsAsReadMutation
} = notificationApi;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface HomeSettings {
    allowResident3rdParty: boolean;
}

const initialState: HomeSettings = {
    allowResident3rdParty: false
};

const homeSettingsSlice = createSlice({
    name: 'homesettings',
    initialState,
    reducers: {
        _setAllowResident3rdParty: (state, action: PayloadAction<boolean>) => {
            state.allowResident3rdParty = action.payload;
        }
    }
});

export const {
    _setAllowResident3rdParty
} = homeSettingsSlice.actions;
export default homeSettingsSlice.reducer;

// groups.ts
import { baseApi } from './base';

export const groupsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createGroup: builder.mutation({
      query: (data: any) => ({
        url: 'groups',
        method: 'POST',
        body: data
      })
    }),
    getGroups: builder.query({
      query: (homeId: string) => `groups/${homeId}/all`
    }),
  })
});

export const {
  useCreateGroupMutation,
  useGetGroupsQuery,
  useLazyGetGroupsQuery,
} = groupsApi;
// auth.ts
import { baseApi } from './base';

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (data) => ({
        url: 'auth/register',
        method: 'POST',
        body: data
      })
    }),
    getUser: builder.query({
      query: () => 'auth/user'
    }),
    getLinkedUsers: builder.query({
      query: (type: string) => `auth/linked-users/${type}`
    }),
    getStaffsForAssign: builder.query({
      query: ({ date }: { date: string }) => `auth/staffs?date=${date}`
    }),
    linkUser: builder.mutation({
      query: ({ userId, userType }: { userId: string; userType: string }) => ({
        url: `auth/link`,
        method: 'PATCH',
        body: {
          linkedUserId: userId,
          linkedUserType: userType
        }
      })
    }),
    unlinkUser: builder.mutation({
      query: ({
        userId,
        userType,
        rating,
        review
      }: {
        userId: string;
        userType: string;
        rating?: number;
        review?: string;
      }) => ({
        url: `auth/unlink`,
        method: 'PATCH',
        body: {
          linkedUserId: userId,
          linkedUserType: userType,
          rating,
          review
        }
      })
    }),
    getUsers: builder.query({
      query: (accountType: string) => 'auth/users/' + accountType
    }),
    getCarerResume: builder.query({
      query: (carerId: string) => `auth/${carerId}/carer-resume`
    }),
    searchUsers: builder.query({
      query: ({ query, accountType }: { query: string; accountType: string }) =>
        `auth/search-users/${accountType}?companyName=${query}`
    }),
    updateAvailability: builder.mutation({
      query: (dates: string[]) => ({
        url: 'auth/update-availability',
        method: 'PUT',
        body: { dates }
      })
    }),
    deleteAvailability: builder.mutation({
      query: (date: string) => ({
        url: `auth/availabilities/${date}`,
        method: 'DELETE'
      })
    }),
    updateFcmToken: builder.mutation({
      query: (fcmToken: string) => ({
        url: `auth/update-fcm-token`,
        method: 'PUT',
        body: { fcmToken }
      })
    }),
    initializeCustomer: builder.mutation<void, void>({
      query: () => ({
        url: 'auth/initialize-customer',
        method: 'POST'
      })
    }),
    requestPasswordReset: builder.mutation<void, string>({
      query: (email: string) => ({
        url: `auth/request-reset`,
        method: 'POST',
        body: { email }
      })
    }),
    resetPassword: builder.mutation<
      void,
      { email: string; code: string; newPassword: string }
    >({
      query: (data) => ({
        url: `auth/reset-password`,
        method: 'POST',
        body: {
          email: data.email,
          code: data.code,
          newPassword: data.newPassword
        }
      })
    }),
    resendVerificationEmail: builder.mutation({
      query: (email) => ({
        url: 'auth/resend-verification-email',
        method: 'POST',
        body: { email }
      })
    }),
    verifyEmailCode: builder.mutation({
      query: ({ email, code }) => ({
        url: 'auth/verify-email',
        method: 'POST',
        body: { token: code }
      })
    }),
    //  deletion

    requestDeleteAccount: builder.mutation({
      query: () => ({
        url: 'auth/request-account-deletion',
        method: 'POST'
      })
    }),
    cancelDeleteRequest: builder.mutation({
      query: (data) => ({
        url: 'auth/cancel-account-deletion',
        method: 'POST',
        body: data
      })
    }),

    // fcm token
    registerFcmToken: builder.mutation({
      query: ({ token, device }: { token: string; device: any }) => ({
        url: 'fcm',
        method: 'POST',
        body: { token, device }
      })
    })
  })
});

export const {
  useRegisterFcmTokenMutation,
  useRequestDeleteAccountMutation,
  useCancelDeleteRequestMutation,
  useRegisterMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetLinkedUsersQuery,
  useLazyGetLinkedUsersQuery,
  useGetStaffsForAssignQuery,
  useLazyGetStaffsForAssignQuery,
  useLinkUserMutation,
  useUnlinkUserMutation,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetCarerResumeQuery,
  useLazyGetCarerResumeQuery,
  useSearchUsersQuery,
  useLazySearchUsersQuery,
  useUpdateAvailabilityMutation,
  useDeleteAvailabilityMutation,
  useUpdateFcmTokenMutation,
  useInitializeCustomerMutation,
  useRequestPasswordResetMutation,
  useResetPasswordMutation,
  useResendVerificationEmailMutation,
  useVerifyEmailCodeMutation
} = authApi;

import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Card,
  CardContent,
  CardActions,
  CircularProgress
} from '@mui/material';
import { Add, Delete, Save } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import {
  useGetCarerApplicationQuery,
  useUpdateApplicationSectionMutation,
  useDeleteArrayItemMutation
} from 'src/redux/@api/carer-application';

interface SkillsProps {
  initialData?: any;
  onSubmit: (data: any, index: number | undefined, section: string) => void;
  selectedSection: string;
  editable?: boolean;
}

const Skills: React.FC<SkillsProps> = ({
  initialData,
  onSubmit,
  selectedSection,
  editable = true
}) => {
  const dispatch = useDispatch();
  const { data: carerApplication, refetch } =
    useGetCarerApplicationQuery(undefined);
  const [updateApplicationSection] = useUpdateApplicationSectionMutation();
  const [deleteArrayItem] = useDeleteArrayItemMutation();

  const [savingSection, setSavingSection] = useState<{
    [key: string]: boolean;
  }>({});
  const [deletingItem, setDeletingItem] = useState<{ [key: string]: boolean }>(
    {}
  );

  const defaultValues = editable
    ? carerApplication?.data[selectedSection] || {}
    : initialData || {};

  const { control, handleSubmit, reset, getValues } = useForm({
    defaultValues
  });

  useEffect(() => {
    if (editable && carerApplication?.data[selectedSection]) {
      reset(carerApplication.data[selectedSection]);
    } else if (!editable && initialData) {
      reset(initialData);
    }
  }, [carerApplication, selectedSection, reset, editable, initialData]);

  const languagesArray = useFieldArray({
    control,
    name: 'languages'
  });

  const careSkillsArray = useFieldArray({
    control,
    name: 'careSkills'
  });

  const specializationsArray = useFieldArray({
    control,
    name: 'specializations'
  });

  const handleSaveSection = async (section: string, index?: number) => {
    if (!editable) return;
    const key = `${section}${index !== undefined ? `.${index}` : ''}`;
    setSavingSection((prev) => ({ ...prev, [key]: true }));
    try {
      dispatch(
        showSnack({
          message: `Saving ${section} information...`,
          color: 'info'
        })
      );

      let data =
        index !== undefined ? getValues(section)[index] : getValues(section);

      await updateApplicationSection({
        section: `skills.${section}`,
        data,
        index
      });

      await refetch();
      dispatch(
        showSnack({
          message: `${section} information saved successfully`,
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Save failed:', error);
      dispatch(
        showSnack({
          message: `Failed to save ${section} information`,
          color: 'error'
        })
      );
    } finally {
      setSavingSection((prev) => ({ ...prev, [key]: false }));
    }
  };

  const handleDeleteSpecialization = async (index: number) => {
    if (!editable) return;
    try {
      dispatch(
        showSnack({
          message: 'Deleting specialization...',
          color: 'info'
        })
      );

      await deleteArrayItem({
        section: 'skills.specializations',
        index
      });

      await refetch();
      dispatch(
        showSnack({
          message: 'Specialization deleted successfully',
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Delete failed:', error);
      dispatch(
        showSnack({
          message: 'Failed to delete specialization',
          color: 'error'
        })
      );
    }
  };

  const handleDeleteItem = async (section: string, index: number) => {
    if (!editable) return;
    const key = `${section}.${index}`;
    setDeletingItem((prev) => ({ ...prev, [key]: true }));
    try {
      dispatch(
        showSnack({
          message: `Deleting ${section} item...`,
          color: 'info'
        })
      );

      await deleteArrayItem({
        section: `skills.${section}`,
        index
      });

      await refetch();
      dispatch(
        showSnack({
          message: `${section} item deleted successfully`,
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Delete failed:', error);
      dispatch(
        showSnack({
          message: `Failed to delete ${section} item`,
          color: 'error'
        })
      );
    } finally {
      setDeletingItem((prev) => ({ ...prev, [key]: false }));
    }
  };

  const renderTextField = (name: string, label: string) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) =>
        editable ? (
          <TextField
            {...field}
            label={label}
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
          />
        ) : (
          <Typography variant="body1">
            <strong>{label}:</strong> {field.value || 'N/A'}
          </Typography>
        )
      }
    />
  );

  const renderArraySection = (
    title: string,
    fieldsArray: any,
    section: string,
    renderFields: (index: number) => React.ReactNode
  ) => (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" gutterBottom color="primary">
        {title}
      </Typography>
      {fieldsArray.fields.map((field: any, index: number) => (
        <Card key={field.id} sx={{ mb: 2 }}>
          <CardContent>{renderFields(index)}</CardContent>
          {editable && (
            <CardActions sx={{ justifyContent: 'space-between', p: 2 }}>
              <Button
                size="small"
                onClick={() => handleDeleteItem(section, index)}
                startIcon={
                  deletingItem[`${section}.${index}`] ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Delete />
                  )
                }
                color="error"
                variant="outlined"
                disabled={deletingItem[`${section}.${index}`]}
              >
                {deletingItem[`${section}.${index}`] ? 'Deleting...' : 'Delete'}
              </Button>
              <Button
                size="small"
                onClick={() => handleSaveSection(section, index)}
                startIcon={
                  savingSection[`${section}.${index}`] ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Save />
                  )
                }
                variant="contained"
                color="primary"
                disabled={savingSection[`${section}.${index}`]}
              >
                {savingSection[`${section}.${index}`] ? 'Saving...' : 'Save'}
              </Button>
            </CardActions>
          )}
        </Card>
      ))}
      {editable && (
        <Button
          size="small"
          onClick={() => fieldsArray.append({})}
          startIcon={<Add />}
          variant="outlined"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
        >
          Add {title}
        </Button>
      )}
    </Box>
  );

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h5" gutterBottom>
        Skills Information
      </Typography>
      <form
        onSubmit={handleSubmit((data) => onSubmit(data, undefined, 'skills'))}
      >
        {renderArraySection(
          'Languages',
          languagesArray,
          'languages',
          (index: number) => (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {renderTextField(`languages.${index}.language`, 'Language')}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name={`languages.${index}.proficiency`}
                  control={control}
                  render={({ field }) =>
                    editable ? (
                      <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel>Proficiency</InputLabel>
                        <Select {...field} label="Proficiency">
                          <MenuItem value="Basic">Basic</MenuItem>
                          <MenuItem value="Conversational">
                            Conversational
                          </MenuItem>
                          <MenuItem value="Fluent">Fluent</MenuItem>
                          <MenuItem value="Native">Native</MenuItem>
                        </Select>
                      </FormControl>
                    ) : (
                      <Typography variant="body1">
                        <strong>Proficiency:</strong> {field.value || 'N/A'}
                      </Typography>
                    )
                  }
                />
              </Grid>
            </Grid>
          )
        )}

        {renderArraySection(
          'Care Skills',
          careSkillsArray,
          'careSkills',
          (index: number) => (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {renderTextField(`careSkills.${index}.skill`, 'Skill')}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name={`careSkills.${index}.experienceLevel`}
                  control={control}
                  render={({ field }) =>
                    editable ? (
                      <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel>Experience Level</InputLabel>
                        <Select {...field} label="Experience Level">
                          <MenuItem value="Novice">Novice</MenuItem>
                          <MenuItem value="Intermediate">Intermediate</MenuItem>
                          <MenuItem value="Expert">Expert</MenuItem>
                        </Select>
                      </FormControl>
                    ) : (
                      <Typography variant="body1">
                        <strong>Experience Level:</strong>{' '}
                        {field.value || 'N/A'}
                      </Typography>
                    )
                  }
                />
              </Grid>
            </Grid>
          )
        )}

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Specializations
          </Typography>
          <Controller
            name="specializations"
            control={control}
            render={({ field }) => (
              <Box>
                {field.value &&
                  field.value.map((spec: string, index: number) => (
                    <Chip
                      key={index}
                      label={spec}
                      onDelete={
                        editable
                          ? () => handleDeleteSpecialization(index)
                          : undefined
                      }
                      sx={{ m: 0.5 }}
                    />
                  ))}
                {editable && (
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Add a specialization"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        const input = e.target as HTMLInputElement;
                        const newSpec = input.value.trim();
                        if (
                          newSpec &&
                          (!field.value || !field.value.includes(newSpec))
                        ) {
                          const newValue = [...(field.value || []), newSpec];
                          field.onChange(newValue);
                          input.value = '';
                          handleSaveSection('specializations');
                        }
                      }
                    }}
                    sx={{ mt: 2 }}
                  />
                )}
              </Box>
            )}
          />
        </Box>

        {editable && (
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={
                savingSection['skills'] ? (
                  <CircularProgress size={20} />
                ) : (
                  <Save />
                )
              }
              disabled={savingSection['skills']}
            >
              {savingSection['skills']
                ? 'Saving...'
                : 'Save Skills Information'}
            </Button>
          </Box>
        )}
      </form>
    </Paper>
  );
};

export default Skills;

import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress,
  useTheme,
  useMediaQuery,
  alpha,
  Divider,
  Chip,
  Avatar
} from '@mui/material';
import {
  useLazyGetHistoricNotificationsQuery,
  useMarkNotificationAsReadMutation
} from 'src/redux/@api/notifications';
import { IHistoricNotification } from 'src/interfaces/historic-notification';

const NotificationsList: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [cursor, setCursor] = useState<string | null>(null);
  const [allNotifications, setAllNotifications] = useState<
    IHistoricNotification[]
  >([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const observerTarget = useRef(null);
  const [fetchNotifications] = useLazyGetHistoricNotificationsQuery();
  const [markAsRead] = useMarkNotificationAsReadMutation();

  const loadNotifications = async () => {
    if (!hasMore || isLoading) return;

    try {
      setIsLoading(true);
      const notificationsData = await fetchNotifications({
        cursor,
        limit: 10
      }).unwrap();

      const newCursor = notificationsData.data.nextCursor;
      setCursor(newCursor);
      setAllNotifications((prev) => [
        ...prev,
        ...notificationsData.data.notifications
      ]);

      if (newCursor === null) {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Failed to load notifications:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !isLoading) {
          loadNotifications();
        }
      },
      { threshold: 0.1 }
    );

    const currentTarget = observerTarget.current;
    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [hasMore, isLoading]);

  useEffect(() => {
    loadNotifications();
  }, []);

  const handleMarkAsRead = async (notificationId: string) => {
    try {
      //   await markAsRead(notificationId).unwrap();
      setAllNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification._id === notificationId
            ? {
                ...notification,
                readBy: [
                  ...notification.readBy,
                  { userId: 'currentUserId', readAt: new Date().toISOString() }
                ]
              }
            : notification
        )
      );
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };

  const isNotificationRead = (notification: IHistoricNotification): boolean => {
    const currentUserId = 'currentUserId'; // Replace with actual current user ID
    return notification.readBy.some((read) => read.userId === currentUserId);
  };

  if (isLoading && allNotifications.length === 0) {
    return <CircularProgress />;
  }

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 600,
        bgcolor: 'background.paper',
        margin: 'auto'
      }}
    >
      {allNotifications.map((notification, index) => (
        <React.Fragment key={notification._id}>
          <ListItem
            alignItems="flex-start"
            onClick={() =>
              !isNotificationRead(notification) &&
              handleMarkAsRead(notification._id)
            }
            sx={{
              py: 2,
              transition: 'background-color 0.3s',
              cursor: isNotificationRead(notification) ? 'default' : 'pointer',
              '&:hover': {
                backgroundColor: isNotificationRead(notification)
                  ? 'transparent'
                  : alpha(theme.palette.primary.light, 0.1)
              }
            }}
          >
            <Avatar
              sx={{
                mr: 2,
                bgcolor:
                  notification.priority === 'high'
                    ? 'error.main'
                    : notification.priority === 'medium'
                    ? 'warning.main'
                    : 'info.main'
              }}
            >
              {notification.title[0].toUpperCase()}
            </Avatar>
            <ListItemText
              primary={
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: isNotificationRead(notification)
                        ? 'normal'
                        : 'bold',
                      color: isNotificationRead(notification)
                        ? 'text.primary'
                        : 'primary.main'
                    }}
                  >
                    {notification.title}
                  </Typography>
                  <Chip
                    label={notification.priority}
                    size="small"
                    color={
                      notification.priority === 'high'
                        ? 'error'
                        : notification.priority === 'medium'
                        ? 'warning'
                        : 'default'
                    }
                  />
                </Box>
              }
              secondary={
                <Box>
                  <Typography variant="body2" color="text.primary" sx={{}}>
                    {notification.content}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {new Date(notification.createdAt).toLocaleString()}
                  </Typography>
                </Box>
              }
            />
          </ListItem>
          {index < allNotifications.length - 1 && (
            <Divider variant="inset" component="li" />
          )}
        </React.Fragment>
      ))}
      {hasMore && <Box ref={observerTarget} sx={{ height: '10px' }} />}
      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
          <CircularProgress size={24} />
        </Box>
      )}
      {!hasMore && allNotifications.length > 0 && (
        <Typography align="center" sx={{ p: 2, color: 'text.secondary' }}>
          No more notifications to load.
        </Typography>
      )}
      {!isLoading && allNotifications.length === 0 && (
        <Typography align="center" sx={{ p: 4, color: 'text.secondary' }}>
          No notifications yet.
        </Typography>
      )}
    </List>
  );
};

export default NotificationsList;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import {
  RequestResetDialog,
  ResetPasswordDialog
} from 'src/components/core/dialogs/users/password-reset';
import {
  useRequestDeleteAccountMutation,
  useUpdateFcmTokenMutation
} from 'src/redux/@api/auth';
import { RootState } from 'src/redux/store';
import { initializeNotifications } from 'src/utils/notifications';
import { _setNotificationEnabled } from 'src/redux/reducers/user-slice';

const StyledSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4)
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

const AccountSettingsTab: React.FC = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state: RootState) => state.userState);
  const [updateFcmToken] = useUpdateFcmTokenMutation();

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      email: '',
      password: '',
      notifications: userState.notificationEnabled,
      twoFactor: false
    }
  });

  useEffect(() => {
    setValue('notifications', userState.notificationEnabled);
  }, [userState.notificationEnabled, setValue]);

  const [openRequestDialog, setOpenRequestDialog] = useState(false);
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [requestDeletion] = useRequestDeleteAccountMutation();

  const handleRequestSuccess = () => {
    setOpenRequestDialog(false);
    setOpenResetDialog(true);
  };

  const handleResetSuccess = () => {
    setOpenResetDialog(false);
    dispatch(
      showSnack({ message: 'Password reset successful', color: 'success' })
    );
  };

  const onSubmit = (data) => {
    setOpenRequestDialog(true);
  };

  const handleDeleteAccount = async () => {
    try {
      const response = await requestDeletion(undefined).unwrap();
      dispatch(
        showSnack({ message: 'Account deletion requested', color: 'success' })
      );
      setOpenDeleteDialog(false);

      window.location.href = '/';
    } catch (error) {
      console.log('Error deleting account:', error);
      setErrorMessage(
        error.message || 'An error occurred while deleting the account'
      );
      setOpenErrorDialog(true);
    }
  };

  const handleNotificationToggle = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isEnabled = event.target.checked;
    if (isEnabled) {
      try {
        await initializeNotifications();
        const fcmToken = localStorage.getItem('fcmToken');
        if (fcmToken) {
          await updateFcmToken(fcmToken).unwrap();
        }
        dispatch(_setNotificationEnabled(true));
        dispatch(
          showSnack({
            message: 'Notifications enabled successfully',
            color: 'success'
          })
        );
      } catch (error) {
        console.error('Error enabling notifications:', error);
        dispatch(
          showSnack({
            message: 'Failed to enable notifications. Please try again.',
            color: 'error'
          })
        );
        setValue('notifications', false);
      }
    } else {
      // Handle disabling notifications if needed
      dispatch(_setNotificationEnabled(false));
      dispatch(showSnack({ message: 'Notifications disabled', color: 'info' }));
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSection>
          <StyledButton type="submit" variant="contained" color="primary">
            Update Password
          </StyledButton>
        </StyledSection>

        <Divider />

        <StyledSection>
          <Typography variant="h6" gutterBottom>
            Notifications
          </Typography>
          <Controller
            name="notifications"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                      handleNotificationToggle(e);
                    }}
                  />
                }
                label="Receive notifications"
              />
            )}
          />
        </StyledSection>

        <Divider />

        <StyledSection>
          <Typography variant="h6" gutterBottom color="error">
            Danger Zone
          </Typography>
          <Typography variant="body2" gutterBottom>
            Once you delete your account, there is no going back. Please be
            certain.
          </Typography>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setOpenDeleteDialog(true)}
          >
            Delete Account
          </Button>
        </StyledSection>
      </form>

      <RequestResetDialog
        open={openRequestDialog}
        onClose={() => setOpenRequestDialog(false)}
        onRequestSuccess={handleRequestSuccess}
      />
      <ResetPasswordDialog
        open={openResetDialog}
        onClose={() => setOpenResetDialog(false)}
        onResetSuccess={handleResetSuccess}
      />

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Confirm Account Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete your account? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteAccount} color="error">
            Delete Account
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openErrorDialog} onClose={() => setOpenErrorDialog(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <Typography>{errorMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenErrorDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AccountSettingsTab;

import { Icon } from '@iconify/react';
import AddIcon from '@mui/icons-material/Add';
import OpacityIcon from '@mui/icons-material/Opacity';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import FilterListIcon from '@mui/icons-material/FilterList';

export const taskTypes = [
    { type: 'pad check', icon: <OpacityIcon /> },
    { type: 'day check', icon: <WbSunnyIcon /> },
    { type: 'night check', icon: <NightsStayIcon /> },
    { type: 'meal', icon: <RestaurantIcon /> },
    { type: 'fluid intake', icon: <OpacityIcon /> },
    { type: 'medication', icon: <MedicalServicesIcon /> },
    { type: 'personal care', icon: <AccessibilityNewIcon /> },
    { type: 'other', icon: <AccessibilityNewIcon /> }
  ];
// src/services/userApi.ts
import { baseApi } from './base';

export const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query({
      query: ({ identifier }: { identifier: string }) =>
        `auth/user/profile?identifier=${identifier}`
    }),
    getLinkedUsers: builder.query<any, string>({
      query: (type) => `auth/linked-users/${type}`
    }),
    linkUser: builder.mutation<any, { userId: string; userType: string }>({
      query: ({ userId, userType }) => ({
        url: `auth/link`,
        method: 'PATCH',
        body: {
          linkedUserId: userId,
          linkedUserType: userType
        }
      })
    }),
    unLinkUser: builder.mutation<
      any,
      { userId: string; userType: string; rating?: number; review?: string }
    >({
      query: ({ userId, userType, rating, review }) => ({
        url: `auth/unlink`,
        method: 'PATCH',
        body: {
          linkedUserId: userId,
          linkedUserType: userType,
          rating,
          review
        }
      })
    }),
    getUsers: builder.query<any, string>({
      query: (accountType) => 'auth/users/' + accountType
    }),
    searchUsers: builder.query<any, { query: string; accountType: string }>({
      query: ({ query, accountType }) =>
        `auth/search-users/${accountType}?companyName=${query}`
    }),
    updateAvailability: builder.mutation<any, string[]>({
      query: (dates) => ({
        url: 'auth/update-availability',
        method: 'PUT',
        body: { dates }
      })
    }),
    deleteAvailability: builder.mutation<any, string>({
      query: (date) => ({
        url: `auth/availabilities/${date}`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useGetUserQuery,
  useGetLinkedUsersQuery,
  useLinkUserMutation,
  useUnLinkUserMutation,
  useGetUsersQuery,
  useSearchUsersQuery,
  useUpdateAvailabilityMutation,
  useDeleteAvailabilityMutation
} = userApi;

import React from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Paper,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { IJoinRequest } from 'src/interfaces/join-requests';
import { useAppSelector } from 'src/redux/hook';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { _clearUser } from 'src/redux/reducers/user-slice';

const StyledPaper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
  width: '100%',
  justifyContent: 'center',
  margin: '0 auto',
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius
}));

const PendingIcon = styled(CircularProgress)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main
}));

const OrganizationName = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

interface PendingJoinRequestProps {
  joinRequest: IJoinRequest;
  onCancel: () => void;
}

const PendingJoinRequest = () => {
  const { pendingJoinRequest: joinRequest } = useAppSelector(
    (state) => state.userState
  );

  const dispatch = useDispatch();

  return (
    <StyledPaper>
      <Typography variant="h5" gutterBottom>
        Join Request Pending
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        Your request to join{' '}
        <OrganizationName>
          {joinRequest?.organization?.name} is pending.
        </OrganizationName>{' '}
        Please wait for the organization to review and accept your request.
      </Typography>
      <Box mt={2}>
        <Typography variant="body2" color="textSecondary">
          Request sent on:{' '}
          {new Date(joinRequest?.requestDate)?.toLocaleDateString()}
        </Typography>
      </Box>
      <StyledButton variant="outlined" color="primary" onClick={() => {}}>
        Cancel Request
      </StyledButton>
      <StyledButton
        variant="contained"
        color="primary"
        onClick={() => {
          localStorage.removeItem('token');
          dispatch(_clearUser());
          location.href = '/';
        }}
      >
        Logout
      </StyledButton>
    </StyledPaper>
  );
};

export default PendingJoinRequest;

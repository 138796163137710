import { baseApi } from './base';

export const applicationVisibilityApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    initializeFieldVisibility: builder.mutation({
      query: () => ({
        url: 'application-visibility/initialize',
        method: 'POST'
      })
    }),
    getFieldVisibility: builder.query({
      query: () => 'application-visibility/'
    }),
    updateFieldVisibility: builder.mutation({
      query: (updatedFields) => ({
        url: 'application-visibility/',
        method: 'PUT',
        body: updatedFields
      })
    })
  })
});

export const {
  useInitializeFieldVisibilityMutation,
  useGetFieldVisibilityQuery,
  useUpdateFieldVisibilityMutation
} = applicationVisibilityApi;

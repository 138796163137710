import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Slider,
  Box,
  Chip
} from '@mui/material';
import { Icon } from '@iconify/react';

type MealType = 'breakfast' | 'lunch' | 'dinner' | 'snack';

interface MealIntakeData {
  mealType: MealType;
  foodType: string;
  platterSize: string;
  amountEaten: number;
  drinkAmount: number;
  assistanceRequired: string;
  comments: string;
}

interface MealIntakeDialogProps {
  open: boolean;
  onClose: () => void;
  onResolve: (data: MealIntakeData) => void;
  prefillData?: Partial<MealIntakeData>;
}

const mealTypes: MealType[] = ['breakfast', 'lunch', 'dinner', 'snack'];

const foodTypes: Record<MealType, string[]> = {
  breakfast: ['Continental', 'English', 'American', 'Healthy', 'Vegetarian'],
  lunch: ['Salad', 'Sandwich', 'Soup', 'Hot Meal', 'Vegetarian'],
  dinner: ['Poultry', 'Fish', 'Red Meat', 'Vegetarian', 'Pasta'],
  snack: ['Fruit', 'Yogurt', 'Nuts', 'Baked Goods', 'Chips']
};

const MealIntakeDialog: React.FC<MealIntakeDialogProps> = ({
  open,
  onClose,
  onResolve,
  prefillData
}) => {
  const [mealType, setMealType] = useState<MealType | ''>('');
  const [foodType, setFoodType] = useState('');
  const [platterSize, setPlatterSize] = useState('');
  const [amountEaten, setAmountEaten] = useState(0);
  const [drinkAmount, setDrinkAmount] = useState(0);
  const [assistanceRequired, setAssistanceRequired] = useState('');
  const [comments, setComments] = useState('');

  useEffect(() => {
    if (prefillData) {
      setMealType(prefillData.mealType || '');
      setFoodType(prefillData.foodType || '');
      setPlatterSize(prefillData.platterSize || '');
      setAmountEaten(prefillData.amountEaten || 0);
      setDrinkAmount(prefillData.drinkAmount || 0);
      setAssistanceRequired(prefillData.assistanceRequired || '');
      setComments(prefillData.comments || '');
    }
  }, [prefillData]);

  const handleResolve = () => {
    if (mealType && foodType && platterSize && assistanceRequired) {
      onResolve({
        mealType,
        foodType,
        platterSize,
        amountEaten,
        drinkAmount,
        assistanceRequired,
        comments
      });
    }
  };

  const isFormValid = mealType && foodType && platterSize && assistanceRequired;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Icon icon="material-symbols:restaurant" width={24} height={24} />
              <Typography variant="h5" ml={1}>
                Meal Intake
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {mealTypes.map((type) => (
                <Chip
                  key={type}
                  label={type.charAt(0).toUpperCase() + type.slice(1)}
                  onClick={() => setMealType(type)}
                  color={mealType === type ? 'primary' : 'default'}
                  variant={mealType === type ? 'filled' : 'outlined'}
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Food Type</InputLabel>
              <Select
                value={foodType}
                onChange={(e) => setFoodType(e.target.value as string)}
                disabled={!mealType}
              >
                {mealType && foodTypes[mealType].map((food) => (
                  <MenuItem key={food} value={food}>
                    {food}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Platter Size</InputLabel>
              <Select
                value={platterSize}
                onChange={(e) => setPlatterSize(e.target.value as string)}
              >
                <MenuItem value="small">Small</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="large">Large</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>Amount Eaten</Typography>
            <Box display="flex" alignItems="center">
              <Icon icon="material-symbols:dining" width={24} height={24} />
              <Slider
                value={amountEaten}
                onChange={(_, newValue) => setAmountEaten(newValue as number)}
                valueLabelDisplay="auto"
                step={10}
                marks
                min={0}
                max={100}
                sx={{ ml: 2, mr: 2, flexGrow: 1 }}
              />
              <Typography>{amountEaten}%</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>Drink Amount (ml)</Typography>
            <Box display="flex" alignItems="center">
              <Icon icon="ion:cafe-outline" width={24} height={24} />
              <Slider
                value={drinkAmount}
                onChange={(_, newValue) => setDrinkAmount(newValue as number)}
                valueLabelDisplay="auto"
                step={50}
                marks
                min={0}
                max={500}
                sx={{ ml: 2, mr: 2, flexGrow: 1 }}
              />
              <Typography>{drinkAmount} ml</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Assistance Required</InputLabel>
              <Select
                value={assistanceRequired}
                onChange={(e) => setAssistanceRequired(e.target.value as string)}
              >
                <MenuItem value="none">None</MenuItem>
                <MenuItem value="minimal">Minimal</MenuItem>
                <MenuItem value="moderate">Moderate</MenuItem>
                <MenuItem value="full">Full</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Comments"
              multiline
              rows={3}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleResolve}
          color="primary"
          variant="contained"
          disabled={!isFormValid}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MealIntakeDialog;
import React from 'react';
import ResidentProgressDashboard from '../home/views/dialogs/charts';
import { useLocation } from 'react-router';
import { Box, Button, IconButton, useTheme } from '@mui/material';
import { ArrowBack, ArrowBackIos } from '@mui/icons-material';

type Props = {};

export default function Charts({}: Props) {
  const location = useLocation();
  const residentId = location.state?.residentId;
  if (!residentId) {
    return <></>;
  }

  const theme = useTheme();
  return (
    <Box
      sx={{
        height: `calc(100vh - ${theme.header.height})`
      }}
    >
      <IconButton
        sx={{
          ml: 2
        }}
        onClick={() => {
          window.history.back();
        }}
      >
        <ArrowBackIos />
      </IconButton>
      <ResidentProgressDashboard residentId={residentId} />
    </Box>
  );
}

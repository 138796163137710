// shifts.ts
import { baseApi } from './base';
import { IShiftPattern, IShiftType } from 'src/interfaces/shift-pattern';

export const shiftsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getShiftPatterns: builder.query({
      query: ({ userId }: { userId: string }) => `shifttype/${userId}`
    }),
    getShifts: builder.query({
      query: () => 'shift',
      providesTags: ['Shift']
    }),
    setShiftPatternInit: builder.mutation({
      query: (data: { shifts: any[] }) => ({
        url: 'shifttype/init',
        method: 'POST',
        body: data
      })
    }),
    getSingleShift: builder.query({
      query: (id: string) => `shift/single/${id}`
    }),
    editShiftPattern: builder.mutation({
      query: ({ data, id }: { data: IShiftType; id: string }) => ({
        url: `shifttype/${id}`,
        method: 'PUT',
        body: { data }
      })
    }),
    deleteShiftPattern: builder.mutation({
      query: (id: string) => ({
        url: `shifttype/${id}/one`,
        method: 'DELETE'
      })
    }),
    publishShiftMultiple: builder.mutation({
      query: (
        data: {
          shiftPattern: IShiftType;
          count: number;
          agentId: string;
          date: string;
          shiftTypeRef: string;
          shiftTypeModel: string;
        }[]
      ) => ({
        url: 'shift/multiple',
        method: 'POST',
        body: { shiftsData: data }
      }),
      invalidatesTags: ['Shift']
    }),
    getUpcomingFreeShifts: builder.query({
      query: ({ date, userId }) =>
        'shift/published/free?date=' + date + '&userId=' + userId
    }),
    publishShiftMultipleFree: builder.mutation({
      query: (
        data: {
          shiftPattern: IShiftType;
          count: number;
          agentId: string;
          date: string;
          shiftTypeRef: string;
          shiftTypeModel: string;
        }[]
      ) => ({
        url: 'shift/multiple/free',
        method: 'POST',
        body: { shiftsData: data }
      }),
      invalidatesTags: ['Shift']
    }),
    deleteShift: builder.mutation({
      query: (id: string) => ({
        url: `shift/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Shift']
    }),
    getShiftWithAssignments: builder.query({
      query: (shiftId: string) => `shift/${shiftId}`,
      providesTags: (result, error, shiftId) => [{ type: 'Shift', id: shiftId }]
    }),
    getAssignedStaffs: builder.query({
      query: (shiftId: string) => `shift/${shiftId}/assigned`,
      providesTags: (result, error, shiftId) => [
        { type: 'AssignedStaffs', id: shiftId }
      ]
    }),
    assignUsersToShift: builder.mutation({
      query: ({ shiftId, assignments }) => ({
        url: `shift/${shiftId}/assign`,
        method: 'POST',
        body: { assignments }
      }),
      invalidatesTags: (result, error, { shiftId }) => [
        { type: 'AssignedStaffs', id: shiftId },
        { type: 'Shift', id: shiftId }
      ]
    }),
    unassignUserFromShift: builder.mutation({
      query: ({ shiftId, userId }) => ({
        url: `shift/${shiftId}/unassign/${userId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Shift', 'AssignedStaffs', 'AgencyShift']
    }),
    updateAssignmentStatus: builder.mutation({
      query: ({ assignmentId, status }) => ({
        url: `shift/assignments/${assignmentId}`,
        method: 'PUT',
        body: { status }
      }),
      invalidatesTags: (result, error, { assignmentId }) => [
        { type: 'AssignedStaffs', id: assignmentId }
      ]
    }),
    getAssignmentsForUser: builder.query({
      query: () => 'shift/assignments/user',
      providesTags: ['AssignedStaffs', 'Shift']
    }),
    replaceCarer: builder.mutation({
      query: ({
        oldCarerId,
        newCarerId,
        shiftId
      }: {
        oldCarerId: string;
        newCarerId: string;
        shiftId: string;
      }) => ({
        url: `shift/${shiftId}/replace-carer`,
        method: 'PUT',
        body: { oldCarerId, newCarerId }
      }),
      invalidatesTags: (result, error, { shiftId }) => [
        { type: 'AssignedStaffs', id: shiftId },
        { type: 'Shift', id: shiftId }
      ]
    }),
    getUnacceptedShiftsForAgency: builder.query({
      query: () => 'shift/agency/unaccepted'
    }),
    acceptShiftByAgency: builder.mutation({
      query: (shiftId: string) => ({
        url: `shift/agency/accept/${shiftId}`,
        method: 'POST'
      })
    }),
    assignCarers: builder.mutation({
      query: (assignments: { shiftId: string; userIds: string[] }[]) => ({
        url: 'shift/assign-carers',
        method: 'POST',
        body: { assignments }
      }),
      invalidatesTags: ['Shift', 'AssignedStaffs']
    }),
    unassignCarer: builder.mutation({
      query: ({ shiftId, carerId }: { shiftId: string; carerId: string }) => ({
        url: `shift/${shiftId}/unassign-carer`,
        method: 'POST',
        body: { carerId }
      }),
      invalidatesTags: ['Shift', 'AssignedStaffs']
    }),
    generateQR: builder.mutation({
      query: (shiftId: string) => ({
        url: `shift/${shiftId}/generateQR`,
        method: 'POST'
      })
    }),
    getPublishedShifts: builder.query({
      query: (month: number) => `/shift/published?month=${month}`,
      providesTags: ['Shift']
    }),
    getAgencyShifts: builder.query({
      query: (month: number) => `/shift/published/agency?month=${month}`,
      providesTags: ['AgencyShift']
    }),
    updateShift: builder.mutation({
      query: (data) => ({
        url: `/shift/${data?._id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: (result, error, data) => [
        { type: 'Shift', id: data?._id },
        { type: 'AssignedStaffs', id: data?._id }
      ]
    }),
    getYourShiftPatterns: builder.query({
      query: () => `/shift-pattern`,
      providesTags: ['ShiftPattern']
    }),
    getOtherShiftPatterns: builder.query({
      query: (userId: string) => `/shift-pattern/other/${userId}`
    }),
    createYourShiftType: builder.mutation({
      query: (data: IShiftPattern) => ({
        url: '/shift-pattern',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['ShiftPattern']
    }),
    deleteYourShiftType: builder.mutation({
      query: (id: string) => ({
        url: `/shift-pattern/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['ShiftPattern']
    }),
    updateYourShiftType: builder.mutation({
      query: (data: IShiftPattern) => ({
        url: `/shift-pattern/${data._id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['ShiftPattern']
    }),
    getHomeShiftTypes: builder.query({
      query: (userId) => `/shift-pattern/home/${userId}`
    }),
    getAgencyShiftTypes: builder.query({
      query: (userId) => `/shift-pattern/agency/${userId}`
    })
  })
});

export const {
  useGetSingleShiftQuery,
  useLazyGetSingleShiftQuery,
  useGetAgencyShiftTypesQuery,
  useGetHomeShiftTypesQuery,
  useGetShiftPatternsQuery,
  useGetShiftsQuery,
  useLazyGetShiftsQuery,
  useLazyGetShiftPatternsQuery,
  useSetShiftPatternInitMutation,
  useEditShiftPatternMutation,
  useDeleteShiftPatternMutation,
  usePublishShiftMultipleMutation,
  useGetUpcomingFreeShiftsQuery,
  usePublishShiftMultipleFreeMutation,
  useDeleteShiftMutation,
  useGetShiftWithAssignmentsQuery,
  useGetAssignedStaffsQuery,
  useAssignUsersToShiftMutation,
  useUnassignUserFromShiftMutation,
  useUpdateAssignmentStatusMutation,
  useGetAssignmentsForUserQuery,
  useReplaceCarerMutation,
  useGetUnacceptedShiftsForAgencyQuery,
  useAcceptShiftByAgencyMutation,
  useAssignCarersMutation,
  useUnassignCarerMutation,
  useGenerateQRMutation,
  useGetPublishedShiftsQuery,
  useGetAgencyShiftsQuery,
  useUpdateShiftMutation,
  useGetYourShiftPatternsQuery,
  useLazyGetYourShiftPatternsQuery,
  useGetOtherShiftPatternsQuery,
  useLazyGetOtherShiftPatternsQuery,
  useCreateYourShiftTypeMutation,
  useDeleteYourShiftTypeMutation,
  useUpdateYourShiftTypeMutation
} = shiftsApi;

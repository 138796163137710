import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { Email } from '@mui/icons-material';

const AccountDeletionPending = () => {
  return (
    <Box
      sx={{ maxWidth: 600, margin: 'auto', padding: 4, textAlign: 'center' }}
    >
      <Typography variant="h4" gutterBottom>
        Account Deletion Pending
      </Typography>
      <Typography variant="body1" paragraph>
        Your account deletion request has been received. For your safety and to
        prevent accidental data loss, your account and all associated data will
        be permanently deleted after 7 days.
      </Typography>
      <Typography variant="body1" paragraph>
        This waiting period is in place to protect your data and give you a
        chance to reconsider or recover your account if needed.
      </Typography>
      <Typography variant="body1" paragraph>
        If you wish to cancel the deletion request and restore your account, or
        if you want to request immediate deletion, please contact our support
        team at:
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 2
        }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<Email />}
          href="mailto:info@wyecare.com"
        >
          info@wyecare.com
        </Button>

        <Button
          variant="outlined"
          color="error"
          sx={{ marginTop: 2 }}
          onClick={() => {
            localStorage.removeItem('token');
            window.location.href = '/login';
          }}
        >
          Logout
        </Button>
      </Box>
    </Box>
  );
};

export default AccountDeletionPending;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PaymentState {
  isOpen: boolean;
  message?: string;
}

const initialState: PaymentState = {
  isOpen: false,
  message:
    'Subscribe to access our top features and take your experience to the next level!'
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    openPaymentDialog: (state, action: PayloadAction<string>) => {
      state.isOpen = true;
      state.message = action.payload;
    },
    closePaymentDialog: (state) => {
      state.isOpen = false;
      state.message = '';
    }
  }
});

export const { openPaymentDialog, closePaymentDialog } = paymentSlice.actions;

export default paymentSlice.reducer;

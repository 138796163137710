import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { IUserShiftTypeModel } from 'src/interfaces/shift-type.interface';
import { shiftsApi } from '../@api/shift';


export interface IRate {
  careHomeId: string;
  weekdayRate: number;
  weekendRate: number;
}

export interface IShiftType {
  name: string;
  startTime: string;
  endTime: string;
  rates: IRate[];
  _id: string;
}

export interface IHomeShiftType {
  userId: string;
  shiftTypes: IShiftType[];
  _id: string;
}

export interface IAgencyShiftType {
  userId: string;
  shiftTypes: IShiftType[];
  _id: string;
}

interface ShiftTypesState {
  agencyShiftTypes: IAgencyShiftType[];
  homeShiftTypes: IHomeShiftType[];
  _id: string;
}

const initialState = {
  agencyShiftType: {
    userId: '',
    shiftTypes: [],
    _id: ''
  },
  homeShiftType: {
    userId: '',
    shiftTypes: [],
    _id: ''
  },
  _id: null
};

const shiftPatternSlice = createSlice({
  name: 'shiftTypes',
  initialState,
  reducers: {
    _setAgencyShiftTypes: (state, action: PayloadAction<any>) => {
      state.agencyShiftType = action.payload;
    },
    _setHomeShiftTypes: (state, action: PayloadAction<any>) => {
      state.homeShiftType = action.payload;
    }

    // Add more reducers as needed
  },
  extraReducers: (builder) => {
    // Add extra reducers as needed
    builder.addMatcher(
      shiftsApi.endpoints.getAgencyShiftTypes.matchFulfilled,
      (state, { payload }) => {
        state.homeShiftType = payload as any;
      }
    );
    builder.addMatcher(
      shiftsApi.endpoints.getHomeShiftTypes.matchFulfilled,
      (state, { payload }) => {
        console.log(payload, 'thyli');
        state.agencyShiftType = payload as any;
      }
    );
  }
});

export const { _setAgencyShiftTypes, _setHomeShiftTypes } =
  shiftPatternSlice.actions;

export default shiftPatternSlice.reducer;

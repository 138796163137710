import React, { useState, useEffect, useCallback } from 'react';
import { Box, CircularProgress, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import TaskGrid from './task-grid';
import { useGetHistoricTasksQuery } from 'src/redux/@api/resident';
import dayjs from 'dayjs';

interface HistoricalTasksTabProps {
  residentId: string;
}

const HistoricalTasksTab: React.FC<HistoricalTasksTabProps> = ({ residentId }) => {
  const [startDate, setStartDate] = useState<Date>(dayjs().startOf('month').toDate());
  const [endDate, setEndDate] = useState<Date>(dayjs().endOf('month').toDate());
  const [page, setPage] = useState(1);
  const [historicalTasks, setHistoricalTasks] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const { data: historicTasksData, refetch: refetchHistoricTasks } = useGetHistoricTasksQuery(
    {
      residentId,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      page,
      limit: 20
    },
    {
      refetchOnMountOrArgChange: true
    }
  );

  useEffect(() => {
    if (historicTasksData) {
      if (page === 1) {
        setHistoricalTasks(historicTasksData.tasks);
      } else {
        setHistoricalTasks(prevTasks => {
          const newTasks = historicTasksData.tasks.filter(
            newTask => !prevTasks.some(prevTask => prevTask._id === newTask._id)
          );
          return [...prevTasks, ...newTasks];
        });
      }
      setHasMore(historicTasksData.currentPage < historicTasksData.totalPages);
    }
  }, [historicTasksData, page]);

  useEffect(() => {
    setPage(1);
    setHasMore(true);
    setHistoricalTasks([]);
    refetchHistoricTasks();
  }, [startDate, endDate, refetchHistoricTasks]);

  const loadMoreHistoricTasks = useCallback(() => {
    if (hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  }, [hasMore]);

  return (
    <Box>
      <Box sx={{ mb: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(newValue) => newValue && setStartDate(newValue)}
          renderInput={(params) => <TextField size="small" {...params} />}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(newValue) => newValue && setEndDate(newValue)}
          renderInput={(params) => <TextField size="small" {...params} />}
        />
      </Box>
      <TaskGrid tasks={historicalTasks} isHistorical onTaskClick={() => {}} />
      {hasMore && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default HistoricalTasksTab;
import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Box,
    Typography
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import LockResetIcon from '@mui/icons-material/LockReset';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { useRequestPasswordResetMutation, useResetPasswordMutation } from 'src/redux/@api/auth';

// Styled components
const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(3),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const IconWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
}));

// Types
interface RequestResetDialogProps {
    open: boolean;
    onClose: () => void;
    onRequestSuccess: () => void;
}

interface ResetPasswordDialogProps {
    open: boolean;
    onClose: () => void;
    onResetSuccess: () => void;
}

// Components
export const RequestResetDialog: React.FC<RequestResetDialogProps> = ({ open, onClose, onRequestSuccess }) => {
    const [email, setEmail] = useState('');
    const [requestReset, { isLoading: requestResetLoading }] = useRequestPasswordResetMutation();
    const dispatch = useDispatch();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await requestReset(email).unwrap();
            dispatch(showSnack({ message: 'Reset code sent to your email!', color: 'success' }));
            onRequestSuccess();
        } catch (error) {
            dispatch(showSnack({ message: (error as Error).message || 'Failed to send reset code', color: 'error' }));
        }
    };

    return (
        <StyledDialog open={open} onClose={onClose}>
            <DialogTitle>Reset Password</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <IconWrapper>
                        <LockResetIcon color="primary" style={{ fontSize: 48 }} />
                    </IconWrapper>
                    <DialogContentText>
                        Enter your email address and we'll send you a code to reset your password.
                    </DialogContentText>
                    <StyledTextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <LoadingButton
                        loading={requestResetLoading}
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Send Reset Code
                    </LoadingButton>
                </DialogActions>
            </form>
        </StyledDialog>
    );
};

export const ResetPasswordDialog: React.FC<ResetPasswordDialogProps> = ({ open, onClose, onResetSuccess }) => {
    const [email, setEmail] = useState('');
    const [resetCode, setResetCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [resetPassword, { isLoading: resetPasswordLoading }] = useResetPasswordMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            dispatch(showSnack({ message: 'Passwords do not match', color: 'error' }));
            return;
        }
        try {
            await resetPassword({ email, code: resetCode, newPassword: password }).unwrap();
            dispatch(showSnack({ message: 'Your password has been changed', color: 'success' }));
            onResetSuccess();
        } catch (error) {
            dispatch(showSnack({ message: (error as Error).message || 'Error updating the password', color: 'error' }));
        }
    };

    return (
        <StyledDialog open={open} onClose={onClose}>
            <DialogTitle>Set New Password</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <IconWrapper>
                        <LockResetIcon color="primary" style={{ fontSize: 48 }} />
                    </IconWrapper>
                    <DialogContentText>
                        Enter the reset code you received and your new password.
                    </DialogContentText>
                    <StyledTextField
                        margin="dense"
                        id="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <StyledTextField
                        margin="dense"
                        id="resetCode"
                        label="Reset Code"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={resetCode}
                        onChange={(e) => setResetCode(e.target.value)}
                        required
                    />
                    <StyledTextField
                        margin="dense"
                        id="password"
                        label="New Password"
                        type="password"
                        fullWidth
                        variant="outlined"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <StyledTextField
                        margin="dense"
                        id="confirmPassword"
                        label="Confirm New Password"
                        type="password"
                        fullWidth
                        variant="outlined"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => navigate('/login')}>Cancel</Button>
                    <LoadingButton
                        loading={resetPasswordLoading}
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Reset Password
                    </LoadingButton>
                </DialogActions>
            </form>
        </StyledDialog>
    );
};

export const PasswordResetFlow: React.FC = () => {
    const [openRequestDialog, setOpenRequestDialog] = useState(false);
    const [openResetDialog, setOpenResetDialog] = useState(false);

    const handleRequestSuccess = () => {
        setOpenRequestDialog(false);
        setOpenResetDialog(true);
    };

    const handleResetSuccess = () => {
        setOpenResetDialog(false);
        // You might want to navigate to login page or show a success message
    };

    return (
        <div>
            <Button onClick={() => setOpenRequestDialog(true)}>Forgot Password?</Button>
            <RequestResetDialog 
                open={openRequestDialog} 
                onClose={() => setOpenRequestDialog(false)}
                onRequestSuccess={handleRequestSuccess}
            />
            <ResetPasswordDialog 
                open={openResetDialog}
                onClose={() => setOpenResetDialog(false)}
                onResetSuccess={handleResetSuccess}
            />
        </div>
    );
};
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  IconButton,
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  ExpandMore,
  ExpandLess
} from '@mui/icons-material';
import moment from 'moment';

import { useAppSelector } from 'src/redux/hook';
import CalendarDay from './calendar-views/day';
import AddShiftDialog from './calendar-views/add-shift';
import ViewShiftDialog from './calendar-views/shift-detail';
import EditShiftDialog from './calendar-views/edit-shift';
import { CalendarContainer } from './calendar-views/styles';
import type { Shift } from './calendar-views/types';
import {
  useGetAgencyShiftsQuery,
  useGetPublishedShiftsQuery
} from 'src/redux/@api/shift';
import IntegratedShiftDialog from './calendar-views/integrated-shift-add';
interface ShiftCalendarProps {
  onMonthChange: (month: number, year: number) => void;
}
const ModernShiftCalendar: React.FC<ShiftCalendarProps> = ({
  onMonthChange
}) => {
  const [currentDate, setCurrentDate] = useState<moment.Moment>(moment());
  const [shifts, setShifts] = useState<any>({});
  const [showFullCalendar, setShowFullCalendar] = useState(false);
  const [isAddShiftOpen, setIsAddShiftOpen] = useState(false);
  const [isViewShiftOpen, setIsViewShiftOpen] = useState(false);
  const [isEditShiftOpen, setIsEditShiftOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [selectedShifts, setSelectedShifts] = useState<Shift[]>([]);
  const [shiftToEdit, setShiftToEdit] = useState<Shift | null>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const userState = useAppSelector((state) => state.userState);

  const {
    data: publishedShifts,
    isLoading: publishedLoading,
    isError: publishedError
  } = useGetPublishedShiftsQuery(currentDate.month() + 1, {
    skip: userState.currentOrganization?.type !== 'home',
    refetchOnMountOrArgChange: true
  });

  const {
    data: agencyShifts,
    isLoading: agencyLoading,
    isError: agencyError
  } = useGetAgencyShiftsQuery(undefined, {
    skip: userState.currentOrganization?.type !== 'agency'
  });

  useEffect(() => {
    // Call onMonthChange when the component mounts
    onMonthChange(currentDate.month() + 1, currentDate.year());
  }, []);

  useEffect(() => {
    if (userState.currentOrganization?.type === 'home' && publishedShifts) {
      const groupedShifts = groupShiftsByDate(publishedShifts);
      setShifts(groupedShifts);
    } else if (
      userState.currentOrganization?.type === 'agency' &&
      agencyShifts
    ) {
      const groupedShifts = groupShiftsByDate(agencyShifts);
      setShifts(groupedShifts);
    }
  }, [userState, publishedShifts, agencyShifts]);

  const groupShiftsByDate = (shiftsData: Shift[]): any => {
    return shiftsData.reduce((acc, shift) => {
      const dateKey = shift.date;
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(shift);
      return acc;
    }, {} as any);
  };

  const handlePrevMonth = (): void => {
    const newDate = moment(currentDate).subtract(1, 'month');
    setCurrentDate(newDate);
    onMonthChange(newDate.month() + 1, newDate.year());
  };

  const handleNextMonth = (): void => {
    const newDate = moment(currentDate).add(1, 'month');
    setCurrentDate(newDate);
    onMonthChange(newDate.month() + 1, newDate.year());
  };

  const handleDayClick = (day: moment.Moment, dayShifts: Shift[]) => {
    const clickedDate = day.clone();
    setSelectedShifts(dayShifts);

    if (dayShifts.length > 0) {
      setIsViewShiftOpen(true);
    } else if (userState.currentOrganization?.type === 'home') {
      setIsAddShiftOpen(true);
    }
  };

  const handleEditShift = (shift: Shift) => {
    setShiftToEdit(shift);
    setIsEditShiftOpen(true);
  };

  const handleUpdateShift = (updatedShift: Shift) => {
    setShifts((prevShifts) => {
      const updatedShifts = { ...prevShifts };
      const dateKey = updatedShift.date;
      updatedShifts[dateKey] = updatedShifts[dateKey].map((shift) =>
        shift._id === updatedShift._id ? updatedShift : shift
      );
      return updatedShifts;
    });
    setSelectedShifts((prevShifts) =>
      prevShifts.map((shift) =>
        shift._id === updatedShift._id ? updatedShift : shift
      )
    );
  };

  const renderCalendarDays = (): JSX.Element[] => {
    const days: JSX.Element[] = [];
    const startDate = moment(currentDate).startOf('month').startOf('week');
    const endDate = moment(currentDate).endOf('month').endOf('week');

    let day = startDate.clone();
    while (day.isSameOrBefore(endDate)) {
      const dateKey = day.format('YYYY-MM-DD');
      const isCurrentMonth = day.month() === currentDate.month();
      const dayShifts = shifts[dateKey] || [];

      days.push(
        <CalendarDay
          key={day.format('YYYY-MM-DD')}
          day={day.clone()}
          isCurrentMonth={isCurrentMonth}
          shifts={dayShifts}
          setSelectedDate={setSelectedDate}
          onClick={() => {
            handleDayClick(day.clone(), dayShifts);
          }}
          isMobile={isMobile}
        />
      );
      day.add(1, 'day');
    }
    return days;
  };

  if (publishedLoading || agencyLoading) {
    return <Typography>Loading shifts...</Typography>;
  }

  if (publishedError || agencyError) {
    return (
      <Typography>Error loading shifts. Please try again later.</Typography>
    );
  }

  return (
    <CalendarContainer>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h5">{currentDate.format('MMMM YYYY')}</Typography>
        <Box>
          <IconButton onClick={handlePrevMonth}>
            <ChevronLeft />
          </IconButton>
          <IconButton onClick={handleNextMonth}>
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>
      <Grid container spacing={1}>
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
          <Grid item xs={12 / 7} key={day}>
            <Typography variant="subtitle1" align="center">
              {day}
            </Typography>
          </Grid>
        ))}
        {isMobile && !showFullCalendar
          ? renderCalendarDays().slice(0, 7)
          : renderCalendarDays()}
      </Grid>
      {isMobile && (
        <Box
          onClick={() => setShowFullCalendar(!showFullCalendar)}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 2,
            cursor: 'pointer'
          }}
        >
          <Typography variant="button" sx={{ mr: 1 }}>
            {showFullCalendar ? 'Show Current Week' : 'View Full Month'}
          </Typography>
          {showFullCalendar ? <ExpandLess /> : <ExpandMore />}
        </Box>
      )}

      <IntegratedShiftDialog
        open={isAddShiftOpen}
        onClose={() => setIsAddShiftOpen(false)}
        selectedDate={selectedDate}
        onAddShift={(newShifts) => {
          setShifts((prevShifts) => {
            const updatedShifts = { ...prevShifts };
            newShifts.forEach((shift) => {
              const dateKey = moment(shift.date).format('YYYY-MM-DD');
              if (!updatedShifts[dateKey]) {
                updatedShifts[dateKey] = [];
              }
              updatedShifts[dateKey].push(shift);
            });
            return updatedShifts;
          });
          setIsAddShiftOpen(false);
        }}
      />

      <ViewShiftDialog
        open={isViewShiftOpen}
        onClose={() => setIsViewShiftOpen(false)}
        selectedDate={selectedDate}
        shifts={selectedShifts}
        onShiftsUpdate={(updatedShifts) => {
          if (selectedDate) {
            setShifts((prevShifts) => ({
              ...prevShifts,
              [selectedDate.format('YYYY-MM-DD')]: updatedShifts
            }));
            setSelectedShifts(updatedShifts);
          }
        }}
        onEditClick={handleEditShift}
        onAddFurther={() => {
          setIsAddShiftOpen(true);
          setIsViewShiftOpen(false);
        }}
      />

      <EditShiftDialog
        open={isEditShiftOpen}
        onClose={() => setIsEditShiftOpen(false)}
        shift={shiftToEdit}
        onEditShift={handleUpdateShift}
      />
    </CalendarContainer>
  );
};

export default ModernShiftCalendar;
